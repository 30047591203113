(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.hM.ds === region.i3.ds)
	{
		return 'on line ' + region.hM.ds;
	}
	return 'on lines ' + region.hM.ds + ' through ' + region.i3.ds;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.m6,
		impl.n8,
		impl.nV,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bl: func(record.bl),
		hP: record.hP,
		hs: record.hs
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bl;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.hP;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.hs) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.m6,
		impl.n8,
		impl.nV,
		function(sendToApp, initialModel) {
			var view = impl.ob;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.m6,
		impl.n8,
		impl.nV,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.hD && impl.hD(sendToApp)
			var view = impl.ob;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.$9);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.lu) && (_VirtualDom_doc.title = title = doc.lu);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.nv;
	var onUrlRequest = impl.nw;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		hD: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.kR === next.kR
							&& curr.jy === next.jy
							&& curr.kJ.a === next.kJ.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		m6: function(flags)
		{
			return A3(impl.m6, flags, _Browser_getUrl(), key);
		},
		ob: impl.ob,
		n8: impl.n8,
		nV: impl.nV
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { m_: 'hidden', mj: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { m_: 'mozHidden', mj: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { m_: 'msHidden', mj: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { m_: 'webkitHidden', mj: 'webkitvisibilitychange' }
		: { m_: 'hidden', mj: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		lf: _Browser_getScene(),
		lW: {
			l_: _Browser_window.pageXOffset,
			l2: _Browser_window.pageYOffset,
			lZ: _Browser_doc.documentElement.clientWidth,
			jx: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		lZ: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		jx: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			lf: {
				lZ: node.scrollWidth,
				jx: node.scrollHeight
			},
			lW: {
				l_: node.scrollLeft,
				l2: node.scrollTop,
				lZ: node.clientWidth,
				jx: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			lf: _Browser_getScene(),
			lW: {
				l_: x,
				l2: y,
				lZ: _Browser_doc.documentElement.clientWidth,
				jx: _Browser_doc.documentElement.clientHeight
			},
			mG: {
				l_: x + rect.left,
				l2: y + rect.top,
				lZ: rect.width,
				jx: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F2(function(request, maybeProgress)
{
	return _Scheduler_binding(function(callback)
	{
		var xhr = new XMLHttpRequest();

		_Http_configureProgress(xhr, maybeProgress);

		xhr.addEventListener('error', function() {
			callback(_Scheduler_fail($elm$http$Http$NetworkError));
		});
		xhr.addEventListener('timeout', function() {
			callback(_Scheduler_fail($elm$http$Http$Timeout));
		});
		xhr.addEventListener('load', function() {
			callback(_Http_handleResponse(xhr, request.mJ.a));
		});

		try
		{
			xhr.open(request.nj, request.n9, true);
		}
		catch (e)
		{
			return callback(_Scheduler_fail($elm$http$Http$BadUrl(request.n9)));
		}

		_Http_configureRequest(xhr, request);

		var body = request.$9;
		xhr.send($elm$http$Http$Internal$isStringBody(body)
			? (xhr.setRequestHeader('Content-Type', body.a), body.b)
			: body.a
		);

		return function() { xhr.abort(); };
	});
});

function _Http_configureProgress(xhr, maybeProgress)
{
	if (!$elm$core$Maybe$isJust(maybeProgress))
	{
		return;
	}

	xhr.addEventListener('progress', function(event) {
		if (!event.lengthComputable)
		{
			return;
		}
		_Scheduler_rawSpawn(maybeProgress.a({
			mf: event.loaded,
			mg: event.total
		}));
	});
}

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.mY; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}

	xhr.responseType = request.mJ.b;
	xhr.withCredentials = request.oe;

	$elm$core$Maybe$isJust(request.n$) && (xhr.timeout = request.n$.a);
}


// RESPONSES

function _Http_handleResponse(xhr, responseToResult)
{
	var response = _Http_toResponse(xhr);

	if (xhr.status < 200 || 300 <= xhr.status)
	{
		response.body = xhr.responseText;
		return _Scheduler_fail($elm$http$Http$BadStatus(response));
	}

	var result = responseToResult(response);

	if ($elm$core$Result$isOk(result))
	{
		return _Scheduler_succeed(result.a);
	}
	else
	{
		response.body = xhr.responseText;
		return _Scheduler_fail(A2($elm$http$Http$BadPayload, result.a, response));
	}
}

function _Http_toResponse(xhr)
{
	return {
		n9: xhr.responseURL,
		nR: { ml: xhr.status, bl: xhr.statusText },
		mY: _Http_parseHeaders(xhr.getAllResponseHeaders()),
		$9: xhr.response
	};
}

function _Http_parseHeaders(rawHeaders)
{
	var headers = $elm$core$Dict$empty;

	if (!rawHeaders)
	{
		return headers;
	}

	var headerPairs = rawHeaders.split('\u000d\u000a');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf('\u003a\u0020');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}

	return headers;
}


// EXPECTORS

function _Http_expectStringResponse(responseToResult)
{
	return {
		$: 0,
		b: 'text',
		a: responseToResult
	};
}

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		b: expect.b,
		a: function(response) {
			var convertedResponse = expect.a(response);
			return A2($elm$core$Result$map, func, convertedResponse);
		}
	};
});


// BODY

function _Http_multipart(parts)
{


	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}

	return $elm$http$Http$Internal$FormDataBody(formData);
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}



// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.np) { flags += 'm'; }
	if (options.mh) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });
var $author$project$Main$ChangedUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$ClickedLink = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.s) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.v),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.v);
		} else {
			var treeLen = builder.s * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.w) : builder.w;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.s);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.v) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.v);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{w: nodeList, s: (len / $elm$core$Array$branchFactor) | 0, v: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {jt: fragment, jy: host, kC: path, kJ: port_, kR: protocol, kT: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$Redirect = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Demo = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotDemoMsg = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotLinkBuilderMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotTransactionsMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$LinkBuilder = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$NotFound = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Transactions = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Demo$GenerateCommittee = 0;
var $author$project$Page$Demo$GotTransactionsData = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$Internal$StringBody = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		$elm$http$Http$Internal$StringBody,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Api$GraphQL$encodeQuery = F2(
	function (query, variables) {
		return $elm$http$Http$jsonBody(
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'query',
						$elm$json$Json$Encode$string(query)),
						_Utils_Tuple2('variables', variables)
					])));
	});
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$Api$GetTxns$committeeQuery = '\n    query CommitteeQuery($committeeId: String!, $transactionType: String, $take: Float, $fromId: String) {\n            aggregations(committeeId: $committeeId) {\n              balance\n              totalSpent\n              totalRaised\n              totalDonors\n              needsReviewCount\n              totalTransactions\n              totalContributionsInProcessing\n              totalDisbursementsInProcessing\n            }\n            committee(committeeId: $committeeId) {\n              candidateLastName\n              officeType\n              bankName\n              id\n              platformPlan\n            }\n    ';
var $author$project$Api$GetTxns$query = $author$project$Api$GetTxns$committeeQuery + '\n        transactions(committeeId: $committeeId, transactionType: $transactionType, take: $take, fromId: $fromId, order: Desc) {\n          id\n          committeeId\n          direction\n          amount\n          paymentMethod\n          bankVerified\n          ruleVerified\n          initiatedTimestamp\n          source\n          paymentDate\n          purposeCode\n          refCode\n          firstName\n          middleName\n          lastName\n          addressLine1\n          addressLine2\n          entityName\n          city\n          state\n          postalCode\n          employer\n          occupation\n          entityType\n          companyName\n          phoneNumber\n          emailAddress\n          transactionType\n          attestsToBeingAnAdultCitizen\n          stripePaymentIntentId\n          cardNumberLastFourDigits\n          finicityCategory\n          finicityBestRepresentation\n          finicityPostedDate\n          finicityTransactionDate\n          finicityNormalizedPayeeName\n          finicityDescription\n          finicityPaymentMethod\n          donorVerificationScore\n        }\n      }\n    ';
var $author$project$TransactionType$toString = function (txnType) {
	switch (txnType) {
		case 0:
			return 'Contribution';
		case 1:
			return 'Disbursement';
		default:
			return 'Deposit';
	}
};
var $author$project$Api$GetTxns$encode = F4(
	function (committeeId, maybeTxnType, maybeTake, maybeFromId) {
		var txnTypeFilter = function () {
			if (!maybeTxnType.$) {
				var txnType = maybeTxnType.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'transactionType',
						$elm$json$Json$Encode$string(
							$author$project$TransactionType$toString(txnType)))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var takeFilter = function () {
			if (!maybeTake.$) {
				var take = maybeTake.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'take',
						$elm$json$Json$Encode$int(take))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var fromIdFilter = function () {
			if (!maybeFromId.$) {
				var fromId = maybeFromId.a;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						'fromId',
						$elm$json$Json$Encode$string(fromId))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var variables = $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'committeeId',
						$elm$json$Json$Encode$string(committeeId))
					]),
				_Utils_ap(
					txnTypeFilter,
					_Utils_ap(takeFilter, fromIdFilter))));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$GetTxns$query, variables);
	});
var $author$project$Api$GetTxns$Model = function (data) {
	return {iJ: data};
};
var $author$project$Api$GetTxns$TransactionsObject = F3(
	function (transactions, aggregations, committee) {
		return {ic: aggregations, iD: committee, lI: transactions};
	});
var $author$project$Aggregations$Model = F8(
	function (balance, totalRaised, totalSpent, totalDonors, totalTransactions, totalContributionsInProcessing, totalDisbursementsInProcessing, needsReviewCount) {
		return {f8: balance, ki: needsReviewCount, hW: totalContributionsInProcessing, hX: totalDisbursementsInProcessing, hY: totalDonors, hZ: totalRaised, h_: totalSpent, lB: totalTransactions};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Aggregations$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'needsReviewCount',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'totalDisbursementsInProcessing',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'totalContributionsInProcessing',
			$elm$json$Json$Decode$int,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'totalTransactions',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'totalDonors',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'totalSpent',
						$elm$json$Json$Decode$int,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'totalRaised',
							$elm$json$Json$Decode$int,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'balance',
								$elm$json$Json$Decode$int,
								$elm$json$Json$Decode$succeed($author$project$Aggregations$Model)))))))));
var $author$project$Committee$Model = F5(
	function (candidateLastName, bankName, officeType, id, platformPlan) {
		return {ij: bankName, ip: candidateLastName, jB: id, kn: officeType, hp: platformPlan};
	});
var $author$project$PlatformPlan$FourUs = 1;
var $author$project$PlatformPlan$Policapital = 0;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$PlatformPlan$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Policapital':
				return $elm$json$Json$Decode$succeed(0);
			case 'FourUs':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Unknown committee plan');
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Committee$decoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'platformPlan',
	$author$project$PlatformPlan$decoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'officeType',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'bankName',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'candidateLastName',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Committee$Model))))));
var $author$project$Transaction$Model = function (id) {
	return function (committeeId) {
		return function (direction) {
			return function (amount) {
				return function (paymentMethod) {
					return function (bankVerified) {
						return function (ruleVerified) {
							return function (initiatedTimestamp) {
								return function (source) {
									return function (paymentDate) {
										return function (bankVerifiedTimestamp) {
											return function (ruleVerifiedTimestamp) {
												return function (purposeCode) {
													return function (refCode) {
														return function (firstName) {
															return function (middleName) {
																return function (lastName) {
																	return function (addressLine1) {
																		return function (addressLine2) {
																			return function (city) {
																				return function (state) {
																					return function (postalCode) {
																						return function (employer) {
																							return function (employmentStatus) {
																								return function (occupation) {
																									return function (entityType) {
																										return function (companyName) {
																											return function (phoneNumber) {
																												return function (emailAddress) {
																													return function (transactionType) {
																														return function (attestsToBeingAnAdultCitizen) {
																															return function (stripePaymentIntentId) {
																																return function (cardNumberLastFourDigits) {
																																	return function (checkNumber) {
																																		return function (entityName) {
																																			return function (owners) {
																																				return function (isSubcontracted) {
																																					return function (isPartialPayment) {
																																						return function (isExistingLiability) {
																																							return function (finicityCategory) {
																																								return function (finicityBestRepresentation) {
																																									return function (finicityPostedDate) {
																																										return function (finicityTransactionDate) {
																																											return function (finicityNormalizedPayeeName) {
																																												return function (finicityDescription) {
																																													return function (inKindDescription) {
																																														return function (inKindType) {
																																															return function (finicityPaymentMethod) {
																																																return function (donorVerificationScore) {
																																																	return {h9: addressLine1, ia: addressLine2, ig: amount, ih: attestsToBeingAnAdultCitizen, ga: bankVerified, il: bankVerifiedTimestamp, is: cardNumberLastFourDigits, iu: checkNumber, iw: city, iE: committeeId, iF: companyName, iP: direction, mD: donorVerificationScore, i0: emailAddress, i1: employer, i2: employmentStatus, i5: entityName, i6: entityType, jf: finicityBestRepresentation, mQ: finicityCategory, mR: finicityDescription, mS: finicityNormalizedPayeeName, jg: finicityPaymentMethod, mT: finicityPostedDate, mU: finicityTransactionDate, jh: firstName, jB: id, m3: inKindDescription, m4: inKindType, jH: initiatedTimestamp, m8: isExistingLiability, na: isPartialPayment, nb: isSubcontracted, j_: lastName, nk: middleName, g7: occupation, ku: owners, kE: paymentDate, kF: paymentMethod, kH: phoneNumber, kM: postalCode, nE: purposeCode, fz: refCode, lb: ruleVerified, lc: ruleVerifiedTimestamp, nQ: source, ln: state, nT: stripePaymentIntentId, lH: transactionType};
																																																};
																																															};
																																														};
																																													};
																																												};
																																											};
																																										};
																																									};
																																								};
																																							};
																																						};
																																					};
																																				};
																																			};
																																		};
																																	};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Direction$In = 0;
var $author$project$Direction$Out = 1;
var $author$project$Direction$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'In':
				return $elm$json$Json$Decode$succeed(0);
			case 'Out':
				return $elm$json$Json$Decode$succeed(1);
			default:
				var badVal = str;
				return $elm$json$Json$Decode$fail('Unknown direction: ' + badVal);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$PaymentMethod$Ach = 1;
var $author$project$PaymentMethod$Cash = 0;
var $author$project$PaymentMethod$Check = 3;
var $author$project$PaymentMethod$Credit = 4;
var $author$project$PaymentMethod$Debit = 6;
var $author$project$PaymentMethod$InKind = 5;
var $author$project$PaymentMethod$OnlineProcessor = 8;
var $author$project$PaymentMethod$Other = 9;
var $author$project$PaymentMethod$Transfer = 7;
var $author$project$PaymentMethod$Wire = 2;
var $author$project$PaymentMethod$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'Cash':
				return $elm$json$Json$Decode$succeed(0);
			case 'Ach':
				return $elm$json$Json$Decode$succeed(1);
			case 'Wire':
				return $elm$json$Json$Decode$succeed(2);
			case 'Check':
				return $elm$json$Json$Decode$succeed(3);
			case 'Credit':
				return $elm$json$Json$Decode$succeed(4);
			case 'Debit':
				return $elm$json$Json$Decode$succeed(6);
			case 'InKind':
				return $elm$json$Json$Decode$succeed(5);
			case 'Transfer':
				return $elm$json$Json$Decode$succeed(7);
			case 'OnlineProcessor':
				return $elm$json$Json$Decode$succeed(8);
			case 'Other':
				return $elm$json$Json$Decode$succeed(9);
			default:
				var badVal = str;
				return $elm$json$Json$Decode$fail('Unknown payment method: ' + badVal);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$PaymentSource$ActBlue = 0;
var $author$project$PaymentSource$Dashboard = 2;
var $author$project$PaymentSource$DonateForm = 3;
var $author$project$PaymentSource$Finicity = 4;
var $author$project$PaymentSource$Other = 5;
var $author$project$PaymentSource$Stripe = 1;
var $author$project$PaymentSource$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		switch (str) {
			case 'ActBlue':
				return $elm$json$Json$Decode$succeed(0);
			case 'Stripe':
				return $elm$json$Json$Decode$succeed(1);
			case 'dashboard':
				return $elm$json$Json$Decode$succeed(2);
			case 'donate_form':
				return $elm$json$Json$Decode$succeed(3);
			case 'finicity':
				return $elm$json$Json$Decode$succeed(4);
			case 'Other':
				return $elm$json$Json$Decode$succeed(5);
			default:
				var badValue = str;
				return $elm$json$Json$Decode$fail('Unknown Source:' + badValue);
		}
	},
	$elm$json$Json$Decode$string);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $author$project$Transaction$maybeBool = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing);
};
var $author$project$EmploymentStatus$Employed = 0;
var $author$project$EmploymentStatus$Retired = 2;
var $author$project$EmploymentStatus$SelfEmployed = 1;
var $author$project$EmploymentStatus$Unemployed = 3;
var $author$project$EmploymentStatus$fromString = function (str) {
	switch (str) {
		case 'Employed':
			return $elm$core$Maybe$Just(0);
		case 'SelfEmployed':
			return $elm$core$Maybe$Just(1);
		case 'Retired':
			return $elm$core$Maybe$Just(2);
		case 'Unemployed':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Transaction$maybeEmploymentStatus = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $author$project$EmploymentStatus$fromString, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $author$project$EntityType$Association = 7;
var $author$project$EntityType$Candidate = 5;
var $author$project$EntityType$Corporation = 4;
var $author$project$EntityType$Family = 0;
var $author$project$EntityType$Individual = 1;
var $author$project$EntityType$LimitedLiabilityCompany = 8;
var $author$project$EntityType$Other = 11;
var $author$project$EntityType$PartnershipIncludingLLPs = 3;
var $author$project$EntityType$PoliticalActionCommittee = 9;
var $author$project$EntityType$PoliticalCommittee = 10;
var $author$project$EntityType$SoleProprietorship = 2;
var $author$project$EntityType$Union = 6;
var $author$project$EntityType$fromString = function (str) {
	switch (str) {
		case 'Fam':
			return $elm$core$Maybe$Just(0);
		case 'Ind':
			return $elm$core$Maybe$Just(1);
		case 'Solep':
			return $elm$core$Maybe$Just(2);
		case 'Part':
			return $elm$core$Maybe$Just(3);
		case 'Can':
			return $elm$core$Maybe$Just(5);
		case 'Corp':
			return $elm$core$Maybe$Just(4);
		case 'Union':
			return $elm$core$Maybe$Just(6);
		case 'Assoc':
			return $elm$core$Maybe$Just(7);
		case 'Llc':
			return $elm$core$Maybe$Just(8);
		case 'Pac':
			return $elm$core$Maybe$Just(9);
		case 'Plc':
			return $elm$core$Maybe$Just(10);
		case 'Oth':
			return $elm$core$Maybe$Just(11);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Transaction$maybeEntityType = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $author$project$EntityType$fromString, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $author$project$InKindType$CampaignExpensesPaid = 1;
var $author$project$InKindType$PropertyGiven = 2;
var $author$project$InKindType$ServicesFacilitiesProvided = 0;
var $author$project$InKindType$fromDataString = function (str) {
	switch (str) {
		case 'ServicesFacilitiesProvided':
			return $elm$core$Maybe$Just(0);
		case 'CampaignExpensesPaid':
			return $elm$core$Maybe$Just(1);
		case 'PropertyGiven':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Transaction$maybeInKindType = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $author$project$InKindType$fromDataString, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $author$project$Transaction$maybeInt = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$int),
		$elm$core$Maybe$Nothing);
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Owners$Owner = F8(
	function (firstName, lastName, addressLine1, addressLine2, city, state, postalCode, percentOwnership) {
		return {h9: addressLine1, ia: addressLine2, iw: city, jh: firstName, j_: lastName, dD: percentOwnership, kM: postalCode, ln: state};
	});
var $author$project$Owners$ownerDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'percentOwnership',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'postalCode',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'state',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'city',
				$elm$json$Json$Decode$string,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'addressLine2',
					$elm$json$Json$Decode$string,
					'',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'addressLine1',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'lastName',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'firstName',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Owners$Owner)))))))));
var $author$project$Owners$decoder = $elm$json$Json$Decode$list($author$project$Owners$ownerDecoder);
var $author$project$Transaction$maybeOwners = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $author$project$Owners$decoder),
		$elm$core$Maybe$Nothing);
};
var $author$project$PaymentMethod$fromString = function (str) {
	switch (str) {
		case 'Cash':
			return $elm$core$Maybe$Just(0);
		case 'Ach':
			return $elm$core$Maybe$Just(1);
		case 'Wire':
			return $elm$core$Maybe$Just(2);
		case 'Check':
			return $elm$core$Maybe$Just(3);
		case 'Credit':
			return $elm$core$Maybe$Just(4);
		case 'Debit':
			return $elm$core$Maybe$Just(6);
		case 'InKind':
			return $elm$core$Maybe$Just(5);
		case 'Transfer':
			return $elm$core$Maybe$Just(7);
		case 'OnlineProcessor':
			return $elm$core$Maybe$Just(8);
		case 'Other':
			return $elm$core$Maybe$Just(9);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Transaction$maybePaymentMethod = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $author$project$PaymentMethod$fromString, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$PurposeCode$BKFEE = 0;
var $author$project$PurposeCode$BKKP = 1;
var $author$project$PurposeCode$BLBD = 2;
var $author$project$PurposeCode$CAR = 3;
var $author$project$PurposeCode$CARSVC = 4;
var $author$project$PurposeCode$CCP = 5;
var $author$project$PurposeCode$CELL = 6;
var $author$project$PurposeCode$CMAIL = 7;
var $author$project$PurposeCode$CNTRB = 8;
var $author$project$PurposeCode$CONSL = 9;
var $author$project$PurposeCode$CONSV = 10;
var $author$project$PurposeCode$EADS = 11;
var $author$project$PurposeCode$EMAIL = 12;
var $author$project$PurposeCode$FUNDR = 13;
var $author$project$PurposeCode$GAS = 14;
var $author$project$PurposeCode$INT = 15;
var $author$project$PurposeCode$LITER = 16;
var $author$project$PurposeCode$LODG = 17;
var $author$project$PurposeCode$LWNSN = 18;
var $author$project$PurposeCode$MEALS = 19;
var $author$project$PurposeCode$MLGE = 20;
var $author$project$PurposeCode$MTG = 21;
var $author$project$PurposeCode$NPD = 22;
var $author$project$PurposeCode$OFFICE = 23;
var $author$project$PurposeCode$OTHER = 24;
var $author$project$PurposeCode$PARK = 25;
var $author$project$PurposeCode$PETIT = 26;
var $author$project$PurposeCode$PIDA = 27;
var $author$project$PurposeCode$POLLS = 28;
var $author$project$PurposeCode$POSTA = 29;
var $author$project$PurposeCode$PRINT = 30;
var $author$project$PurposeCode$PROFL = 31;
var $author$project$PurposeCode$RADIO = 32;
var $author$project$PurposeCode$REIMB = 33;
var $author$project$PurposeCode$RENTO = 34;
var $author$project$PurposeCode$TOLLS = 35;
var $author$project$PurposeCode$TVADS = 36;
var $author$project$PurposeCode$UTILS = 37;
var $author$project$PurposeCode$VOTER = 38;
var $author$project$PurposeCode$WAGE = 39;
var $author$project$PurposeCode$WAGES = 40;
var $author$project$PurposeCode$XPORT = 41;
var $author$project$PurposeCode$purposeCodeText = _List_fromArray(
	[
		_Utils_Tuple3(0, 'BKFEE', 'Bank Fees'),
		_Utils_Tuple3(2, 'BLBD', 'Billboard'),
		_Utils_Tuple3(1, 'BKKP', 'Bookkeeping'),
		_Utils_Tuple3(9, 'CONSL', 'Campaign Consultant'),
		_Utils_Tuple3(16, 'LITER', 'Campaign Literature'),
		_Utils_Tuple3(7, 'CMAIL', 'Campaign Mailings'),
		_Utils_Tuple3(40, 'WAGES', 'Campaign Salaries'),
		_Utils_Tuple3(3, 'CAR', 'Car Rental, Payment, Etc.'),
		_Utils_Tuple3(6, 'CELL', 'Cell Phone'),
		_Utils_Tuple3(10, 'CONSV', 'Constituent Services'),
		_Utils_Tuple3(5, 'CCP', 'Credit Card Payment'),
		_Utils_Tuple3(12, 'EMAIL', 'Email'),
		_Utils_Tuple3(13, 'FUNDR', 'Fundraising'),
		_Utils_Tuple3(14, 'EMAIL', 'Gas'),
		_Utils_Tuple3(15, 'INT', 'Interest Expense'),
		_Utils_Tuple3(18, 'LWNSN', 'Lawn Signs'),
		_Utils_Tuple3(17, 'LODG', 'Lodging'),
		_Utils_Tuple3(19, 'MEALS', 'Meals'),
		_Utils_Tuple3(21, 'MTG', 'Meeting'),
		_Utils_Tuple3(20, 'MLGE', 'Mileage'),
		_Utils_Tuple3(22, 'NPD', 'Non-Political Donations'),
		_Utils_Tuple3(23, 'OFFICE', 'Office Expenses'),
		_Utils_Tuple3(34, 'RENTO', 'Office Rent'),
		_Utils_Tuple3(11, 'EADS', 'Online Ads'),
		_Utils_Tuple3(24, 'OTHER', 'Other'),
		_Utils_Tuple3(27, 'PIDA', 'PIDA'),
		_Utils_Tuple3(25, 'PARK', 'Parking'),
		_Utils_Tuple3(26, 'PETIT', 'Petition Expenses'),
		_Utils_Tuple3(8, 'CNTRB', 'Political Contributions'),
		_Utils_Tuple3(28, 'POLLS', 'Polling Costs'),
		_Utils_Tuple3(29, 'POSTA', 'Postage'),
		_Utils_Tuple3(30, 'PRINT', 'Print Ads'),
		_Utils_Tuple3(31, 'PROFL', 'Professional Services'),
		_Utils_Tuple3(32, 'RADIO', 'Radio Ads'),
		_Utils_Tuple3(33, 'REIMB', 'Reimbursement'),
		_Utils_Tuple3(4, 'CARSVC', 'Taxi, Uber, Etc.'),
		_Utils_Tuple3(36, 'TVADS', 'Television Ads'),
		_Utils_Tuple3(35, 'TOLLS', 'Tolls'),
		_Utils_Tuple3(41, 'XPORT', 'Transportation'),
		_Utils_Tuple3(37, 'UTILS', 'Utilities'),
		_Utils_Tuple3(38, 'VOTER', 'Voter Reg. Material'),
		_Utils_Tuple3(39, 'WAGE', 'Wages')
	]);
var $author$project$PurposeCode$fromString = function (str) {
	var maybePurpose = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (_v2) {
				var p = _v2.b;
				return _Utils_eq(p, str);
			},
			$author$project$PurposeCode$purposeCodeText));
	if (!maybePurpose.$) {
		var _v1 = maybePurpose.a;
		var val = _v1.a;
		return $elm$core$Maybe$Just(val);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Transaction$maybePurposeCode = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $author$project$PurposeCode$fromString, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $author$project$Transaction$maybeString = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $author$project$TransactionType$Contribution = 0;
var $author$project$TransactionType$Deposit = 2;
var $author$project$TransactionType$Disbursement = 1;
var $author$project$TransactionType$fromString = function (str) {
	switch (str) {
		case 'Contribution':
			return $elm$core$Maybe$Just(0);
		case 'Disbursement':
			return $elm$core$Maybe$Just(1);
		case 'Deposit':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Transaction$maybeTransactionType = function (name) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		name,
		A2($elm$json$Json$Decode$map, $author$project$TransactionType$fromString, $elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing);
};
var $author$project$Transaction$decoder = A2(
	$author$project$Transaction$maybeInt,
	'donorVerificationScore',
	A2(
		$author$project$Transaction$maybePaymentMethod,
		'finicityPaymentMethod',
		A2(
			$author$project$Transaction$maybeInKindType,
			'inKindType',
			A2(
				$author$project$Transaction$maybeString,
				'inKindDescription',
				A2(
					$author$project$Transaction$maybeString,
					'finicityDescription',
					A2(
						$author$project$Transaction$maybeString,
						'finicityNormalizedPayeeName',
						A2(
							$author$project$Transaction$maybeInt,
							'finicityTransactionDate',
							A2(
								$author$project$Transaction$maybeInt,
								'finicityPostedDate',
								A2(
									$author$project$Transaction$maybeString,
									'finicityBestRepresentation',
									A2(
										$author$project$Transaction$maybeString,
										'finicityCategory',
										A2(
											$author$project$Transaction$maybeBool,
											'isExistingLiability',
											A2(
												$author$project$Transaction$maybeBool,
												'isPartialPayment',
												A2(
													$author$project$Transaction$maybeBool,
													'isSubcontracted',
													A2(
														$author$project$Transaction$maybeOwners,
														'owners',
														A2(
															$author$project$Transaction$maybeString,
															'entityName',
															A2(
																$author$project$Transaction$maybeString,
																'checkNumber',
																A2(
																	$author$project$Transaction$maybeString,
																	'cardNumberLastFourDigits',
																	A2(
																		$author$project$Transaction$maybeString,
																		'stripePaymentIntentId',
																		A2(
																			$author$project$Transaction$maybeBool,
																			'attestsToBeingAnAdultCitizen',
																			A2(
																				$author$project$Transaction$maybeTransactionType,
																				'transactionType',
																				A2(
																					$author$project$Transaction$maybeString,
																					'emailAddress',
																					A2(
																						$author$project$Transaction$maybeString,
																						'phoneNumber',
																						A2(
																							$author$project$Transaction$maybeString,
																							'companyName',
																							A2(
																								$author$project$Transaction$maybeEntityType,
																								'entityType',
																								A2(
																									$author$project$Transaction$maybeString,
																									'occupation',
																									A2(
																										$author$project$Transaction$maybeEmploymentStatus,
																										'employmentStatus',
																										A2(
																											$author$project$Transaction$maybeString,
																											'employer',
																											A2(
																												$author$project$Transaction$maybeString,
																												'postalCode',
																												A2(
																													$author$project$Transaction$maybeString,
																													'state',
																													A2(
																														$author$project$Transaction$maybeString,
																														'city',
																														A2(
																															$author$project$Transaction$maybeString,
																															'addressLine2',
																															A2(
																																$author$project$Transaction$maybeString,
																																'addressLine1',
																																A2(
																																	$author$project$Transaction$maybeString,
																																	'lastName',
																																	A2(
																																		$author$project$Transaction$maybeString,
																																		'middleName',
																																		A2(
																																			$author$project$Transaction$maybeString,
																																			'firstName',
																																			A2(
																																				$author$project$Transaction$maybeString,
																																				'refCode',
																																				A2(
																																					$author$project$Transaction$maybePurposeCode,
																																					'purposeCode',
																																					A2(
																																						$author$project$Transaction$maybeInt,
																																						'ruleVerifiedTimestamp',
																																						A2(
																																							$author$project$Transaction$maybeInt,
																																							'bankVerifiedTimestamp',
																																							A3(
																																								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																								'paymentDate',
																																								$elm$json$Json$Decode$int,
																																								A3(
																																									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																									'source',
																																									$author$project$PaymentSource$decoder,
																																									A3(
																																										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																										'initiatedTimestamp',
																																										$elm$json$Json$Decode$int,
																																										A3(
																																											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																											'ruleVerified',
																																											$elm$json$Json$Decode$bool,
																																											A3(
																																												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																												'bankVerified',
																																												$elm$json$Json$Decode$bool,
																																												A3(
																																													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																													'paymentMethod',
																																													$author$project$PaymentMethod$decoder,
																																													A3(
																																														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																														'amount',
																																														$elm$json$Json$Decode$int,
																																														A3(
																																															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																															'direction',
																																															$author$project$Direction$decoder,
																																															A3(
																																																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																																'committeeId',
																																																$elm$json$Json$Decode$string,
																																																A3(
																																																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																																																	'id',
																																																	$elm$json$Json$Decode$string,
																																																	$elm$json$Json$Decode$succeed($author$project$Transaction$Model))))))))))))))))))))))))))))))))))))))))))))))))));
var $author$project$Transactions$decoder = $elm$json$Json$Decode$list($author$project$Transaction$decoder);
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Api$GetTxns$decodeObject = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Api$GetTxns$TransactionsObject,
	A2($elm$json$Json$Decode$field, 'transactions', $author$project$Transactions$decoder),
	A2($elm$json$Json$Decode$field, 'aggregations', $author$project$Aggregations$decoder),
	A2($elm$json$Json$Decode$field, 'committee', $author$project$Committee$decoder));
var $author$project$Api$GetTxns$decode = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GetTxns$Model,
	A2($elm$json$Json$Decode$field, 'data', $author$project$Api$GetTxns$decodeObject));
var $author$project$Api$Endpoint$Endpoint = $elm$core$Basics$identity;
var $author$project$Api$Token = $elm$core$Basics$identity;
var $elm$http$Http$Internal$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Internal$Header;
var $author$project$Api$credHeader = function (_v0) {
	var token = _v0;
	return A2($elm$http$Http$header, 'authorization', 'Bearer ' + token);
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadPayload = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Internal$FormDataBody = function (a) {
	return {$: 2, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$http$Http$Internal$isStringBody = function (body) {
	if (body.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$expectStringResponse = _Http_expectStringResponse;
var $elm$http$Http$expectJson = function (decoder) {
	return $elm$http$Http$expectStringResponse(
		function (response) {
			var _v0 = A2($elm$json$Json$Decode$decodeString, decoder, response.$9);
			if (_v0.$ === 1) {
				var decodeError = _v0.a;
				return $elm$core$Result$Err(
					$elm$json$Json$Decode$errorToString(decodeError));
			} else {
				var value = _v0.a;
				return $elm$core$Result$Ok(value);
			}
		});
};
var $elm$http$Http$Internal$Request = $elm$core$Basics$identity;
var $elm$http$Http$request = $elm$core$Basics$identity;
var $author$project$Api$Endpoint$unwrap = function (_v0) {
	var str = _v0;
	return str;
};
var $author$project$Api$Endpoint$request = function (config) {
	return $elm$http$Http$request(
		{
			$9: config.$9,
			mJ: config.mJ,
			mY: config.mY,
			nj: config.nj,
			n$: config.n$,
			n9: $author$project$Api$Endpoint$unwrap(config.n9),
			oe: config.oe
		});
};
var $author$project$Api$post = F4(
	function (url, token, body, decoder) {
		return $author$project$Api$Endpoint$request(
			{
				$9: body,
				mJ: $elm$http$Http$expectJson(decoder),
				mY: _List_fromArray(
					[
						$author$project$Api$credHeader(token)
					]),
				nj: 'POST',
				n$: $elm$core$Maybe$Nothing,
				n9: url,
				oe: false
			});
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $elm$http$Http$toTask = function (_v0) {
	var request_ = _v0;
	return A2(_Http_toTask, request_, $elm$core$Maybe$Nothing);
};
var $elm$http$Http$send = F2(
	function (resultToMessage, request_) {
		return A2(
			$elm$core$Task$attempt,
			resultToMessage,
			$elm$http$Http$toTask(request_));
	});
var $author$project$Api$GraphQL$send = F4(
	function (decoder, msg, config, body) {
		var request = A4($author$project$Api$post, config.ma, config.n6, body, decoder);
		return A2($elm$http$Http$send, msg, request);
	});
var $author$project$Api$GetTxns$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$GetTxns$decode, msg, config);
	});
var $author$project$Page$Demo$getTransactions = F2(
	function (model, maybeTxnType) {
		return A3(
			$author$project$Api$GetTxns$send,
			$author$project$Page$Demo$GotTransactionsData,
			model.gh,
			A4($author$project$Api$GetTxns$encode, model.iE, maybeTxnType, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$Demo$init = F5(
	function (config, session, aggs, committee, committeeId) {
		var initModel = {ic: aggs, iD: committee, iE: committeeId, gh: config, c5: 0, gv: _List_Nil, bf: _List_Nil, aG: false, aH: 0, bk: $elm$core$Maybe$Nothing, kB: '', bX: false, bY: false, bZ: false, fJ: session, lH: $elm$core$Maybe$Nothing};
		return _Utils_Tuple2(
			initModel,
			A2($author$project$Page$Demo$getTransactions, initModel, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$LinkBuilder$GotTransactionsData = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$LinkBuilder$getTransactions = F2(
	function (model, maybeTxnType) {
		return A3(
			$author$project$Api$GetTxns$send,
			$author$project$Page$LinkBuilder$GotTransactionsData,
			model.gh,
			A4($author$project$Api$GetTxns$encode, model.iE, maybeTxnType, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$LinkBuilder$init = F5(
	function (config, session, aggs, committee, committeeId) {
		var initModel = {ic: aggs, ig: '', iD: committee, iE: committeeId, gh: config, fz: '', fJ: session, n9: ''};
		return _Utils_Tuple2(
			initModel,
			A2($author$project$Page$LinkBuilder$getTransactions, initModel, $elm$core$Maybe$Nothing));
	});
var $author$project$Page$Transactions$Closed = 2;
var $author$project$DeleteInfo$Uninitialized = 2;
var $rundis$elm_bootstrap$Bootstrap$Alert$Closed = 3;
var $rundis$elm_bootstrap$Bootstrap$Alert$closed = 3;
var $author$project$Page$Transactions$GotTxnSet = function (a) {
	return {$: 56, a: a};
};
var $author$project$Pagination$size = 15;
var $author$project$Page$Transactions$getNextTxnsSet = function (model) {
	return A3(
		$author$project$Api$GetTxns$send,
		$author$project$Page$Transactions$GotTxnSet,
		model.gh,
		A4(
			$author$project$Api$GetTxns$encode,
			model.iE,
			model.aT,
			$elm$core$Maybe$Just($author$project$Pagination$size),
			model.eA));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$Hide = 3;
var $rundis$elm_bootstrap$Bootstrap$Modal$hidden = 3;
var $author$project$FormID$CreateContrib = 1;
var $author$project$OwnersView$init = F2(
	function (owners, id) {
		return {
			h9: '',
			ia: '',
			iw: '',
			c1: {h9: '', ia: '', iw: '', jh: '', j_: '', dD: '', kM: '', ln: ''},
			iQ: false,
			gv: _List_Nil,
			jh: '',
			df: $elm$core$Maybe$Nothing,
			au: false,
			j_: '',
			eX: id,
			ku: owners,
			dD: '',
			kM: '',
			ln: ''
		};
	});
var $author$project$FormID$toString = function (formID) {
	switch (formID) {
		case 0:
			return 'create-disb';
		case 1:
			return 'create-contrib';
		case 2:
			return 'reconcile-disb';
		case 3:
			return 'reconcile-contrib';
		case 4:
			return 'amend-disb';
		default:
			return 'amend-contrib';
	}
};
var $author$project$CreateContribution$init = function (committeeId) {
	return {
		h9: '',
		ia: '',
		ig: '',
		ir: '',
		iu: '',
		iw: '',
		iE: committeeId,
		iH: '',
		i0: '',
		i1: '',
		i2: $elm$core$Maybe$Nothing,
		i5: '',
		gu: '',
		gv: _List_Nil,
		i9: '',
		ja: '',
		jh: '',
		m2: '',
		m4: $elm$core$Maybe$Nothing,
		jO: false,
		eO: false,
		j_: '',
		kb: $elm$core$Maybe$Nothing,
		kc: $elm$core$Maybe$Nothing,
		ni: $elm$core$Maybe$Nothing,
		nk: '',
		g7: '',
		kv: A2(
			$author$project$OwnersView$init,
			_List_Nil,
			$elm$core$Maybe$Just(
				$author$project$FormID$toString(1))),
		kE: '',
		kF: $elm$core$Maybe$Nothing,
		kH: '',
		kM: '',
		ln: '',
		lo: false
	};
};
var $author$project$CreateDisbursement$init = function (committeeId) {
	return {h9: '', ia: '', ig: '', iu: '', iw: '', iE: committeeId, i5: '', m8: $elm$core$Maybe$Nothing, m9: $elm$core$Maybe$Nothing, na: $elm$core$Maybe$Nothing, nb: $elm$core$Maybe$Nothing, gO: true, kc: $elm$core$Maybe$Nothing, kE: '', kF: $elm$core$Maybe$Nothing, kM: '', nE: $elm$core$Maybe$Nothing, ln: ''};
};
var $author$project$Transaction$init = {h9: $elm$core$Maybe$Nothing, ia: $elm$core$Maybe$Nothing, ig: 0, ih: $elm$core$Maybe$Nothing, ga: false, il: $elm$core$Maybe$Nothing, is: $elm$core$Maybe$Nothing, iu: $elm$core$Maybe$Nothing, iw: $elm$core$Maybe$Nothing, iE: '', iF: $elm$core$Maybe$Nothing, iP: 1, mD: $elm$core$Maybe$Nothing, i0: $elm$core$Maybe$Nothing, i1: $elm$core$Maybe$Nothing, i2: $elm$core$Maybe$Nothing, i5: $elm$core$Maybe$Nothing, i6: $elm$core$Maybe$Nothing, jf: $elm$core$Maybe$Nothing, mQ: $elm$core$Maybe$Nothing, mR: $elm$core$Maybe$Nothing, mS: $elm$core$Maybe$Nothing, jg: $elm$core$Maybe$Nothing, mT: $elm$core$Maybe$Nothing, mU: $elm$core$Maybe$Nothing, jh: $elm$core$Maybe$Nothing, jB: '', m3: $elm$core$Maybe$Nothing, m4: $elm$core$Maybe$Nothing, jH: 0, m8: $elm$core$Maybe$Nothing, na: $elm$core$Maybe$Nothing, nb: $elm$core$Maybe$Nothing, j_: $elm$core$Maybe$Nothing, nk: $elm$core$Maybe$Nothing, g7: $elm$core$Maybe$Nothing, ku: $elm$core$Maybe$Nothing, kE: 0, kF: 9, kH: $elm$core$Maybe$Nothing, kM: $elm$core$Maybe$Nothing, nE: $elm$core$Maybe$Nothing, fz: $elm$core$Maybe$Nothing, lb: false, lc: $elm$core$Maybe$Nothing, nQ: 5, ln: $elm$core$Maybe$Nothing, nT: $elm$core$Maybe$Nothing, lH: $elm$core$Maybe$Nothing};
var $justinmimbs$timezone_data$TimeZone$Specification$Rules = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Zone = F2(
	function (history, current) {
		return {gl: current, at: history};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$ZoneState = F2(
	function (standardOffset, zoneRules) {
		return {hL: standardOffset, h6: zoneRules};
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $justinmimbs$timezone_data$TimeZone$maxYear = 2037;
var $justinmimbs$timezone_data$TimeZone$minYear = 1970;
var $justinmimbs$timezone_data$TimeZone$Specification$DateTime = F5(
	function (year, month, day, time, clock) {
		return {a6: clock, mw: day, no: month, d: time, on: year};
	});
var $elm$time$Time$Jan = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$Universal = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch = function (_v0) {
	dropChangesBeforeEpoch:
	while (true) {
		var initial = _v0.a;
		var changes = _v0.b;
		if (changes.b) {
			var change = changes.a;
			var rest = changes.b;
			if (change.hM <= 0) {
				var $temp$_v0 = _Utils_Tuple2(change.e, rest);
				_v0 = $temp$_v0;
				continue dropChangesBeforeEpoch;
			} else {
				return _Utils_Tuple2(initial, changes);
			}
		} else {
			return _Utils_Tuple2(initial, _List_Nil);
		}
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $justinmimbs$timezone_data$RataDie$weekdayNumber = function (rd) {
	var _v0 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v0) {
		return 7;
	} else {
		var n = _v0;
		return n;
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$timezone_data$RataDie$floorWeekday = F2(
	function (weekday, rd) {
		var daysSincePreviousWeekday = A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$timezone_data$RataDie$weekdayNumber(rd) + 7) - $justinmimbs$timezone_data$RataDie$weekdayToNumber(weekday));
		return rd - daysSincePreviousWeekday;
	});
var $justinmimbs$timezone_data$RataDie$ceilingWeekday = F2(
	function (weekday, rd) {
		var floored = A2($justinmimbs$timezone_data$RataDie$floorWeekday, weekday, rd);
		return _Utils_eq(rd, floored) ? rd : (floored + 7);
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$timezone_data$RataDie$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$timezone_data$RataDie$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$timezone_data$RataDie$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
	return (365 * y) + leapYears;
};
var $justinmimbs$timezone_data$RataDie$dayOfMonth = F3(
	function (y, m, d) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + d;
	});
var $justinmimbs$timezone_data$RataDie$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$timezone_data$RataDie$lastOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + A2($justinmimbs$timezone_data$RataDie$daysInMonth, y, m);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie = function (rd) {
	return (rd - 719163) * 1440;
};
var $elm$core$List$sortBy = _List_sortBy;
var $justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment = F2(
	function (clock, _v0) {
		var standard = _v0.bq;
		var save = _v0.O;
		switch (clock) {
			case 0:
				return 0;
			case 1:
				return 0 - standard;
			default:
				return 0 - (standard + save);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime = function (_v0) {
	var year = _v0.on;
	var month = _v0.no;
	var day = _v0.mw;
	var time = _v0.d;
	return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
		A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, month, day)) + time;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime = F2(
	function (offset, datetime) {
		return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime(datetime) + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, datetime.a6, offset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges = F5(
	function (previousOffset, start, until, standardOffset, rules) {
		var transitions = A2(
			$elm$core$List$concatMap,
			function (year) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.hM;
					},
					A2(
						$elm$core$List$map,
						function (rule) {
							return {
								a6: rule.a6,
								O: rule.O,
								hM: $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
									function () {
										var _v2 = rule.mw;
										switch (_v2.$) {
											case 0:
												var day = _v2.a;
												return A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.no, day);
											case 1:
												var weekday = _v2.a;
												var after = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$ceilingWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.no, after));
											case 2:
												var weekday = _v2.a;
												var before = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.no, before));
											default:
												var weekday = _v2.a;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A2($justinmimbs$timezone_data$RataDie$lastOfMonth, year, rule.no));
										}
									}()) + rule.d
							};
						},
						A2(
							$elm$core$List$filter,
							function (rule) {
								return (_Utils_cmp(rule.ju, year) < 1) && (_Utils_cmp(year, rule.lv) < 1);
							},
							rules)));
			},
			A2($elm$core$List$range, start.on - 1, until.on));
		var initialOffset = {O: 0, bq: standardOffset};
		var initialChange = {
			e: standardOffset,
			hM: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
		};
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (transition, _v1) {
					var currentOffset = _v1.a;
					var changes = _v1.b;
					var newOffset = {O: transition.O, bq: standardOffset};
					if (_Utils_cmp(
						transition.hM + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.a6, previousOffset),
						initialChange.hM) < 1) {
						var updatedInitialChange = {e: standardOffset + transition.O, hM: initialChange.hM};
						return _Utils_Tuple2(
							newOffset,
							_List_fromArray(
								[updatedInitialChange]));
					} else {
						if (_Utils_cmp(
							transition.hM + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.a6, currentOffset),
							A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, currentOffset, until)) < 0) {
							var change = {
								e: standardOffset + transition.O,
								hM: transition.hM + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.a6, currentOffset)
							};
							return _Utils_Tuple2(
								newOffset,
								A2($elm$core$List$cons, change, changes));
						} else {
							return _Utils_Tuple2(currentOffset, changes);
						}
					}
				}),
			_Utils_Tuple2(
				initialOffset,
				_List_fromArray(
					[initialChange])),
			transitions);
		var nextOffset = _v0.a;
		var descendingChanges = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(descendingChanges),
			nextOffset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges = F4(
	function (previousOffset, start, until, _v0) {
		var standardOffset = _v0.hL;
		var zoneRules = _v0.h6;
		if (!zoneRules.$) {
			var save = zoneRules.a;
			return _Utils_Tuple2(
				_List_fromArray(
					[
						{
						e: standardOffset + save,
						hM: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
					}
					]),
				{O: save, bq: standardOffset});
		} else {
			var rules = zoneRules.a;
			return A5($justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges, previousOffset, start, until, standardOffset, rules);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp = F4(
	function (f, a, rev, list) {
		stripDuplicatesByHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(rev);
			} else {
				var x = list.a;
				var xs = list.b;
				var b = f(x);
				var rev_ = (!_Utils_eq(a, b)) ? A2($elm$core$List$cons, x, rev) : rev;
				var $temp$f = f,
					$temp$a = b,
					$temp$rev = rev_,
					$temp$list = xs;
				f = $temp$f;
				a = $temp$a;
				rev = $temp$rev;
				list = $temp$list;
				continue stripDuplicatesByHelp;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges = F3(
	function (zoneStart, zoneUntil, zone) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var state = _v1.a;
					var nextStart = _v1.b;
					var start = _v2.a;
					var ranges = _v2.b;
					return _Utils_Tuple2(
						nextStart,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple3(start, state, nextStart),
							ranges));
				}),
			_Utils_Tuple2(zoneStart, _List_Nil),
			zone.at);
		var currentStart = _v0.a;
		var historyRanges = _v0.b;
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple3(currentStart, zone.gl, zoneUntil),
				historyRanges));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$toOffsets = F3(
	function (minYear, maxYear, zone) {
		var initialState = function () {
			var _v5 = zone.at;
			if (_v5.b) {
				var _v6 = _v5.a;
				var earliest = _v6.a;
				return earliest;
			} else {
				return zone.gl;
			}
		}();
		var initialOffset = {
			O: function () {
				var _v4 = initialState.h6;
				if (!_v4.$) {
					var save = _v4.a;
					return save;
				} else {
					return 0;
				}
			}(),
			bq: initialState.hL
		};
		var ascendingChanges = A4(
			$justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp,
			function ($) {
				return $.e;
			},
			initialOffset.bq + initialOffset.O,
			_List_Nil,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v1, _v2) {
						var start = _v1.a;
						var state = _v1.b;
						var until = _v1.c;
						var prevOffset = _v2.a;
						var prevChanges = _v2.b;
						var _v3 = A4($justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges, prevOffset, start, until, state);
						var nextChanges = _v3.a;
						var nextOffset = _v3.b;
						return _Utils_Tuple2(
							nextOffset,
							_Utils_ap(prevChanges, nextChanges));
					}),
				_Utils_Tuple2(initialOffset, _List_Nil),
				A3(
					$justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges,
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, minYear, 0, 1, 0, 0),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, maxYear + 1, 0, 1, 0, 0),
					zone)).b);
		var _v0 = $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch(
			_Utils_Tuple2(initialOffset.bq + initialOffset.O, ascendingChanges));
		var initial = _v0.a;
		var ascending = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(ascending),
			initial);
	});
var $justinmimbs$timezone_data$TimeZone$fromSpecification = function (zone) {
	var _v0 = A3($justinmimbs$timezone_data$TimeZone$Specification$toOffsets, $justinmimbs$timezone_data$TimeZone$minYear, $justinmimbs$timezone_data$TimeZone$maxYear, zone);
	var descending = _v0.a;
	var bottom = _v0.b;
	return A2($elm$time$Time$customZone, bottom, descending);
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$time$Time$Apr = 3;
var $justinmimbs$timezone_data$TimeZone$Specification$Day = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Feb = 1;
var $justinmimbs$timezone_data$TimeZone$Specification$Last = function (a) {
	return {$: 3, a: a};
};
var $elm$time$Time$Mar = 2;
var $justinmimbs$timezone_data$TimeZone$Specification$Next = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $justinmimbs$timezone_data$TimeZone$Specification$Rule = F7(
	function (from, to, month, day, time, clock, save) {
		return {a6: clock, mw: day, ju: from, no: month, O: save, d: time, lv: to};
	});
var $elm$time$Time$Sun = 6;
var $justinmimbs$timezone_data$TimeZone$Specification$WallClock = 2;
var $justinmimbs$timezone_data$TimeZone$rules_US = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		1973,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__new_york = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $ryannhg$date_format$DateFormat$DayOfMonthFixed = {$: 7};
var $ryannhg$date_format$DateFormat$dayOfMonthFixed = $ryannhg$date_format$DateFormat$DayOfMonthFixed;
var $ryannhg$date_format$DateFormat$Language$Language = F6(
	function (toMonthName, toMonthAbbreviation, toWeekdayName, toWeekdayAbbreviation, toAmPm, toOrdinalSuffix) {
		return {n0: toAmPm, n1: toMonthAbbreviation, n2: toMonthName, cP: toOrdinalSuffix, n4: toWeekdayAbbreviation, n5: toWeekdayName};
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $ryannhg$date_format$DateFormat$Language$toEnglishAmPm = function (hour) {
	return (hour > 11) ? 'pm' : 'am';
};
var $ryannhg$date_format$DateFormat$Language$toEnglishMonthName = function (month) {
	switch (month) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishSuffix = function (num) {
	var _v0 = A2($elm$core$Basics$modBy, 100, num);
	switch (_v0) {
		case 11:
			return 'th';
		case 12:
			return 'th';
		case 13:
			return 'th';
		default:
			var _v1 = A2($elm$core$Basics$modBy, 10, num);
			switch (_v1) {
				case 1:
					return 'st';
				case 2:
					return 'nd';
				case 3:
					return 'rd';
				default:
					return 'th';
			}
	}
};
var $ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName = function (weekday) {
	switch (weekday) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $ryannhg$date_format$DateFormat$Language$english = A6(
	$ryannhg$date_format$DateFormat$Language$Language,
	$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishMonthName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
	A2(
		$elm$core$Basics$composeR,
		$ryannhg$date_format$DateFormat$Language$toEnglishWeekdayName,
		$elm$core$String$left(3)),
	$ryannhg$date_format$DateFormat$Language$toEnglishAmPm,
	$ryannhg$date_format$DateFormat$Language$toEnglishSuffix);
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.hM, posixMinutes) < 0) {
					return posixMinutes + era.e;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $ryannhg$date_format$DateFormat$amPm = F3(
	function (language, zone, posix) {
		return language.n0(
			A2($elm$time$Time$toHour, zone, posix));
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		mw: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		no: month,
		on: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).mw;
	});
var $ryannhg$date_format$DateFormat$dayOfMonth = $elm$time$Time$toDay;
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $ryannhg$date_format$DateFormat$days = _List_fromArray(
	[6, 0, 1, 2, 3, 4, 5]);
var $elm$time$Time$toWeekday = F2(
	function (zone, time) {
		var _v0 = A2(
			$elm$core$Basics$modBy,
			7,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60 * 24));
		switch (_v0) {
			case 0:
				return 3;
			case 1:
				return 4;
			case 2:
				return 5;
			case 3:
				return 6;
			case 4:
				return 0;
			case 5:
				return 1;
			default:
				return 2;
		}
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $ryannhg$date_format$DateFormat$dayOfWeek = F2(
	function (zone, posix) {
		return function (_v1) {
			var i = _v1.a;
			return i;
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(0, 6),
				$elm$core$List$head(
					A2(
						$elm$core$List$filter,
						function (_v0) {
							var day = _v0.b;
							return _Utils_eq(
								day,
								A2($elm$time$Time$toWeekday, zone, posix));
						},
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, day) {
									return _Utils_Tuple2(i, day);
								}),
							$ryannhg$date_format$DateFormat$days)))));
	});
var $ryannhg$date_format$DateFormat$isLeapYear = function (year_) {
	return (!(!A2($elm$core$Basics$modBy, 4, year_))) ? false : ((!(!A2($elm$core$Basics$modBy, 100, year_))) ? true : ((!(!A2($elm$core$Basics$modBy, 400, year_))) ? false : true));
};
var $ryannhg$date_format$DateFormat$daysInMonth = F2(
	function (year_, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $ryannhg$date_format$DateFormat$isLeapYear(year_) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$May = 4;
var $elm$time$Time$Sep = 8;
var $ryannhg$date_format$DateFormat$months = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).no;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $ryannhg$date_format$DateFormat$monthPair = F2(
	function (zone, posix) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(0, 0),
			$elm$core$List$head(
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var i = _v0.a;
						var m = _v0.b;
						return _Utils_eq(
							m,
							A2($elm$time$Time$toMonth, zone, posix));
					},
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (a, b) {
								return _Utils_Tuple2(a, b);
							}),
						$ryannhg$date_format$DateFormat$months))));
	});
var $ryannhg$date_format$DateFormat$monthNumber_ = F2(
	function (zone, posix) {
		return 1 + function (_v0) {
			var i = _v0.a;
			var m = _v0.b;
			return i;
		}(
			A2($ryannhg$date_format$DateFormat$monthPair, zone, posix));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).on;
	});
var $ryannhg$date_format$DateFormat$dayOfYear = F2(
	function (zone, posix) {
		var monthsBeforeThisOne = A2(
			$elm$core$List$take,
			A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) - 1,
			$ryannhg$date_format$DateFormat$months);
		var daysBeforeThisMonth = $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$ryannhg$date_format$DateFormat$daysInMonth(
					A2($elm$time$Time$toYear, zone, posix)),
				monthsBeforeThisOne));
		return daysBeforeThisMonth + A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix);
	});
var $ryannhg$date_format$DateFormat$quarter = F2(
	function (zone, posix) {
		return (A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix) / 4) | 0;
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $ryannhg$date_format$DateFormat$toFixedLength = F2(
	function (totalChars, num) {
		var numStr = $elm$core$String$fromInt(num);
		var numZerosNeeded = totalChars - $elm$core$String$length(numStr);
		var zeros = A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				function (_v0) {
					return '0';
				},
				A2($elm$core$List$range, 1, numZerosNeeded)));
		return _Utils_ap(zeros, numStr);
	});
var $elm$core$String$toLower = _String_toLower;
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $ryannhg$date_format$DateFormat$toNonMilitary = function (num) {
	return (!num) ? 12 : ((num <= 12) ? num : (num - 12));
};
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$core$String$toUpper = _String_toUpper;
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$core$Basics$round = _Basics_round;
var $ryannhg$date_format$DateFormat$millisecondsPerYear = $elm$core$Basics$round((((1000 * 60) * 60) * 24) * 365.25);
var $ryannhg$date_format$DateFormat$firstDayOfYear = F2(
	function (zone, time) {
		return $elm$time$Time$millisToPosix(
			$ryannhg$date_format$DateFormat$millisecondsPerYear * A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$weekOfYear = F2(
	function (zone, posix) {
		var firstDay = A2($ryannhg$date_format$DateFormat$firstDayOfYear, zone, posix);
		var firstDayOffset = A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, firstDay);
		var daysSoFar = A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix);
		return (((daysSoFar + firstDayOffset) / 7) | 0) + 1;
	});
var $ryannhg$date_format$DateFormat$year = F2(
	function (zone, time) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, time));
	});
var $ryannhg$date_format$DateFormat$piece = F4(
	function (language, zone, posix, token) {
		switch (token.$) {
			case 0:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 1:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cP(num));
				}(
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 2:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$monthNumber_, zone, posix));
			case 3:
				return language.n1(
					A2($elm$time$Time$toMonth, zone, posix));
			case 4:
				return language.n2(
					A2($elm$time$Time$toMonth, zone, posix));
			case 17:
				return $elm$core$String$fromInt(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 18:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cP(num));
				}(
					1 + A2($ryannhg$date_format$DateFormat$quarter, zone, posix));
			case 5:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 6:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cP(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 7:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$dayOfMonth, zone, posix));
			case 8:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 9:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cP(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 10:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($ryannhg$date_format$DateFormat$dayOfYear, zone, posix));
			case 11:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 12:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cP(num));
				}(
					A2($ryannhg$date_format$DateFormat$dayOfWeek, zone, posix));
			case 13:
				return language.n4(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 14:
				return language.n5(
					A2($elm$time$Time$toWeekday, zone, posix));
			case 19:
				return $elm$core$String$fromInt(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 20:
				return function (num) {
					return _Utils_ap(
						$elm$core$String$fromInt(num),
						language.cP(num));
				}(
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 21:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($ryannhg$date_format$DateFormat$weekOfYear, zone, posix));
			case 15:
				return A2(
					$elm$core$String$right,
					2,
					A2($ryannhg$date_format$DateFormat$year, zone, posix));
			case 16:
				return A2($ryannhg$date_format$DateFormat$year, zone, posix);
			case 22:
				return $elm$core$String$toUpper(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 23:
				return $elm$core$String$toLower(
					A3($ryannhg$date_format$DateFormat$amPm, language, zone, posix));
			case 24:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toHour, zone, posix));
			case 25:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toHour, zone, posix));
			case 26:
				return $elm$core$String$fromInt(
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 27:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					$ryannhg$date_format$DateFormat$toNonMilitary(
						A2($elm$time$Time$toHour, zone, posix)));
			case 28:
				return $elm$core$String$fromInt(
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 29:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					1 + A2($elm$time$Time$toHour, zone, posix));
			case 30:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMinute, zone, posix));
			case 31:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toMinute, zone, posix));
			case 32:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toSecond, zone, posix));
			case 33:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					2,
					A2($elm$time$Time$toSecond, zone, posix));
			case 34:
				return $elm$core$String$fromInt(
					A2($elm$time$Time$toMillis, zone, posix));
			case 35:
				return A2(
					$ryannhg$date_format$DateFormat$toFixedLength,
					3,
					A2($elm$time$Time$toMillis, zone, posix));
			default:
				var string = token.a;
				return string;
		}
	});
var $ryannhg$date_format$DateFormat$formatWithLanguage = F4(
	function (language, tokens, zone, time) {
		return A2(
			$elm$core$String$join,
			'',
			A2(
				$elm$core$List$map,
				A3($ryannhg$date_format$DateFormat$piece, language, zone, time),
				tokens));
	});
var $ryannhg$date_format$DateFormat$format = $ryannhg$date_format$DateFormat$formatWithLanguage($ryannhg$date_format$DateFormat$Language$english);
var $ryannhg$date_format$DateFormat$MonthFixed = {$: 2};
var $ryannhg$date_format$DateFormat$monthFixed = $ryannhg$date_format$DateFormat$MonthFixed;
var $ryannhg$date_format$DateFormat$Text = function (a) {
	return {$: 36, a: a};
};
var $ryannhg$date_format$DateFormat$text = $ryannhg$date_format$DateFormat$Text;
var $ryannhg$date_format$DateFormat$YearNumber = {$: 16};
var $ryannhg$date_format$DateFormat$yearNumber = $ryannhg$date_format$DateFormat$YearNumber;
var $author$project$Timestamp$isoFormatter = $ryannhg$date_format$DateFormat$format(
	_List_fromArray(
		[
			$ryannhg$date_format$DateFormat$yearNumber,
			$ryannhg$date_format$DateFormat$text('-'),
			$ryannhg$date_format$DateFormat$monthFixed,
			$ryannhg$date_format$DateFormat$text('-'),
			$ryannhg$date_format$DateFormat$dayOfMonthFixed
		]));
var $author$project$Timestamp$formDate = F2(
	function (timezone, milliTime) {
		var posixTime = $elm$time$Time$millisToPosix(milliTime);
		return A2($author$project$Timestamp$isoFormatter, timezone, posixTime);
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$TxnForm$ContribRuleUnverified$getRelatedContrib = F2(
	function (txn, txns) {
		var filteredtxns = A2(
			$elm$core$List$filter,
			function (val) {
				return (_Utils_cmp(val.ig, txn.ig) < 1) && ((!val.iP) && ((!val.ga) && (val.lb && (_Utils_cmp(val.kE, txn.kE) < 1))));
			},
			txns);
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				function ($) {
					return $.kE;
				},
				filteredtxns));
	});
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {bH: decimals, jL: integers, ks: original, fn: prefix, fM: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.jL,
				$elm$core$List$singleton(formatted.bH))));
	return onlyZeros ? 1 : ((formatted.ks < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.bH;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $elm$core$String$fromFloat = _String_fromNumber;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.bH;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = $cuducos$elm_format_number$FormatNumber$Parser$splitThousands(
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{fn: locale.e0, fM: locale.g4});
			case 0:
				return _Utils_update(
					partial,
					{fn: locale.kK, fM: locale.kL});
			default:
				return _Utils_update(
					partial,
					{fn: locale.l6, fM: locale.l7});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.bG, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.b1, formatted.jL);
		var decimals = stringfyDecimals(formatted.bH);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.fn, integers, decimals, formatted.fM]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$base = {
	bG: '.',
	bH: $cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	e0: '−',
	g4: '',
	kK: '',
	kL: '',
	b1: '',
	l6: '',
	l7: ''
};
var $cuducos$elm_format_number$FormatNumber$Locales$usLocale = _Utils_update(
	$cuducos$elm_format_number$FormatNumber$Locales$base,
	{
		bH: $cuducos$elm_format_number$FormatNumber$Locales$Exact(2),
		b1: ','
	});
var $author$project$Cents$toUnsignedDollar = function (cents) {
	return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, cents / 100);
};
var $author$project$Cents$toDollarData = function (cents) {
	return A3(
		$elm$core$String$replace,
		',',
		'',
		$author$project$Cents$toUnsignedDollar(cents));
};
var $author$project$TxnForm$ContribRuleUnverified$init = F3(
	function (config, txns, bankTxn) {
		return {
			h9: '',
			ia: '',
			ig: $author$project$Cents$toDollarData(bankTxn.ig),
			ik: bankTxn,
			ir: '',
			iu: '',
			iw: '',
			iE: bankTxn.iE,
			gh: config,
			bE: false,
			a8: false,
			iH: '',
			iQ: false,
			i0: '',
			i1: '',
			i2: $elm$core$Maybe$Nothing,
			i5: '',
			gu: '',
			gv: _List_Nil,
			i9: '',
			ja: '',
			jh: '',
			m2: '',
			m4: $elm$core$Maybe$Nothing,
			eP: '',
			j_: '',
			dt: false,
			kb: $elm$core$Maybe$Nothing,
			kc: $elm$core$Maybe$Nothing,
			ni: $elm$core$Maybe$Nothing,
			nk: '',
			g7: '',
			nB: '',
			ku: bankTxn.ku,
			kv: A2(
				$author$project$OwnersView$init,
				A2($elm$core$Maybe$withDefault, _List_Nil, bankTxn.ku),
				$elm$core$Maybe$Nothing),
			kE: A2(
				$author$project$Timestamp$formDate,
				$justinmimbs$timezone_data$TimeZone$america__new_york(0),
				bankTxn.kE),
			kF: $elm$core$Maybe$Nothing,
			kH: '',
			kM: '',
			ht: true,
			fA: A2($author$project$TxnForm$ContribRuleUnverified$getRelatedContrib, bankTxn, txns),
			li: _List_Nil,
			ln: '',
			lo: false,
			fO: $justinmimbs$timezone_data$TimeZone$america__new_york(0)
		};
	});
var $author$project$FormID$AmendContrib = 5;
var $author$project$OrgOrInd$Ind = 1;
var $author$project$OrgOrInd$Org = 0;
var $author$project$OrgOrInd$fromEntityType = function (entityType) {
	switch (entityType) {
		case 1:
			return 1;
		case 0:
			return 1;
		case 5:
			return 1;
		default:
			return 0;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Popover$State = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Popover$initialState = {
	gp: {
		g8: 0,
		e4: 0,
		hu: {jx: 0, gP: 0, fQ: 0, lZ: 0}
	},
	cn: false
};
var $elm$core$String$toFloat = _String_toFloat;
var $author$project$Cents$strToUnsignedDollar = function (cents) {
	var _v0 = $elm$core$String$toFloat(cents);
	if (!_v0.$) {
		var val = _v0.a;
		return A2($cuducos$elm_format_number$FormatNumber$format, $cuducos$elm_format_number$FormatNumber$Locales$usLocale, val / 100);
	} else {
		return '';
	}
};
var $author$project$Cents$stringToDollar = function (str) {
	var maybeTup = $elm$core$String$uncons(str);
	if (!maybeTup.$) {
		var val = maybeTup.a;
		var firstChar = val.a;
		var rest = val.b;
		return (firstChar === '-') ? ('-' + ('$' + $author$project$Cents$strToUnsignedDollar(rest))) : ('$' + $author$project$Cents$strToUnsignedDollar(str));
	} else {
		return '$';
	}
};
var $author$project$TxnForm$ContribRuleVerified$init = function (txn) {
	return {
		h9: A2($elm$core$Maybe$withDefault, '', txn.h9),
		ia: A2($elm$core$Maybe$withDefault, '', txn.ia),
		ig: $author$project$Cents$stringToDollar(
			$elm$core$String$fromInt(txn.ig)),
		ir: '',
		iu: A2($elm$core$Maybe$withDefault, '', txn.iu),
		iw: A2($elm$core$Maybe$withDefault, '', txn.iw),
		iE: txn.iE,
		iH: '',
		iQ: true,
		i0: A2($elm$core$Maybe$withDefault, '', txn.i0),
		i1: A2($elm$core$Maybe$withDefault, '', txn.i1),
		i2: txn.i2,
		i5: A2($elm$core$Maybe$withDefault, '', txn.i5),
		gu: '',
		gv: _List_Nil,
		i9: '',
		ja: '',
		jh: A2($elm$core$Maybe$withDefault, '', txn.jh),
		m2: A2($elm$core$Maybe$withDefault, '', txn.m3),
		m4: txn.m4,
		jO: true,
		jS: true,
		gO: true,
		j_: A2($elm$core$Maybe$withDefault, '', txn.j_),
		dt: false,
		kb: txn.i6,
		kc: $elm$core$Maybe$Nothing,
		ni: A2($elm$core$Maybe$map, $author$project$OrgOrInd$fromEntityType, txn.i6),
		nk: A2($elm$core$Maybe$withDefault, '', txn.nk),
		g7: A2($elm$core$Maybe$withDefault, '', txn.g7),
		nB: '',
		ku: A2($elm$core$Maybe$withDefault, _List_Nil, txn.ku),
		kv: A2(
			$author$project$OwnersView$init,
			A2($elm$core$Maybe$withDefault, _List_Nil, txn.ku),
			$elm$core$Maybe$Just(
				$author$project$FormID$toString(5))),
		kE: A2(
			$author$project$Timestamp$formDate,
			$justinmimbs$timezone_data$TimeZone$america__new_york(0),
			txn.kE),
		kF: $elm$core$Maybe$Just(txn.kF),
		kH: A2($elm$core$Maybe$withDefault, '', txn.kH),
		hq: $rundis$elm_bootstrap$Bootstrap$Popover$initialState,
		kM: A2($elm$core$Maybe$withDefault, '', txn.kM),
		dJ: false,
		ln: A2($elm$core$Maybe$withDefault, '', txn.ln),
		lo: false,
		dX: txn
	};
};
var $author$project$TxnForm$DisbRuleUnverified$getRelatedDisb = F2(
	function (txn, txns) {
		return A2(
			$elm$core$List$filter,
			function (val) {
				return (val.kF !== 5) && ((_Utils_cmp(val.ig, txn.ig) < 1) && ((val.iP === 1) && ((!val.ga) && val.lb)));
			},
			txns);
	});
var $author$project$TxnForm$DisbRuleUnverified$init = F3(
	function (config, txns, bankTxn) {
		return {
			h9: '',
			ia: '',
			ig: $author$project$Cents$toDollarData(bankTxn.ig),
			ik: bankTxn,
			iu: '',
			iw: '',
			iE: bankTxn.iE,
			gh: config,
			bb: false,
			aQ: false,
			iQ: true,
			i5: '',
			m8: $elm$core$Maybe$Nothing,
			m9: $elm$core$Maybe$Nothing,
			na: $elm$core$Maybe$Nothing,
			nb: $elm$core$Maybe$Nothing,
			eP: '',
			kc: $elm$core$Maybe$Nothing,
			kE: A2(
				$author$project$Timestamp$formDate,
				$justinmimbs$timezone_data$TimeZone$america__new_york(0),
				bankTxn.kE),
			kF: $elm$core$Maybe$Just(bankTxn.kF),
			kM: '',
			nE: $elm$core$Maybe$Nothing,
			ht: true,
			fA: A2($author$project$TxnForm$DisbRuleUnverified$getRelatedDisb, bankTxn, txns),
			li: _List_Nil,
			ln: '',
			fO: $justinmimbs$timezone_data$TimeZone$america__new_york(0)
		};
	});
var $author$project$TxnForm$DisbRuleVerified$init = function (txn) {
	return {
		h9: A2($elm$core$Maybe$withDefault, '', txn.h9),
		ia: A2($elm$core$Maybe$withDefault, '', txn.ia),
		ig: '',
		iu: A2($elm$core$Maybe$withDefault, '', txn.iu),
		iw: A2($elm$core$Maybe$withDefault, '', txn.iw),
		i5: A2($elm$core$Maybe$withDefault, '', txn.i5),
		bP: true,
		m8: txn.m8,
		m9: $elm$core$Maybe$Nothing,
		na: txn.na,
		nb: txn.nb,
		gO: true,
		dt: false,
		kc: $elm$core$Maybe$Nothing,
		kE: '',
		kF: $elm$core$Maybe$Just(txn.kF),
		hq: $rundis$elm_bootstrap$Bootstrap$Popover$initialState,
		kM: A2($elm$core$Maybe$withDefault, '', txn.kM),
		nE: txn.nE,
		dJ: false,
		ln: A2($elm$core$Maybe$withDefault, '', txn.ln),
		dX: txn
	};
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area = F4(
	function (top, left, width, height) {
		return {jx: height, gP: left, fQ: top, lZ: width};
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$Closed = 2;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$State = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState = {
	dv: A4($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area, 0, 0, 0, 0),
	nR: 2,
	fP: A4($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$Area, 0, 0, 0, 0)
};
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$Page$Transactions$init = F5(
	function (config, session, aggs, committee, committeeId) {
		var initModel = {
			cU: $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState,
			ic: aggs,
			r: $rundis$elm_bootstrap$Bootstrap$Alert$closed,
			iD: committee,
			iE: committeeId,
			gh: config,
			L: A3($author$project$TxnForm$ContribRuleUnverified$init, config, _List_Nil, $author$project$Transaction$init),
			bA: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			bB: false,
			c$: false,
			o: $author$project$TxnForm$ContribRuleVerified$init($author$project$Transaction$init),
			ar: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			bC: false,
			c0: false,
			A: $author$project$CreateContribution$init(committeeId),
			a9: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			ba: false,
			B: $author$project$CreateDisbursement$init(committeeId),
			bc: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			cd: false,
			D: A3($author$project$TxnForm$DisbRuleUnverified$init, config, _List_Nil, $author$project$Transaction$init),
			bd: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			bI: false,
			c6: false,
			p: $author$project$TxnForm$DisbRuleVerified$init($author$project$Transaction$init),
			aR: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			bJ: false,
			c7: false,
			ez: false,
			c8: false,
			bO: false,
			aT: $elm$core$Maybe$Nothing,
			dc: $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState,
			eA: $elm$core$Maybe$Nothing,
			dh: 2,
			di: $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState,
			cj: $elm$core$Maybe$Nothing,
			dj: $rundis$elm_bootstrap$Bootstrap$Dropdown$initialState,
			bg: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
			bh: false,
			bR: 'All Transactions',
			V: false,
			G: 2,
			dt: true,
			e$: false,
			cw: false,
			dB: $author$project$Pagination$size,
			fJ: session,
			ls: $elm$time$Time$utc,
			lI: _List_Nil
		};
		return _Utils_Tuple2(
			initModel,
			$author$project$Page$Transactions$getNextTxnsSet(initModel));
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {bQ: frag, bU: params, bv: unvisited, aZ: value, b4: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.bv;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.aZ);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.aZ);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.kC),
					$elm$url$Url$Parser$prepareQuery(url.kT),
					url.jt,
					$elm$core$Basics$identity)));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.b4;
		var unvisited = _v1.bv;
		var params = _v1.bU;
		var frag = _v1.bQ;
		var value = _v1.aZ;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $author$project$CommitteeId$parse = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Maybe$Just(''),
			A2(
				$elm$url$Url$Parser$parse,
				$elm$url$Url$Parser$query(
					$elm$url$Url$Parser$Query$string('committeeId')),
				url)));
};
var $author$project$Aggregations$init = {f8: 0, ki: 0, hW: 0, hX: 0, hY: 0, hZ: 0, h_: 0, lB: 0};
var $author$project$Main$toAggregations = function (page) {
	switch (page.$) {
		case 1:
			var session = page.a;
			return $author$project$Aggregations$init;
		case 0:
			var session = page.a;
			return $author$project$Aggregations$init;
		case 3:
			var transactions = page.a;
			return transactions.ic;
		case 2:
			var linkBuilder = page.a;
			return linkBuilder.ic;
		default:
			var demo = page.a;
			return demo.ic;
	}
};
var $author$project$Committee$init = {ij: '', ip: '', jB: '', kn: '', hp: 0};
var $author$project$Main$toCommittee = function (page) {
	switch (page.$) {
		case 1:
			var session = page.a;
			return $author$project$Committee$init;
		case 0:
			var session = page.a;
			return $author$project$Committee$init;
		case 3:
			var transactions = page.a;
			return transactions.iD;
		case 2:
			var linkBuilder = page.a;
			return linkBuilder.iD;
		default:
			var demo = page.a;
			return demo.iD;
	}
};
var $author$project$Page$Demo$toSession = function (model) {
	return model.fJ;
};
var $author$project$Page$LinkBuilder$toSession = function (model) {
	return model.fJ;
};
var $author$project$Page$Transactions$toSession = function (model) {
	return model.fJ;
};
var $author$project$Main$toSession = function (page) {
	switch (page.$) {
		case 1:
			var session = page.a;
			return session;
		case 0:
			var session = page.a;
			return session;
		case 3:
			var transactions = page.a;
			return $author$project$Page$Transactions$toSession(transactions);
		case 2:
			var session = page.a;
			return $author$project$Page$LinkBuilder$toSession(session);
		default:
			var session = page.a;
			return $author$project$Page$Demo$toSession(session);
	}
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $author$project$Main$updateWith = F3(
	function (toModel, toMsg, _v0) {
		var subModel = _v0.a;
		var subCmd = _v0.b;
		return _Utils_Tuple2(
			toModel(subModel),
			A2($elm$core$Platform$Cmd$map, toMsg, subCmd));
	});
var $author$project$Main$changeRouteTo = F4(
	function (url, config, maybeRoute, model) {
		var session = $author$project$Main$toSession(model);
		var committeeId = $author$project$CommitteeId$parse(url);
		var committee = $author$project$Main$toCommittee(model);
		var aggregations = $author$project$Main$toAggregations(model);
		if (maybeRoute.$ === 1) {
			return _Utils_Tuple2(
				$author$project$Main$NotFound(session),
				$elm$core$Platform$Cmd$none);
		} else {
			switch (maybeRoute.a.$) {
				case 0:
					var _v1 = maybeRoute.a;
					return A3(
						$author$project$Main$updateWith,
						$author$project$Main$Transactions,
						$author$project$Main$GotTransactionsMsg,
						A5($author$project$Page$Transactions$init, config, session, aggregations, committee, committeeId));
				case 2:
					var id = maybeRoute.a.a;
					return A3(
						$author$project$Main$updateWith,
						$author$project$Main$Transactions,
						$author$project$Main$GotTransactionsMsg,
						A5($author$project$Page$Transactions$init, config, session, aggregations, committee, id));
				case 1:
					var id = maybeRoute.a.a;
					return A3(
						$author$project$Main$updateWith,
						$author$project$Main$LinkBuilder,
						$author$project$Main$GotLinkBuilderMsg,
						A5($author$project$Page$LinkBuilder$init, config, session, aggregations, committee, id));
				default:
					var id = maybeRoute.a.a;
					return A3(
						$author$project$Main$updateWith,
						$author$project$Main$Demo,
						$author$project$Main$GotDemoMsg,
						A5($author$project$Page$Demo$init, config, session, aggregations, committee, id));
			}
		}
	});
var $author$project$Route$Demo = function (a) {
	return {$: 3, a: a};
};
var $author$project$Route$LinkBuilder = function (a) {
	return {$: 1, a: a};
};
var $author$project$Route$Transactions = function (a) {
	return {$: 2, a: a};
};
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.b4;
		var unvisited = _v0.bv;
		var params = _v0.bU;
		var frag = _v0.bQ;
		var value = _v0.aZ;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.b4;
			var unvisited = _v1.bv;
			var params = _v1.bU;
			var frag = _v1.bQ;
			var value = _v1.aZ;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.b4;
		var unvisited = _v0.bv;
		var params = _v0.bU;
		var frag = _v0.bQ;
		var value = _v0.aZ;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.b4;
			var unvisited = _v0.bv;
			var params = _v0.bU;
			var frag = _v0.bQ;
			var value = _v0.aZ;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $author$project$Route$parser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Transactions,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('committee'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$LinkBuilder,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('committee'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$s('link-builder')))),
			A2(
			$elm$url$Url$Parser$map,
			$author$project$Route$Demo,
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('committee'),
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$string,
					$elm$url$Url$Parser$s('demo'))))
		]));
var $author$project$Route$fromUrl = $elm$url$Url$Parser$parse($author$project$Route$parser);
var $author$project$Session$LoggedIn = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Session$fromViewer = F2(
	function (key, token) {
		return A2($author$project$Session$LoggedIn, key, token);
	});
var $author$project$Main$init = F3(
	function (config, url, navKey) {
		return A4(
			$author$project$Main$changeRouteTo,
			url,
			config,
			$author$project$Route$fromUrl(url),
			$author$project$Main$Redirect(
				A2($author$project$Session$fromViewer, navKey, config.n6)));
	});
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Page$Demo$Tick = function (a) {
	return {$: 5, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {kQ: processes, lq: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.kQ;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.lq);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $author$project$Page$Demo$subscriptions = function (model) {
	return A2($elm$time$Time$every, 1000, $author$project$Page$Demo$Tick);
};
var $author$project$Page$LinkBuilder$subscriptions = function (model) {
	return $elm$core$Platform$Sub$none;
};
var $author$project$Page$Transactions$AnimateCreateContributionModal = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$Transactions$AnimateGenerateDisclosureModal = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Transactions$ContribRuleUnverifiedModalAnimate = function (a) {
	return {$: 44, a: a};
};
var $author$project$Page$Transactions$ContribRuleVerifiedModalAnimate = function (a) {
	return {$: 49, a: a};
};
var $author$project$Page$Transactions$CreateContributionModalUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$Transactions$CreateDisbursementModalAnimate = function (a) {
	return {$: 28, a: a};
};
var $author$project$Page$Transactions$DeleteAlertMsg = function (a) {
	return {$: 58, a: a};
};
var $author$project$Page$Transactions$DisbRuleUnverifiedModalAnimate = function (a) {
	return {$: 33, a: a};
};
var $author$project$Page$Transactions$DisbRuleVerifiedModalAnimate = function (a) {
	return {$: 38, a: a};
};
var $author$project$Page$Transactions$ToggleActionsDropdown = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$Transactions$ToggleFiltersDropdown = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$Transactions$ToggleGenerateDisclosureModalDownloadDropdown = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$Transactions$ToggleGenerateDisclosureModalPreviewDropdown = function (a) {
	return {$: 18, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Alert$FadeClose = 2;
var $elm$browser$Browser$AnimationManager$Time = function (a) {
	return {$: 0, a: a};
};
var $elm$browser$Browser$AnimationManager$State = F3(
	function (subs, request, oldTime) {
		return {g9: oldTime, k5: request, lp: subs};
	});
var $elm$browser$Browser$AnimationManager$init = $elm$core$Task$succeed(
	A3($elm$browser$Browser$AnimationManager$State, _List_Nil, $elm$core$Maybe$Nothing, 0));
var $elm$browser$Browser$AnimationManager$now = _Browser_now(0);
var $elm$browser$Browser$AnimationManager$rAF = _Browser_rAF(0);
var $elm$browser$Browser$AnimationManager$onEffects = F3(
	function (router, subs, _v0) {
		var request = _v0.k5;
		var oldTime = _v0.g9;
		var _v1 = _Utils_Tuple2(request, subs);
		if (_v1.a.$ === 1) {
			if (!_v1.b.b) {
				var _v2 = _v1.a;
				return $elm$browser$Browser$AnimationManager$init;
			} else {
				var _v4 = _v1.a;
				return A2(
					$elm$core$Task$andThen,
					function (pid) {
						return A2(
							$elm$core$Task$andThen,
							function (time) {
								return $elm$core$Task$succeed(
									A3(
										$elm$browser$Browser$AnimationManager$State,
										subs,
										$elm$core$Maybe$Just(pid),
										time));
							},
							$elm$browser$Browser$AnimationManager$now);
					},
					$elm$core$Process$spawn(
						A2(
							$elm$core$Task$andThen,
							$elm$core$Platform$sendToSelf(router),
							$elm$browser$Browser$AnimationManager$rAF)));
			}
		} else {
			if (!_v1.b.b) {
				var pid = _v1.a.a;
				return A2(
					$elm$core$Task$andThen,
					function (_v3) {
						return $elm$browser$Browser$AnimationManager$init;
					},
					$elm$core$Process$kill(pid));
			} else {
				return $elm$core$Task$succeed(
					A3($elm$browser$Browser$AnimationManager$State, subs, request, oldTime));
			}
		}
	});
var $elm$browser$Browser$AnimationManager$onSelfMsg = F3(
	function (router, newTime, _v0) {
		var subs = _v0.lp;
		var oldTime = _v0.g9;
		var send = function (sub) {
			if (!sub.$) {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(
						$elm$time$Time$millisToPosix(newTime)));
			} else {
				var tagger = sub.a;
				return A2(
					$elm$core$Platform$sendToApp,
					router,
					tagger(newTime - oldTime));
			}
		};
		return A2(
			$elm$core$Task$andThen,
			function (pid) {
				return A2(
					$elm$core$Task$andThen,
					function (_v1) {
						return $elm$core$Task$succeed(
							A3(
								$elm$browser$Browser$AnimationManager$State,
								subs,
								$elm$core$Maybe$Just(pid),
								newTime));
					},
					$elm$core$Task$sequence(
						A2($elm$core$List$map, send, subs)));
			},
			$elm$core$Process$spawn(
				A2(
					$elm$core$Task$andThen,
					$elm$core$Platform$sendToSelf(router),
					$elm$browser$Browser$AnimationManager$rAF)));
	});
var $elm$browser$Browser$AnimationManager$Delta = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$AnimationManager$subMap = F2(
	function (func, sub) {
		if (!sub.$) {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Time(
				A2($elm$core$Basics$composeL, func, tagger));
		} else {
			var tagger = sub.a;
			return $elm$browser$Browser$AnimationManager$Delta(
				A2($elm$core$Basics$composeL, func, tagger));
		}
	});
_Platform_effectManagers['Browser.AnimationManager'] = _Platform_createManager($elm$browser$Browser$AnimationManager$init, $elm$browser$Browser$AnimationManager$onEffects, $elm$browser$Browser$AnimationManager$onSelfMsg, 0, $elm$browser$Browser$AnimationManager$subMap);
var $elm$browser$Browser$AnimationManager$subscription = _Platform_leaf('Browser.AnimationManager');
var $elm$browser$Browser$AnimationManager$onAnimationFrame = function (tagger) {
	return $elm$browser$Browser$AnimationManager$subscription(
		$elm$browser$Browser$AnimationManager$Time(tagger));
};
var $elm$browser$Browser$Events$onAnimationFrame = $elm$browser$Browser$AnimationManager$onAnimationFrame;
var $rundis$elm_bootstrap$Bootstrap$Alert$subscriptions = F2(
	function (visibility, animateMsg) {
		if (visibility === 1) {
			return $elm$browser$Browser$Events$onAnimationFrame(
				function (_v1) {
					return animateMsg(2);
				});
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$ListenClicks = 1;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {kI: pids, lp: subs};
	});
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {i7: event, jX: key};
	});
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.kI,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.jX;
		var event = _v0.i7;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.lp);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $rundis$elm_bootstrap$Bootstrap$Dropdown$updateStatus = F2(
	function (status, _v0) {
		var stateRec = _v0;
		return _Utils_update(
			stateRec,
			{nR: status});
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions = F2(
	function (state, toMsg) {
		var status = state.nR;
		switch (status) {
			case 0:
				return $elm$browser$Browser$Events$onAnimationFrame(
					function (_v1) {
						return toMsg(
							A2($rundis$elm_bootstrap$Bootstrap$Dropdown$updateStatus, 1, state));
					});
			case 1:
				return $elm$browser$Browser$Events$onClick(
					$elm$json$Json$Decode$succeed(
						toMsg(
							A2($rundis$elm_bootstrap$Bootstrap$Dropdown$updateStatus, 2, state))));
			default:
				return $elm$core$Platform$Sub$none;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$FadeClose = 2;
var $rundis$elm_bootstrap$Bootstrap$Modal$subscriptions = F2(
	function (visibility, animateMsg) {
		if (visibility === 1) {
			return $elm$browser$Browser$Events$onAnimationFrame(
				function (_v1) {
					return animateMsg(2);
				});
		} else {
			return $elm$core$Platform$Sub$none;
		}
	});
var $author$project$CreateContribution$GotEmailValidationRes = function (a) {
	return {$: 29, a: a};
};
var $author$project$CreateContribution$GotPhoneValidationRes = function (a) {
	return {$: 30, a: a};
};
var $author$project$CreateContribution$isValidEmailReceiver = _Platform_incomingPort('isValidEmailReceiver', $elm$json$Json$Decode$value);
var $author$project$CreateContribution$isValidNumReceiver = _Platform_incomingPort('isValidNumReceiver', $elm$json$Json$Decode$value);
var $author$project$CreateContribution$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$CreateContribution$isValidNumReceiver($author$project$CreateContribution$GotPhoneValidationRes),
				$author$project$CreateContribution$isValidEmailReceiver($author$project$CreateContribution$GotEmailValidationRes)
			]));
};
var $author$project$Page$Transactions$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.a9, $author$project$Page$Transactions$AnimateCreateContributionModal),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.bg, $author$project$Page$Transactions$AnimateGenerateDisclosureModal),
				A2($rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions, model.cU, $author$project$Page$Transactions$ToggleActionsDropdown),
				A2($rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions, model.dc, $author$project$Page$Transactions$ToggleFiltersDropdown),
				A2($rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions, model.di, $author$project$Page$Transactions$ToggleGenerateDisclosureModalDownloadDropdown),
				A2($rundis$elm_bootstrap$Bootstrap$Dropdown$subscriptions, model.dj, $author$project$Page$Transactions$ToggleGenerateDisclosureModalPreviewDropdown),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.bc, $author$project$Page$Transactions$CreateDisbursementModalAnimate),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.bd, $author$project$Page$Transactions$DisbRuleUnverifiedModalAnimate),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.aR, $author$project$Page$Transactions$DisbRuleVerifiedModalAnimate),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.bA, $author$project$Page$Transactions$ContribRuleUnverifiedModalAnimate),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$subscriptions, model.ar, $author$project$Page$Transactions$ContribRuleVerifiedModalAnimate),
				A2($rundis$elm_bootstrap$Bootstrap$Alert$subscriptions, model.r, $author$project$Page$Transactions$DeleteAlertMsg),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Page$Transactions$CreateContributionModalUpdated,
				$author$project$CreateContribution$subscriptions(model.A))
			]));
};
var $author$project$Main$subscriptions = function (model) {
	switch (model.$) {
		case 3:
			var transactions = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotTransactionsMsg,
				$author$project$Page$Transactions$subscriptions(transactions));
		case 2:
			var linkBuilder = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotLinkBuilderMsg,
				$author$project$Page$LinkBuilder$subscriptions(linkBuilder));
		case 4:
			var demo = model.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$GotDemoMsg,
				$author$project$Page$Demo$subscriptions(demo));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Route$Home = {$: 0};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Session$navKey = function (_v0) {
	var key = _v0.a;
	return key;
};
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Route$routeToPieces = function (page) {
	switch (page.$) {
		case 0:
			return _List_Nil;
		case 2:
			var id = page.a;
			return _List_fromArray(
				['committee', id]);
		case 1:
			var id = page.a;
			return _List_fromArray(
				['committee', id, 'link-builder']);
		default:
			var id = page.a;
			return _List_fromArray(
				['committee', id, 'demo']);
	}
};
var $author$project$Route$routeToString = function (page) {
	return '/' + A2(
		$elm$core$String$join,
		'/',
		$author$project$Route$routeToPieces(page));
};
var $author$project$Route$replaceUrl = F2(
	function (key, route) {
		return A2(
			$elm$browser$Browser$Navigation$replaceUrl,
			key,
			$author$project$Route$routeToString(route));
	});
var $author$project$Main$toConfig = function (page) {
	switch (page.$) {
		case 1:
			var session = page.a;
			return {ma: '', mm: '', mn: '', mC: '', k2: '', n6: ''};
		case 0:
			var session = page.a;
			return {ma: '', mm: '', mn: '', mC: '', k2: '', n6: ''};
		case 3:
			var transactions = page.a;
			return transactions.gh;
		case 2:
			var linkBuilder = page.a;
			return linkBuilder.gh;
		default:
			var demo = page.a;
			return demo.gh;
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.kR;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.jt,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.kT,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.kJ,
					_Utils_ap(http, url.jy)),
				url.kC)));
};
var $author$project$Page$Demo$Disbursement = 1;
var $author$project$Page$Demo$ManageDemoCommittee = 1;
var $author$project$Api$graphQLErrorDecoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string)));
var $author$project$Api$decodeError = function (error) {
	if (error.$ === 3) {
		var response = error.a;
		var _v1 = A2($elm$json$Json$Decode$decodeString, $author$project$Api$graphQLErrorDecoder, response.$9);
		if (!_v1.$) {
			var value = _v1.a;
			return value;
		} else {
			var err = _v1.a;
			return _List_fromArray(
				['Server error']);
		}
	} else {
		return _List_fromArray(
			['Server Error']);
	}
};
var $author$project$Page$Demo$GenDemoCommitteeGotResp = function (a) {
	return {$: 2, a: a};
};
var $author$project$Api$GenDemoCommittee$query = '\n    mutation(\n        $password: String!\n        $demoType: DemoType\n    ) {\n      generateCommittee(\n        genCommittee: {\n            password: $password,\n            demoType: $demoType\n        }\n      ) {\n        id\n      }\n    }\n    ';
var $author$project$Api$GenDemoCommittee$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(model.kB)),
					_Utils_Tuple2(
					'demoType',
					$elm$json$Json$Encode$string(model.iN))
				]));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$GenDemoCommittee$query, variables);
	});
var $author$project$Api$GraphQL$ResValidationFailure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Api$GraphQL$graphQLErrorDecoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string)));
var $author$project$Api$GraphQL$mutationValidationFailureDecoder = A2($elm$json$Json$Decode$map, $author$project$Api$GraphQL$ResValidationFailure, $author$project$Api$GraphQL$graphQLErrorDecoder);
var $author$project$Api$GraphQL$Success = function (a) {
	return {$: 0, a: a};
};
var $author$project$Api$GenDemoCommittee$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'generateCommittee',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$GenDemoCommittee$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$GenDemoCommittee$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$GenDemoCommittee$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$GenDemoCommittee$decoder, msg, config);
	});
var $author$project$Page$Demo$toGenDemoCommittee = function (model) {
	return {iN: 'Clean', kB: model.kB};
};
var $author$project$Page$Demo$genDemoCommittee = function (model) {
	return A3(
		$author$project$Api$GenDemoCommittee$send,
		$author$project$Page$Demo$GenDemoCommitteeGotResp,
		model.gh,
		A2($author$project$Api$GenDemoCommittee$encode, $author$project$Page$Demo$toGenDemoCommittee, model));
};
var $author$project$Cognito$loginUrl = F2(
	function (_v0, committeeId) {
		var cognitoDomain = _v0.mn;
		var cognitoClientId = _v0.mm;
		var redirectUri = _v0.k2;
		return cognitoDomain + ('/login?client_id=' + (cognitoClientId + ('&response_type=token&scope=email+openid+profile&redirect_uri=' + (redirectUri + ('&state=' + committeeId)))));
	});
var $author$project$Page$Demo$ReconcileDemoTxnGotResp = function (a) {
	return {$: 8, a: a};
};
var $author$project$Api$ReconcileDemoTxn$query = '\nmutation(\n  $password: String!\n  $committeeId: String!\n) {\n  reconcileOneDemoTransaction(\n    manageDemoCommitteeInput: {\n      password: $password,\n      committeeId: $committeeId,\n    }\n  ) {\n    id\n  }\n}\n    ';
var $author$project$Api$ReconcileDemoTxn$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(model.kB)),
					_Utils_Tuple2(
					'committeeId',
					$elm$json$Json$Encode$string(model.iE))
				]));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$ReconcileDemoTxn$query, variables);
	});
var $author$project$Api$ReconcileDemoTxn$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'reconcileOneDemoTransaction',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$ReconcileDemoTxn$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$ReconcileDemoTxn$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$ReconcileDemoTxn$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$ReconcileDemoTxn$decoder, msg, config);
	});
var $author$project$Page$Demo$toReconcileDemoTxn = function (model) {
	return {
		iE: A2($elm$core$Maybe$withDefault, '', model.bk),
		kB: model.kB
	};
};
var $author$project$Page$Demo$reconcileDemoTxn = function (model) {
	return A3(
		$author$project$Api$ReconcileDemoTxn$send,
		$author$project$Page$Demo$ReconcileDemoTxnGotResp,
		model.gh,
		A2($author$project$Api$ReconcileDemoTxn$encode, $author$project$Page$Demo$toReconcileDemoTxn, model));
};
var $author$project$Page$Demo$SeedDemoBankRecordGotResp = function (a) {
	return {$: 7, a: a};
};
var $author$project$Api$SeedDemoBankRecords$query = '\nmutation(\n  $password: String!\n  $committeeId: String!\n  $transactionType: TransactionType!\n) {\n  seedDemoBankRecords(\n    seedDemoBankRecordsInput: {\n      password: $password,\n      committeeId: $committeeId,\n      transactionType: $transactionType\n    }\n  ) {\n    id\n  }\n}\n    ';
var $author$project$Api$SeedDemoBankRecords$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'password',
					$elm$json$Json$Encode$string(model.kB)),
					_Utils_Tuple2(
					'committeeId',
					$elm$json$Json$Encode$string(model.iE)),
					_Utils_Tuple2(
					'transactionType',
					$elm$json$Json$Encode$string(model.lH))
				]));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$SeedDemoBankRecords$query, variables);
	});
var $author$project$Api$SeedDemoBankRecords$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'seedDemoBankRecords',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$SeedDemoBankRecords$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$SeedDemoBankRecords$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$SeedDemoBankRecords$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$SeedDemoBankRecords$decoder, msg, config);
	});
var $author$project$Page$Demo$toSeedDemoBankRecord = function (model) {
	return {
		iE: A2($elm$core$Maybe$withDefault, '', model.bk),
		kB: model.kB,
		lH: A2($elm$core$Maybe$withDefault, '', model.lH)
	};
};
var $author$project$Page$Demo$seedDemoBankRecord = F2(
	function (model, txnType) {
		var state = _Utils_update(
			model,
			{lH: txnType});
		return A3(
			$author$project$Api$SeedDemoBankRecords$send,
			$author$project$Page$Demo$SeedDemoBankRecordGotResp,
			model.gh,
			A2($author$project$Api$SeedDemoBankRecords$encode, $author$project$Page$Demo$toSeedDemoBankRecord, state));
	});
var $author$project$Page$Demo$txnTypeToString = function (transactionType) {
	if (!transactionType) {
		return 'Contribution';
	} else {
		return 'Disbursement';
	}
};
var $author$project$Page$Demo$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 5:
				var time = msg.a;
				var _v1 = model.aG;
				if (_v1) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aH: model.aH + 7}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kB: str}),
					$elm$core$Platform$Cmd$none);
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fJ: session}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var res = msg.a;
				if (!res.$) {
					var body = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ic: body.iJ.ic, iD: body.iJ.iD, gv: _List_Nil}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(
							A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gv: _List_Nil, aG: true}),
					$author$project$Page$Demo$genDemoCommittee(model));
			case 2:
				var res = msg.a;
				if (!res.$) {
					var createContribResp = res.a;
					if (!createContribResp.$) {
						var id = createContribResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									c5: 1,
									gv: _List_Nil,
									aG: false,
									aH: 0,
									bk: $elm$core$Maybe$Just(id)
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var errList = createContribResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gv: $elm$core$List$singleton(
										A2(
											$elm$core$Maybe$withDefault,
											'Unexpected API response',
											$elm$core$List$head(errList))),
									aG: false,
									aH: 0
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gv: $author$project$Api$decodeError(err),
								aG: false,
								aH: 0
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 6:
				var txnType = msg.a;
				var loadingModel = (txnType === 1) ? _Utils_update(
					model,
					{bZ: true}) : _Utils_update(
					model,
					{bY: true});
				return _Utils_Tuple2(
					_Utils_update(
						loadingModel,
						{
							lH: $elm$core$Maybe$Just(
								$author$project$Page$Demo$txnTypeToString(txnType))
						}),
					A2(
						$author$project$Page$Demo$seedDemoBankRecord,
						model,
						$elm$core$Maybe$Just(
							$author$project$Page$Demo$txnTypeToString(txnType))));
			case 7:
				var res = msg.a;
				if (!res.$) {
					var seedDemoResp = res.a;
					if (!seedDemoResp.$) {
						var id = seedDemoResp.a;
						var txn = A2($elm$core$Maybe$withDefault, '', model.lH);
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gv: _List_Nil,
									bf: _Utils_ap(
										model.bf,
										_List_fromArray(
											[txn + (' Bank Record' + ' Seeded')])),
									bY: false,
									bZ: false
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var errList = seedDemoResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gv: $elm$core$List$singleton(
										A2(
											$elm$core$Maybe$withDefault,
											'Unexpected API response',
											$elm$core$List$head(errList))),
									bY: false,
									bZ: false
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gv: $author$project$Api$decodeError(err),
								aG: false,
								aH: 0,
								bY: false,
								bZ: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bX: true}),
					$author$project$Page$Demo$reconcileDemoTxn(model));
			case 8:
				var res = msg.a;
				if (!res.$) {
					var seedDemoResp = res.a;
					if (!seedDemoResp.$) {
						var id = seedDemoResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gv: _List_Nil,
									bf: _Utils_ap(
										model.bf,
										_List_fromArray(
											['Transaction Reconciled'])),
									bX: false
								}),
							$elm$core$Platform$Cmd$none);
					} else {
						var errList = seedDemoResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gv: $elm$core$List$singleton(
										A2(
											$elm$core$Maybe$withDefault,
											'Unexpected API response',
											$elm$core$List$head(errList))),
									bX: false
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								gv: $author$project$Api$decodeError(err),
								bX: false
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c5: 0, gv: _List_Nil, bf: _List_Nil, aG: false, aH: 0, bk: $elm$core$Maybe$Nothing, kB: ''}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$LinkBuilder$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fJ: session}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fz: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			default:
				var res = msg.a;
				if (!res.$) {
					var body = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ic: body.iJ.ic, iD: body.iJ.iD}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(
							A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
				}
		}
	});
var $author$project$FormID$AmendDisb = 4;
var $author$project$DeleteInfo$Confirmed = 0;
var $author$project$Page$Transactions$ContribRuleUnverifiedModalUpdate = function (a) {
	return {$: 45, a: a};
};
var $author$project$Page$Transactions$ContribRuleVerifiedModalUpdate = function (a) {
	return {$: 50, a: a};
};
var $author$project$FormID$CreateDisb = 0;
var $author$project$Page$Transactions$CreateDisbursementModalUpdate = function (a) {
	return {$: 29, a: a};
};
var $author$project$Page$Transactions$DisbRuleUnverifiedModalUpdate = function (a) {
	return {$: 34, a: a};
};
var $author$project$Page$Transactions$DisbRuleVerifiedModalUpdate = function (a) {
	return {$: 39, a: a};
};
var $author$project$Page$Transactions$Download = 0;
var $author$project$Page$Transactions$FileDisclosureDelayed = {$: 21};
var $author$project$Page$Transactions$Preview = 1;
var $author$project$FormID$ReconcileContrib = 3;
var $author$project$FormID$ReconcileDisb = 2;
var $andrewMacmurray$elm_delay$Delay$Second = 1;
var $author$project$DeleteInfo$Unconfirmed = 1;
var $andrewMacmurray$elm_delay$Delay$Duration = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Process$sleep = _Process_sleep;
var $andrewMacmurray$elm_delay$Delay$after_ = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(time));
	});
var $andrewMacmurray$elm_delay$Delay$Minute = 2;
var $andrewMacmurray$elm_delay$Delay$toMillis = function (_v0) {
	var t = _v0.a;
	var u = _v0.b;
	switch (u) {
		case 0:
			return t;
		case 1:
			return 1000 * t;
		case 2:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 1));
		default:
			return $andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, 60 * t, 2));
	}
};
var $andrewMacmurray$elm_delay$Delay$after = F3(
	function (time, unit, msg) {
		return A2(
			$andrewMacmurray$elm_delay$Delay$after_,
			$andrewMacmurray$elm_delay$Delay$toMillis(
				A2($andrewMacmurray$elm_delay$Delay$Duration, time, unit)),
			msg);
	});
var $author$project$Page$Transactions$ContribRuleVerifiedGotMutResp = function (a) {
	return {$: 52, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$amendTxnEncoder = function (model) {
	return {h9: model.h9, ia: model.ia, ig: model.dX.ig, iu: model.iu, iw: model.iw, iE: model.iE, i0: model.i0, i1: model.i1, i2: model.i2, i5: model.i5, jh: model.jh, m2: model.m2, m4: model.m4, j_: model.j_, kb: model.kb, ni: model.ni, nk: model.nk, g7: model.g7, nB: model.nB, ku: model.ku, kE: model.kE, kF: model.dX.kF, kH: model.kH, kM: model.kM, ln: model.ln, dX: model.dX};
};
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {mw: d, no: m, on: y};
			}
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		hb: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		on: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.on, 0, date.hb);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.mw;
	});
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {iz: col, mt: contextStack, kO: problem, aK: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.aK, s.iz, x, s.h));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.e, s.c);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{iz: 1, h: s.h, m: s.m, e: s.e + 1, aK: s.aK + 1, c: s.c}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{iz: s.iz + 1, h: s.h, m: s.m, e: newOffset, aK: s.aK, c: s.c}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.kO;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.c),
			s.e) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.e, s1.e, s0.c),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.e, s.aK, s.iz, s.c);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{iz: newCol, h: s.h, m: s.m, e: newOffset, aK: newRow, c: s.c});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn) && A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d) : $elm$core$Result$Err(
			'Invalid calendar date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(mn) + (', ' + ($elm$core$String$fromInt(d) + ')'))))));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 365, od) || ((od === 366) && $justinmimbs$date$Date$isLeapYear(y))) ? $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od) : $elm$core$Result$Err(
			'Invalid ordinal date (' + ($elm$core$String$fromInt(y) + (', ' + ($elm$core$String$fromInt(od) + ')'))));
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		return (A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn) && (A3($justinmimbs$date$Date$isBetweenInt, 1, 52, wn) || ((wn === 53) && $justinmimbs$date$Date$is53WeekYear(wy)))) ? $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn) : $elm$core$Result$Err(
			'Invalid week date (' + ($elm$core$String$fromInt(wy) + (', ' + ($elm$core$String$fromInt(wn) + (', ' + ($elm$core$String$fromInt(wdn) + ')'))))));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {iz: col, kO: problem, aK: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.aK, p.iz, p.kO);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{iz: 1, h: _List_Nil, m: 1, e: 0, aK: 1, c: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$DateTime = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$InternalDateTime = F2(
	function (date, time) {
		return {b: date, d: time};
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$Day = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt = function (_v0) {
	var day = _v0;
	return day;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$isLeapYear = function (_v0) {
	var _int = _v0;
	return (!A2($elm$core$Basics$modBy, 4, _int)) && ((!A2($elm$core$Basics$modBy, 400, _int)) || (!(!A2($elm$core$Basics$modBy, 100, _int))));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf = F2(
	function (year, month) {
		switch (month) {
			case 0:
				return 31;
			case 1:
				return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$isLeapYear(year) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayFromInt = F3(
	function (year, month, day) {
		var maxValidDay = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(
			A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, year, month));
		return ((day > 0) && (A2($elm$core$Basics$compare, day, maxValidDay) !== 2)) ? $elm$core$Maybe$Just(day) : $elm$core$Maybe$Nothing;
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$Date = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays = F2(
	function (lhs, rhs) {
		return A2(
			$elm$core$Basics$compare,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(lhs),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(rhs));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromYearMonthDay = F3(
	function (y, m, d) {
		var maxDay = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, y, m);
		var _v0 = A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$compareDays, d, maxDay);
		if (_v0 === 2) {
			return $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Just(
				{mw: d, no: m, on: y});
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawDay = F3(
	function (year, month, day) {
		return A2(
			$elm$core$Maybe$andThen,
			A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromYearMonthDay, year, month),
			A3($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayFromInt, year, month, day));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$Year = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearFromInt = function (year) {
	return (year > 0) ? $elm$core$Maybe$Just(year) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawParts = function (_v0) {
	var year = _v0.on;
	var month = _v0.no;
	var day = _v0.mw;
	return A2(
		$elm$core$Maybe$andThen,
		function (y) {
			return A3($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawDay, y, month, day);
		},
		$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearFromInt(year));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$fromRawParts = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$fromRawParts;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$InternalTime = F4(
	function (hours, minutes, seconds, milliseconds) {
		return {m$: hours, nl: milliseconds, nm: minutes, nO: seconds};
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Time = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Hour = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursFromInt = function (hours) {
	return ((hours >= 0) && (hours < 24)) ? $elm$core$Maybe$Just(hours) : $elm$core$Maybe$Nothing;
};
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Millisecond = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsFromInt = function (millis) {
	return ((millis >= 0) && (millis < 1000)) ? $elm$core$Maybe$Just(millis) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Minute = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesFromInt = function (minutes) {
	return ((minutes >= 0) && (minutes < 60)) ? $elm$core$Maybe$Just(minutes) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$Second = $elm$core$Basics$identity;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsFromInt = function (seconds) {
	return ((seconds >= 0) && (seconds < 60)) ? $elm$core$Maybe$Just(seconds) : $elm$core$Maybe$Nothing;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts = function (_v0) {
	var hours = _v0.m$;
	var minutes = _v0.nm;
	var seconds = _v0.nO;
	var milliseconds = _v0.nl;
	return A5(
		$elm$core$Maybe$map4,
		F4(
			function (h, m, s, mm) {
				return A4($PanagiotisGeorgiadis$elm_datetime$Clock$Internal$InternalTime, h, m, s, mm);
			}),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursFromInt(hours),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesFromInt(minutes),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsFromInt(seconds),
		$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsFromInt(milliseconds));
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$fromRawParts = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$fromRawParts;
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$fromRawParts = F2(
	function (rawDate, rawTime) {
		return A3(
			$elm$core$Maybe$map2,
			F2(
				function (date, time) {
					return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$InternalDateTime, date, time);
				}),
			$PanagiotisGeorgiadis$elm_datetime$Calendar$fromRawParts(rawDate),
			$PanagiotisGeorgiadis$elm_datetime$Clock$fromRawParts(rawTime));
	});
var $PanagiotisGeorgiadis$elm_datetime$DateTime$fromRawParts = F2(
	function (rawDate, rawTime) {
		return A2($PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$fromRawParts, rawDate, rawTime);
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.no;
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay = ((1000 * 60) * 60) * 24;
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInYear = function (year) {
	return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$isLeapYear(year) ? ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * 366) : ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * 365);
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceEpoch = function (_v0) {
	var year = _v0;
	var getTotalMillis = A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$elm$core$List$sum,
			$elm$core$List$map($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInYear)),
		$elm$core$List$filterMap($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$yearFromInt));
	var epochYear = 1970;
	return (year >= 1970) ? getTotalMillis(
		A2($elm$core$List$range, epochYear, year - 1)) : (-getTotalMillis(
		A2($elm$core$List$range, year, epochYear - 1)));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheMonth = function (day) {
	return $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(day) - 1);
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{w: nodeList, s: nodeListSize, v: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$months = $elm$core$Array$fromList(
	_List_fromArray(
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]));
var $elm$core$Elm$JsArray$appendN = _JsArray_appendN;
var $elm$core$Elm$JsArray$slice = _JsArray_slice;
var $elm$core$Array$appendHelpBuilder = F2(
	function (tail, builder) {
		var tailLen = $elm$core$Elm$JsArray$length(tail);
		var notAppended = ($elm$core$Array$branchFactor - $elm$core$Elm$JsArray$length(builder.v)) - tailLen;
		var appended = A3($elm$core$Elm$JsArray$appendN, $elm$core$Array$branchFactor, builder.v, tail);
		return (notAppended < 0) ? {
			w: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.w),
			s: builder.s + 1,
			v: A3($elm$core$Elm$JsArray$slice, notAppended, tailLen, tail)
		} : ((!notAppended) ? {
			w: A2(
				$elm$core$List$cons,
				$elm$core$Array$Leaf(appended),
				builder.w),
			s: builder.s + 1,
			v: $elm$core$Elm$JsArray$empty
		} : {w: builder.w, s: builder.s, v: appended});
	});
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$sliceLeft = F2(
	function (from, array) {
		var len = array.a;
		var tree = array.c;
		var tail = array.d;
		if (!from) {
			return array;
		} else {
			if (_Utils_cmp(
				from,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					len - from,
					$elm$core$Array$shiftStep,
					$elm$core$Elm$JsArray$empty,
					A3(
						$elm$core$Elm$JsArray$slice,
						from - $elm$core$Array$tailIndex(len),
						$elm$core$Elm$JsArray$length(tail),
						tail));
			} else {
				var skipNodes = (from / $elm$core$Array$branchFactor) | 0;
				var helper = F2(
					function (node, acc) {
						if (!node.$) {
							var subTree = node.a;
							return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
						} else {
							var leaf = node.a;
							return A2($elm$core$List$cons, leaf, acc);
						}
					});
				var leafNodes = A3(
					$elm$core$Elm$JsArray$foldr,
					helper,
					_List_fromArray(
						[tail]),
					tree);
				var nodesToInsert = A2($elm$core$List$drop, skipNodes, leafNodes);
				if (!nodesToInsert.b) {
					return $elm$core$Array$empty;
				} else {
					var head = nodesToInsert.a;
					var rest = nodesToInsert.b;
					var firstSlice = from - (skipNodes * $elm$core$Array$branchFactor);
					var initialBuilder = {
						w: _List_Nil,
						s: 0,
						v: A3(
							$elm$core$Elm$JsArray$slice,
							firstSlice,
							$elm$core$Elm$JsArray$length(head),
							head)
					};
					return A2(
						$elm$core$Array$builderToArray,
						true,
						A3($elm$core$List$foldl, $elm$core$Array$appendHelpBuilder, initialBuilder, rest));
				}
			}
		}
	});
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$fetchNewTail = F4(
	function (shift, end, treeEnd, tree) {
		fetchNewTail:
		while (true) {
			var pos = $elm$core$Array$bitMask & (treeEnd >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var sub = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$end = end,
					$temp$treeEnd = treeEnd,
					$temp$tree = sub;
				shift = $temp$shift;
				end = $temp$end;
				treeEnd = $temp$treeEnd;
				tree = $temp$tree;
				continue fetchNewTail;
			} else {
				var values = _v0.a;
				return A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, values);
			}
		}
	});
var $elm$core$Array$hoistTree = F3(
	function (oldShift, newShift, tree) {
		hoistTree:
		while (true) {
			if ((_Utils_cmp(oldShift, newShift) < 1) || (!$elm$core$Elm$JsArray$length(tree))) {
				return tree;
			} else {
				var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, 0, tree);
				if (!_v0.$) {
					var sub = _v0.a;
					var $temp$oldShift = oldShift - $elm$core$Array$shiftStep,
						$temp$newShift = newShift,
						$temp$tree = sub;
					oldShift = $temp$oldShift;
					newShift = $temp$newShift;
					tree = $temp$tree;
					continue hoistTree;
				} else {
					return tree;
				}
			}
		}
	});
var $elm$core$Elm$JsArray$unsafeSet = _JsArray_unsafeSet;
var $elm$core$Array$sliceTree = F3(
	function (shift, endIdx, tree) {
		var lastPos = $elm$core$Array$bitMask & (endIdx >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, lastPos, tree);
		if (!_v0.$) {
			var sub = _v0.a;
			var newSub = A3($elm$core$Array$sliceTree, shift - $elm$core$Array$shiftStep, endIdx, sub);
			return (!$elm$core$Elm$JsArray$length(newSub)) ? A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree) : A3(
				$elm$core$Elm$JsArray$unsafeSet,
				lastPos,
				$elm$core$Array$SubTree(newSub),
				A3($elm$core$Elm$JsArray$slice, 0, lastPos + 1, tree));
		} else {
			return A3($elm$core$Elm$JsArray$slice, 0, lastPos, tree);
		}
	});
var $elm$core$Array$sliceRight = F2(
	function (end, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		if (_Utils_eq(end, len)) {
			return array;
		} else {
			if (_Utils_cmp(
				end,
				$elm$core$Array$tailIndex(len)) > -1) {
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					startShift,
					tree,
					A3($elm$core$Elm$JsArray$slice, 0, $elm$core$Array$bitMask & end, tail));
			} else {
				var endIdx = $elm$core$Array$tailIndex(end);
				var depth = $elm$core$Basics$floor(
					A2(
						$elm$core$Basics$logBase,
						$elm$core$Array$branchFactor,
						A2($elm$core$Basics$max, 1, endIdx - 1)));
				var newShift = A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep);
				return A4(
					$elm$core$Array$Array_elm_builtin,
					end,
					newShift,
					A3(
						$elm$core$Array$hoistTree,
						startShift,
						newShift,
						A3($elm$core$Array$sliceTree, startShift, endIdx, tree)),
					A4($elm$core$Array$fetchNewTail, startShift, end, endIdx, tree));
			}
		}
	});
var $elm$core$Array$translateIndex = F2(
	function (index, _v0) {
		var len = _v0.a;
		var posIndex = (index < 0) ? (len + index) : index;
		return (posIndex < 0) ? 0 : ((_Utils_cmp(posIndex, len) > 0) ? len : posIndex);
	});
var $elm$core$Array$slice = F3(
	function (from, to, array) {
		var correctTo = A2($elm$core$Array$translateIndex, to, array);
		var correctFrom = A2($elm$core$Array$translateIndex, from, array);
		return (_Utils_cmp(correctFrom, correctTo) > 0) ? $elm$core$Array$empty : A2(
			$elm$core$Array$sliceLeft,
			correctFrom,
			A2($elm$core$Array$sliceRight, correctTo, array));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getPrecedingMonths = function (month) {
	return $elm$core$Array$toList(
		A3(
			$elm$core$Array$slice,
			0,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$monthToInt(month) - 1,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$months));
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheYear = F2(
	function (year, month) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (m, res) {
					return res + ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisInADay * $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$dayToInt(
						A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$lastDayOf, year, m)));
				}),
			0,
			$PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$getPrecedingMonths(month));
	});
var $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toMillis = function (_v0) {
	var year = _v0.on;
	var month = _v0.no;
	var day = _v0.mw;
	return ($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceEpoch(year) + A2($PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheYear, year, month)) + $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$millisSinceStartOfTheMonth(day);
};
var $PanagiotisGeorgiadis$elm_datetime$Calendar$toMillis = $PanagiotisGeorgiadis$elm_datetime$Calendar$Internal$toMillis;
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt = function (_v0) {
	var hours = _v0;
	return hours;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt = function (_v0) {
	var milliseconds = _v0;
	return milliseconds;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt = function (_v0) {
	var minutes = _v0;
	return minutes;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt = function (_v0) {
	var seconds = _v0;
	return seconds;
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$toMillis = function (_v0) {
	var hours = _v0.m$;
	var minutes = _v0.nm;
	var seconds = _v0.nO;
	var milliseconds = _v0.nl;
	return $elm$core$List$sum(
		_List_fromArray(
			[
				$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$hoursToInt(hours) * 3600000,
				$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$minutesToInt(minutes) * 60000,
				$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$secondsToInt(seconds) * 1000,
				$PanagiotisGeorgiadis$elm_datetime$Clock$Internal$millisecondsToInt(milliseconds)
			]));
};
var $PanagiotisGeorgiadis$elm_datetime$Clock$toMillis = $PanagiotisGeorgiadis$elm_datetime$Clock$Internal$toMillis;
var $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$toMillis = function (_v0) {
	var date = _v0.b;
	var time = _v0.d;
	return $PanagiotisGeorgiadis$elm_datetime$Calendar$toMillis(date) + $PanagiotisGeorgiadis$elm_datetime$Clock$toMillis(time);
};
var $PanagiotisGeorgiadis$elm_datetime$DateTime$toMillis = $PanagiotisGeorgiadis$elm_datetime$DateTime$Internal$toMillis;
var $author$project$Timestamp$dateStringToMillis = function (val) {
	if (val === '') {
		return 0;
	} else {
		var _v0 = $justinmimbs$date$Date$fromIsoString(val);
		if (!_v0.$) {
			var date = _v0.a;
			var y = $justinmimbs$date$Date$year(date);
			var m = $justinmimbs$date$Date$month(date);
			var d = $justinmimbs$date$Date$day(date);
			var _v1 = A2(
				$PanagiotisGeorgiadis$elm_datetime$DateTime$fromRawParts,
				{mw: d, no: m, on: y},
				{m$: 5, nl: 0, nm: 0, nO: 0});
			if (!_v1.$) {
				var datetime = _v1.a;
				return $PanagiotisGeorgiadis$elm_datetime$DateTime$toMillis(datetime);
			} else {
				return 0;
			}
		} else {
			var a = _v0.a;
			return 0;
		}
	}
};
var $author$project$EmploymentStatus$toDataString = function (src) {
	switch (src) {
		case 0:
			return 'Employed';
		case 1:
			return 'SelfEmployed';
		case 2:
			return 'Retired';
		default:
			return 'Unemployed';
	}
};
var $author$project$EmploymentStatus$fromMaybeToString = A2(
	$elm$core$Basics$composeL,
	$elm$core$Maybe$withDefault(''),
	$elm$core$Maybe$map($author$project$EmploymentStatus$toDataString));
var $author$project$EntityType$toDataString = function (entityType) {
	switch (entityType) {
		case 0:
			return 'Fam';
		case 1:
			return 'Ind';
		case 2:
			return 'Solep';
		case 3:
			return 'Part';
		case 5:
			return 'Can';
		case 4:
			return 'Corp';
		case 6:
			return 'Union';
		case 7:
			return 'Assoc';
		case 8:
			return 'Llc';
		case 9:
			return 'Pac';
		case 10:
			return 'Plc';
		default:
			return 'Oth';
	}
};
var $author$project$EntityType$fromMaybeToStringWithDefaultInd = function (maybeEntityType) {
	return $author$project$EntityType$toDataString(
		A2($elm$core$Maybe$withDefault, 1, maybeEntityType));
};
var $author$project$Api$GraphQL$optionalFieldNotZero = F2(
	function (key, val) {
		return (val > 0) ? _List_fromArray(
			[
				_Utils_Tuple2(
				key,
				$elm$json$Json$Encode$int(val))
			]) : _List_Nil;
	});
var $author$project$Owners$encoder = function (owner) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'firstName',
				$elm$json$Json$Encode$string(owner.jh)),
				_Utils_Tuple2(
				'lastName',
				$elm$json$Json$Encode$string(owner.j_)),
				_Utils_Tuple2(
				'addressLine1',
				$elm$json$Json$Encode$string(owner.h9)),
				_Utils_Tuple2(
				'addressLine2',
				$elm$json$Json$Encode$string(owner.ia)),
				_Utils_Tuple2(
				'city',
				$elm$json$Json$Encode$string(owner.iw)),
				_Utils_Tuple2(
				'state',
				$elm$json$Json$Encode$string(owner.ln)),
				_Utils_Tuple2(
				'postalCode',
				$elm$json$Json$Encode$string(owner.kM)),
				_Utils_Tuple2(
				'percentOwnership',
				$elm$json$Json$Encode$string(owner.dD))
			]));
};
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Api$GraphQL$optionalFieldOwners = F2(
	function (key, val) {
		return _Utils_eq(val, _List_Nil) ? _List_Nil : _List_fromArray(
			[
				_Utils_Tuple2(
				key,
				A2($elm$json$Json$Encode$list, $author$project$Owners$encoder, val))
			]);
	});
var $author$project$Api$GraphQL$optionalFieldString = F2(
	function (key, val) {
		return (val === '') ? _List_Nil : _List_fromArray(
			[
				_Utils_Tuple2(
				key,
				$elm$json$Json$Encode$string(val))
			]);
	});
var $author$project$Api$AmendContrib$query = '\n    mutation(\n      $committeeId: String!\n      $transactionId: String!\n      $amount: Float\n      $paymentMethod: PaymentMethod\n      $owners: [Owner!]\n      $firstName: String\n      $lastName: String\n      $addressLine1: String\n      $city: String\n      $state: State\n      $postalCode: String\n      $entityType: EntityType\n      $emailAddress: String\n      $paymentDate: Float\n      $checkNumber: String\n      $entityName: String\n      $employer: String\n      $occupation: String\n      $middleName: String\n      $refCode: String\n      $addressLine2: String\n      $companyName: String\n      $phoneNumber: String\n      $attestsToBeingAnAdultCitizen: Boolean\n      $employmentStatus: EmploymentStatus\n    ) {\n      amendContribution(\n        amendContributionData: {\n          committeeId: $committeeId\n          transactionId: $transactionId\n          amount: $amount\n          paymentMethod: $paymentMethod\n          owners: $owners\n          firstName: $firstName\n          lastName: $lastName\n          addressLine1: $addressLine1\n          city: $city\n          state: $state\n          postalCode: $postalCode\n          entityType: $entityType\n          emailAddress: $emailAddress\n          paymentDate: $paymentDate\n          checkNumber: $checkNumber\n          entityName: $entityName\n          employer: $employer\n          occupation: $occupation\n          middleName: $middleName\n          refCode: $refCode\n          addressLine2: $addressLine2\n          companyName: $companyName\n          phoneNumber: $phoneNumber\n          attestsToBeingAnAdultCitizen: $attestsToBeingAnAdultCitizen\n          employmentStatus: $employmentStatus\n        }\n      ) {\n        id\n      }\n    }\n    ';
var $author$project$PaymentMethod$toDataString = function (method) {
	switch (method) {
		case 0:
			return 'Cash';
		case 1:
			return 'Ach';
		case 2:
			return 'Wire';
		case 3:
			return 'Check';
		case 6:
			return 'Debit';
		case 4:
			return 'Credit';
		case 5:
			return 'InKind';
		case 7:
			return 'Transfer';
		case 8:
			return 'OnlineProcessor';
		default:
			return 'Other';
	}
};
var $author$project$Api$AmendContrib$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'committeeId',
						$elm$json$Json$Encode$string(model.dX.iE)),
						_Utils_Tuple2(
						'transactionId',
						$elm$json$Json$Encode$string(model.dX.jB)),
						_Utils_Tuple2(
						'amount',
						$elm$json$Json$Encode$int(model.dX.ig)),
						_Utils_Tuple2(
						'paymentMethod',
						$elm$json$Json$Encode$string(
							$author$project$PaymentMethod$toDataString(model.dX.kF))),
						_Utils_Tuple2(
						'firstName',
						$elm$json$Json$Encode$string(model.jh)),
						_Utils_Tuple2(
						'lastName',
						$elm$json$Json$Encode$string(model.j_)),
						_Utils_Tuple2(
						'addressLine1',
						$elm$json$Json$Encode$string(model.h9)),
						_Utils_Tuple2(
						'city',
						$elm$json$Json$Encode$string(model.iw)),
						_Utils_Tuple2(
						'state',
						$elm$json$Json$Encode$string(model.ln)),
						_Utils_Tuple2(
						'postalCode',
						$elm$json$Json$Encode$string(model.kM))
					]),
				_Utils_ap(
					A2(
						$author$project$Api$GraphQL$optionalFieldString,
						'entityType',
						$author$project$EntityType$fromMaybeToStringWithDefaultInd(model.kb)),
					_Utils_ap(
						A2($author$project$Api$GraphQL$optionalFieldString, 'emailAddress', model.i0),
						_Utils_ap(
							A2(
								$author$project$Api$GraphQL$optionalFieldNotZero,
								'paymentDate',
								$author$project$Timestamp$dateStringToMillis(model.kE)),
							_Utils_ap(
								A2($author$project$Api$GraphQL$optionalFieldString, 'checkNumber', model.iu),
								_Utils_ap(
									A2($author$project$Api$GraphQL$optionalFieldString, 'entityName', model.i5),
									_Utils_ap(
										A2($author$project$Api$GraphQL$optionalFieldOwners, 'owners', model.ku),
										_Utils_ap(
											A2($author$project$Api$GraphQL$optionalFieldString, 'employer', model.i1),
											_Utils_ap(
												A2($author$project$Api$GraphQL$optionalFieldString, 'occupation', model.g7),
												_Utils_ap(
													A2($author$project$Api$GraphQL$optionalFieldString, 'addressLine2', model.ia),
													_Utils_ap(
														A2($author$project$Api$GraphQL$optionalFieldString, 'occupation', model.g7),
														_Utils_ap(
															A2($author$project$Api$GraphQL$optionalFieldString, 'phoneNumber', model.kH),
															A2(
																$author$project$Api$GraphQL$optionalFieldString,
																'employmentStatus',
																$author$project$EmploymentStatus$fromMaybeToString(model.i2)))))))))))))));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$AmendContrib$query, variables);
	});
var $author$project$Api$AmendContrib$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'amendContribution',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$AmendContrib$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$AmendContrib$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$AmendContrib$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$AmendContrib$decoder, msg, config);
	});
var $author$project$Page$Transactions$amendContrib = function (model) {
	return A3(
		$author$project$Api$AmendContrib$send,
		$author$project$Page$Transactions$ContribRuleVerifiedGotMutResp,
		model.gh,
		A2($author$project$Api$AmendContrib$encode, $author$project$TxnForm$ContribRuleVerified$amendTxnEncoder, model.o));
};
var $author$project$Page$Transactions$DisbRuleVerifiedGotMutResp = function (a) {
	return {$: 41, a: a};
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$PurposeCode$toString = function (purpose) {
	var maybePurpose = $elm$core$List$head(
		A2(
			$elm$core$List$filter,
			function (_v2) {
				var p = _v2.a;
				return _Utils_eq(p, purpose);
			},
			$author$project$PurposeCode$purposeCodeText));
	if (!maybePurpose.$) {
		var _v1 = maybePurpose.a;
		var val = _v1.b;
		return val;
	} else {
		return '';
	}
};
var $author$project$PurposeCode$fromMaybeToString = A2(
	$elm$core$Basics$composeL,
	$elm$core$Maybe$withDefault('---'),
	$elm$core$Maybe$map($author$project$PurposeCode$toString));
var $author$project$Api$AmendDisb$query = '\n    mutation (\n      $committeeId: String!\n      $transactionId: String!\n      $entityName: String\n      $addressLine1: String\n      $addressLine2: String\n      $city: String\n      $state: State\n      $postalCode: String\n      $paymentDate: Float\n      $checkNumber: String\n      $purposeCode: PurposeCode\n      $isExistingLiability: Boolean\n      $isPartialPayment: Boolean\n      $isSubContracted: Boolean\n    ) {\n      amendDisbursement(\n        amendDisbursementData: {\n          committeeId: $committeeId\n          transactionId: $transactionId\n          entityName: $entityName\n          addressLine1: $addressLine1\n          addressLine2: $addressLine2\n          city: $city\n          state: $state\n          postalCode: $postalCode\n          paymentDate: $paymentDate\n          checkNumber: $checkNumber\n          purposeCode: $purposeCode\n          isExistingLiability: $isExistingLiability\n          isPartialPayment: $isPartialPayment\n          isSubcontracted: $isSubContracted\n        }\n      ) {\n        id\n      }\n    }\n    ';
var $author$project$Api$AmendDisb$encode = function (model) {
	var variables = $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'committeeId',
					$elm$json$Json$Encode$string(model.dX.iE)),
					_Utils_Tuple2(
					'transactionId',
					$elm$json$Json$Encode$string(model.dX.jB))
				]),
			_Utils_ap(
				A2($author$project$Api$GraphQL$optionalFieldString, 'entityName', model.i5),
				_Utils_ap(
					A2($author$project$Api$GraphQL$optionalFieldString, 'addressLine1', model.h9),
					_Utils_ap(
						A2($author$project$Api$GraphQL$optionalFieldString, 'addressLine2', model.ia),
						_Utils_ap(
							A2($author$project$Api$GraphQL$optionalFieldString, 'city', model.iw),
							_Utils_ap(
								A2($author$project$Api$GraphQL$optionalFieldString, 'state', model.ln),
								_Utils_ap(
									A2($author$project$Api$GraphQL$optionalFieldString, 'postalCode', model.kM),
									_Utils_ap(
										A2(
											$author$project$Api$GraphQL$optionalFieldNotZero,
											'paymentDate',
											$author$project$Timestamp$dateStringToMillis(model.kE)),
										_Utils_ap(
											A2($author$project$Api$GraphQL$optionalFieldString, 'checkNumber', model.iu),
											_Utils_ap(
												A2(
													$author$project$Api$GraphQL$optionalFieldString,
													'purposeCode',
													$author$project$PurposeCode$fromMaybeToString(model.nE)),
												_Utils_ap(
													_List_fromArray(
														[
															_Utils_Tuple2(
															'isExistingLiability',
															$elm$json$Json$Encode$bool(
																A2($elm$core$Maybe$withDefault, false, model.m8)))
														]),
													_Utils_ap(
														_List_fromArray(
															[
																_Utils_Tuple2(
																'isPartialPayment',
																$elm$json$Json$Encode$bool(
																	A2($elm$core$Maybe$withDefault, false, model.na)))
															]),
														_List_fromArray(
															[
																_Utils_Tuple2(
																'isSubContracted',
																$elm$json$Json$Encode$bool(
																	A2($elm$core$Maybe$withDefault, false, model.nb)))
															]))))))))))))));
	return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$AmendDisb$query, variables);
};
var $author$project$Api$AmendDisb$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'amendDisbursement',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$AmendDisb$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$AmendDisb$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$AmendDisb$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$AmendDisb$decoder, msg, config);
	});
var $author$project$Page$Transactions$amendDisb = function (model) {
	return A3(
		$author$project$Api$AmendDisb$send,
		$author$project$Page$Transactions$DisbRuleVerifiedGotMutResp,
		model.gh,
		$author$project$Api$AmendDisb$encode(model.p));
};
var $author$project$Page$Transactions$isNeedsReviewTxn = function (txn) {
	return ((!txn.lb) && txn.ga) || (txn.lb && (!txn.ga));
};
var $author$project$Page$Transactions$applyNeedsReviewFilter = F2(
	function (model, txns) {
		return model.bO ? A2($elm$core$List$filter, $author$project$Page$Transactions$isNeedsReviewTxn, txns) : txns;
	});
var $author$project$Page$Transactions$GotCreateContributionResponse = function (a) {
	return {$: 10, a: a};
};
var $author$project$Cents$fromDollars = function (amountStr) {
	var _v0 = $elm$core$String$toFloat(amountStr);
	if (!_v0.$) {
		var _float = _v0.a;
		return $elm$core$Basics$round(_float * 100);
	} else {
		return 0;
	}
};
var $author$project$PaymentMethod$fromMaybeToString = A2(
	$elm$core$Basics$composeL,
	$elm$core$Maybe$withDefault('---'),
	$elm$core$Maybe$map($author$project$PaymentMethod$toDataString));
var $author$project$Api$GraphQL$optionalFieldStringInt = F2(
	function (key, val) {
		return (val === '') ? _List_Nil : _List_fromArray(
			[
				_Utils_Tuple2(
				key,
				$elm$json$Json$Encode$int(
					A2(
						$elm$core$Maybe$withDefault,
						1,
						$elm$core$String$toInt(val))))
			]);
	});
var $author$project$Api$CreateContrib$query = '\n    mutation(\n      $committeeId: String!\n      $amount: Float!\n      $paymentMethod: PaymentMethod!\n      $firstName: String!\n      $lastName: String!\n      $addressLine1: String!\n      $city: String!\n      $state: State!\n      $postalCode: String!\n      $entityType: EntityType!\n      $employmentStatus: EmploymentStatus\n      $emailAddress: String\n      $phoneNumber: String\n      $paymentDate: Float!\n      $cardNumber: String\n      $cardExpirationMonth: Float\n      $cardExpirationYear: Float\n      $cardCVC: String\n      $checkNumber: String\n      $entityName: String\n      $owners: [Owner!]\n      $employer: String\n      $occupation: String\n      $middleName: String\n      $refCode: String\n      $inKindDescription: String\n      $inKindType: InKindType\n      $processPayment: Boolean!\n    ) {\n      createContribution(createContributionData: {\n        committeeId: $committeeId\n        amount: $amount\n        paymentMethod: $paymentMethod\n        firstName: $firstName\n        lastName: $lastName\n        addressLine1: $addressLine1\n        city: $city\n        state: $state\n        postalCode: $postalCode\n        entityType: $entityType\n        employmentStatus: $employmentStatus\n        emailAddress: $emailAddress\n        phoneNumber: $phoneNumber\n        paymentDate: $paymentDate\n        cardNumber: $cardNumber\n        cardExpirationMonth: $cardExpirationMonth\n        cardExpirationYear: $cardExpirationYear\n        cardCVC: $cardCVC\n        checkNumber: $checkNumber\n        entityName: $entityName\n        owners: $owners\n        employer: $employer\n        occupation: $occupation\n        middleName: $middleName\n        refCode: $refCode\n        inKindDescription: $inKindDescription\n        inKindType: $inKindType\n        processPayment: $processPayment\n      }) {\n        id\n      }\n    }\n    ';
var $author$project$InKindType$toDataString = function (src) {
	switch (src) {
		case 0:
			return 'ServicesFacilitiesProvided';
		case 1:
			return 'CampaignExpensesPaid';
		default:
			return 'PropertyGiven';
	}
};
var $author$project$Api$CreateContrib$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'committeeId',
						$elm$json$Json$Encode$string(model.iE)),
						_Utils_Tuple2(
						'amount',
						$elm$json$Json$Encode$int(
							$author$project$Cents$fromDollars(model.ig))),
						_Utils_Tuple2(
						'paymentMethod',
						$elm$json$Json$Encode$string(
							$author$project$PaymentMethod$fromMaybeToString(model.kF))),
						_Utils_Tuple2(
						'firstName',
						$elm$json$Json$Encode$string(model.jh)),
						_Utils_Tuple2(
						'lastName',
						$elm$json$Json$Encode$string(model.j_)),
						_Utils_Tuple2(
						'addressLine1',
						$elm$json$Json$Encode$string(model.h9)),
						_Utils_Tuple2(
						'city',
						$elm$json$Json$Encode$string(model.iw)),
						_Utils_Tuple2(
						'state',
						$elm$json$Json$Encode$string(model.ln)),
						_Utils_Tuple2(
						'postalCode',
						$elm$json$Json$Encode$string(model.kM)),
						_Utils_Tuple2(
						'entityType',
						$elm$json$Json$Encode$string(
							$author$project$EntityType$fromMaybeToStringWithDefaultInd(model.kb))),
						_Utils_Tuple2(
						'transactionType',
						$elm$json$Json$Encode$string(
							$author$project$TransactionType$toString(0))),
						_Utils_Tuple2(
						'processPayment',
						$elm$json$Json$Encode$bool(model.kP))
					]),
				_Utils_ap(
					A2($author$project$Api$GraphQL$optionalFieldString, 'emailAddress', model.i0),
					_Utils_ap(
						A2(
							$author$project$Api$GraphQL$optionalFieldNotZero,
							'paymentDate',
							$author$project$Timestamp$dateStringToMillis(model.kE)),
						_Utils_ap(
							A2($author$project$Api$GraphQL$optionalFieldString, 'cardNumber', model.ir),
							_Utils_ap(
								A2($author$project$Api$GraphQL$optionalFieldStringInt, 'cardExpirationMonth', model.i9),
								_Utils_ap(
									A2($author$project$Api$GraphQL$optionalFieldStringInt, 'cardExpirationYear', model.ja),
									_Utils_ap(
										A2($author$project$Api$GraphQL$optionalFieldString, 'cardCVC', model.iH),
										_Utils_ap(
											A2($author$project$Api$GraphQL$optionalFieldString, 'checkNumber', model.iu),
											_Utils_ap(
												A2($author$project$Api$GraphQL$optionalFieldString, 'entityName', model.i5),
												_Utils_ap(
													A2(
														$author$project$Api$GraphQL$optionalFieldOwners,
														'owners',
														A2($elm$core$Maybe$withDefault, _List_Nil, model.ku)),
													_Utils_ap(
														A2($author$project$Api$GraphQL$optionalFieldString, 'employer', model.i1),
														_Utils_ap(
															A2($author$project$Api$GraphQL$optionalFieldString, 'occupation', model.g7),
															_Utils_ap(
																A2($author$project$Api$GraphQL$optionalFieldString, 'middleName', model.nk),
																_Utils_ap(
																	A2($author$project$Api$GraphQL$optionalFieldString, 'addressLine2', model.ia),
																	_Utils_ap(
																		A2($author$project$Api$GraphQL$optionalFieldString, 'phoneNumber', model.kH),
																		_Utils_ap(
																			A2(
																				$author$project$Api$GraphQL$optionalFieldString,
																				'employmentStatus',
																				$author$project$EmploymentStatus$fromMaybeToString(model.i2)),
																			_Utils_ap(
																				A2($author$project$Api$GraphQL$optionalFieldString, 'inKindDescription', model.m2),
																				A2(
																					$author$project$Api$GraphQL$optionalFieldString,
																					'inKindType',
																					A2(
																						$elm$core$Maybe$withDefault,
																						'',
																						A2($elm$core$Maybe$map, $author$project$InKindType$toDataString, model.m4)))))))))))))))))))));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$CreateContrib$query, variables);
	});
var $author$project$Api$CreateContrib$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'createContribution',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$CreateContrib$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$CreateContrib$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$CreateContrib$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$CreateContrib$decoder, msg, config);
	});
var $author$project$OwnersView$toMaybeOwners = function (model) {
	return _Utils_eq(model.ku, _List_Nil) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(model.ku);
};
var $author$project$CreateContribution$toEncodeModel = function (model) {
	return {
		h9: model.h9,
		ia: model.ia,
		ig: model.ig,
		ir: model.ir,
		iu: model.iu,
		iw: model.iw,
		iE: model.iE,
		iH: model.iH,
		i0: model.i0,
		i1: model.i1,
		i2: model.i2,
		i5: model.i5,
		i9: model.i9,
		ja: model.ja,
		jh: model.jh,
		m2: model.m2,
		m4: model.m4,
		j_: model.j_,
		kb: model.kb,
		nk: model.nk,
		g7: model.g7,
		ku: $author$project$OwnersView$toMaybeOwners(model.kv),
		kE: model.kE,
		kF: model.kF,
		kH: model.kH,
		kM: model.kM,
		kP: true,
		ln: model.ln
	};
};
var $author$project$Page$Transactions$createContribution = function (model) {
	return A3(
		$author$project$Api$CreateContrib$send,
		$author$project$Page$Transactions$GotCreateContributionResponse,
		model.gh,
		A2($author$project$Api$CreateContrib$encode, $author$project$CreateContribution$toEncodeModel, model.A));
};
var $author$project$Page$Transactions$GotCreateDisbursementResponse = function (a) {
	return {$: 11, a: a};
};
var $author$project$Api$CreateDisb$query = '\n    mutation(\n      $committeeId: String!\n      $amount: Float!\n      $paymentMethod: PaymentMethod!\n      $entityName: String!\n      $addressLine1: String!\n      $city: String!\n      $state: State!\n      $postalCode: String!\n      $isSubcontracted: Boolean!\n      $isPartialPayment: Boolean!\n      $isExistingLiability: Boolean!\n      $purposeCode: PurposeCode!\n      $paymentDate: Float!\n      $checkNumber: String\n      $addressLine2: String\n    ) {\n      createDisbursement(createDisbursementData: {\n        committeeId: $committeeId\n        amount: $amount\n        paymentMethod: $paymentMethod\n        entityName: $entityName\n        addressLine1: $addressLine1\n        city: $city\n        state: $state\n        postalCode: $postalCode\n        isSubcontracted: $isSubcontracted\n        isPartialPayment: $isPartialPayment\n        isExistingLiability: $isExistingLiability\n        purposeCode: $purposeCode\n        paymentDate: $paymentDate\n        checkNumber: $checkNumber\n        addressLine2: $addressLine2\n      }) {\n        id\n      }\n    }\n    ';
var $author$project$Api$CreateDisb$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_Utils_ap(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'committeeId',
						$elm$json$Json$Encode$string(model.iE)),
						_Utils_Tuple2(
						'amount',
						$elm$json$Json$Encode$int(
							$author$project$Cents$fromDollars(model.ig))),
						_Utils_Tuple2(
						'paymentMethod',
						$elm$json$Json$Encode$string(
							$author$project$PaymentMethod$fromMaybeToString(model.kF))),
						_Utils_Tuple2(
						'entityName',
						$elm$json$Json$Encode$string(model.i5)),
						_Utils_Tuple2(
						'addressLine1',
						$elm$json$Json$Encode$string(model.h9)),
						_Utils_Tuple2(
						'city',
						$elm$json$Json$Encode$string(model.iw)),
						_Utils_Tuple2(
						'state',
						$elm$json$Json$Encode$string(model.ln)),
						_Utils_Tuple2(
						'postalCode',
						$elm$json$Json$Encode$string(model.kM)),
						_Utils_Tuple2(
						'isSubcontracted',
						$elm$json$Json$Encode$bool(
							A2($elm$core$Maybe$withDefault, false, model.nb))),
						_Utils_Tuple2(
						'isPartialPayment',
						$elm$json$Json$Encode$bool(
							A2($elm$core$Maybe$withDefault, false, model.na))),
						_Utils_Tuple2(
						'isExistingLiability',
						$elm$json$Json$Encode$bool(
							A2($elm$core$Maybe$withDefault, false, model.m8))),
						_Utils_Tuple2(
						'purposeCode',
						$elm$json$Json$Encode$string(
							$author$project$PurposeCode$toString(
								A2($elm$core$Maybe$withDefault, 24, model.nE)))),
						_Utils_Tuple2(
						'paymentDate',
						$elm$json$Json$Encode$int(
							$author$project$Timestamp$dateStringToMillis(model.kE))),
						_Utils_Tuple2(
						'transactionType',
						$elm$json$Json$Encode$string(
							$author$project$TransactionType$toString(1)))
					]),
				_Utils_ap(
					A2($author$project$Api$GraphQL$optionalFieldString, 'checkNumber', model.iu),
					A2($author$project$Api$GraphQL$optionalFieldString, 'addressLine2', model.ia))));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$CreateDisb$query, variables);
	});
var $author$project$Api$CreateDisb$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'createDisbursement',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$CreateDisb$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$CreateDisb$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$CreateDisb$send = $author$project$Api$GraphQL$send($author$project$Api$CreateDisb$decoder);
var $author$project$CreateDisbursement$toEncodeModel = function (model) {
	return {h9: model.h9, ia: model.ia, ig: model.ig, iu: model.iu, iw: model.iw, iE: model.iE, i5: model.i5, m8: model.m8, m9: model.m9, na: model.na, nb: model.nb, kE: model.kE, kF: model.kF, kM: model.kM, nE: model.nE, ln: model.ln};
};
var $author$project$Page$Transactions$createDisbursement = function (model) {
	return A3(
		$author$project$Api$CreateDisb$send,
		$author$project$Page$Transactions$GotCreateDisbursementResponse,
		model.gh,
		A2($author$project$Api$CreateDisb$encode, $author$project$CreateDisbursement$toEncodeModel, model.B));
};
var $author$project$Page$Transactions$GotDeleteTxnMutResp = function (a) {
	return {$: 54, a: a};
};
var $author$project$Page$Transactions$deleteTxnMapper = F2(
	function (txnId, model) {
		return {iE: model.iE, jB: txnId};
	});
var $author$project$Api$DeleteTxn$query = '\n    mutation(\n        $id: String!\n        $committeeId: String!\n      ) {\n        deleteTransaction(\n          id: $id\n          committeeId: $committeeId\n        ) {\n          id\n        }\n      }\n    ';
var $author$project$Api$DeleteTxn$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$string(model.jB)),
					_Utils_Tuple2(
					'committeeId',
					$elm$json$Json$Encode$string(model.iE))
				]));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$DeleteTxn$query, variables);
	});
var $author$project$Api$DeleteTxn$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'deleteTransaction',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$DeleteTxn$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$DeleteTxn$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$DeleteTxn$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$DeleteTxn$decoder, msg, config);
	});
var $author$project$Page$Transactions$deleteTxn = F2(
	function (model, txnId) {
		return A3(
			$author$project$Api$DeleteTxn$send,
			$author$project$Page$Transactions$GotDeleteTxnMutResp,
			model.gh,
			A2(
				$author$project$Api$DeleteTxn$encode,
				$author$project$Page$Transactions$deleteTxnMapper(txnId),
				model));
	});
var $author$project$CreateContribution$fromError = F2(
	function (model, error) {
		return _Utils_update(
			model,
			{
				kc: $elm$core$Maybe$Just(error)
			});
	});
var $author$project$CreateDisbursement$fromError = F2(
	function (model, error) {
		return _Utils_update(
			model,
			{
				kc: $elm$core$Maybe$Just(error)
			});
	});
var $author$project$TxnForm$ContribRuleUnverified$fromError = F2(
	function (model, str) {
		return _Utils_update(
			model,
			{
				kc: $elm$core$Maybe$Just(str)
			});
	});
var $author$project$TxnForm$ContribRuleVerified$fromError = F2(
	function (model, error) {
		return _Utils_update(
			model,
			{
				kc: $elm$core$Maybe$Just(error)
			});
	});
var $author$project$TxnForm$DisbRuleUnverified$fromError = F2(
	function (model, error) {
		return _Utils_update(
			model,
			{
				kc: $elm$core$Maybe$Just(error)
			});
	});
var $author$project$TxnForm$DisbRuleVerified$fromError = F2(
	function (model, error) {
		return _Utils_update(
			model,
			{
				kc: $elm$core$Maybe$Just(error)
			});
	});
var $author$project$Page$Transactions$GotTxnsData = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Transactions$getRehydrateTxnsSet = F2(
	function (model, maybeTxnType) {
		return A3(
			$author$project$Api$GetTxns$send,
			$author$project$Page$Transactions$GotTxnsData,
			model.gh,
			A4(
				$author$project$Api$GetTxns$encode,
				model.iE,
				maybeTxnType,
				$elm$core$Maybe$Just(model.dB),
				$elm$core$Maybe$Nothing));
	});
var $author$project$Page$Transactions$GotReportData = function (a) {
	return {$: 13, a: a};
};
var $author$project$Api$GetReport$query = '\n    query(\n      $committeeId: String!\n      $includeHeaders: Boolean!\n    ) {\n      report(\n        committeeId: $committeeId\n        includeHeaders: $includeHeaders\n      ) {\n        csvData\n      }\n    }\n    ';
var $author$project$Api$GetReport$encode = F2(
	function (committeeId, includeHeaders) {
		return A2(
			$author$project$Api$GraphQL$encodeQuery,
			$author$project$Api$GetReport$query,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'committeeId',
						$elm$json$Json$Encode$string(committeeId)),
						_Utils_Tuple2(
						'includeHeaders',
						$elm$json$Json$Encode$bool(includeHeaders))
					])));
	});
var $author$project$Api$GetReport$Model = function (data) {
	return {iJ: data};
};
var $author$project$Api$GetReport$Object = function (report) {
	return {k4: report};
};
var $author$project$Api$GetReport$Report = function (csvData) {
	return {iG: csvData};
};
var $author$project$Api$GetReport$reportDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'csvData',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Api$GetReport$Report));
var $author$project$Api$GetReport$decodeObject = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GetReport$Object,
	A2($elm$json$Json$Decode$field, 'report', $author$project$Api$GetReport$reportDecoder));
var $author$project$Api$GetReport$decoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GetReport$Model,
	A2($elm$json$Json$Decode$field, 'data', $author$project$Api$GetReport$decodeObject));
var $author$project$Api$GetReport$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$GetReport$decoder, msg, config);
	});
var $author$project$Page$Transactions$getReport = F2(
	function (model, includeHeaders) {
		return A3(
			$author$project$Api$GetReport$send,
			$author$project$Page$Transactions$GotReportData,
			model.gh,
			A2($author$project$Api$GetReport$encode, model.iE, includeHeaders));
	});
var $author$project$Page$Transactions$getTransactions = F2(
	function (model, maybeTxnType) {
		return A3(
			$author$project$Api$GetTxns$send,
			$author$project$Page$Transactions$GotTxnsData,
			model.gh,
			A4($author$project$Api$GetTxns$encode, model.iE, maybeTxnType, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing));
	});
var $author$project$TxnForm$ContribRuleVerified$loadingInit = function () {
	var state = $author$project$TxnForm$ContribRuleVerified$init($author$project$Transaction$init);
	return _Utils_update(
		state,
		{dt: true});
}();
var $author$project$TxnForm$DisbRuleVerified$loadingInit = function () {
	var state = $author$project$TxnForm$DisbRuleVerified$init($author$project$Transaction$init);
	return _Utils_update(
		state,
		{dt: true});
}();
var $author$project$TxnForm$ContribRuleUnverified = 3;
var $author$project$TxnForm$ContribRuleVerified = 2;
var $author$project$TxnForm$ContribUnverified = 4;
var $author$project$TxnForm$DisbRuleUnverified = 1;
var $author$project$TxnForm$DisbRuleVerified = 0;
var $author$project$TxnForm$NoOp = 5;
var $author$project$TxnForm$fromTxn = function (txn) {
	var _v0 = _Utils_Tuple3(txn.iP, txn.lb, txn.ga);
	if (_v0.a === 1) {
		if (_v0.b) {
			if (!_v0.c) {
				var _v1 = _v0.a;
				return 0;
			} else {
				var _v2 = _v0.a;
				return 0;
			}
		} else {
			if (_v0.c) {
				var _v3 = _v0.a;
				return 1;
			} else {
				return 5;
			}
		}
	} else {
		if (_v0.b) {
			if (!_v0.c) {
				var _v4 = _v0.a;
				return 2;
			} else {
				var _v5 = _v0.a;
				return 2;
			}
		} else {
			if (_v0.c) {
				var _v6 = _v0.a;
				return 3;
			} else {
				var _v7 = _v0.a;
				return 4;
			}
		}
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$Show = 0;
var $rundis$elm_bootstrap$Bootstrap$Modal$shown = 0;
var $author$project$Page$Transactions$openTxnFormModalLoaded = F2(
	function (model, txn) {
		var _v0 = $author$project$TxnForm$fromTxn(txn);
		switch (_v0) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3($author$project$TxnForm$DisbRuleUnverified$init, model.gh, model.lI, txn),
							bd: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							p: $author$project$TxnForm$DisbRuleVerified$init(txn),
							aR: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: $author$project$TxnForm$ContribRuleVerified$init(txn),
							ar: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							o: $author$project$TxnForm$ContribRuleVerified$init(txn),
							ar: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Transactions$GotTxnData = function (a) {
	return {$: 12, a: a};
};
var $author$project$Api$GetTxn$query = '\n        query TransactionQuery($committeeId: String!, $id: String!) {\n          transaction(committeeId: $committeeId, id: $id) {\n            id\n            committeeId\n            direction\n            amount\n            paymentMethod\n            bankVerified\n            ruleVerified\n            initiatedTimestamp\n            source\n            paymentDate\n            purposeCode\n            refCode\n            firstName\n            middleName\n            lastName\n            addressLine1\n            addressLine2\n            entityName\n            city\n            state\n            postalCode\n            employer\n            employmentStatus\n            occupation\n            entityType\n            companyName\n            owners {\n              firstName\n              lastName\n              addressLine1\n              addressLine2\n              city\n              state\n              postalCode\n              percentOwnership\n            }\n            phoneNumber\n            emailAddress\n            transactionType\n            attestsToBeingAnAdultCitizen\n            stripePaymentIntentId\n            cardNumberLastFourDigits\n            checkNumber\n            finicityCategory\n            finicityBestRepresentation\n            finicityPostedDate\n            finicityTransactionDate\n            finicityNormalizedPayeeName\n            finicityDescription\n            isSubcontracted\n            isPartialPayment\n            isExistingLiability\n            inKindDescription\n            inKindType\n            finicityPaymentMethod\n            donorVerificationScore\n          }\n        }\n    ';
var $author$project$Api$GetTxn$encode = F2(
	function (committeeId, txnId) {
		return A2(
			$author$project$Api$GraphQL$encodeQuery,
			$author$project$Api$GetTxn$query,
			$elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'committeeId',
						$elm$json$Json$Encode$string(committeeId)),
						_Utils_Tuple2(
						'id',
						$elm$json$Json$Encode$string(txnId))
					])));
	});
var $author$project$Api$GetTxn$Model = function (data) {
	return {iJ: data};
};
var $author$project$Api$GetTxn$TransactionObject = function (transaction) {
	return {lG: transaction};
};
var $author$project$Api$GetTxn$decodeTransactionObject = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GetTxn$TransactionObject,
	A2($elm$json$Json$Decode$field, 'transaction', $author$project$Transaction$decoder));
var $author$project$Api$GetTxn$decoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GetTxn$Model,
	A2($elm$json$Json$Decode$field, 'data', $author$project$Api$GetTxn$decodeTransactionObject));
var $author$project$Api$GetTxn$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$GetTxn$decoder, msg, config);
	});
var $author$project$Page$Transactions$getTransaction = F2(
	function (model, txnId) {
		return A3(
			$author$project$Api$GetTxn$send,
			$author$project$Page$Transactions$GotTxnData,
			model.gh,
			A2($author$project$Api$GetTxn$encode, model.iE, txnId));
	});
var $author$project$Page$Transactions$openTxnFormModalLoading = F2(
	function (model, txn) {
		var _v0 = $author$project$TxnForm$fromTxn(txn);
		switch (_v0) {
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							D: A3($author$project$TxnForm$DisbRuleUnverified$init, model.gh, model.lI, txn),
							bd: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{p: $author$project$TxnForm$DisbRuleVerified$loadingInit, aR: $rundis$elm_bootstrap$Bootstrap$Modal$shown}),
					A2($author$project$Page$Transactions$getTransaction, model, txn.jB));
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							L: A3($author$project$TxnForm$ContribRuleUnverified$init, model.gh, model.lI, txn),
							bA: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: $author$project$TxnForm$ContribRuleVerified$loadingInit, ar: $rundis$elm_bootstrap$Bootstrap$Modal$shown}),
					A2($author$project$Page$Transactions$getTransaction, model, txn.jB));
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: $author$project$TxnForm$ContribRuleVerified$loadingInit, ar: $rundis$elm_bootstrap$Bootstrap$Modal$shown}),
					A2($author$project$Page$Transactions$getTransaction, model, txn.jB));
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$Transactions$ContribRuleUnverifiedGotReconcileMutResp = function (a) {
	return {$: 47, a: a};
};
var $author$project$Api$ReconcileTxn$query = '\n    mutation(\n      $committeeId: String!,\n      $selectedTransactions: [String!]!,\n      $bankTransaction: String!\n    ) {\n      reconcileTransaction(\n        reconcileTransactionData: {\n            selectedTransactions: $selectedTransactions,\n            bankTransaction: $bankTransaction,\n            committeeId: $committeeId\n        }\n      ) {\n        id\n      }\n    }\n    ';
var $author$project$Api$ReconcileTxn$encode = F2(
	function (mapper, val) {
		var model = mapper(val);
		var variables = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'selectedTransactions',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$string,
						A2(
							$elm$core$List$map,
							function (txn) {
								return txn.jB;
							},
							model.li))),
					_Utils_Tuple2(
					'bankTransaction',
					$elm$json$Json$Encode$string(model.ik.jB)),
					_Utils_Tuple2(
					'committeeId',
					$elm$json$Json$Encode$string(model.iE))
				]));
		return A2($author$project$Api$GraphQL$encodeQuery, $author$project$Api$ReconcileTxn$query, variables);
	});
var $author$project$TxnForm$ContribRuleUnverified$reconcileTxnEncoder = function (model) {
	return {ik: model.ik, iE: model.iE, li: model.li};
};
var $author$project$Api$ReconcileTxn$successDecoder = A2(
	$elm$json$Json$Decode$map,
	$author$project$Api$GraphQL$Success,
	A2(
		$elm$json$Json$Decode$field,
		'data',
		A2(
			$elm$json$Json$Decode$field,
			'reconcileTransaction',
			A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string))));
var $author$project$Api$ReconcileTxn$decoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[$author$project$Api$ReconcileTxn$successDecoder, $author$project$Api$GraphQL$mutationValidationFailureDecoder]));
var $author$project$Api$ReconcileTxn$send = F2(
	function (msg, config) {
		return A3($author$project$Api$GraphQL$send, $author$project$Api$ReconcileTxn$decoder, msg, config);
	});
var $author$project$Page$Transactions$reconcileContrib = function (model) {
	return A3(
		$author$project$Api$ReconcileTxn$send,
		$author$project$Page$Transactions$ContribRuleUnverifiedGotReconcileMutResp,
		model.gh,
		A2($author$project$Api$ReconcileTxn$encode, $author$project$TxnForm$ContribRuleUnverified$reconcileTxnEncoder, model.L));
};
var $author$project$Page$Transactions$DisbRuleUnverifiedGotReconcileMutResp = function (a) {
	return {$: 36, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$reconcileTxnEncoder = function (model) {
	return {ik: model.ik, iE: model.iE, li: model.li};
};
var $author$project$Page$Transactions$reconcileDisb = function (model) {
	return A3(
		$author$project$Api$ReconcileTxn$send,
		$author$project$Page$Transactions$DisbRuleUnverifiedGotReconcileMutResp,
		model.gh,
		A2($author$project$Api$ReconcileTxn$encode, $author$project$TxnForm$DisbRuleUnverified$reconcileTxnEncoder, model.D));
};
var $author$project$Page$Transactions$NoOp = {$: 25};
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $author$project$Page$Transactions$scrollToError = function (id) {
	return A2(
		$elm$core$Task$attempt,
		function (_v1) {
			return $author$project$Page$Transactions$NoOp;
		},
		A2(
			$elm$core$Task$andThen,
			function (_v0) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, 0, 0);
			},
			$elm$browser$Browser$Dom$getViewportOf(id)));
};
var $rundis$elm_bootstrap$Bootstrap$Alert$Shown = 0;
var $rundis$elm_bootstrap$Bootstrap$Alert$shown = 0;
var $elm$file$File$Download$string = F3(
	function (name, mime, content) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$never,
			A3(_File_download, name, mime, content));
	});
var $author$project$Api$GetTxns$toAggs = function (model) {
	return model.iJ.ic;
};
var $author$project$Api$GetTxns$toCommittee = function (model) {
	return model.iJ.iD;
};
var $author$project$Api$GetReport$toCsvData = function (model) {
	return model.iJ.k4.iG;
};
var $author$project$Api$GetTxn$toTxn = function (model) {
	return model.iJ.lG;
};
var $author$project$Api$GetTxns$toTxns = function (model) {
	return model.iJ.lI;
};
var $author$project$CreateContribution$OwnersViewUpdated = function (a) {
	return {$: 20, a: a};
};
var $author$project$CreateContribution$sendEmail = _Platform_outgoingPort('sendEmail', $elm$json$Json$Encode$string);
var $author$project$CreateContribution$sendPhone = _Platform_outgoingPort('sendPhone', $elm$json$Json$Encode$string);
var $author$project$OwnersView$EditingOwner = 0;
var $author$project$OwnersView$clearForm = function (model) {
	return _Utils_update(
		model,
		{h9: '', ia: '', iw: '', jh: '', j_: '', dD: '', kM: '', ln: ''});
};
var $author$project$Owners$toHash = function (owner) {
	return owner.jh + ('-' + (owner.j_ + ('-' + (owner.h9 + ('-' + (owner.iw + ('-' + (owner.ln + ('-' + owner.kM)))))))));
};
var $author$project$OwnersView$editOwnerInfo = F2(
	function (newOwnerInfo, model) {
		return _Utils_update(
			model,
			{
				au: false,
				ku: A2(
					$elm$core$List$map,
					function (owner) {
						return _Utils_eq(
							$author$project$Owners$toHash(owner),
							$author$project$Owners$toHash(model.c1)) ? newOwnerInfo : owner;
					},
					model.ku)
			});
	});
var $author$project$Owners$foldOwnership = function (owners) {
	return A3(
		$elm$core$List$foldl,
		$elm$core$Basics$add,
		0,
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeL,
				A2(
					$elm$core$Basics$composeL,
					$elm$core$Maybe$withDefault(0),
					$elm$core$String$toFloat),
				function ($) {
					return $.dD;
				}),
			owners));
};
var $author$project$Owners$ownershipToFloat = function (newOwner) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toFloat(newOwner.dD));
};
var $author$project$OwnersView$NoOp = {$: 13};
var $author$project$ViewportHeight$bottom = 0;
var $author$project$FormID$fromString = function (str) {
	switch (str) {
		case 'create-disb':
			return $elm$core$Maybe$Just(0);
		case 'create-contrib':
			return $elm$core$Maybe$Just(1);
		case 'reconcile-disb':
			return $elm$core$Maybe$Just(2);
		case 'reconcile-contrib':
			return $elm$core$Maybe$Just(3);
		case 'amend-disb':
			return $elm$core$Maybe$Just(4);
		case 'amend-contrib':
			return $elm$core$Maybe$Just(5);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$ViewportHeight$middle = 400;
var $author$project$ViewportHeight$top = 800;
var $author$project$ViewportHeight$idToViewHeight = function (id) {
	var _v0 = $author$project$FormID$fromString(id);
	_v0$3:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a) {
				case 1:
					var _v1 = _v0.a;
					return $author$project$ViewportHeight$bottom;
				case 3:
					var _v2 = _v0.a;
					return $author$project$ViewportHeight$middle;
				case 5:
					var _v3 = _v0.a;
					return $author$project$ViewportHeight$top;
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return $author$project$ViewportHeight$bottom;
};
var $author$project$OwnersView$scrollToError = function (id) {
	return A2(
		$elm$core$Task$attempt,
		function (_v1) {
			return $author$project$OwnersView$NoOp;
		},
		A2(
			$elm$core$Task$andThen,
			function (_v0) {
				return A3(
					$elm$browser$Browser$Dom$setViewportOf,
					id,
					0,
					$author$project$ViewportHeight$idToViewHeight(id));
			},
			$elm$browser$Browser$Dom$getViewportOf(id)));
};
var $author$project$OwnersView$setEditOwner = F2(
	function (model, owner) {
		return _Utils_update(
			model,
			{h9: owner.h9, ia: owner.ia, iw: owner.iw, c1: owner, jh: owner.jh, au: true, j_: owner.j_, ku: model.ku, dD: owner.dD, kM: owner.kM, ln: owner.ln});
	});
var $rtfeldman$elm_validate$Validate$Valid = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$validate = F2(
	function (_v0, subject) {
		var getErrors = _v0;
		var _v1 = getErrors(subject);
		if (!_v1.b) {
			return $elm$core$Result$Ok(subject);
		} else {
			var errors = _v1;
			return $elm$core$Result$Err(errors);
		}
	});
var $rtfeldman$elm_validate$Validate$Validator = $elm$core$Basics$identity;
var $rtfeldman$elm_validate$Validate$firstErrorHelp = F2(
	function (validators, subject) {
		firstErrorHelp:
		while (true) {
			if (!validators.b) {
				return _List_Nil;
			} else {
				var getErrors = validators.a;
				var rest = validators.b;
				var _v1 = getErrors(subject);
				if (!_v1.b) {
					var $temp$validators = rest,
						$temp$subject = subject;
					validators = $temp$validators;
					subject = $temp$subject;
					continue firstErrorHelp;
				} else {
					var errors = _v1;
					return errors;
				}
			}
		}
	});
var $rtfeldman$elm_validate$Validate$firstError = function (validators) {
	var getErrors = function (subject) {
		return A2($rtfeldman$elm_validate$Validate$firstErrorHelp, validators, subject);
	};
	return getErrors;
};
var $rtfeldman$elm_validate$Validate$ifTrue = F2(
	function (test, error) {
		var getErrors = function (subject) {
			return test(subject) ? _List_fromArray(
				[error]) : _List_Nil;
		};
		return getErrors;
	});
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $rtfeldman$elm_validate$Validate$ifBlank = F2(
	function (subjectToString, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return $rtfeldman$elm_validate$Validate$isBlank(
					subjectToString(subject));
			},
			error);
	});
var $author$project$Owners$validator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.dD;
			},
			'Ownership percentage must be a valid a number.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.jh;
			},
			'Owner First name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.j_;
			},
			'Owner Last name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.h9;
			},
			'Owner Address 1 is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.iw;
			},
			'Owner City is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ln;
			},
			'Owner State is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.kM;
			},
			'Owner Postal Code is missing.')
		]));
var $author$project$OwnersView$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 11:
				var newOwner = {h9: model.h9, ia: model.ia, iw: model.iw, jh: model.jh, j_: model.j_, dD: model.dD, kM: model.kM, ln: model.ln};
				var _v1 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$Owners$validator, newOwner);
				if (_v1.$ === 1) {
					var messages = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{gv: messages}),
						$author$project$OwnersView$scrollToError(
							A2($elm$core$Maybe$withDefault, '', model.eX)));
				} else {
					var totalPercentage = $author$project$Owners$foldOwnership(model.ku) + $author$project$Owners$ownershipToFloat(newOwner);
					if (totalPercentage > 100) {
						var remainder = $elm$core$String$fromFloat(
							$author$project$Owners$ownershipToFloat(newOwner) - (totalPercentage - 100));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									gv: _List_fromArray(
										['Ownership percentage total must add up to 100%. You have ' + (remainder + '% left to attribute.')])
								}),
							$author$project$OwnersView$scrollToError(
								A2($elm$core$Maybe$withDefault, '', model.eX)));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									h9: '',
									ia: '',
									iw: '',
									gv: _List_Nil,
									jh: '',
									au: false,
									j_: '',
									ku: _Utils_ap(
										model.ku,
										_List_fromArray(
											[newOwner])),
									dD: '',
									kM: '',
									ln: ''
								}),
							$elm$core$Platform$Cmd$none);
					}
				}
			case 0:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jh: str}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j_: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var newOwner = {h9: model.h9, ia: model.ia, iw: model.iw, jh: model.jh, j_: model.j_, dD: model.dD, kM: model.kM, ln: model.ln};
				var state = A2($author$project$OwnersView$editOwnerInfo, newOwner, model);
				var stateWithClearForm = $author$project$OwnersView$clearForm(state);
				return _Utils_Tuple2(
					_Utils_update(
						stateWithClearForm,
						{au: false}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dD: str}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var deletedOwner = msg.a;
				var state = $author$project$OwnersView$clearForm(model);
				var newOwners = A2(
					$elm$core$List$filter,
					function (owner) {
						return !_Utils_eq(
							$author$project$Owners$toHash(owner),
							$author$project$Owners$toHash(deletedOwner));
					},
					model.ku);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{ku: newOwners}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var owner = msg.a;
				var state = A2($author$project$OwnersView$setEditOwner, model, owner);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{
							df: $elm$core$Maybe$Just(0)
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var form = msg.a;
				var state = $author$project$OwnersView$clearForm(model);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{df: form, au: !model.au}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$CreateContribution$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 24:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 0:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var maybeOrgOrInd = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gv: _List_Nil, kb: $elm$core$Maybe$Nothing, ni: maybeOrgOrInd}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var entityName = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i5: entityName}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var maybeEntityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kb: maybeEntityType}),
					$elm$core$Platform$Cmd$none);
			case 20:
				var subMsg = msg.a;
				var _v1 = A2($author$project$OwnersView$update, subMsg, model.kv);
				var subModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kv: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$CreateContribution$OwnersViewUpdated, subCmd));
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kH: str}),
					$author$project$CreateContribution$sendPhone(str));
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i0: str}),
					$author$project$CreateContribution$sendEmail(str));
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jh: str}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nk: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j_: str}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var entityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							kb: $elm$core$Maybe$Just(entityType)
						}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i2: str}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i1: str}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{g7: str}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iu: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kE: str}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i9: str}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ir: str}),
					$elm$core$Platform$Cmd$none);
			case 26:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iH: str}),
					$elm$core$Platform$Cmd$none);
			case 21:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ja: str}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kF: str}),
					$elm$core$Platform$Cmd$none);
			case 27:
				var t = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m4: t}),
					$elm$core$Platform$Cmd$none);
			case 28:
				var t = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m2: t}),
					$elm$core$Platform$Cmd$none);
			case 29:
				var value = msg.a;
				var _v2 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$bool, value);
				if (!_v2.$) {
					var data = _v2.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{jO: data}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = _v2.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var value = msg.a;
				var _v3 = A2($elm$json$Json$Decode$decodeValue, $elm$json$Json$Decode$bool, value);
				if (!_v3.$) {
					var data = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eO: data}),
						$elm$core$Platform$Cmd$none);
				} else {
					var error = _v3.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$CreateDisbursement$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nE: str}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var pm = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gO: false, kF: pm}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i5: str}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iu: str}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kE: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nb: bool}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{na: bool}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m8: bool}),
					$elm$core$Platform$Cmd$none);
			default:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m9: bool}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$OwnersViewUpdated = function (a) {
	return {$: 21, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$clearForm = function (model) {
	return _Utils_update(
		model,
		{
			h9: '',
			ia: '',
			ig: $author$project$Cents$toDollarData(model.ik.ig),
			ir: '',
			iu: '',
			iw: '',
			bE: false,
			a8: false,
			iH: '',
			i0: '',
			i1: '',
			i2: $elm$core$Maybe$Nothing,
			i5: '',
			i9: '',
			ja: '',
			jh: '',
			j_: '',
			kb: $elm$core$Maybe$Nothing,
			kc: $elm$core$Maybe$Nothing,
			ni: $elm$core$Maybe$Nothing,
			nk: '',
			g7: '',
			nB: '',
			ku: $elm$core$Maybe$Nothing,
			kv: A2(
				$author$project$OwnersView$init,
				A2($elm$core$Maybe$withDefault, _List_Nil, model.ku),
				$elm$core$Maybe$Just(
					$author$project$FormID$toString(3))),
			kE: A2($author$project$Timestamp$formDate, model.fO, model.ik.kE),
			kF: $elm$core$Maybe$Nothing,
			kH: '',
			kM: '',
			ln: ''
		});
};
var $author$project$TxnForm$ContribRuleUnverified$CreateContribMutResp = function (a) {
	return {$: 35, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$createContribEncoder = function (model) {
	return {
		h9: model.h9,
		ia: model.ia,
		ig: model.ig,
		ir: model.ir,
		iu: model.iu,
		iw: model.iw,
		iE: model.iE,
		iH: model.iH,
		i0: model.i0,
		i1: model.i1,
		i2: model.i2,
		i5: model.i5,
		i9: model.i9,
		ja: model.ja,
		jh: model.jh,
		m2: model.m2,
		m4: model.m4,
		j_: model.j_,
		kb: model.kb,
		nk: model.nk,
		g7: model.g7,
		ku: $author$project$OwnersView$toMaybeOwners(model.kv),
		kE: model.kE,
		kF: model.kF,
		kH: model.kH,
		kM: model.kM,
		kP: false,
		ln: model.ln
	};
};
var $author$project$TxnForm$ContribRuleUnverified$createContrib = function (model) {
	return A3(
		$author$project$Api$CreateContrib$send,
		$author$project$TxnForm$ContribRuleUnverified$CreateContribMutResp,
		model.gh,
		A2($author$project$Api$CreateContrib$encode, $author$project$TxnForm$ContribRuleUnverified$createContribEncoder, model));
};
var $author$project$TxnForm$ContribRuleUnverified$getTxnById = F2(
	function (txns, id) {
		var matches = A2(
			$elm$core$List$filter,
			function (t) {
				return _Utils_eq(t.jB, id);
			},
			txns);
		if (matches.b && (!matches.b.b)) {
			var match = matches.a;
			return $elm$core$Maybe$Just(match);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$GetTxnsGotResp = function (a) {
	return {$: 36, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$getTxns = function (model) {
	return A3(
		$author$project$Api$GetTxns$send,
		$author$project$TxnForm$ContribRuleUnverified$GetTxnsGotResp,
		model.gh,
		A4(
			$author$project$Api$GetTxns$encode,
			model.iE,
			$elm$core$Maybe$Just(0),
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing));
};
var $author$project$TxnForm$ContribRuleUnverified$NoOp = {$: 0};
var $author$project$TxnForm$ContribRuleUnverified$scrollToError = function (id) {
	return A2(
		$elm$core$Task$attempt,
		function (_v1) {
			return $author$project$TxnForm$ContribRuleUnverified$NoOp;
		},
		A2(
			$elm$core$Task$andThen,
			function (_v0) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, 0, 800);
			},
			$elm$browser$Browser$Dom$getViewportOf(id)));
};
var $rtfeldman$elm_validate$Validate$all = function (validators) {
	var newGetErrors = function (subject) {
		var accumulateErrors = F2(
			function (_v0, totalErrors) {
				var getErrors = _v0;
				return _Utils_ap(
					totalErrors,
					getErrors(subject));
			});
		return A3($elm$core$List$foldl, accumulateErrors, _List_Nil, validators);
	};
	return newGetErrors;
};
var $author$project$Cents$fromMaybeDollars = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toFloat,
	$elm$core$Maybe$map(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$mul(100),
			$elm$core$Basics$round)));
var $author$project$Cents$toDollar = function (num) {
	var numStr = $elm$core$String$fromInt(num);
	var maybeTup = $elm$core$String$uncons(numStr);
	if (!maybeTup.$) {
		var val = maybeTup.a;
		var firstChar = val.a;
		var rest = val.b;
		return (firstChar === '-') ? ('-' + ('$' + $author$project$Cents$strToUnsignedDollar(rest))) : ('$' + $author$project$Cents$strToUnsignedDollar(numStr));
	} else {
		return '$';
	}
};
var $author$project$Errors$fromMaxAmount = F2(
	function (maxCents, valStr) {
		var maybeVal = $author$project$Cents$fromMaybeDollars(valStr);
		if (!maybeVal.$) {
			var val = maybeVal.a;
			var _v1 = A2($elm$core$Basics$compare, val, maxCents);
			if (_v1 === 2) {
				return _List_fromArray(
					[
						'Amount exceeds ' + $author$project$Cents$toDollar(maxCents)
					]);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_fromArray(
				['Amount must be a number']);
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$amountMaxToErrors = function (model) {
	return A2($author$project$Errors$fromMaxAmount, model.ik.ig, model.ig);
};
var $rtfeldman$elm_validate$Validate$fromErrors = function (toErrors) {
	return toErrors;
};
var $author$project$TxnForm$ContribRuleUnverified$amountValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ig;
			},
			'Amount is missing.'),
			$rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$amountMaxToErrors)
		]));
var $author$project$Timestamp$format = F2(
	function (zone, time) {
		var timePosix = $elm$time$Time$millisToPosix(time);
		var year = $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, zone, timePosix));
		var month = function () {
			var _v0 = A2($elm$time$Time$toMonth, zone, timePosix);
			switch (_v0) {
				case 0:
					return 'January';
				case 1:
					return 'February';
				case 2:
					return 'March';
				case 3:
					return 'April';
				case 4:
					return 'May';
				case 5:
					return 'June';
				case 6:
					return 'July';
				case 7:
					return 'August';
				case 8:
					return 'September';
				case 9:
					return 'October';
				case 10:
					return 'November';
				default:
					return 'December';
			}
		}();
		var day = $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, zone, timePosix));
		return month + (' ' + (day + (', ' + year)));
	});
var $author$project$Errors$fromMaxDate = F3(
	function (timezone, max, val) {
		var _v0 = A2($elm$core$Basics$compare, val, max);
		if (_v0 === 2) {
			return _List_fromArray(
				[
					'Date must be on or before ' + A2($author$project$Timestamp$format, timezone, max)
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$dateMaxToErrors = function (model) {
	return A3(
		$author$project$Errors$fromMaxDate,
		model.fO,
		model.ik.kE,
		$author$project$Timestamp$dateStringToMillis(model.kE));
};
var $author$project$Errors$fromFamilyStatus = F2(
	function (orgOrInd, entityType) {
		if ((!orgOrInd.$) && (orgOrInd.a === 1)) {
			var _v1 = orgOrInd.a;
			if (entityType.$ === 1) {
				return _List_fromArray(
					['Family Status is missing']);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$familyStatusOnModelToErrors = function (_v0) {
	var maybeOrgOrInd = _v0.ni;
	var maybeEntityType = _v0.kb;
	return A2($author$project$Errors$fromFamilyStatus, maybeOrgOrInd, maybeEntityType);
};
var $author$project$TxnForm$ContribRuleUnverified$familyStatusValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$familyStatusOnModelToErrors);
var $author$project$Errors$fromOrgType = F3(
	function (orgType, entity, entityName) {
		if ((!orgType.$) && (!orgType.a)) {
			var _v1 = orgType.a;
			if (!entity.$) {
				var a = entity.a;
				var _v3 = $elm$core$String$isEmpty(entityName);
				if (_v3) {
					return _List_fromArray(
						['Entity Name is missing']);
				} else {
					return _List_Nil;
				}
			} else {
				return _List_fromArray(
					['Org Classification is missing']);
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$orgTypeOnModelToErrors = function (_v0) {
	var maybeOrgOrInd = _v0.ni;
	var maybeEntityType = _v0.kb;
	var entityName = _v0.i5;
	return A3($author$project$Errors$fromOrgType, maybeOrgOrInd, maybeEntityType, entityName);
};
var $author$project$TxnForm$ContribRuleUnverified$orgTypeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$orgTypeOnModelToErrors);
var $author$project$EntityType$isLLCorLLP = function (contributorType) {
	_v0$2:
	while (true) {
		if (!contributorType.$) {
			switch (contributorType.a) {
				case 8:
					var _v1 = contributorType.a;
					return true;
				case 3:
					var _v2 = contributorType.a;
					return true;
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return false;
};
var $author$project$Errors$fromOwners = F2(
	function (owners, maybeEntity) {
		if ($author$project$EntityType$isLLCorLLP(maybeEntity)) {
			var totalPercentage = $author$project$Owners$foldOwnership(owners);
			if (totalPercentage !== 100) {
				var remainder = $elm$core$String$fromFloat(
					$elm$core$Basics$abs(100 - totalPercentage)) + '%';
				return _List_fromArray(
					['Ownership percentage total must add up to 100%. Total is off by ' + (remainder + '.')]);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$ownersOnModelToErrors = function (_v0) {
	var ownersViewModel = _v0.kv;
	var maybeEntityType = _v0.kb;
	return A2($author$project$Errors$fromOwners, ownersViewModel.ku, maybeEntityType);
};
var $author$project$TxnForm$ContribRuleUnverified$ownersValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$ownersOnModelToErrors);
var $author$project$Errors$fromContribPaymentInfo = function (config) {
	var _v0 = config.kF;
	_v0$3:
	while (true) {
		if (!_v0.$) {
			switch (_v0.a) {
				case 5:
					var _v1 = _v0.a;
					var _v2 = config.m4;
					if (!_v2.$) {
						var a = _v2.a;
						var _v3 = $elm$core$String$isEmpty(config.m3);
						if (_v3) {
							return _List_fromArray(
								['In-Kind Description is missing']);
						} else {
							return _List_Nil;
						}
					} else {
						return _List_fromArray(
							['In-Kind Info is missing']);
					}
				case 3:
					var _v4 = _v0.a;
					var _v5 = $elm$core$String$isEmpty(config.iu);
					if (_v5) {
						return _List_fromArray(
							['Check Number is missing']);
					} else {
						return _List_Nil;
					}
				case 4:
					var _v6 = _v0.a;
					return $elm$core$String$isEmpty(config.ir) ? _List_fromArray(
						['Card Number is missing']) : ($elm$core$String$isEmpty(config.i9) ? _List_fromArray(
						['Expiration Month is missing']) : ($elm$core$String$isEmpty(config.ja) ? _List_fromArray(
						['Expiration Year is missing']) : ($elm$core$String$isEmpty(config.iH) ? _List_fromArray(
						['CCV is missing']) : _List_Nil)));
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return _List_Nil;
};
var $author$project$TxnForm$ContribRuleUnverified$paymentInfoOnModelToErrors = function (model) {
	return $author$project$Errors$fromContribPaymentInfo(
		{ir: model.ir, iu: model.iu, iH: model.iH, i9: model.i9, ja: model.ja, m3: model.m2, m4: model.m4, kF: model.kF});
};
var $author$project$TxnForm$ContribRuleUnverified$paymentInfoValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$paymentInfoOnModelToErrors);
var $author$project$Errors$fromPostalCode = function (postalCode) {
	var length = $elm$core$String$length(postalCode);
	return (length < 5) ? _List_fromArray(
		['ZIP code is too short.']) : ((length > 9) ? _List_fromArray(
		['ZIP code is too long.']) : _List_Nil);
};
var $author$project$TxnForm$ContribRuleUnverified$postalCodeOnModelToErrors = function (model) {
	return $author$project$Errors$fromPostalCode(model.kM);
};
var $author$project$TxnForm$ContribRuleUnverified$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$postalCodeOnModelToErrors);
var $rtfeldman$elm_validate$Validate$ifNothing = F2(
	function (subjectToMaybe, error) {
		return A2(
			$rtfeldman$elm_validate$Validate$ifTrue,
			function (subject) {
				return _Utils_eq(
					subjectToMaybe(subject),
					$elm$core$Maybe$Nothing);
			},
			error);
	});
var $author$project$TxnForm$ContribRuleUnverified$requiredFieldValidators = _List_fromArray(
	[
		$author$project$TxnForm$ContribRuleUnverified$paymentInfoValidator,
		$author$project$TxnForm$ContribRuleUnverified$familyStatusValidator,
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ig;
		},
		'Payment Amount is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kE;
		},
		'Payment Date is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.kF;
		},
		'Processing Info is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.jh;
		},
		'First Name is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.j_;
		},
		'Last name is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.iw;
		},
		'City is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ln;
		},
		'State is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kM;
		},
		'Postal Code is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.h9;
		},
		'Address is missing')
	]);
var $author$project$TxnForm$ContribRuleUnverified$validator = $rtfeldman$elm_validate$Validate$all(
	_Utils_ap(
		$author$project$TxnForm$ContribRuleUnverified$requiredFieldValidators,
		_List_fromArray(
			[
				$author$project$TxnForm$ContribRuleUnverified$postalCodeValidator,
				$author$project$TxnForm$ContribRuleUnverified$amountValidator,
				$rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleUnverified$dateMaxToErrors),
				$author$project$TxnForm$ContribRuleUnverified$paymentInfoValidator,
				$author$project$TxnForm$ContribRuleUnverified$orgTypeValidator,
				$author$project$TxnForm$ContribRuleUnverified$ownersValidator,
				$author$project$TxnForm$ContribRuleUnverified$familyStatusValidator
			])));
var $author$project$TxnForm$ContribRuleUnverified$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 27:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var maybeOrgOrInd = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gv: _List_Nil, kb: $elm$core$Maybe$Nothing, ni: maybeOrgOrInd}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var entityName = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i5: entityName}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var maybeEntityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kb: maybeEntityType}),
					$elm$core$Platform$Cmd$none);
			case 19:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 20:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nB: str}),
					$elm$core$Platform$Cmd$none);
			case 21:
				var subMsg = msg.a;
				var _v1 = A2($author$project$OwnersView$update, subMsg, model.kv);
				var subModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kv: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$TxnForm$ContribRuleUnverified$OwnersViewUpdated, subCmd));
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kH: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i0: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jh: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nk: str}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j_: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var entityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							kb: $elm$core$Maybe$Just(entityType)
						}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i2: str}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i1: str}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{g7: str}),
					$elm$core$Platform$Cmd$none);
			case 28:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iu: str}),
					$elm$core$Platform$Cmd$none);
			case 29:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kE: str}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i9: str}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ir: str}),
					$elm$core$Platform$Cmd$none);
			case 26:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iH: str}),
					$elm$core$Platform$Cmd$none);
			case 22:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ja: str}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kF: str}),
					$elm$core$Platform$Cmd$none);
			case 31:
				var desc = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m2: desc}),
					$elm$core$Platform$Cmd$none);
			case 30:
				var t = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m4: t}),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iQ: !model.iQ}),
					$elm$core$Platform$Cmd$none);
			case 36:
				var res = msg.a;
				if (!res.$) {
					var body = res.a;
					var relatedTxns = A2(
						$author$project$TxnForm$ContribRuleUnverified$getRelatedContrib,
						model.ik,
						$author$project$Api$GetTxns$toTxns(body));
					var resTxnOrEmpty = A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							$elm$core$List$singleton,
							A2($author$project$TxnForm$ContribRuleUnverified$getTxnById, relatedTxns, model.eP)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fA: A2(
									$author$project$TxnForm$ContribRuleUnverified$getRelatedContrib,
									model.ik,
									$author$project$Api$GetTxns$toTxns(body)),
								li: _Utils_ap(model.li, resTxnOrEmpty)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(
							A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
				}
			case 32:
				return _Utils_Tuple2(
					function () {
						var resetFormModel = $author$project$TxnForm$ContribRuleUnverified$clearForm(model);
						return _Utils_update(
							resetFormModel,
							{a8: !model.a8});
					}(),
					$elm$core$Platform$Cmd$none);
			case 33:
				var _v3 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$TxnForm$ContribRuleUnverified$validator, model);
				if (_v3.$ === 1) {
					var errors = _v3.a;
					var error = A2(
						$elm$core$Maybe$withDefault,
						'Form error',
						$elm$core$List$head(errors));
					return _Utils_Tuple2(
						A2($author$project$TxnForm$ContribRuleUnverified$fromError, model, error),
						$author$project$TxnForm$ContribRuleUnverified$scrollToError(
							$author$project$FormID$toString(3)));
				} else {
					var val = _v3.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bE: true}),
						$author$project$TxnForm$ContribRuleUnverified$createContrib(model));
				}
			case 34:
				var clickedTxn = msg.a;
				var isChecked = msg.b;
				var selected = isChecked ? _Utils_ap(
					model.li,
					_List_fromArray(
						[clickedTxn])) : A2(
					$elm$core$List$filter,
					function (txn) {
						return !_Utils_eq(txn.jB, clickedTxn.jB);
					},
					model.li);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a8: false, li: selected}),
					$elm$core$Platform$Cmd$none);
			default:
				var res = msg.a;
				if (!res.$) {
					var createContribResp = res.a;
					if (!createContribResp.$) {
						var id = createContribResp.a;
						var resetFormModel = $author$project$TxnForm$ContribRuleUnverified$clearForm(model);
						return _Utils_Tuple2(
							_Utils_update(
								resetFormModel,
								{eP: id}),
							$author$project$TxnForm$ContribRuleUnverified$getTxns(model));
					} else {
						var errList = createContribResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bE: false,
									kc: $elm$core$List$head(errList)
								}),
							$author$project$TxnForm$ContribRuleUnverified$scrollToError(
								$author$project$FormID$toString(3)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bE: false,
								kc: $elm$core$List$head(
									$author$project$Api$decodeError(err))
							}),
						$author$project$TxnForm$ContribRuleUnverified$scrollToError(
							$author$project$FormID$toString(3)));
				}
		}
	});
var $author$project$TxnForm$ContribRuleVerified$OwnersViewUpdated = function (a) {
	return {$: 23, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 29:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var maybeOrgOrInd = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gv: _List_Nil, kb: $elm$core$Maybe$Nothing, ni: maybeOrgOrInd}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var entityName = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i5: entityName}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var maybeEntityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kb: maybeEntityType}),
					$elm$core$Platform$Cmd$none);
			case 21:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 22:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nB: str}),
					$elm$core$Platform$Cmd$none);
			case 23:
				var subMsg = msg.a;
				var _v1 = A2($author$project$OwnersView$update, subMsg, model.kv);
				var subModel = _v1.a;
				var subCmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ku: subModel.ku, kv: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$TxnForm$ContribRuleVerified$OwnersViewUpdated, subCmd));
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kH: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i0: str}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jh: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nk: str}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j_: str}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 20:
				var entityType = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							kb: $elm$core$Maybe$Just(entityType)
						}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i2: str}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i1: str}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{g7: str}),
					$elm$core$Platform$Cmd$none);
			case 30:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iu: str}),
					$elm$core$Platform$Cmd$none);
			case 31:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kE: str}),
					$elm$core$Platform$Cmd$none);
			case 25:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i9: str}),
					$elm$core$Platform$Cmd$none);
			case 26:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ir: str}),
					$elm$core$Platform$Cmd$none);
			case 28:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iH: str}),
					$elm$core$Platform$Cmd$none);
			case 24:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ja: str}),
					$elm$core$Platform$Cmd$none);
			case 27:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kF: str}),
					$elm$core$Platform$Cmd$none);
			case 32:
				var desc = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m2: desc}),
					$elm$core$Platform$Cmd$none);
			case 33:
				var t = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m4: t}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var _v2 = model.iQ;
				if (_v2) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{iQ: false, gO: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var state = $author$project$TxnForm$ContribRuleVerified$init(model.dX);
					return _Utils_Tuple2(
						_Utils_update(
							state,
							{iQ: true, gO: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dJ: !model.dJ}),
					$elm$core$Platform$Cmd$none);
			default:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hq: state}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$TxnForm$DisbRuleUnverified$clearForm = function (model) {
	return _Utils_update(
		model,
		{
			h9: '',
			ia: '',
			ig: $author$project$Cents$toDollarData(model.ik.ig),
			iu: '',
			iw: '',
			bb: false,
			aQ: false,
			iQ: true,
			i5: '',
			m8: $elm$core$Maybe$Nothing,
			m9: $elm$core$Maybe$Nothing,
			na: $elm$core$Maybe$Nothing,
			nb: $elm$core$Maybe$Nothing,
			eP: '',
			kc: $elm$core$Maybe$Nothing,
			kE: A2($author$project$Timestamp$formDate, model.fO, model.ik.kE),
			kM: '',
			nE: $elm$core$Maybe$Nothing,
			ht: true,
			ln: ''
		});
};
var $author$project$TxnForm$DisbRuleUnverified$CreateDisbGotResp = function (a) {
	return {$: 19, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$toEncodeModel = function (model) {
	return {h9: model.h9, ia: model.ia, ig: model.ig, iu: model.iu, iw: model.iw, iE: model.iE, i5: model.i5, m8: model.m8, m9: model.m9, na: model.na, nb: model.nb, kE: model.kE, kF: model.kF, kM: model.kM, nE: model.nE, ln: model.ln};
};
var $author$project$TxnForm$DisbRuleUnverified$createDisb = function (model) {
	return A3(
		$author$project$Api$CreateDisb$send,
		$author$project$TxnForm$DisbRuleUnverified$CreateDisbGotResp,
		model.gh,
		A2($author$project$Api$CreateDisb$encode, $author$project$TxnForm$DisbRuleUnverified$toEncodeModel, model));
};
var $author$project$TxnForm$DisbRuleUnverified$getTxnById = F2(
	function (txns, id) {
		var matches = A2(
			$elm$core$List$filter,
			function (t) {
				return _Utils_eq(t.jB, id);
			},
			txns);
		if (matches.b && (!matches.b.b)) {
			var match = matches.a;
			return $elm$core$Maybe$Just(match);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$TxnForm$DisbRuleUnverified$GetTxnsGotResp = function (a) {
	return {$: 20, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$getTxns = function (model) {
	return A3(
		$author$project$Api$GetTxns$send,
		$author$project$TxnForm$DisbRuleUnverified$GetTxnsGotResp,
		model.gh,
		A4(
			$author$project$Api$GetTxns$encode,
			model.iE,
			$elm$core$Maybe$Just(1),
			$elm$core$Maybe$Nothing,
			$elm$core$Maybe$Nothing));
};
var $author$project$TxnForm$DisbRuleUnverified$NoOp = {$: 0};
var $author$project$TxnForm$DisbRuleUnverified$scrollToError = function (id) {
	return A2(
		$elm$core$Task$attempt,
		function (_v1) {
			return $author$project$TxnForm$DisbRuleUnverified$NoOp;
		},
		A2(
			$elm$core$Task$andThen,
			function (_v0) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, 0, 400);
			},
			$elm$browser$Browser$Dom$getViewportOf(id)));
};
var $author$project$TxnForm$DisbRuleUnverified$amountMaxToErrors = function (model) {
	return A2($author$project$Errors$fromMaxAmount, model.ik.ig, model.ig);
};
var $author$project$TxnForm$DisbRuleUnverified$amountValidator = $rtfeldman$elm_validate$Validate$all(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ig;
			},
			'Amount is missing.'),
			$rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleUnverified$amountMaxToErrors)
		]));
var $author$project$TxnForm$DisbRuleUnverified$dateMaxToErrors = function (model) {
	return A3(
		$author$project$Errors$fromMaxDate,
		model.fO,
		model.ik.kE,
		$author$project$Timestamp$dateStringToMillis(model.kE));
};
var $author$project$Errors$fromInKind = function (isInKind) {
	if (!isInKind.$) {
		var bool = isInKind.a;
		if (bool) {
			return _List_fromArray(
				['In-Kind option is currently not supported']);
		} else {
			return _List_Nil;
		}
	} else {
		return _List_Nil;
	}
};
var $author$project$TxnForm$DisbRuleUnverified$isInKindOnModelToErrors = function (model) {
	return $author$project$Errors$fromInKind(model.m9);
};
var $author$project$TxnForm$DisbRuleUnverified$isInKindValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleUnverified$isInKindOnModelToErrors);
var $author$project$TxnForm$DisbRuleUnverified$postalCodeOnModelToErrors = function (model) {
	return $author$project$Errors$fromPostalCode(model.kM);
};
var $author$project$TxnForm$DisbRuleUnverified$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleUnverified$postalCodeOnModelToErrors);
var $author$project$Errors$fromDisbPaymentInfo = F2(
	function (payMethod, checkNumber) {
		if ((!payMethod.$) && (payMethod.a === 3)) {
			var _v1 = payMethod.a;
			var _v2 = $elm$core$String$isEmpty(checkNumber);
			if (_v2) {
				return _List_fromArray(
					['Check Number is missing']);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$TxnForm$DisbRuleUnverified$paymentInfoOnModelToErrors = function (_v0) {
	var paymentMethod = _v0.kF;
	var checkNumber = _v0.iu;
	return A2($author$project$Errors$fromDisbPaymentInfo, paymentMethod, checkNumber);
};
var $author$project$TxnForm$DisbRuleUnverified$paymentInfoValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleUnverified$paymentInfoOnModelToErrors);
var $author$project$TxnForm$DisbRuleUnverified$requiredFieldValidators = _List_fromArray(
	[
		$author$project$TxnForm$DisbRuleUnverified$paymentInfoValidator,
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.i5;
		},
		'Entity name is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.h9;
		},
		'Address 1 is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.iw;
		},
		'City is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ln;
		},
		'State is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kM;
		},
		'Postal Code is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kE;
		},
		'Payment Date is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.kF;
		},
		'Processing Info is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.nb;
		},
		'Subcontracted Information is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.na;
		},
		'Partial Payment Information is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.m8;
		},
		'Existing Liability Information is missing')
	]);
var $author$project$TxnForm$DisbRuleUnverified$validator = $rtfeldman$elm_validate$Validate$all(
	_Utils_ap(
		$author$project$TxnForm$DisbRuleUnverified$requiredFieldValidators,
		_List_fromArray(
			[
				$author$project$TxnForm$DisbRuleUnverified$postalCodeValidator,
				$author$project$TxnForm$DisbRuleUnverified$amountValidator,
				$author$project$TxnForm$DisbRuleUnverified$isInKindValidator,
				$rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleUnverified$dateMaxToErrors)
			])));
var $author$project$TxnForm$DisbRuleUnverified$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nE: str}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var pm = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kF: pm}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i5: str}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iu: str}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kE: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nb: bool}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{na: bool}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m8: bool}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m9: bool}),
					$elm$core$Platform$Cmd$none);
			case 16:
				return _Utils_Tuple2(
					function () {
						var resetFormModel = $author$project$TxnForm$DisbRuleUnverified$clearForm(model);
						return _Utils_update(
							resetFormModel,
							{aQ: !model.aQ});
					}(),
					$elm$core$Platform$Cmd$none);
			case 17:
				var _v1 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$TxnForm$DisbRuleUnverified$validator, model);
				if (_v1.$ === 1) {
					var errors = _v1.a;
					var error = A2(
						$elm$core$Maybe$withDefault,
						'Form error',
						$elm$core$List$head(errors));
					return _Utils_Tuple2(
						A2($author$project$TxnForm$DisbRuleUnverified$fromError, model, error),
						$author$project$TxnForm$DisbRuleUnverified$scrollToError(
							$author$project$FormID$toString(2)));
				} else {
					var val = _v1.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bb: true}),
						$author$project$TxnForm$DisbRuleUnverified$createDisb(model));
				}
			case 18:
				var clickedTxn = msg.a;
				var isChecked = msg.b;
				var selected = isChecked ? _Utils_ap(
					model.li,
					_List_fromArray(
						[clickedTxn])) : A2(
					$elm$core$List$filter,
					function (txn) {
						return !_Utils_eq(txn.jB, clickedTxn.jB);
					},
					model.li);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aQ: false, li: selected}),
					$elm$core$Platform$Cmd$none);
			case 19:
				var res = msg.a;
				if (!res.$) {
					var createDisbResp = res.a;
					if (!createDisbResp.$) {
						var id = createDisbResp.a;
						var resetFormModel = $author$project$TxnForm$DisbRuleUnverified$clearForm(model);
						return _Utils_Tuple2(
							_Utils_update(
								resetFormModel,
								{bb: false, aQ: false, eP: id}),
							$author$project$TxnForm$DisbRuleUnverified$getTxns(model));
					} else {
						var errList = createDisbResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									bb: false,
									kc: $elm$core$List$head(errList)
								}),
							$author$project$TxnForm$DisbRuleUnverified$scrollToError(
								$author$project$FormID$toString(2)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								bb: false,
								kc: $elm$core$List$head(
									$author$project$Api$decodeError(err))
							}),
						$author$project$TxnForm$DisbRuleUnverified$scrollToError(
							$author$project$FormID$toString(2)));
				}
			case 20:
				var res = msg.a;
				if (!res.$) {
					var body = res.a;
					var relatedTxns = A2(
						$author$project$TxnForm$DisbRuleUnverified$getRelatedDisb,
						model.ik,
						$author$project$Api$GetTxns$toTxns(body));
					var resTxnOrEmpty = A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							$elm$core$List$singleton,
							A2($author$project$TxnForm$DisbRuleUnverified$getTxnById, relatedTxns, model.eP)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fA: A2(
									$author$project$TxnForm$DisbRuleUnverified$getRelatedDisb,
									model.ik,
									$author$project$Api$GetTxns$toTxns(body)),
								li: _Utils_ap(model.li, resTxnOrEmpty)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(
							A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$TxnForm$DisbRuleVerified$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 8:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nE: str}),
					$elm$core$Platform$Cmd$none);
			case 15:
				var pm = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kF: pm}),
					$elm$core$Platform$Cmd$none);
			case 13:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ig: str}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{i5: str}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iu: str}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kE: str}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{h9: str}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ia: str}),
					$elm$core$Platform$Cmd$none);
			case 5:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{iw: str}),
					$elm$core$Platform$Cmd$none);
			case 6:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ln: str}),
					$elm$core$Platform$Cmd$none);
			case 7:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kM: str}),
					$elm$core$Platform$Cmd$none);
			case 9:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{nb: bool}),
					$elm$core$Platform$Cmd$none);
			case 10:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{na: bool}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m8: bool}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var bool = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{m9: bool}),
					$elm$core$Platform$Cmd$none);
			case 18:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dJ: !model.dJ}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var _v1 = model.bP;
				if (_v1) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bP: false, gO: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var state = $author$project$TxnForm$DisbRuleVerified$init(model.dX);
					return _Utils_Tuple2(
						_Utils_update(
							state,
							{bP: true, gO: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 0:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hq: state}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $janjelinek$creditcard_validation$Payment$CreditCard$Validation$checkLength = function (input) {
	return ((input !== '') && ($elm$core$String$length(input) <= 19)) ? $elm$core$Maybe$Just(input) : $elm$core$Maybe$Nothing;
};
var $janjelinek$creditcard_validation$Payment$CreditCard$Validation$parseInt = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		$elm$core$String$toInt(input));
};
var $janjelinek$creditcard_validation$Payment$CreditCard$Validation$checkLuhn = function (input) {
	var multiplyOdds = $elm$core$List$indexedMap(
		F2(
			function (i, item) {
				if (!A2($elm$core$Basics$modBy, 2, i)) {
					var result = $janjelinek$creditcard_validation$Payment$CreditCard$Validation$parseInt(item) * 2;
					return (result > 9) ? (result - 9) : result;
				} else {
					return $janjelinek$creditcard_validation$Payment$CreditCard$Validation$parseInt(item);
				}
			}));
	var lastDigit = $janjelinek$creditcard_validation$Payment$CreditCard$Validation$parseInt(
		A2($elm$core$String$right, 1, input));
	var dropLastAndReverse = A2($elm$core$Basics$composeR, $elm$core$List$reverse, $elm$core$List$tail);
	var checkSumMod = function (n) {
		var modulo = A2($elm$core$Basics$modBy, 10, n);
		return (modulo > 0) ? _Utils_eq(10 - modulo, lastDigit) : _Utils_eq(modulo, lastDigit);
	};
	return A2(
		$elm$core$Maybe$map,
		checkSumMod,
		A2(
			$elm$core$Maybe$map,
			$elm$core$List$sum,
			A2(
				$elm$core$Maybe$map,
				multiplyOdds,
				dropLastAndReverse(
					A2($elm$core$String$split, '', input)))));
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {m5: index, ne: match, nt: number, nU: submatches};
	});
var $elm$regex$Regex$find = _Regex_findAtMost(_Regex_infinity);
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{mh: false, np: false},
		string);
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $elm$regex$Regex$never = _Regex_never;
var $janjelinek$creditcard_validation$Payment$CreditCard$Validation$isNumber = function (input) {
	var regexRule = A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString('^[\\d]+$'));
	return $elm$core$List$isEmpty(
		A2($elm$regex$Regex$find, regexRule, input)) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(input);
};
var $janjelinek$creditcard_validation$Payment$CreditCard$Validation$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$andThen,
			$janjelinek$creditcard_validation$Payment$CreditCard$Validation$checkLuhn,
			A2(
				$elm$core$Maybe$andThen,
				$janjelinek$creditcard_validation$Payment$CreditCard$Validation$checkLength,
				A2(
					$elm$core$Maybe$andThen,
					$janjelinek$creditcard_validation$Payment$CreditCard$Validation$isNumber,
					$elm$core$Maybe$Just(input)))));
};
var $author$project$Errors$fromCreditCardInfo = F2(
	function (paymentMethod, cardNumber) {
		if ((!paymentMethod.$) && (paymentMethod.a === 4)) {
			var _v1 = paymentMethod.a;
			return ($elm$core$String$length(cardNumber) < 13) ? _List_fromArray(
				['Credit Card number is too short']) : ((!$janjelinek$creditcard_validation$Payment$CreditCard$Validation$isValid(cardNumber)) ? _List_fromArray(
				['Invalid Credit Card']) : _List_Nil);
		} else {
			return _List_Nil;
		}
	});
var $author$project$ContribInfo$creditCardNumberOnModelToErrors = function (_v0) {
	var paymentMethod = _v0.kF;
	var cardNumber = _v0.ir;
	return A2($author$project$Errors$fromCreditCardInfo, paymentMethod, cardNumber);
};
var $author$project$ContribInfo$creditCardNumberValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$ContribInfo$creditCardNumberOnModelToErrors);
var $author$project$ContribInfo$familyStatusOnModelToErrors = function (_v0) {
	var maybeOrgOrInd = _v0.ni;
	var maybeEntityType = _v0.kb;
	return A2($author$project$Errors$fromFamilyStatus, maybeOrgOrInd, maybeEntityType);
};
var $author$project$ContribInfo$familyStatusValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$ContribInfo$familyStatusOnModelToErrors);
var $author$project$ContribInfo$orgTypeOnModelToErrors = function (_v0) {
	var maybeOrgOrInd = _v0.ni;
	var maybeEntityType = _v0.kb;
	var entityName = _v0.i5;
	return A3($author$project$Errors$fromOrgType, maybeOrgOrInd, maybeEntityType, entityName);
};
var $author$project$ContribInfo$orgTypeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$ContribInfo$orgTypeOnModelToErrors);
var $author$project$ContribInfo$ownersOnModelToErrors = function (_v0) {
	var owners = _v0.ku;
	var maybeEntityType = _v0.kb;
	return A2($author$project$Errors$fromOwners, owners, maybeEntityType);
};
var $author$project$ContribInfo$ownersValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$ContribInfo$ownersOnModelToErrors);
var $author$project$ContribInfo$postalCodeOnModelToErrors = function (model) {
	return $author$project$Errors$fromPostalCode(model.kM);
};
var $author$project$ContribInfo$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$ContribInfo$postalCodeOnModelToErrors);
var $author$project$ContribInfo$paymentInfoOnModelToErrors = function (model) {
	return $author$project$Errors$fromContribPaymentInfo(
		{ir: model.ir, iu: model.iu, iH: model.iH, i9: model.i9, ja: model.ja, m3: model.m2, m4: model.m4, kF: model.kF});
};
var $author$project$ContribInfo$paymentInfoValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$ContribInfo$paymentInfoOnModelToErrors);
var $author$project$ContribInfo$requiredFieldValidators = _List_fromArray(
	[
		$author$project$ContribInfo$paymentInfoValidator,
		$author$project$ContribInfo$familyStatusValidator,
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ig;
		},
		'Payment Amount is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kE;
		},
		'Payment Date is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.kF;
		},
		'Processing Info is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.jh;
		},
		'First Name is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.j_;
		},
		'Last name is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.iw;
		},
		'City is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ln;
		},
		'State is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kM;
		},
		'Postal Code is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.h9;
		},
		'Address is missing'),
		$author$project$ContribInfo$paymentInfoValidator
	]);
var $author$project$ContribInfo$contribInfoValidator = $rtfeldman$elm_validate$Validate$firstError(
	_Utils_ap(
		$author$project$ContribInfo$requiredFieldValidators,
		_List_fromArray(
			[$author$project$ContribInfo$postalCodeValidator, $author$project$ContribInfo$orgTypeValidator, $author$project$ContribInfo$ownersValidator, $author$project$ContribInfo$familyStatusValidator, $author$project$ContribInfo$creditCardNumberValidator])));
var $author$project$ContribInfo$validateModel = F2(
	function (mapper, val) {
		var model = mapper(val);
		return A2($rtfeldman$elm_validate$Validate$validate, $author$project$ContribInfo$contribInfoValidator, model);
	});
var $author$project$TxnForm$ContribRuleVerified$orgTypeOnModelToErrors = function (_v0) {
	var maybeOrgOrInd = _v0.ni;
	var maybeEntityType = _v0.kb;
	var entityName = _v0.i5;
	return A3($author$project$Errors$fromOrgType, maybeOrgOrInd, maybeEntityType, entityName);
};
var $author$project$TxnForm$ContribRuleVerified$orgTypeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleVerified$orgTypeOnModelToErrors);
var $author$project$TxnForm$ContribRuleVerified$ownersOnModelToErrors = function (_v0) {
	var owners = _v0.ku;
	var maybeEntityType = _v0.kb;
	return A2($author$project$Errors$fromOwners, owners, maybeEntityType);
};
var $author$project$TxnForm$ContribRuleVerified$ownersValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleVerified$ownersOnModelToErrors);
var $author$project$Errors$fromAmendContribPaymentInfo = F2(
	function (paymentMethod, checkNumber) {
		if ((!paymentMethod.$) && (paymentMethod.a === 3)) {
			var _v1 = paymentMethod.a;
			var _v2 = $elm$core$String$isEmpty(checkNumber);
			if (_v2) {
				return _List_fromArray(
					['Check Number is missing']);
			} else {
				return _List_Nil;
			}
		} else {
			return _List_Nil;
		}
	});
var $author$project$TxnForm$ContribRuleVerified$paymentInfoOnModelToErrors = function (_v0) {
	var paymentMethod = _v0.kF;
	var checkNumber = _v0.iu;
	return A2($author$project$Errors$fromAmendContribPaymentInfo, paymentMethod, checkNumber);
};
var $author$project$TxnForm$ContribRuleVerified$paymentInfoValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleVerified$paymentInfoOnModelToErrors);
var $author$project$TxnForm$ContribRuleVerified$postalCodeOnModelToErrors = function (model) {
	return $author$project$Errors$fromPostalCode(model.kM);
};
var $author$project$TxnForm$ContribRuleVerified$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$ContribRuleVerified$postalCodeOnModelToErrors);
var $author$project$TxnForm$ContribRuleVerified$requiredFieldValidators = _List_fromArray(
	[
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ig;
		},
		'Payment Amount is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kE;
		},
		'Payment Date is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.kF;
		},
		'Processing Info is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.jh;
		},
		'First Name is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.j_;
		},
		'Last name is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.iw;
		},
		'City is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ln;
		},
		'State is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kM;
		},
		'Postal Code is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.h9;
		},
		'Address is missing')
	]);
var $author$project$TxnForm$ContribRuleVerified$contribInfoValidator = $rtfeldman$elm_validate$Validate$firstError(
	_Utils_ap(
		$author$project$TxnForm$ContribRuleVerified$requiredFieldValidators,
		_List_fromArray(
			[$author$project$TxnForm$ContribRuleVerified$postalCodeValidator, $author$project$TxnForm$ContribRuleVerified$orgTypeValidator, $author$project$TxnForm$ContribRuleVerified$ownersValidator, $author$project$TxnForm$ContribRuleVerified$paymentInfoValidator])));
var $author$project$TxnForm$ContribRuleVerified$validateModel = F2(
	function (mapper, val) {
		var model = mapper(val);
		return A2($rtfeldman$elm_validate$Validate$validate, $author$project$TxnForm$ContribRuleVerified$contribInfoValidator, model);
	});
var $author$project$CreateContribution$validationMapper = function (model) {
	return {h9: model.h9, ia: model.ia, ig: model.ig, ir: model.ir, iu: model.iu, iw: model.iw, iH: model.iH, i0: model.i0, i1: model.i1, i2: model.i2, i5: model.i5, i9: model.i9, ja: model.ja, jh: model.jh, m2: model.m2, m4: model.m4, jO: model.jO, jS: model.eO, j_: model.j_, kb: model.kb, ni: model.ni, nk: model.nk, g7: model.g7, ku: model.kv.ku, kE: model.kE, kF: model.kF, kH: model.kH, kM: model.kM, ln: model.ln};
};
var $author$project$TxnForm$ContribRuleVerified$validationMapper = function (model) {
	return {h9: model.h9, ia: model.ia, ig: model.ig, iu: model.iu, iw: model.iw, i0: model.i0, i1: model.i1, i2: model.i2, i5: model.i5, jh: model.jh, m2: model.m2, m4: model.m4, jO: model.jO, jS: model.jS, j_: model.j_, kb: model.kb, ni: model.ni, nk: model.nk, g7: model.g7, ku: model.kv.ku, kE: model.kE, kF: model.kF, kH: model.kH, kM: model.kM, ln: model.ln};
};
var $author$project$CreateDisbursement$amountValidator = A2(
	$rtfeldman$elm_validate$Validate$ifBlank,
	function ($) {
		return $.ig;
	},
	'Amount is missing.');
var $author$project$CreateDisbursement$isInKindOnModelToErrors = function (model) {
	return $author$project$Errors$fromInKind(model.m9);
};
var $author$project$CreateDisbursement$isInKindValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$CreateDisbursement$isInKindOnModelToErrors);
var $author$project$CreateDisbursement$postalCodeOnModelToErrors = function (model) {
	return $author$project$Errors$fromPostalCode(model.kM);
};
var $author$project$CreateDisbursement$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$CreateDisbursement$postalCodeOnModelToErrors);
var $author$project$CreateDisbursement$paymentInfoOnModelToErrors = function (_v0) {
	var paymentMethod = _v0.kF;
	var checkNumber = _v0.iu;
	return A2($author$project$Errors$fromDisbPaymentInfo, paymentMethod, checkNumber);
};
var $author$project$CreateDisbursement$paymentInfoValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$CreateDisbursement$paymentInfoOnModelToErrors);
var $author$project$CreateDisbursement$requiredFieldValidators = _List_fromArray(
	[
		$author$project$CreateDisbursement$paymentInfoValidator,
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.i5;
		},
		'Entity name is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.h9;
		},
		'Address 1 is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.iw;
		},
		'City is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.ln;
		},
		'State is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kM;
		},
		'Postal Code is missing.'),
		A2(
		$rtfeldman$elm_validate$Validate$ifBlank,
		function ($) {
			return $.kE;
		},
		'Payment Date is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.kF;
		},
		'Processing Info is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.nb;
		},
		'Subcontracted Information is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.na;
		},
		'Partial Payment Information is missing'),
		A2(
		$rtfeldman$elm_validate$Validate$ifNothing,
		function ($) {
			return $.m8;
		},
		'Existing Liability Information is missing')
	]);
var $author$project$CreateDisbursement$validator = $rtfeldman$elm_validate$Validate$firstError(
	_Utils_ap(
		$author$project$CreateDisbursement$requiredFieldValidators,
		_List_fromArray(
			[$author$project$CreateDisbursement$postalCodeValidator, $author$project$CreateDisbursement$amountValidator, $author$project$CreateDisbursement$isInKindValidator])));
var $author$project$TxnForm$DisbRuleVerified$isInKindOnModelToErrors = function (model) {
	return $author$project$Errors$fromInKind(model.m9);
};
var $author$project$TxnForm$DisbRuleVerified$isInKindValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleVerified$isInKindOnModelToErrors);
var $author$project$TxnForm$DisbRuleVerified$postalCodeOnModelToErrors = function (model) {
	return $author$project$Errors$fromPostalCode(model.kM);
};
var $author$project$TxnForm$DisbRuleVerified$postalCodeValidator = $rtfeldman$elm_validate$Validate$fromErrors($author$project$TxnForm$DisbRuleVerified$postalCodeOnModelToErrors);
var $author$project$TxnForm$DisbRuleVerified$validator = $rtfeldman$elm_validate$Validate$firstError(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.i5;
			},
			'Entity name is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.h9;
			},
			'Address 1 is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.iw;
			},
			'City is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.ln;
			},
			'State is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifBlank,
			function ($) {
				return $.kM;
			},
			'Postal Code is missing.'),
			A2(
			$rtfeldman$elm_validate$Validate$ifNothing,
			function ($) {
				return $.nb;
			},
			'Subcontracted Information is missing'),
			A2(
			$rtfeldman$elm_validate$Validate$ifNothing,
			function ($) {
				return $.na;
			},
			'Partial Payment Information is missing'),
			A2(
			$rtfeldman$elm_validate$Validate$ifNothing,
			function ($) {
				return $.m8;
			},
			'Existing Liability Information is missing'),
			$author$project$TxnForm$DisbRuleVerified$postalCodeValidator,
			$author$project$TxnForm$DisbRuleVerified$isInKindValidator
		]));
var $author$project$Page$Transactions$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 53:
				var txn = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, V: true, G: 1}),
					A2($author$project$Page$Transactions$deleteTxn, model, txn.jB));
			case 54:
				var res = msg.a;
				if (!res.$) {
					var mutResp = res.a;
					if (!mutResp.$) {
						var id = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ar: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, aR: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, V: false}),
							A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
					} else {
						var errList = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									o: A2(
										$author$project$TxnForm$ContribRuleVerified$fromError,
										model.o,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									p: A2(
										$author$project$TxnForm$DisbRuleVerified$fromError,
										model.p,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									V: false
								}),
							$elm$core$Platform$Cmd$none);
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								o: A2(
									$author$project$TxnForm$ContribRuleVerified$fromError,
									model.o,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err)))),
								p: A2(
									$author$project$TxnForm$DisbRuleVerified$fromError,
									model.p,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err))))
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 42:
				var txn = msg.a;
				var state = _Utils_update(
					model,
					{bh: false});
				return A2($author$project$Page$Transactions$openTxnFormModalLoading, state, txn);
			case 33:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bd: visibility}),
					$elm$core$Platform$Cmd$none);
			case 32:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, bd: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, c6: false, bh: true, G: 2}),
					A2($author$project$Page$Transactions$getRehydrateTxnsSet, model, model.aT));
			case 35:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bI: true}),
					$author$project$Page$Transactions$reconcileDisb(model));
			case 34:
				var subMsg = msg.a;
				var _v3 = A2($author$project$TxnForm$DisbRuleUnverified$update, subMsg, model.D);
				var subModel = _v3.a;
				var subCmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{D: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Transactions$DisbRuleUnverifiedModalUpdate, subCmd));
			case 36:
				var res = msg.a;
				if (!res.$) {
					var mutResp = res.a;
					if (!mutResp.$) {
						var id = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									D: A3($author$project$TxnForm$DisbRuleUnverified$init, model.gh, _List_Nil, model.D.ik),
									bI: false,
									c6: true
								}),
							A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
					} else {
						var errList = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									D: A2(
										$author$project$TxnForm$DisbRuleUnverified$fromError,
										model.D,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									bI: false
								}),
							$author$project$Page$Transactions$scrollToError(
								$author$project$FormID$toString(2)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								D: A2(
									$author$project$TxnForm$DisbRuleUnverified$fromError,
									model.D,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err)))),
								bI: false
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(2)));
				}
			case 41:
				var res = msg.a;
				if (!res.$) {
					var mutResp = res.a;
					if (!mutResp.$) {
						var id = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, p: $author$project$TxnForm$DisbRuleVerified$loadingInit, bJ: false, c7: true}),
							A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
					} else {
						var errList = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									p: A2(
										$author$project$TxnForm$DisbRuleVerified$fromError,
										model.p,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									bJ: false
								}),
							$author$project$Page$Transactions$scrollToError(
								$author$project$FormID$toString(4)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								p: A2(
									$author$project$TxnForm$DisbRuleVerified$fromError,
									model.p,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err)))),
								bJ: false
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(4)));
				}
			case 38:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aR: visibility}),
					$elm$core$Platform$Cmd$none);
			case 37:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, aR: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, c7: false, bh: true, G: 2}),
					$elm$core$Platform$Cmd$none);
			case 40:
				var _v8 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$TxnForm$DisbRuleVerified$validator, model.p);
				if (_v8.$ === 1) {
					var errors = _v8.a;
					var error = A2(
						$elm$core$Maybe$withDefault,
						'Form error',
						$elm$core$List$head(errors));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								p: A2($author$project$TxnForm$DisbRuleVerified$fromError, model.p, error)
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(4)));
				} else {
					var val = _v8.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bJ: true}),
						$author$project$Page$Transactions$amendDisb(model));
				}
			case 39:
				var subMsg = msg.a;
				var _v9 = A2($author$project$TxnForm$DisbRuleVerified$update, subMsg, model.p);
				var subModel = _v9.a;
				var subCmd = _v9.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{p: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Transactions$DisbRuleVerifiedModalUpdate, subCmd));
			case 44:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bA: visibility}),
					$elm$core$Platform$Cmd$none);
			case 43:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, bA: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, c$: false, bh: true, G: 2}),
					A2($author$project$Page$Transactions$getRehydrateTxnsSet, model, model.aT));
			case 46:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bB: true}),
					$author$project$Page$Transactions$reconcileContrib(model));
			case 45:
				var subMsg = msg.a;
				var _v10 = A2($author$project$TxnForm$ContribRuleUnverified$update, subMsg, model.L);
				var subModel = _v10.a;
				var subCmd = _v10.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{L: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Transactions$ContribRuleUnverifiedModalUpdate, subCmd));
			case 47:
				var res = msg.a;
				if (!res.$) {
					var mutResp = res.a;
					if (!mutResp.$) {
						var id = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									L: A3($author$project$TxnForm$ContribRuleUnverified$init, model.gh, _List_Nil, model.L.ik),
									bB: false,
									c$: true
								}),
							A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
					} else {
						var errList = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									L: A2(
										$author$project$TxnForm$ContribRuleUnverified$fromError,
										model.L,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									bB: false
								}),
							$author$project$Page$Transactions$scrollToError(
								$author$project$FormID$toString(3)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								L: A2(
									$author$project$TxnForm$ContribRuleUnverified$fromError,
									model.L,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err)))),
								bB: false
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(3)));
				}
			case 52:
				var res = msg.a;
				if (!res.$) {
					var mutResp = res.a;
					if (!mutResp.$) {
						var id = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, o: $author$project$TxnForm$ContribRuleVerified$loadingInit, bC: false, c0: true}),
							A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
					} else {
						var errList = mutResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									o: A2(
										$author$project$TxnForm$ContribRuleVerified$fromError,
										model.o,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									bC: false
								}),
							$author$project$Page$Transactions$scrollToError(
								$author$project$FormID$toString(5)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								o: A2(
									$author$project$TxnForm$ContribRuleVerified$fromError,
									model.o,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err)))),
								bC: false
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(5)));
				}
			case 49:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ar: visibility}),
					$elm$core$Platform$Cmd$none);
			case 48:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: $rundis$elm_bootstrap$Bootstrap$Alert$closed, ar: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, c0: false, bh: true, G: 2}),
					$elm$core$Platform$Cmd$none);
			case 51:
				var _v15 = A2($author$project$TxnForm$ContribRuleVerified$validateModel, $author$project$TxnForm$ContribRuleVerified$validationMapper, model.o);
				if (_v15.$ === 1) {
					var errors = _v15.a;
					var error = A2(
						$elm$core$Maybe$withDefault,
						'Form error',
						$elm$core$List$head(errors));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								o: A2($author$project$TxnForm$ContribRuleVerified$fromError, model.o, error)
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(5)));
				} else {
					var val = _v15.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{bC: true}),
						$author$project$Page$Transactions$amendContrib(model));
				}
			case 50:
				var subMsg = msg.a;
				var _v16 = A2($author$project$TxnForm$ContribRuleVerified$update, subMsg, model.o);
				var subModel = _v16.a;
				var subCmd = _v16.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Transactions$ContribRuleVerifiedModalUpdate, subCmd));
			case 0:
				var session = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fJ: session}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var format = msg.a;
				var includeHeaders = msg.b;
				if (format === 1) {
					return _Utils_Tuple2(
						model,
						A2($author$project$Page$Transactions$getReport, model, includeHeaders));
				} else {
					return _Utils_Tuple2(
						model,
						A3($elm$file$File$Download$string, '2021-periodic-report-july.pdf', 'text/pdf', '2021-periodic-report-july'));
				}
			case 13:
				var res = msg.a;
				var _v18 = model.dh;
				switch (_v18) {
					case 0:
						if (!res.$) {
							var body = res.a;
							return _Utils_Tuple2(
								model,
								A3(
									$elm$file$File$Download$string,
									'report.csv',
									'text/csv',
									$author$project$Api$GetReport$toCsvData(body)));
						} else {
							return _Utils_Tuple2(
								model,
								$elm$browser$Browser$Navigation$load(
									A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
						}
					case 1:
						if (!res.$) {
							var body = res.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										cj: $elm$core$Maybe$Just(
											$author$project$Api$GetReport$toCsvData(body))
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(
								model,
								$elm$browser$Browser$Navigation$load(
									A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
						}
					default:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 7:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bg: visibility}),
					$elm$core$Platform$Cmd$none);
			case 12:
				var res = msg.a;
				var _v21 = model.bh;
				if (!_v21) {
					if (!res.$) {
						var body = res.a;
						return A2(
							$author$project$Page$Transactions$openTxnFormModalLoaded,
							model,
							$author$project$Api$GetTxn$toTxn(body));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var res = msg.a;
				if (!res.$) {
					var body = res.a;
					var txns = A2(
						$author$project$Page$Transactions$applyNeedsReviewFilter,
						model,
						$author$project$Api$GetTxns$toTxns(body));
					var committee = $author$project$Api$GetTxns$toCommittee(body);
					var aggs = $author$project$Api$GetTxns$toAggs(body);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ic: aggs, iD: committee, dt: false, lI: txns}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(
							A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
				}
			case 4:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							A: $author$project$CreateContribution$init(model.iE),
							a9: $rundis$elm_bootstrap$Bootstrap$Modal$shown
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a9: $rundis$elm_bootstrap$Bootstrap$Modal$hidden}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var _v24 = A2($author$project$ContribInfo$validateModel, $author$project$CreateContribution$validationMapper, model.A);
				if (_v24.$ === 1) {
					var errors = _v24.a;
					var error = A2(
						$elm$core$Maybe$withDefault,
						'Form error',
						$elm$core$List$head(errors));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								A: A2($author$project$CreateContribution$fromError, model.A, error)
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(1)));
				} else {
					var val = _v24.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ba: true}),
						$author$project$Page$Transactions$createContribution(model));
				}
			case 9:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{a9: visibility}),
					$elm$core$Platform$Cmd$none);
			case 6:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bg: $rundis$elm_bootstrap$Bootstrap$Modal$shown}),
					$elm$core$Platform$Cmd$none);
			case 5:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cj: $elm$core$Maybe$Nothing, bg: $rundis$elm_bootstrap$Bootstrap$Modal$hidden}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var subMsg = msg.a;
				var _v25 = A2($author$project$CreateContribution$update, subMsg, model.A);
				var subModel = _v25.a;
				var subCmd = _v25.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{A: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Transactions$CreateContributionModalUpdated, subCmd));
			case 10:
				var res = msg.a;
				if (!res.$) {
					var createContribResp = res.a;
					if (!createContribResp.$) {
						var id = createContribResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a9: $rundis$elm_bootstrap$Bootstrap$Modal$hidden, ba: false}),
							A2($author$project$Page$Transactions$getTransactions, model, model.aT));
					} else {
						var errList = createContribResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									A: A2(
										$author$project$CreateContribution$fromError,
										model.A,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									ba: false
								}),
							$author$project$Page$Transactions$scrollToError(
								$author$project$FormID$toString(1)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								A: A2(
									$author$project$CreateContribution$fromError,
									model.A,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err)))),
								ba: false
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(1)));
				}
			case 11:
				var res = msg.a;
				if (!res.$) {
					var createDisbResp = res.a;
					if (!createDisbResp.$) {
						var id = createDisbResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: $author$project$CreateDisbursement$init(model.iE),
									bc: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
									cd: false
								}),
							A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
					} else {
						var errList = createDisbResp.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									B: A2(
										$author$project$CreateDisbursement$fromError,
										model.B,
										A2(
											$elm$core$Maybe$withDefault,
											'Unexplained error',
											$elm$core$List$head(errList))),
									cd: false
								}),
							$author$project$Page$Transactions$scrollToError(
								$author$project$FormID$toString(0)));
					}
				} else {
					var err = res.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ba: false,
								B: A2(
									$author$project$CreateDisbursement$fromError,
									model.B,
									A2(
										$elm$core$Maybe$withDefault,
										'Server Error',
										$elm$core$List$head(
											$author$project$Api$decodeError(err))))
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(0)));
				}
			case 15:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cU: state}),
					$elm$core$Platform$Cmd$none);
			case 16:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dc: state}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dh: 0, di: state}),
					$elm$core$Platform$Cmd$none);
			case 18:
				var state = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dh: 1, dj: state}),
					$elm$core$Platform$Cmd$none);
			case 19:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cj: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 22:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bO: false,
							aT: $elm$core$Maybe$Just(0),
							bR: 'Contributions'
						}),
					A2(
						$author$project$Page$Transactions$getTransactions,
						model,
						$elm$core$Maybe$Just(0)));
			case 23:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bO: false,
							aT: $elm$core$Maybe$Just(1),
							bR: 'Disbursements'
						}),
					A2(
						$author$project$Page$Transactions$getTransactions,
						model,
						$elm$core$Maybe$Just(1)));
			case 24:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							bO: true,
							bg: $rundis$elm_bootstrap$Bootstrap$Modal$hidden,
							bR: 'Needs Review (' + ($elm$core$String$fromInt(model.ic.ki) + ')')
						}),
					A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
			case 26:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bO: false, aT: $elm$core$Maybe$Nothing, bR: 'All Transactions'}),
					A2($author$project$Page$Transactions$getTransactions, model, $elm$core$Maybe$Nothing));
			case 20:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{c8: true}),
					A3($andrewMacmurray$elm_delay$Delay$after, 2, 1, $author$project$Page$Transactions$FileDisclosureDelayed));
			case 21:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ez: true, c8: false}),
					$elm$core$Platform$Cmd$none);
			case 25:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 30:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bc: $rundis$elm_bootstrap$Bootstrap$Modal$shown}),
					$elm$core$Platform$Cmd$none);
			case 27:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							B: $author$project$CreateDisbursement$init(model.iE),
							bc: $rundis$elm_bootstrap$Bootstrap$Modal$hidden
						}),
					$elm$core$Platform$Cmd$none);
			case 31:
				var _v30 = A2($rtfeldman$elm_validate$Validate$validate, $author$project$CreateDisbursement$validator, model.B);
				if (_v30.$ === 1) {
					var errors = _v30.a;
					var error = A2(
						$elm$core$Maybe$withDefault,
						'Form error',
						$elm$core$List$head(errors));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								B: A2($author$project$CreateDisbursement$fromError, model.B, error)
							}),
						$author$project$Page$Transactions$scrollToError(
							$author$project$FormID$toString(0)));
				} else {
					var val = _v30.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{cd: true}),
						$author$project$Page$Transactions$createDisbursement(model));
				}
			case 28:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bc: visibility}),
					$elm$core$Platform$Cmd$none);
			case 29:
				var subMsg = msg.a;
				var _v31 = A2($author$project$CreateDisbursement$update, subMsg, model.B);
				var subModel = _v31.a;
				var subCmd = _v31.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{B: subModel}),
					A2($elm$core$Platform$Cmd$map, $author$project$Page$Transactions$CreateDisbursementModalUpdate, subCmd));
			case 55:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cw: true, dB: model.dB + $author$project$Pagination$size}),
					$author$project$Page$Transactions$getNextTxnsSet(model));
			case 56:
				var res = msg.a;
				if (!res.$) {
					var body = res.a;
					var txns = A2(
						$author$project$Page$Transactions$applyNeedsReviewFilter,
						model,
						$author$project$Api$GetTxns$toTxns(body));
					var moreDisabled = !$elm$core$List$length(txns);
					var fromId = A2(
						$elm$core$Maybe$map,
						function (txn) {
							return txn.jB;
						},
						$elm$core$List$head(
							$elm$core$List$reverse(txns)));
					var committee = $author$project$Api$GetTxns$toCommittee(body);
					var aggs = $author$project$Api$GetTxns$toAggs(body);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ic: aggs,
								iD: committee,
								eA: fromId,
								dt: false,
								e$: moreDisabled,
								cw: false,
								lI: $elm$core$List$concat(
									_List_fromArray(
										[model.lI, txns]))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						model,
						$elm$browser$Browser$Navigation$load(
							A2($author$project$Cognito$loginUrl, model.gh, model.iE)));
				}
			case 57:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: $rundis$elm_bootstrap$Bootstrap$Alert$shown, G: 0}),
					$elm$core$Platform$Cmd$none);
			default:
				var visibility = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{r: visibility}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		var _v0 = _Utils_Tuple2(msg, model);
		_v0$6:
		while (true) {
			switch (_v0.a.$) {
				case 1:
					var urlRequest = _v0.a.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								$author$project$Session$navKey(
									$author$project$Main$toSession(model)),
								$elm$url$Url$toString(url)));
					} else {
						var href = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(href));
					}
				case 0:
					var url = _v0.a.a;
					return A4(
						$author$project$Main$changeRouteTo,
						url,
						$author$project$Main$toConfig(model),
						$author$project$Route$fromUrl(url),
						model);
				case 4:
					if (_v0.b.$ === 3) {
						var subMsg = _v0.a.a;
						var home = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Transactions,
							$author$project$Main$GotTransactionsMsg,
							A2($author$project$Page$Transactions$update, subMsg, home));
					} else {
						break _v0$6;
					}
				case 2:
					if (_v0.b.$ === 2) {
						var subMsg = _v0.a.a;
						var linkBuilder = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$LinkBuilder,
							$author$project$Main$GotLinkBuilderMsg,
							A2($author$project$Page$LinkBuilder$update, subMsg, linkBuilder));
					} else {
						break _v0$6;
					}
				case 5:
					if (_v0.b.$ === 4) {
						var subMsg = _v0.a.a;
						var demo = _v0.b.a;
						return A3(
							$author$project$Main$updateWith,
							$author$project$Main$Demo,
							$author$project$Main$GotDemoMsg,
							A2($author$project$Page$Demo$update, subMsg, demo));
					} else {
						break _v0$6;
					}
				default:
					if (_v0.b.$ === 1) {
						var session = _v0.a.a;
						return _Utils_Tuple2(
							$author$project$Main$Redirect(session),
							A2(
								$author$project$Route$replaceUrl,
								$author$project$Session$navKey(session),
								$author$project$Route$Home));
					} else {
						break _v0$6;
					}
			}
		}
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	});
var $author$project$Page$Demo = 4;
var $author$project$Page$LinkBuilder = 2;
var $author$project$Page$Other = 0;
var $author$project$Page$Transactions = 3;
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$div = _VirtualDom_node('div');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p5 = $elm$html$Html$Attributes$class('p-5');
var $author$project$Page$contentContainer = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p5]),
		_List_fromArray(
			[content]));
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColAttrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowAttrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Column = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$col = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Column(
			{mk: children, ny: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$containerFluid = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-fluid')
					]),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml4 = $elm$html$Html$Attributes$class('ml-4');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb3 = $elm$html$Html$Attributes$class('pb-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt3 = $elm$html$Html$Attributes$class('pt-3');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width = F2(
	function (screenSize, columnCount) {
		return {iA: columnCount, lh: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$XS = 0;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign = F2(
	function (align_, options) {
		var _v0 = align_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						ed: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						eb: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						ea: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						d9: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						ec: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset = F2(
	function (offset_, options) {
		var _v0 = offset_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						e6: $elm$core$Maybe$Just(offset_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						e3: $elm$core$Maybe$Just(offset_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						e2: $elm$core$Maybe$Just(offset_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						e1: $elm$core$Maybe$Just(offset_)
					});
			default:
				return _Utils_update(
					options,
					{
						e5: $elm$core$Maybe$Just(offset_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder = F2(
	function (order_, options) {
		var _v0 = order_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						fi: $elm$core$Maybe$Just(order_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fg: $elm$core$Maybe$Just(order_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fe: $elm$core$Maybe$Just(order_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						fd: $elm$core$Maybe$Just(order_)
					});
			default:
				return _Utils_update(
					options,
					{
						fh: $elm$core$Maybe$Just(order_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull = F2(
	function (pull_, options) {
		var _v0 = pull_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						fs: $elm$core$Maybe$Just(pull_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fq: $elm$core$Maybe$Just(pull_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fp: $elm$core$Maybe$Just(pull_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						fo: $elm$core$Maybe$Just(pull_)
					});
			default:
				return _Utils_update(
					options,
					{
						fr: $elm$core$Maybe$Just(pull_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush = F2(
	function (push_, options) {
		var _v0 = push_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						fx: $elm$core$Maybe$Just(push_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fv: $elm$core$Maybe$Just(push_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fu: $elm$core$Maybe$Just(push_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						ft: $elm$core$Maybe$Just(push_)
					});
			default:
				return _Utils_update(
					options,
					{
						fw: $elm$core$Maybe$Just(push_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth = F2(
	function (width_, options) {
		var _v0 = width_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						d4: $elm$core$Maybe$Just(width_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						d2: $elm$core$Maybe$Just(width_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						d1: $elm$core$Maybe$Just(width_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						d0: $elm$core$Maybe$Just(width_)
					});
			default:
				return _Utils_update(
					options,
					{
						d3: $elm$core$Maybe$Just(width_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 6:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs)
					});
			case 0:
				var width_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColWidth, width_, options);
			case 1:
				var offset_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOffset, offset_, options);
			case 2:
				var pull_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPull, pull_, options);
			case 3:
				var push_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColPush, push_, options);
			case 4:
				var order_ = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOrder, order_, options);
			case 5:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColAlign, align, options);
			default:
				var align = modifier.a;
				return _Utils_update(
					options,
					{
						fN: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('1');
		case 2:
			return $elm$core$Maybe$Just('2');
		case 3:
			return $elm$core$Maybe$Just('3');
		case 4:
			return $elm$core$Maybe$Just('4');
		case 5:
			return $elm$core$Maybe$Just('5');
		case 6:
			return $elm$core$Maybe$Just('6');
		case 7:
			return $elm$core$Maybe$Just('7');
		case 8:
			return $elm$core$Maybe$Just('8');
		case 9:
			return $elm$core$Maybe$Just('9');
		case 10:
			return $elm$core$Maybe$Just('10');
		case 11:
			return $elm$core$Maybe$Just('11');
		case 12:
			return $elm$core$Maybe$Just('12');
		default:
			return $elm$core$Maybe$Just('auto');
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption = function (size) {
	switch (size) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just('sm');
		case 2:
			return $elm$core$Maybe$Just('md');
		case 3:
			return $elm$core$Maybe$Just('lg');
		default:
			return $elm$core$Maybe$Just('xl');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass = function (_v0) {
	var screenSize = _v0.lh;
	var columnCount = _v0.iA;
	return $elm$html$Html$Attributes$class(
		'col' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return '-' + v;
				},
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$columnCountOption(columnCount)))));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes = function (widths) {
	var width_ = function (w) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthClass, w);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, width_, widths));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions = {d9: $elm$core$Maybe$Nothing, ea: $elm$core$Maybe$Nothing, eb: $elm$core$Maybe$Nothing, ec: $elm$core$Maybe$Nothing, ed: $elm$core$Maybe$Nothing, eg: _List_Nil, e1: $elm$core$Maybe$Nothing, e2: $elm$core$Maybe$Nothing, e3: $elm$core$Maybe$Nothing, e5: $elm$core$Maybe$Nothing, e6: $elm$core$Maybe$Nothing, fd: $elm$core$Maybe$Nothing, fe: $elm$core$Maybe$Nothing, fg: $elm$core$Maybe$Nothing, fh: $elm$core$Maybe$Nothing, fi: $elm$core$Maybe$Nothing, fo: $elm$core$Maybe$Nothing, fp: $elm$core$Maybe$Nothing, fq: $elm$core$Maybe$Nothing, fr: $elm$core$Maybe$Nothing, fs: $elm$core$Maybe$Nothing, ft: $elm$core$Maybe$Nothing, fu: $elm$core$Maybe$Nothing, fv: $elm$core$Maybe$Nothing, fw: $elm$core$Maybe$Nothing, fx: $elm$core$Maybe$Nothing, fN: $elm$core$Maybe$Nothing, d0: $elm$core$Maybe$Nothing, d1: $elm$core$Maybe$Nothing, d2: $elm$core$Maybe$Nothing, d3: $elm$core$Maybe$Nothing, d4: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		default:
			return '11';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString = function (screenSize) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize);
	if (!_v0.$) {
		var s = _v0.a;
		return '-' + (s + '-');
	} else {
		return '-';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass = function (_v0) {
	var screenSize = _v0.lh;
	var offsetCount = _v0.ko;
	return $elm$html$Html$Attributes$class(
		'offset' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetCountOption(offsetCount)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes = function (offsets) {
	var offset_ = function (m) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetClass, m);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, offset_, offsets));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption = function (size) {
	switch (size) {
		case 0:
			return 'first';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		case 12:
			return '12';
		default:
			return 'last';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes = function (orders) {
	var order_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.lh;
			var moveCount = m.a.cx;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'order' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderColOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, order_, orders));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption = function (size) {
	switch (size) {
		case 0:
			return '0';
		case 1:
			return '1';
		case 2:
			return '2';
		case 3:
			return '3';
		case 4:
			return '4';
		case 5:
			return '5';
		case 6:
			return '6';
		case 7:
			return '7';
		case 8:
			return '8';
		case 9:
			return '9';
		case 10:
			return '10';
		case 11:
			return '11';
		default:
			return '12';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes = function (pulls) {
	var pull_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.lh;
			var moveCount = m.a.cx;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'pull' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, pull_, pulls));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes = function (pushes) {
	var push_ = function (m) {
		if (!m.$) {
			var screenSize = m.a.lh;
			var moveCount = m.a.cx;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class(
					'push' + ($rundis$elm_bootstrap$Bootstrap$Grid$Internal$screenSizeToPartialString(screenSize) + $rundis$elm_bootstrap$Bootstrap$Grid$Internal$moveCountOption(moveCount))));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, push_, pushes));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption = function (dir) {
	switch (dir) {
		case 1:
			return 'center';
		case 0:
			return 'left';
		default:
			return 'right';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass = function (_v0) {
	var dir = _v0.iO;
	var size = _v0.hE;
	return $elm$html$Html$Attributes$class(
		'text' + (A2(
			$elm$core$Maybe$withDefault,
			'-',
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return '-' + (s + '-');
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size))) + $rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignDirOption(dir)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		default:
			return 'end';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass = F2(
	function (prefix, _v0) {
		var align = _v0.ie;
		var screenSize = _v0.lh;
		return $elm$html$Html$Attributes$class(
			_Utils_ap(
				prefix,
				_Utils_ap(
					A2(
						$elm$core$Maybe$withDefault,
						'',
						A2(
							$elm$core$Maybe$map,
							function (v) {
								return v + '-';
							},
							$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))),
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$verticalAlignOption(align))));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes = F2(
	function (prefix, aligns) {
		var align = function (a) {
			return A2(
				$elm$core$Maybe$map,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignClass(prefix),
				a);
		};
		return A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, align, aligns));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyColOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultColOptions, modifiers);
	var shouldAddDefaultXs = !$elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[options.d4, options.d2, options.d1, options.d0, options.d3])));
	return _Utils_ap(
		$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colWidthsToAttributes(
			_List_fromArray(
				[
					shouldAddDefaultXs ? $elm$core$Maybe$Just(
					A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, 0, 0)) : options.d4,
					options.d2,
					options.d1,
					options.d0,
					options.d3
				])),
		_Utils_ap(
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$offsetsToAttributes(
				_List_fromArray(
					[options.e6, options.e3, options.e2, options.e1, options.e5])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pullsToAttributes(
					_List_fromArray(
						[options.fs, options.fq, options.fp, options.fo, options.fr])),
				_Utils_ap(
					$rundis$elm_bootstrap$Bootstrap$Grid$Internal$pushesToAttributes(
						_List_fromArray(
							[options.fx, options.fv, options.fu, options.ft, options.fw])),
					_Utils_ap(
						$rundis$elm_bootstrap$Bootstrap$Grid$Internal$orderToAttributes(
							_List_fromArray(
								[options.fi, options.fg, options.fe, options.fd, options.fh])),
						_Utils_ap(
							A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
								'align-self-',
								_List_fromArray(
									[options.ed, options.eb, options.ea, options.d9, options.ec])),
							_Utils_ap(
								function () {
									var _v0 = options.fN;
									if (!_v0.$) {
										var a = _v0.a;
										return _List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(a)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								options.eg)))))));
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $rundis$elm_bootstrap$Bootstrap$Grid$renderCol = function (column) {
	switch (column.$) {
		case 0:
			var options = column.a.ny;
			var children = column.a.mk;
			return A2(
				$elm$html$Html$div,
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
		case 1:
			var e = column.a;
			return e;
		default:
			var options = column.a.ny;
			var children = column.a.mk;
			return A3(
				$elm$html$Html$Keyed$node,
				'div',
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
				children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign = F2(
	function (align, options) {
		var _v0 = align.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						eH: $elm$core$Maybe$Just(align)
					});
			case 1:
				return _Utils_update(
					options,
					{
						eF: $elm$core$Maybe$Just(align)
					});
			case 2:
				return _Utils_update(
					options,
					{
						eE: $elm$core$Maybe$Just(align)
					});
			case 3:
				return _Utils_update(
					options,
					{
						eD: $elm$core$Maybe$Just(align)
					});
			default:
				return _Utils_update(
					options,
					{
						eG: $elm$core$Maybe$Just(align)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign = F2(
	function (align_, options) {
		var _v0 = align_.lh;
		switch (_v0) {
			case 0:
				return _Utils_update(
					options,
					{
						f_: $elm$core$Maybe$Just(align_)
					});
			case 1:
				return _Utils_update(
					options,
					{
						fY: $elm$core$Maybe$Just(align_)
					});
			case 2:
				return _Utils_update(
					options,
					{
						fX: $elm$core$Maybe$Just(align_)
					});
			case 3:
				return _Utils_update(
					options,
					{
						fW: $elm$core$Maybe$Just(align_)
					});
			default:
				return _Utils_update(
					options,
					{
						fZ: $elm$core$Maybe$Just(align_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 2:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs)
					});
			case 0:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowVAlign, align, options);
			default:
				var align = modifier.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowHAlign, align, options);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions = {eg: _List_Nil, eD: $elm$core$Maybe$Nothing, eE: $elm$core$Maybe$Nothing, eF: $elm$core$Maybe$Nothing, eG: $elm$core$Maybe$Nothing, eH: $elm$core$Maybe$Nothing, fW: $elm$core$Maybe$Nothing, fX: $elm$core$Maybe$Nothing, fY: $elm$core$Maybe$Nothing, fZ: $elm$core$Maybe$Nothing, f_: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption = function (align) {
	switch (align) {
		case 0:
			return 'start';
		case 1:
			return 'center';
		case 2:
			return 'end';
		case 3:
			return 'around';
		default:
			return 'between';
	}
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass = function (_v0) {
	var align = _v0.ie;
	var screenSize = _v0.lh;
	return $elm$html$Html$Attributes$class(
		'justify-content-' + (A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (v) {
					return v + '-';
				},
				$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(screenSize))) + $rundis$elm_bootstrap$Bootstrap$General$Internal$horizontalAlignOption(align)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes = function (aligns) {
	var align = function (a) {
		return A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$General$Internal$hAlignClass, a);
	};
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		A2($elm$core$List$map, align, aligns));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$applyRowOption, $rundis$elm_bootstrap$Bootstrap$Grid$Internal$defaultRowOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('row')
			]),
		_Utils_ap(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$vAlignsToAttributes,
				'align-items-',
				_List_fromArray(
					[options.f_, options.fY, options.fX, options.fW, options.fZ])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$hAlignsToAttributes(
					_List_fromArray(
						[options.eH, options.eF, options.eE, options.eD, options.eG])),
				options.eg)));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$row = F2(
	function (options, cols) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Grid$renderCol, cols));
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $author$project$Asset$glyph = F2(
	function (name, more) {
		return A2(
			$elm$html$Html$i,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('align-middle fa'),
						$elm$html$Html$Attributes$class(name)
					]),
				more),
			_List_Nil);
	});
var $author$project$Asset$userGlyph = $author$project$Asset$glyph('fas fa-user');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Aggregations$agg = function (_v0) {
	var name = _v0.a;
	var amount = _v0.b;
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$col,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-weight-bold')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(name)
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-size-24')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(amount)
							]))
					]))
			]));
};
var $author$project$Aggregations$view = function (aggregates) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_Nil,
				A2(
					$elm$core$List$map,
					$author$project$Aggregations$agg,
					_List_fromArray(
						[
							_Utils_Tuple2(
							'Balance',
							$author$project$Cents$toDollar(aggregates.f8)),
							_Utils_Tuple2(
							'Pending in',
							$author$project$Cents$toDollar(aggregates.hW)),
							_Utils_Tuple2(
							'Pending out',
							$author$project$Cents$toDollar(aggregates.hX)),
							_Utils_Tuple2(
							'Total raised',
							$author$project$Cents$toDollar(aggregates.hZ)),
							_Utils_Tuple2(
							'Total spent',
							$author$project$Cents$toDollar(aggregates.h_)),
							_Utils_Tuple2(
							'Total donors',
							$elm$core$String$fromInt(aggregates.hY))
						])))
			]));
};
var $author$project$Asset$bellGlyph = $author$project$Asset$glyph('fas fa-bell');
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Page$warningBell = function (str) {
	var shouldWarn = ((str === '0') || (str === '')) ? false : true;
	return A2(
		$elm$html$Html$a,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Asset$bellGlyph(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('account-control-icon', true),
								_Utils_Tuple2('warning', shouldWarn)
							]))
					]))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col10 = 10;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$width = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColWidth(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Width, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs10 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 10);
var $author$project$Page$header = function (aggregations) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pt3,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pb3,
				$elm$html$Html$Attributes$class('header-container border-bottom border-blue align-middle')
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('align-middle align-items-center')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs10]),
						_List_fromArray(
							[
								$author$project$Aggregations$view(aggregations)
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-right')
									]))
							]),
						_List_fromArray(
							[
								$author$project$Page$warningBell(
								$elm$core$String$fromInt(aggregations.ki)),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml4]),
								_List_fromArray(
									[
										$author$project$Asset$userGlyph(
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('account-control-icon')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Page$mainContainer = F2(
	function (aggregations, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('app-container')
				]),
			_List_fromArray(
				[
					$author$project$Page$header(aggregations),
					$author$project$Page$contentContainer(content)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$Around = 3;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign = F2(
	function (screenSize, align) {
		return {ie: align, lh: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign = F2(
	function (size, align) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$RowHAlign(
			A2($rundis$elm_bootstrap$Bootstrap$General$Internal$HAlign, size, align));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$aroundXs = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 0, 3);
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Page$nameInfoRow = function (name) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$aroundXs,
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-center')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h1,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('display-5')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(name)
							]))
					]))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$Center = 1;
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$centerXs = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 0, 1);
var $author$project$Asset$Image = $elm$core$Basics$identity;
var $author$project$Asset$image = function (filename) {
	return '/assets/images/' + filename;
};
var $author$project$Asset$adirondackTrustCompanyLogo = $author$project$Asset$image('adirondack-trust-company.png');
var $author$project$Asset$chaseBankLogo = $author$project$Asset$image('chase-logo-header.png');
var $author$project$Asset$citizensBankLogo = $author$project$Asset$image('citizens-bank-logo.png');
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$span = _VirtualDom_node('span');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Asset$src = function (_v0) {
	var url = _v0;
	return $elm$html$Html$Attributes$src(url);
};
var $author$project$Bank$stringToLogo = function (bank) {
	switch (bank) {
		case 'chase':
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Asset$src($author$project$Asset$chaseBankLogo),
						$elm$html$Html$Attributes$class('header-info-bank-logo')
					]),
				_List_Nil);
		case 'avanti':
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Asset$src($author$project$Asset$chaseBankLogo),
						$elm$html$Html$Attributes$class('header-info-bank-logo')
					]),
				_List_Nil);
		case 'adirondack trust company':
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Asset$src($author$project$Asset$adirondackTrustCompanyLogo),
						$elm$html$Html$Attributes$class('header-info-bank-logo')
					]),
				_List_Nil);
		case 'citizens banks':
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Asset$src($author$project$Asset$citizensBankLogo),
						$elm$html$Html$Attributes$class('header-info-bank-logo')
					]),
				_List_Nil);
		default:
			return A2($elm$html$Html$span, _List_Nil, _List_Nil);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col6 = 6;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 6);
var $author$project$Page$ruleInfoRow = function (committee) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Grid$Row$centerXs]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6,
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('border-right border-blue text-center align-self-center'),
								$elm$html$Html$Attributes$class('text-right text-capitalize')
							]))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(committee.kn)
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
				_List_fromArray(
					[
						$author$project$Bank$stringToLogo(committee.ij)
					]))
			]));
};
var $author$project$Page$committeeInfoContainer = function (committee) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Page$nameInfoRow(committee.ip),
				$author$project$Page$ruleInfoRow(committee)
			]));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $author$project$Route$href = function (targetRoute) {
	return $elm$html$Html$Attributes$href(
		$author$project$Route$routeToString(targetRoute));
};
var $author$project$Asset$usLogo = $author$project$Asset$image('logo-hires-wing.png');
var $author$project$Page$logo = function (committee) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-center')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$author$project$Route$href(
						$author$project$Route$Transactions(committee.jB))
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$author$project$Asset$src($author$project$Asset$usLogo),
								$elm$html$Html$Attributes$class('header-logo')
							]),
						_List_Nil)
					]))
			]));
};
var $author$project$Asset$coinsGlyph = $author$project$Asset$glyph('fal fa-coins');
var $author$project$Asset$linkGlyph = $author$project$Asset$glyph('fa-link');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5 = $elm$html$Html$Attributes$class('mt-5');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb4 = $elm$html$Html$Attributes$class('mb-4');
var $author$project$Page$pageIsActive = F2(
	function (page, route) {
		var _v0 = _Utils_Tuple2(page, route);
		_v0$2:
		while (true) {
			switch (_v0.a) {
				case 2:
					if (_v0.b.$ === 1) {
						var _v1 = _v0.a;
						return true;
					} else {
						break _v0$2;
					}
				case 3:
					if (_v0.b.$ === 2) {
						var _v2 = _v0.a;
						return true;
					} else {
						break _v0$2;
					}
				default:
					break _v0$2;
			}
		}
		return false;
	});
var $author$project$Page$selected = F2(
	function (page, route) {
		return A2($author$project$Page$pageIsActive, page, route) ? 'color-selected' : '';
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col3 = 3;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs3 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 3);
var $author$project$Page$navRow = F5(
	function (enabled, glyph, page, route, label) {
		var activeText = enabled ? '' : 'text-muted';
		var activeRoute = enabled ? _List_fromArray(
			[
				$author$project$Route$href(route)
			]) : _List_Nil;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$centerXs,
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('hover-underline hover-black')
						])),
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_Utils_ap(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('hover-black ' + activeText)
									]),
								activeRoute),
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class(
											'' + A2($author$project$Page$selected, page, route))
										]),
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Grid$row,
											_List_fromArray(
												[$rundis$elm_bootstrap$Bootstrap$Grid$Row$aroundXs]),
											_List_fromArray(
												[
													A2(
													$rundis$elm_bootstrap$Bootstrap$Grid$col,
													_List_fromArray(
														[
															$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs3,
															$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('text-center')
																]))
														]),
													_List_fromArray(
														[glyph])),
													A2(
													$rundis$elm_bootstrap$Bootstrap$Grid$col,
													_List_fromArray(
														[
															$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('font-size-18')
																]))
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(label)
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$navContainer = F2(
	function (page, committee) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5]),
			_List_fromArray(
				[
					A5(
					$author$project$Page$navRow,
					true,
					$author$project$Asset$coinsGlyph(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tool-glyph')
							])),
					page,
					$author$project$Route$Transactions(committee.jB),
					'Treasury Manager'),
					A5(
					$author$project$Page$navRow,
					true,
					$author$project$Asset$linkGlyph(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('tool-glyph')
							])),
					page,
					$author$project$Route$LinkBuilder(committee.jB),
					'Link Builder')
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0 = $elm$html$Html$Attributes$class('pl-0');
var $author$project$Page$sidebar = F2(
	function (page, committee) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sidebar-container border-right border-blue'),
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0
				]),
			_List_fromArray(
				[
					$author$project$Page$committeeInfoContainer(committee),
					A2($author$project$Page$navContainer, page, committee),
					$author$project$Page$logo(committee)
				]));
	});
var $author$project$Page$view = F5(
	function (config, aggregations, committee, page, _v0) {
		var title = _v0.lu;
		var content = _v0.ms;
		return {
			$9: A2(
				$elm$core$List$cons,
				A2($author$project$Page$sidebar, page, committee),
				A2(
					$elm$core$List$cons,
					A2($author$project$Page$mainContainer, aggregations, content),
					_List_Nil)),
			lu: title + ' - Treasury Manager'
		};
	});
var $author$project$Page$Blank$view = {
	ms: $elm$html$Html$text(''),
	lu: ''
};
var $author$project$Page$Demo$GenDemoCommitteeClicked = {$: 4};
var $author$project$Page$Demo$PasswordUpdated = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Block = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Button$block = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Block;
var $elm$html$Html$button = _VirtualDom_node('button');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size = modifier.a;
				return _Utils_update(
					options,
					{
						hE: $elm$core$Maybe$Just(size)
					});
			case 1:
				var coloring = modifier.a;
				return _Utils_update(
					options,
					{
						a7: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				return _Utils_update(
					options,
					{ej: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{iQ: val});
			default:
				var attrs = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions = {eg: _List_Nil, ej: false, a7: $elm$core$Maybe$Nothing, iQ: false, hE: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass = function (role) {
	switch (role) {
		case 0:
			return 'primary';
		case 1:
			return 'secondary';
		case 2:
			return 'success';
		case 3:
			return 'info';
		case 4:
			return 'warning';
		case 5:
			return 'danger';
		case 6:
			return 'dark';
		case 7:
			return 'light';
		default:
			return 'link';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Internal$Button$applyModifier, $rundis$elm_bootstrap$Bootstrap$Internal$Button$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('btn', true),
						_Utils_Tuple2('btn-block', options.ej),
						_Utils_Tuple2('disabled', options.iQ)
					])),
				$elm$html$Html$Attributes$disabled(options.iQ)
			]),
		_Utils_ap(
			function () {
				var _v0 = A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption, options.hE);
				if (!_v0.$) {
					var s = _v0.a;
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$class('btn-' + s)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.a7;
					if (!_v1.$) {
						if (!_v1.a.$) {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						} else {
							var role = _v1.a.a;
							return _List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									'btn-outline-' + $rundis$elm_bootstrap$Bootstrap$Internal$Button$roleClass(role))
								]);
						}
					} else {
						return _List_Nil;
					}
				}(),
				options.eg)));
};
var $rundis$elm_bootstrap$Bootstrap$Button$button = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$button,
			$rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(options),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Internal$Button$Disabled(disabled_);
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Primary = 0;
var $rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(0));
var $rundis$elm_bootstrap$Bootstrap$Spinner$Size = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$Small = 1;
var $rundis$elm_bootstrap$Bootstrap$Spinner$small = $rundis$elm_bootstrap$Bootstrap$Spinner$Size(1);
var $rundis$elm_bootstrap$Bootstrap$Spinner$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var spinnerKind = modifier.a;
				return _Utils_update(
					options,
					{bj: spinnerKind});
			case 1:
				var spinnerSize = modifier.a;
				return _Utils_update(
					options,
					{hE: spinnerSize});
			case 2:
				var color_ = modifier.a;
				return _Utils_update(
					options,
					{
						et: $elm$core$Maybe$Just(color_)
					});
			default:
				var list = modifier.a;
				return _Utils_update(
					options,
					{eg: list});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Spinner$Border = 0;
var $rundis$elm_bootstrap$Bootstrap$Spinner$Normal = 0;
var $rundis$elm_bootstrap$Bootstrap$Spinner$defaultOptions = {eg: _List_Nil, et: $elm$core$Maybe$Nothing, bj: 0, hE: 0};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName = function (kind_) {
	if (!kind_) {
		return 'spinner-border';
	} else {
		return 'spinner-grow';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$kindClass = A2($elm$core$Basics$composeL, $elm$html$Html$Attributes$class, $rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName);
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $rundis$elm_bootstrap$Bootstrap$Spinner$sizeAttributes = F2(
	function (size_, kind_) {
		switch (size_) {
			case 0:
				return $elm$core$Maybe$Nothing;
			case 1:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class(
							$rundis$elm_bootstrap$Bootstrap$Spinner$kindClassName(kind_) + '-sm')
						]));
			default:
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$style, 'width', '3rem'),
							A2($elm$html$Html$Attributes$style, 'height', '3rem')
						]));
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass = F2(
	function (prefix, role) {
		return $elm$html$Html$Attributes$class(
			prefix + ('-' + function () {
				switch (role) {
					case 0:
						return 'primary';
					case 1:
						return 'secondary';
					case 2:
						return 'success';
					case 3:
						return 'info';
					case 4:
						return 'warning';
					case 5:
						return 'danger';
					case 6:
						return 'light';
					default:
						return 'dark';
				}
			}()));
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass = function (color) {
	if (color.$ === 1) {
		return $elm$html$Html$Attributes$class('text-white');
	} else {
		var role = color.a;
		return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'text', role);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$toAttributes = function (options) {
	return _Utils_ap(
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			_List_fromArray(
				[
					$elm$core$Maybe$Just(
					$rundis$elm_bootstrap$Bootstrap$Spinner$kindClass(options.bj)),
					A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass, options.et)
				])),
		_Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($rundis$elm_bootstrap$Bootstrap$Spinner$sizeAttributes, options.hE, options.bj)),
			_Utils_ap(
				_List_fromArray(
					[
						A2($elm$html$Html$Attributes$attribute, 'role', 'status')
					]),
				options.eg)));
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$spinner = F2(
	function (options, children) {
		var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Spinner$applyModifier, $rundis$elm_bootstrap$Bootstrap$Spinner$defaultOptions, options);
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Spinner$toAttributes(opts),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Spinner$srMessage = function (msg) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sr-only')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(msg)
			]));
};
var $author$project$SubmitButton$spinner = A2(
	$rundis$elm_bootstrap$Bootstrap$Spinner$spinner,
	_List_fromArray(
		[$rundis$elm_bootstrap$Bootstrap$Spinner$small]),
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Spinner$srMessage('Loading...')
		]));
var $author$project$SubmitButton$block = F5(
	function (attrs, label, submitMsg, loading, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Events$onClick(submitMsg),
						attrs)),
					$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled),
					$rundis$elm_bootstrap$Bootstrap$Button$block
				]),
			_List_fromArray(
				[
					loading ? $author$project$SubmitButton$spinner : $elm$html$Html$text(label)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Progress$Attrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Progress$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Progress$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Progress$Label = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Progress$label = function (text) {
	return $rundis$elm_bootstrap$Bootstrap$Progress$Label(
		_List_fromArray(
			[
				$elm$html$Html$text(text)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb1 = $elm$html$Html$Attributes$class('mb-1');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1 = $elm$html$Html$Attributes$class('mt-1');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3 = $elm$html$Html$Attributes$class('mt-3');
var $rundis$elm_bootstrap$Bootstrap$Progress$Options = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Progress$applyOption = F2(
	function (modifier, _v0) {
		var options = _v0;
		switch (modifier.$) {
			case 0:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{aZ: value_});
			case 1:
				var height_ = modifier.a;
				return _Utils_update(
					options,
					{jx: height_});
			case 2:
				var label_ = modifier.a;
				return _Utils_update(
					options,
					{jY: label_});
			case 3:
				var role_ = modifier.a;
				return _Utils_update(
					options,
					{fC: role_});
			case 4:
				var striped_ = modifier.a;
				return _Utils_update(
					options,
					{hQ: striped_});
			case 5:
				var animated_ = modifier.a;
				return _Utils_update(
					options,
					{ee: animated_});
			case 6:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{eg: attrs_});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{h4: attrs_});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Progress$defaultOptions = {ee: false, eg: _List_Nil, jx: $elm$core$Maybe$Nothing, jY: _List_Nil, fC: $elm$core$Maybe$Nothing, hQ: false, aZ: 0, h4: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Progress$roleClass = function (role) {
	return $elm$html$Html$Attributes$class(
		function () {
			switch (role) {
				case 0:
					return 'bg-success';
				case 1:
					return 'bg-info';
				case 2:
					return 'bg-warning';
				default:
					return 'bg-danger';
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Progress$toAttributes = function (_v0) {
	var options = _v0;
	return $elm$core$List$concat(
		_List_fromArray(
			[
				_List_fromArray(
				[
					A2($elm$html$Html$Attributes$attribute, 'role', 'progressbar'),
					A2(
					$elm$html$Html$Attributes$attribute,
					'aria-value-now',
					$elm$core$String$fromFloat(options.aZ)),
					A2($elm$html$Html$Attributes$attribute, 'aria-valuemin', '0'),
					A2($elm$html$Html$Attributes$attribute, 'aria-valuemax', '100'),
					A2(
					$elm$html$Html$Attributes$style,
					'width',
					$elm$core$String$fromFloat(options.aZ) + '%'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('progress-bar', true),
							_Utils_Tuple2('progress-bar-striped', options.hQ || options.ee),
							_Utils_Tuple2('progress-bar-animated', options.ee)
						]))
				]),
				function () {
				var _v1 = options.jx;
				if (!_v1.$) {
					var height_ = _v1.a;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$Attributes$style,
							'height',
							$elm$core$String$fromInt(height_) + 'px')
						]);
				} else {
					return _List_Nil;
				}
			}(),
				function () {
				var _v2 = options.fC;
				if (!_v2.$) {
					var role_ = _v2.a;
					return _List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Progress$roleClass(role_)
						]);
				} else {
					return _List_Nil;
				}
			}(),
				options.eg
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Progress$renderBar = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Progress$applyOption, $rundis$elm_bootstrap$Bootstrap$Progress$defaultOptions, modifiers);
	var opts = options;
	return A2(
		$elm$html$Html$div,
		$rundis$elm_bootstrap$Bootstrap$Progress$toAttributes(options),
		opts.jY);
};
var $rundis$elm_bootstrap$Bootstrap$Progress$progress = function (modifiers) {
	var _v0 = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Progress$applyOption, $rundis$elm_bootstrap$Bootstrap$Progress$defaultOptions, modifiers);
	var options = _v0;
	return A2(
		$elm$html$Html$div,
		A2(
			$elm$core$List$cons,
			$elm$html$Html$Attributes$class('progress'),
			options.h4),
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Progress$renderBar(modifiers)
			]));
};
var $author$project$LoadingBar$progressToPercent = function (val) {
	return $elm$core$String$fromInt(val) + '%';
};
var $rundis$elm_bootstrap$Bootstrap$Progress$Striped = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Progress$striped = $rundis$elm_bootstrap$Bootstrap$Progress$Striped(true);
var $rundis$elm_bootstrap$Bootstrap$Progress$Value = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Progress$value = function (val) {
	return $rundis$elm_bootstrap$Bootstrap$Progress$Value(val);
};
var $rundis$elm_bootstrap$Bootstrap$Progress$WrapperAttrs = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Progress$wrapperAttrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Progress$WrapperAttrs(attrs_);
};
var $author$project$LoadingBar$view = F2(
	function (isSubmitting, progress) {
		if (isSubmitting) {
			var fixedProg = (progress >= 100) ? 99 : progress;
			return _List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Progress$progress(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Progress$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb1])),
							$rundis$elm_bootstrap$Bootstrap$Progress$value(fixedProg),
							$rundis$elm_bootstrap$Bootstrap$Progress$label(
							$author$project$LoadingBar$progressToPercent(fixedProg)),
							$rundis$elm_bootstrap$Bootstrap$Progress$striped,
							$rundis$elm_bootstrap$Bootstrap$Progress$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('rounded')
								])),
							$rundis$elm_bootstrap$Bootstrap$Progress$wrapperAttrs(
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
									$elm$html$Html$Attributes$class('height-md')
								]))
						]))
				]);
		} else {
			return _List_Nil;
		}
	});
var $author$project$SubmitButton$blockWithLoadingBar = F5(
	function (attrs, label, submitMsg, isSubmitting, progress) {
		if (isSubmitting) {
			return A2($author$project$LoadingBar$view, true, progress);
		} else {
			return $elm$core$List$singleton(
				A5($author$project$SubmitButton$block, attrs, label, submitMsg, isSubmitting, isSubmitting));
		}
	});
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $rundis$elm_bootstrap$Bootstrap$Form$applyModifier = F2(
	function (modifier, options) {
		var value = modifier;
		return _Utils_update(
			options,
			{
				eg: _Utils_ap(options.eg, value)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions = {eg: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Form$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-group')
			]),
		options.eg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$group = F2(
	function (options, children) {
		return A2(
			$elm$html$Html$div,
			$rundis$elm_bootstrap$Bootstrap$Form$toAttributes(options),
			children);
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $elm$html$Html$small = _VirtualDom_node('small');
var $rundis$elm_bootstrap$Bootstrap$Form$help = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$small,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('form-text text-muted'),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Id = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$id = function (id_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Id(id_);
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $rundis$elm_bootstrap$Bootstrap$Form$label = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$label,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('form-control-label'),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$onInput = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$OnInput(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Password = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Input = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Type = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$create = F2(
	function (tipe, options) {
		return {
			ny: A2(
				$elm$core$List$cons,
				$rundis$elm_bootstrap$Bootstrap$Form$Input$Type(tipe),
				options)
		};
	});
var $elm$html$Html$input = _VirtualDom_node('input');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						hE: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						jB: $elm$core$Maybe$Just(id_)
					});
			case 2:
				var tipe = modifier.a;
				return _Utils_update(
					options,
					{dQ: tipe});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{iQ: val});
			case 4:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						aZ: $elm$core$Maybe$Just(value_)
					});
			case 7:
				var value_ = modifier.a;
				return _Utils_update(
					options,
					{
						fm: $elm$core$Maybe$Just(value_)
					});
			case 5:
				var onInput_ = modifier.a;
				return _Utils_update(
					options,
					{
						fa: $elm$core$Maybe$Just(onInput_)
					});
			case 6:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						f$: $elm$core$Maybe$Just(validation_)
					});
			case 8:
				var val = modifier.a;
				return _Utils_update(
					options,
					{fy: val});
			case 9:
				var val = modifier.a;
				return _Utils_update(
					options,
					{dE: val});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Text = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions = {eg: _List_Nil, iQ: false, jB: $elm$core$Maybe$Nothing, fa: $elm$core$Maybe$Nothing, fm: $elm$core$Maybe$Nothing, dE: false, fy: false, hE: $elm$core$Maybe$Nothing, dQ: 0, f$: $elm$core$Maybe$Nothing, aZ: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$readonly = $elm$html$Html$Attributes$boolProperty('readOnly');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute = function (size) {
	return A2(
		$elm$core$Maybe$map,
		function (s) {
			return $elm$html$Html$Attributes$class('form-control-' + s);
		},
		$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size));
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute = function (inputType) {
	return $elm$html$Html$Attributes$type_(
		function () {
			switch (inputType) {
				case 0:
					return 'text';
				case 1:
					return 'password';
				case 2:
					return 'datetime-local';
				case 3:
					return 'date';
				case 4:
					return 'month';
				case 5:
					return 'time';
				case 6:
					return 'week';
				case 7:
					return 'number';
				case 8:
					return 'email';
				case 9:
					return 'url';
				case 10:
					return 'search';
				case 11:
					return 'tel';
				default:
					return 'color';
			}
		}());
};
var $rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString = function (validation) {
	if (!validation) {
		return 'is-valid';
	} else {
		return 'is-invalid';
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute = function (validation) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation));
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Input$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Input$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				options.dE ? 'form-control-plaintext' : 'form-control'),
				$elm$html$Html$Attributes$disabled(options.iQ),
				$elm$html$Html$Attributes$readonly(options.fy || options.dE),
				$rundis$elm_bootstrap$Bootstrap$Form$Input$typeAttribute(options.dQ)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.jB),
						A2($elm$core$Maybe$andThen, $rundis$elm_bootstrap$Bootstrap$Form$Input$sizeAttribute, options.hE),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$value, options.aZ),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$placeholder, options.fm),
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onInput, options.fa),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Input$validationAttribute, options.f$)
					])),
			options.eg));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$view = function (_v0) {
	var options = _v0.ny;
	return A2(
		$elm$html$Html$input,
		$rundis$elm_bootstrap$Bootstrap$Form$Input$toAttributes(options),
		_List_Nil);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$input = F2(
	function (tipe, options) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Input$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Input$create, tipe, options));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Input$password = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(1);
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $author$project$Page$Demo$idToCommitteeUrl = F2(
	function (config, id) {
		return config.k2 + ('/committee/' + id);
	});
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Page$Demo$urlRow = function (model) {
	var _v0 = model.bk;
	if (!_v0.$) {
		var id = _v0.a;
		return $elm$core$List$singleton(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h5,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5]),
								_List_fromArray(
									[
										$elm$html$Html$text('Committee Link')
									])),
								A2(
								$elm$html$Html$a,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$href(
										A2($author$project$Page$Demo$idToCommitteeUrl, model.gh, id)),
										$elm$html$Html$Attributes$class('d-block max-height-80'),
										$elm$html$Html$Attributes$target('_blank'),
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2($author$project$Page$Demo$idToCommitteeUrl, model.gh, id))
									]))
							]))
					])));
	} else {
		return _List_Nil;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Value = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$value = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Value(value_);
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2 = $elm$html$Html$Attributes$class('mb-2');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2 = $elm$html$Html$Attributes$class('mt-2');
var $author$project$Errors$view = function (errors) {
	if (!errors.b) {
		return _List_Nil;
	} else {
		var x = errors.a;
		var xs = errors.b;
		return $elm$core$List$singleton(
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2,
						$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2,
						$elm$html$Html$Attributes$class('text-danger')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(x)
					])));
	}
};
var $author$project$Page$Demo$formRow = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
				$elm$html$Html$Attributes$class('fade-in')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$h2,
									_List_Nil,
									_List_fromArray(
										[
											$elm$html$Html$text('Generate Demo Committee')
										]))
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs6]),
							_Utils_ap(
								$author$project$Errors$view(model.gv),
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Form$group,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rundis$elm_bootstrap$Bootstrap$Form$label,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$for('password')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text('Passcode')
														])),
													$rundis$elm_bootstrap$Bootstrap$Form$Input$password(
													_List_fromArray(
														[
															$rundis$elm_bootstrap$Bootstrap$Form$Input$id('password'),
															$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Page$Demo$PasswordUpdated),
															$rundis$elm_bootstrap$Bootstrap$Form$Input$value(model.kB)
														])),
													A2(
													$rundis$elm_bootstrap$Bootstrap$Form$help,
													_List_Nil,
													_List_fromArray(
														[
															$elm$html$Html$text('Or maybe you shouldn\'t be here...')
														]))
												]))
										]),
									A5($author$project$SubmitButton$blockWithLoadingBar, _List_Nil, 'Generate', $author$project$Page$Demo$GenDemoCommitteeClicked, model.aG, model.aH))))
						]))
				]),
			$author$project$Page$Demo$urlRow(model)));
};
var $author$project$Page$Demo$Contribution = 0;
var $author$project$Page$Demo$ReconcileDemoTxnClicked = {$: 9};
var $author$project$Page$Demo$ResetView = {$: 10};
var $author$project$Page$Demo$SeedBankRecordClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$LabelWithData$dataText = function (data) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('font-size-large')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(data)
			]));
};
var $author$project$Page$Demo$demoLabel = function (label) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				]))
		]);
};
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Page$Demo$eventList = function (model) {
	return A2(
		$elm$html$Html$ul,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (e) {
				return A2(
					$elm$html$Html$li,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(e)
						]));
			},
			model.bf));
};
var $author$project$Page$Demo$manageDemoUrl = function (model) {
	var _v0 = model.bk;
	if (!_v0.$) {
		var id = _v0.a;
		return _Utils_ap(
			$author$project$Page$Demo$demoLabel('Committee Link'),
			$elm$core$List$singleton(
				A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$href(
							A2($author$project$Page$Demo$idToCommitteeUrl, model.gh, id)),
							$elm$html$Html$Attributes$target('_blank')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2($author$project$Page$Demo$idToCommitteeUrl, model.gh, id))
						]))));
	} else {
		return _List_Nil;
	}
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rundis$elm_bootstrap$Bootstrap$Button$onClick = function (message) {
	return $rundis$elm_bootstrap$Bootstrap$Button$attrs(
		_List_fromArray(
			[
				A2(
				$elm$html$Html$Events$preventDefaultOn,
				'click',
				$elm$json$Json$Decode$succeed(
					_Utils_Tuple2(message, true)))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Danger = 5;
var $rundis$elm_bootstrap$Bootstrap$Button$outlineDanger = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(5));
var $author$project$Page$Demo$resetButton = function (msg) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlineDanger,
				$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg),
				$rundis$elm_bootstrap$Bootstrap$Button$disabled(false),
				$rundis$elm_bootstrap$Bootstrap$Button$block,
				$rundis$elm_bootstrap$Bootstrap$Button$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Reset')
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col12 = 12;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 12);
var $author$project$Page$Demo$manageDemoView = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
				$elm$html$Html$Attributes$class('fade-in')
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs12]),
						_Utils_ap(
							_List_fromArray(
								[
									$author$project$LabelWithData$dataText('Manage Demo Committee')
								]),
							_Utils_ap(
								$author$project$Errors$view(model.gv),
								$author$project$Page$Demo$manageDemoUrl(model))))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs3]),
						_Utils_ap(
							$author$project$Page$Demo$demoLabel('Actions'),
							_Utils_ap(
								_List_fromArray(
									[
										A5(
										$author$project$SubmitButton$block,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$attribute, 'data-cy', 'seedMoneyIn')
											]),
										'Seed Money In',
										$author$project$Page$Demo$SeedBankRecordClicked(0),
										model.bY,
										false)
									]),
								_Utils_ap(
									_List_fromArray(
										[
											A5(
											$author$project$SubmitButton$block,
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'data-cy', 'seedMoneyOut'),
													$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3
												]),
											'Seed Money Out',
											$author$project$Page$Demo$SeedBankRecordClicked(1),
											model.bZ,
											false)
										]),
									_Utils_ap(
										_List_fromArray(
											[
												A5(
												$author$project$SubmitButton$block,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$attribute, 'data-cy', 'reconcileOne'),
														$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3
													]),
												'Reconcile One',
												$author$project$Page$Demo$ReconcileDemoTxnClicked,
												model.bX,
												false)
											]),
										_Utils_ap(
											_List_fromArray(
												[
													$author$project$Page$Demo$resetButton($author$project$Page$Demo$ResetView)
												]),
											_Utils_ap(
												$author$project$Page$Demo$demoLabel('Event Log'),
												_List_fromArray(
													[
														$author$project$Page$Demo$eventList(model)
													]))))))))
					]))
			]));
};
var $author$project$Page$Demo$view = function (model) {
	return {
		ms: A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					function () {
					var _v0 = model.c5;
					if (!_v0) {
						return $author$project$Page$Demo$formRow(model);
					} else {
						return $author$project$Page$Demo$manageDemoView(model);
					}
				}()
				])),
		lu: '4US - Demo'
	};
};
var $author$project$Page$LinkBuilder$AmountUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$LinkBuilder$RefCodeUpdated = function (a) {
	return {$: 1, a: a};
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$crossOrigin = F3(
	function (prePath, pathSegments, parameters) {
		return prePath + ('/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters)));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Page$LinkBuilder$createUrl = F4(
	function (donorUrl, committeeId, refCode, amount) {
		var refCodeVal = ($elm$core$String$length(refCode) > 0) ? _List_fromArray(
			[
				A2($elm$url$Url$Builder$string, 'refCode', refCode)
			]) : _List_Nil;
		var amountVal = ($elm$core$String$length(amount) > 0) ? _List_fromArray(
			[
				A2($elm$url$Url$Builder$string, 'amount', amount)
			]) : _List_Nil;
		return A3(
			$elm$url$Url$Builder$crossOrigin,
			donorUrl,
			_List_fromArray(
				['committee', committeeId]),
			_Utils_ap(refCodeVal, amountVal));
	});
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$Attrs = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Card$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Card$Internal$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Card$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$CardBlock = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$applyBlockModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 0:
				var align = option.a;
				return _Utils_update(
					options,
					{
						a4: $elm$core$Maybe$Just(align)
					});
			case 1:
				var role = option.a;
				return _Utils_update(
					options,
					{
						a7: $elm$core$Maybe$Just(role)
					});
			case 2:
				var color = option.a;
				return _Utils_update(
					options,
					{
						br: $elm$core$Maybe$Just(color)
					});
			default:
				var attrs = option.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$defaultBlockOptions = {a4: $elm$core$Maybe$Nothing, eg: _List_Nil, a7: $elm$core$Maybe$Nothing, br: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$blockAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Card$Internal$applyBlockModifier, $rundis$elm_bootstrap$Bootstrap$Card$Internal$defaultBlockOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card-body')
			]),
		_Utils_ap(
			function () {
				var _v0 = options.a4;
				if (!_v0.$) {
					var align = _v0.a;
					return _List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(align)
						]);
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.a7;
					if (!_v1.$) {
						var role = _v1.a;
						return _List_fromArray(
							[
								A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v2 = options.br;
						if (!_v2.$) {
							var color = _v2.a;
							return _List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass(color)
								]);
						} else {
							return _List_Nil;
						}
					}(),
					options.eg))));
};
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$block = F2(
	function (options, items) {
		return $rundis$elm_bootstrap$Bootstrap$Card$Internal$CardBlock(
			A2(
				$elm$html$Html$div,
				$rundis$elm_bootstrap$Bootstrap$Card$Internal$blockAttributes(options),
				A2(
					$elm$core$List$map,
					function (_v0) {
						var e = _v0;
						return e;
					},
					items)));
	});
var $rundis$elm_bootstrap$Bootstrap$Card$block = F3(
	function (options, items, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				ge: _Utils_ap(
					conf.ge,
					_List_fromArray(
						[
							A2($rundis$elm_bootstrap$Bootstrap$Card$Internal$block, options, items)
						]))
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Card$config = function (options) {
	return {ge: _List_Nil, gy: $elm$core$Maybe$Nothing, eJ: $elm$core$Maybe$Nothing, gD: $elm$core$Maybe$Nothing, gE: $elm$core$Maybe$Nothing, ny: options};
};
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$BlockItem = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Card$Block$custom = function (element) {
	return element;
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3 = $elm$html$Html$Attributes$class('mb-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3 = $elm$html$Html$Attributes$class('p-3');
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Button$primary = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(0));
var $pablohirafuji$elm_qrcode$QRCode$Quartile = 2;
var $pablohirafuji$elm_qrcode$QRCode$QRCode = $elm$core$Basics$identity;
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex = F3(
	function (size, row, col) {
		return (size * row) + col;
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$isOccupy = F4(
	function (row, col, size, matrix) {
		var _v0 = A2(
			$elm$core$Array$get,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			matrix);
		if ((!_v0.$) && (!_v0.a.$)) {
			return true;
		} else {
			return false;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$nextModule = function (placement) {
	var row = placement.aK;
	var col = placement.iz;
	var isRight = placement.aU;
	var isUp = placement.dp;
	return isRight ? _Utils_update(
		placement,
		{iz: col - 1, aU: false}) : (isUp ? _Utils_update(
		placement,
		{iz: col + 1, aU: true, aK: row - 1}) : _Utils_update(
		placement,
		{iz: col + 1, aU: true, aK: row + 1}));
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$bitToColor = F2(
	function (_byte, offset) {
		return (1 & (_byte >> (7 - offset))) === 1;
	});
var $elm$core$Array$setHelp = F4(
	function (shift, index, value, tree) {
		var pos = $elm$core$Array$bitMask & (index >>> shift);
		var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
		if (!_v0.$) {
			var subTree = _v0.a;
			var newSub = A4($elm$core$Array$setHelp, shift - $elm$core$Array$shiftStep, index, value, subTree);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$SubTree(newSub),
				tree);
		} else {
			var values = _v0.a;
			var newLeaf = A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, values);
			return A3(
				$elm$core$Elm$JsArray$unsafeSet,
				pos,
				$elm$core$Array$Leaf(newLeaf),
				tree);
		}
	});
var $elm$core$Array$set = F3(
	function (index, value, array) {
		var len = array.a;
		var startShift = array.b;
		var tree = array.c;
		var tail = array.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? array : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			tree,
			A3($elm$core$Elm$JsArray$unsafeSet, $elm$core$Array$bitMask & index, value, tail)) : A4(
			$elm$core$Array$Array_elm_builtin,
			len,
			startShift,
			A4($elm$core$Array$setHelp, startShift, index, value, tree),
			tail));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setDataModule = F3(
	function (_v0, _byte, offset) {
		var size = _v0.hE;
		var row = _v0.aK;
		var col = _v0.iz;
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(
					false,
					A2($pablohirafuji$elm_qrcode$QRCode$Matrix$bitToColor, _byte, offset))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$addDataModule = F4(
	function (placement, bytes, offset, matrix) {
		addDataModule:
		while (true) {
			var size = placement.hE;
			var row = placement.aK;
			var col = placement.iz;
			if (!bytes.b) {
				return matrix;
			} else {
				var head = bytes.a;
				var tail = bytes.b;
				if (offset >= 8) {
					var $temp$placement = placement,
						$temp$bytes = tail,
						$temp$offset = 0,
						$temp$matrix = matrix;
					placement = $temp$placement;
					bytes = $temp$bytes;
					offset = $temp$offset;
					matrix = $temp$matrix;
					continue addDataModule;
				} else {
					if (col === 6) {
						var $temp$placement = _Utils_update(
							placement,
							{iz: col - 1, aU: true}),
							$temp$bytes = bytes,
							$temp$offset = offset,
							$temp$matrix = matrix;
						placement = $temp$placement;
						bytes = $temp$bytes;
						offset = $temp$offset;
						matrix = $temp$matrix;
						continue addDataModule;
					} else {
						if (row < 0) {
							var $temp$placement = _Utils_update(
								placement,
								{iz: col - 2, aU: true, dp: false, aK: 0}),
								$temp$bytes = bytes,
								$temp$offset = offset,
								$temp$matrix = matrix;
							placement = $temp$placement;
							bytes = $temp$bytes;
							offset = $temp$offset;
							matrix = $temp$matrix;
							continue addDataModule;
						} else {
							if (_Utils_cmp(row, size) > -1) {
								var $temp$placement = _Utils_update(
									placement,
									{iz: col - 2, aU: true, dp: true, aK: size - 1}),
									$temp$bytes = bytes,
									$temp$offset = offset,
									$temp$matrix = matrix;
								placement = $temp$placement;
								bytes = $temp$bytes;
								offset = $temp$offset;
								matrix = $temp$matrix;
								continue addDataModule;
							} else {
								if (A4($pablohirafuji$elm_qrcode$QRCode$Matrix$isOccupy, row, col, size, matrix)) {
									var $temp$placement = $pablohirafuji$elm_qrcode$QRCode$Matrix$nextModule(placement),
										$temp$bytes = bytes,
										$temp$offset = offset,
										$temp$matrix = matrix;
									placement = $temp$placement;
									bytes = $temp$bytes;
									offset = $temp$offset;
									matrix = $temp$matrix;
									continue addDataModule;
								} else {
									var $temp$placement = $pablohirafuji$elm_qrcode$QRCode$Matrix$nextModule(placement),
										$temp$bytes = bytes,
										$temp$offset = offset + 1,
										$temp$matrix = A4($pablohirafuji$elm_qrcode$QRCode$Matrix$setDataModule, placement, head, offset, matrix);
									placement = $temp$placement;
									bytes = $temp$bytes;
									offset = $temp$offset;
									matrix = $temp$matrix;
									continue addDataModule;
								}
							}
						}
					}
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$initPlacement = function (size) {
	return {iz: size + 1, aU: true, dp: true, aK: size + 1, hE: size};
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$addData = F3(
	function (size, bytes, matrix) {
		return A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$addDataModule,
			$pablohirafuji$elm_qrcode$QRCode$Matrix$initPlacement(size),
			bytes,
			0,
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Error$AlignmentPatternNotFound = {$: 0};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPatternData = $elm$core$Array$fromList(
	_List_fromArray(
		[
			_List_Nil,
			_List_fromArray(
			[6, 18]),
			_List_fromArray(
			[6, 22]),
			_List_fromArray(
			[6, 26]),
			_List_fromArray(
			[6, 30]),
			_List_fromArray(
			[6, 34]),
			_List_fromArray(
			[6, 22, 38]),
			_List_fromArray(
			[6, 24, 42]),
			_List_fromArray(
			[6, 26, 46]),
			_List_fromArray(
			[6, 28, 50]),
			_List_fromArray(
			[6, 30, 54]),
			_List_fromArray(
			[6, 32, 58]),
			_List_fromArray(
			[6, 34, 62]),
			_List_fromArray(
			[6, 26, 46, 66]),
			_List_fromArray(
			[6, 26, 48, 70]),
			_List_fromArray(
			[6, 26, 50, 74]),
			_List_fromArray(
			[6, 30, 54, 78]),
			_List_fromArray(
			[6, 30, 56, 82]),
			_List_fromArray(
			[6, 30, 58, 86]),
			_List_fromArray(
			[6, 34, 62, 90]),
			_List_fromArray(
			[6, 28, 50, 72, 94]),
			_List_fromArray(
			[6, 26, 50, 74, 98]),
			_List_fromArray(
			[6, 30, 54, 78, 102]),
			_List_fromArray(
			[6, 28, 54, 80, 106]),
			_List_fromArray(
			[6, 32, 58, 84, 110]),
			_List_fromArray(
			[6, 30, 58, 86, 114]),
			_List_fromArray(
			[6, 34, 62, 90, 118]),
			_List_fromArray(
			[6, 26, 50, 74, 98, 122]),
			_List_fromArray(
			[6, 30, 54, 78, 102, 126]),
			_List_fromArray(
			[6, 26, 52, 78, 104, 130]),
			_List_fromArray(
			[6, 30, 56, 82, 108, 134]),
			_List_fromArray(
			[6, 34, 60, 86, 112, 138]),
			_List_fromArray(
			[6, 30, 58, 86, 114, 142]),
			_List_fromArray(
			[6, 34, 62, 90, 118, 146]),
			_List_fromArray(
			[6, 30, 54, 78, 102, 126, 150]),
			_List_fromArray(
			[6, 24, 50, 76, 102, 128, 154]),
			_List_fromArray(
			[6, 28, 54, 80, 106, 132, 158]),
			_List_fromArray(
			[6, 32, 58, 84, 110, 136, 162]),
			_List_fromArray(
			[6, 26, 54, 82, 110, 138, 166]),
			_List_fromArray(
			[6, 30, 58, 86, 114, 142, 170])
		]));
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord = F2(
	function (rows, cols) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (row, list) {
					return A3(
						$elm$core$List$foldl,
						F2(
							function (col, list_) {
								return A2(
									$elm$core$List$cons,
									_Utils_Tuple2(row, col),
									list_);
							}),
						list,
						cols);
				}),
			_List_Nil,
			rows);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$isValidAlign = F2(
	function (size, _v0) {
		var row = _v0.a;
		var col = _v0.b;
		return ((row > 10) || ((10 < col) && (_Utils_cmp(col, size - 10) < 0))) && ((_Utils_cmp(row, size - 10) < 0) || (col > 10));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentRange = A2($elm$core$List$range, -2, 2);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentColor = F2(
	function (row, col) {
		return (_Utils_eq(row, -2) || ((row === 2) || (_Utils_eq(col, -2) || ((col === 2) || ((!row) && (!col)))))) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(true, true)) : $elm$core$Maybe$Just(
			_Utils_Tuple2(true, false));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignModule = F4(
	function (size, rowPos, colPos, _v0) {
		var row = _v0.a;
		var col = _v0.b;
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row + rowPos, col + colPos),
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentColor, row, col));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignment = F3(
	function (size, _v0, matrix) {
		var row = _v0.a;
		var col = _v0.b;
		return A3(
			$elm$core$List$foldl,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignModule, size, row, col),
			matrix,
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord, $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentRange, $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentRange));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignments = F3(
	function (size, locations, matrix) {
		return A3(
			$elm$core$List$foldl,
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignment(size),
			matrix,
			A2(
				$elm$core$List$filter,
				$pablohirafuji$elm_qrcode$QRCode$Matrix$isValidAlign(size),
				A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord, locations, locations)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPattern = F3(
	function (version, size, matrix) {
		return A2(
			$elm$core$Result$map,
			function (a) {
				return A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setAlignments, size, a, matrix);
			},
			A2(
				$elm$core$Result$fromMaybe,
				$pablohirafuji$elm_qrcode$QRCode$Error$AlignmentPatternNotFound,
				A2($elm$core$Array$get, version - 1, $pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPatternData)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$darkModule = F2(
	function (version, size) {
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, (4 * version) + 9, 8),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(true, true)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$finderRange = A2($elm$core$List$range, 0, 8);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$finderColor = F2(
	function (row, col) {
		return ((1 <= row) && ((row <= 7) && ((col === 1) || (col === 7)))) || (((1 <= col) && ((col <= 7) && ((row === 1) || (row === 7)))) || ((3 <= row) && ((row <= 5) && ((3 <= col) && (col <= 5)))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFinder = F5(
	function (size, rowOffset, colOffset, _v0, matrix) {
		var row = _v0.a;
		var col = _v0.b;
		var finalRow = row + rowOffset;
		var finalCol = col + colOffset;
		return ((finalRow < 0) || ((finalCol < 0) || ((_Utils_cmp(finalRow, size) > -1) || (_Utils_cmp(finalCol, size) > -1)))) ? matrix : A3(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, finalRow, finalCol),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(
					true,
					A2($pablohirafuji$elm_qrcode$QRCode$Matrix$finderColor, row, col))),
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern = F4(
	function (size, rowOffset, colOffset, matrix) {
		return A3(
			$elm$core$List$foldl,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setFinder, size, rowOffset, colOffset),
			matrix,
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getAreaCoord, $pablohirafuji$elm_qrcode$QRCode$Matrix$finderRange, $pablohirafuji$elm_qrcode$QRCode$Matrix$finderRange));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskColor = F2(
	function (maybeModule, isChange) {
		if (isChange) {
			if ((!maybeModule.$) && (!maybeModule.a.a)) {
				var _v1 = maybeModule.a;
				var isDark = _v1.b;
				return $elm$core$Maybe$Just(
					_Utils_Tuple2(false, !isDark));
			} else {
				return maybeModule;
			}
		} else {
			return maybeModule;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getCoord = F2(
	function (size, index) {
		return _Utils_Tuple2(
			(index / size) | 0,
			A2($elm$core$Basics$modBy, size, index));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskFunction = F4(
	function (_function, size, index, maybeModule) {
		return A2(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskColor,
			maybeModule,
			_function(
				A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getCoord, size, index)));
	});
var $elm$core$Elm$JsArray$foldl = _JsArray_foldl;
var $elm$core$Elm$JsArray$indexedMap = _JsArray_indexedMap;
var $elm$core$Array$indexedMap = F2(
	function (func, _v0) {
		var len = _v0.a;
		var tree = _v0.c;
		var tail = _v0.d;
		var initialBuilder = {
			w: _List_Nil,
			s: 0,
			v: A3(
				$elm$core$Elm$JsArray$indexedMap,
				func,
				$elm$core$Array$tailIndex(len),
				tail)
		};
		var helper = F2(
			function (node, builder) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, builder, subTree);
				} else {
					var leaf = node.a;
					var offset = builder.s * $elm$core$Array$branchFactor;
					var mappedLeaf = $elm$core$Array$Leaf(
						A3($elm$core$Elm$JsArray$indexedMap, func, offset, leaf));
					return {
						w: A2($elm$core$List$cons, mappedLeaf, builder.w),
						s: builder.s + 1,
						v: builder.v
					};
				}
			});
		return A2(
			$elm$core$Array$builderToArray,
			true,
			A3($elm$core$Elm$JsArray$foldl, helper, initialBuilder, tree));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$maskFunction = function (mask) {
	switch (mask) {
		case 0:
			return function (_v1) {
				var row = _v1.a;
				var col = _v1.b;
				return !A2($elm$core$Basics$modBy, 2, row + col);
			};
		case 1:
			return function (_v2) {
				var row = _v2.a;
				return !A2($elm$core$Basics$modBy, 2, row);
			};
		case 2:
			return function (_v3) {
				var col = _v3.b;
				return !A2($elm$core$Basics$modBy, 3, col);
			};
		case 3:
			return function (_v4) {
				var row = _v4.a;
				var col = _v4.b;
				return !A2($elm$core$Basics$modBy, 3, row + col);
			};
		case 4:
			return function (_v5) {
				var row = _v5.a;
				var col = _v5.b;
				return !A2(
					$elm$core$Basics$modBy,
					2,
					$elm$core$Basics$floor(row / 2) + $elm$core$Basics$floor(col / 3));
			};
		case 5:
			return function (_v6) {
				var row = _v6.a;
				var col = _v6.b;
				return !(A2($elm$core$Basics$modBy, 2, row * col) + A2($elm$core$Basics$modBy, 3, row * col));
			};
		case 6:
			return function (_v7) {
				var row = _v7.a;
				var col = _v7.b;
				return !A2(
					$elm$core$Basics$modBy,
					2,
					A2($elm$core$Basics$modBy, 2, row * col) + A2($elm$core$Basics$modBy, 3, row * col));
			};
		default:
			return function (_v8) {
				var row = _v8.a;
				var col = _v8.b;
				return !A2(
					$elm$core$Basics$modBy,
					2,
					A2($elm$core$Basics$modBy, 3, row * col) + A2($elm$core$Basics$modBy, 2, row + col));
			};
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$applyMask = F3(
	function (size, mask, matrix) {
		return A2(
			$elm$core$Array$indexedMap,
			A2(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$applyMaskFunction,
				$pablohirafuji$elm_qrcode$QRCode$Matrix$maskFunction(mask),
				size),
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$breakList = F3(
	function (width, list, acc) {
		breakList:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(acc);
			} else {
				var $temp$width = width,
					$temp$list = A2($elm$core$List$drop, width, list),
					$temp$acc = A2(
					$elm$core$List$cons,
					A2($elm$core$List$take, width, list),
					acc);
				width = $temp$width;
				list = $temp$list;
				acc = $temp$acc;
				continue breakList;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$isDarkModule = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($elm$core$Tuple$second),
	$elm$core$Maybe$withDefault(false));
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score_ = F2(
	function (simplifiedList, _v0) {
		rule1Score_:
		while (true) {
			var last = _v0.a;
			var partialScore = _v0.b;
			var score = _v0.c;
			if (!simplifiedList.b) {
				return (partialScore >= 5) ? ((score + partialScore) - 2) : score;
			} else {
				var head = simplifiedList.a;
				var tail = simplifiedList.b;
				if (_Utils_eq(last, head)) {
					var $temp$simplifiedList = tail,
						$temp$_v0 = _Utils_Tuple3(last, partialScore + 1, score);
					simplifiedList = $temp$simplifiedList;
					_v0 = $temp$_v0;
					continue rule1Score_;
				} else {
					if (partialScore >= 5) {
						var $temp$simplifiedList = tail,
							$temp$_v0 = _Utils_Tuple3(head, 0, (score + partialScore) - 2);
						simplifiedList = $temp$simplifiedList;
						_v0 = $temp$_v0;
						continue rule1Score_;
					} else {
						var $temp$simplifiedList = tail,
							$temp$_v0 = _Utils_Tuple3(head, 0, score);
						simplifiedList = $temp$simplifiedList;
						_v0 = $temp$_v0;
						continue rule1Score_;
					}
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map(
		function (a) {
			return A2(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score_,
				a,
				_Utils_Tuple3(false, 0, 0));
		}),
	$elm$core$List$sum);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score_ = F4(
	function (row1, row2, maybeLast, score) {
		rule2Score_:
		while (true) {
			if (!row1.b) {
				return score;
			} else {
				var head = row1.a;
				var tail = row1.b;
				if (!row2.b) {
					return score;
				} else {
					var head2 = row2.a;
					var tail2 = row2.b;
					if (_Utils_eq(head, head2)) {
						if (_Utils_eq(
							$elm$core$Maybe$Just(head),
							maybeLast)) {
							var $temp$row1 = tail,
								$temp$row2 = tail2,
								$temp$maybeLast = $elm$core$Maybe$Just(head),
								$temp$score = score + 3;
							row1 = $temp$row1;
							row2 = $temp$row2;
							maybeLast = $temp$maybeLast;
							score = $temp$score;
							continue rule2Score_;
						} else {
							var $temp$row1 = tail,
								$temp$row2 = tail2,
								$temp$maybeLast = $elm$core$Maybe$Just(head),
								$temp$score = score;
							row1 = $temp$row1;
							row2 = $temp$row2;
							maybeLast = $temp$maybeLast;
							score = $temp$score;
							continue rule2Score_;
						}
					} else {
						var $temp$row1 = tail,
							$temp$row2 = tail2,
							$temp$maybeLast = $elm$core$Maybe$Nothing,
							$temp$score = score;
						row1 = $temp$row1;
						row2 = $temp$row2;
						maybeLast = $temp$maybeLast;
						score = $temp$score;
						continue rule2Score_;
					}
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score = F2(
	function (list, score) {
		rule2Score:
		while (true) {
			if (list.b && list.b.b) {
				var head1 = list.a;
				var _v1 = list.b;
				var head2 = _v1.a;
				var tail = _v1.b;
				var $temp$list = tail,
					$temp$score = score + A4($pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score_, head1, head2, $elm$core$Maybe$Nothing, 0);
				list = $temp$list;
				score = $temp$score;
				continue rule2Score;
			} else {
				return score;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score_ = F2(
	function (simplifiedList, score) {
		rule3Score_:
		while (true) {
			_v0$3:
			while (true) {
				if (!simplifiedList.b) {
					return score;
				} else {
					if (!simplifiedList.a) {
						if (((((((((((((((((((simplifiedList.b.b && (!simplifiedList.b.a)) && simplifiedList.b.b.b) && (!simplifiedList.b.b.a)) && simplifiedList.b.b.b.b) && (!simplifiedList.b.b.b.a)) && simplifiedList.b.b.b.b.b) && simplifiedList.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.b.b.b.b.a) {
							var _v1 = simplifiedList.b;
							var _v2 = _v1.b;
							var _v3 = _v2.b;
							var _v4 = _v3.b;
							var _v5 = _v4.b;
							var _v6 = _v5.b;
							var _v7 = _v6.b;
							var _v8 = _v7.b;
							var _v9 = _v8.b;
							var _v10 = _v9.b;
							var tail = _v10.b;
							var $temp$simplifiedList = tail,
								$temp$score = score + 40;
							simplifiedList = $temp$simplifiedList;
							score = $temp$score;
							continue rule3Score_;
						} else {
							break _v0$3;
						}
					} else {
						if (((((((((((((((((((simplifiedList.b.b && (!simplifiedList.b.a)) && simplifiedList.b.b.b) && simplifiedList.b.b.a) && simplifiedList.b.b.b.b) && simplifiedList.b.b.b.a) && simplifiedList.b.b.b.b.b) && simplifiedList.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b) && simplifiedList.b.b.b.b.b.b.a) && simplifiedList.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.b.a)) && simplifiedList.b.b.b.b.b.b.b.b.b.b.b) && (!simplifiedList.b.b.b.b.b.b.b.b.b.b.a)) {
							var _v11 = simplifiedList.b;
							var _v12 = _v11.b;
							var _v13 = _v12.b;
							var _v14 = _v13.b;
							var _v15 = _v14.b;
							var _v16 = _v15.b;
							var _v17 = _v16.b;
							var _v18 = _v17.b;
							var _v19 = _v18.b;
							var _v20 = _v19.b;
							var tail = _v20.b;
							var $temp$simplifiedList = tail,
								$temp$score = score + 40;
							simplifiedList = $temp$simplifiedList;
							score = $temp$score;
							continue rule3Score_;
						} else {
							break _v0$3;
						}
					}
				}
			}
			var head = simplifiedList.a;
			var tail = simplifiedList.b;
			var $temp$simplifiedList = tail,
				$temp$score = score;
			simplifiedList = $temp$simplifiedList;
			score = $temp$score;
			continue rule3Score_;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score = A2($elm$core$List$foldl, $pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score_, 0);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$rule4Score = F2(
	function (size, simplifiedList) {
		var moduleCount = size * size;
		var darkCount = $elm$core$List$length(
			A2($elm$core$List$filter, $elm$core$Basics$identity, simplifiedList));
		var darkPerc = $elm$core$Basics$round((100 * darkCount) / moduleCount);
		var remOf5 = darkPerc % 5;
		var nextMult5 = $elm$core$Basics$round(
			$elm$core$Basics$abs((darkPerc + (5 - remOf5)) - 50) / 5);
		var prevMult5 = $elm$core$Basics$round(
			$elm$core$Basics$abs((darkPerc - remOf5) - 50) / 5);
		return A2($elm$core$Basics$min, prevMult5, nextMult5) * 10;
	});
var $pablohirafuji$elm_qrcode$QRCode$Helpers$transpose = function (ll) {
	transpose:
	while (true) {
		if (!ll.b) {
			return _List_Nil;
		} else {
			if (!ll.a.b) {
				var xss = ll.b;
				var $temp$ll = xss;
				ll = $temp$ll;
				continue transpose;
			} else {
				var _v1 = ll.a;
				var x = _v1.a;
				var xs = _v1.b;
				var xss = ll.b;
				var tails = A2($elm$core$List$filterMap, $elm$core$List$tail, xss);
				var heads = A2($elm$core$List$filterMap, $elm$core$List$head, xss);
				return A2(
					$elm$core$List$cons,
					A2($elm$core$List$cons, x, heads),
					$pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(
						A2($elm$core$List$cons, xs, tails)));
			}
		}
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getMaskScore = F2(
	function (size, matrix) {
		var list = A2(
			$elm$core$List$map,
			$pablohirafuji$elm_qrcode$QRCode$Matrix$isDarkModule,
			$elm$core$Array$toList(matrix));
		var rowList = A3($pablohirafuji$elm_qrcode$QRCode$Matrix$breakList, size, list, _List_Nil);
		var transposedRowList = $pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(rowList);
		return function (b) {
			return _Utils_Tuple2(rowList, b);
		}(
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$rule4Score, size, list) + ($pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score(transposedRowList) + ($pablohirafuji$elm_qrcode$QRCode$Matrix$rule3Score(rowList) + (A2($pablohirafuji$elm_qrcode$QRCode$Matrix$rule2Score, rowList, 0) + ($pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score(transposedRowList) + $pablohirafuji$elm_qrcode$QRCode$Matrix$rule1Score(rowList))))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$ecLevelToInt = function (ecLevel) {
	switch (ecLevel) {
		case 0:
			return 1;
		case 1:
			return 0;
		case 2:
			return 3;
		default:
			return 2;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit = function (_int) {
	var helper = F2(
		function (digit, int_) {
			helper:
			while (true) {
				if (!(!int_)) {
					var $temp$digit = digit + 1,
						$temp$int_ = int_ >>> 1;
					digit = $temp$digit;
					int_ = $temp$int_;
					continue helper;
				} else {
					return digit;
				}
			}
		});
	return A2(helper, 0, _int);
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$maskToInt = function (mask) {
	switch (mask) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		case 4:
			return 4;
		case 5:
			return 5;
		case 6:
			return 6;
		default:
			return 7;
	}
};
var $elm$core$Bitwise$or = _Bitwise_or;
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$encodeFormatInfo = F2(
	function (ecLevel, mask) {
		var g15Mask = 21522;
		var g15Int = 1335;
		var g15Digit = $pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(g15Int);
		var formatInfoInt = $pablohirafuji$elm_qrcode$QRCode$Matrix$maskToInt(mask) | ($pablohirafuji$elm_qrcode$QRCode$Matrix$ecLevelToInt(ecLevel) << 3);
		var helper = function (d_) {
			helper:
			while (true) {
				if (($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g15Digit) >= 0) {
					var $temp$d_ = d_ ^ (g15Int << ($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g15Digit));
					d_ = $temp$d_;
					continue helper;
				} else {
					return g15Mask ^ (d_ | (formatInfoInt << 10));
				}
			}
		};
		var d = formatInfoInt << 10;
		return helper(d);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoHorizontal = F2(
	function (size, count) {
		return (count < 8) ? _Utils_Tuple2(8, (size - count) - 1) : ((count < 9) ? _Utils_Tuple2(8, 15 - count) : _Utils_Tuple2(8, (15 - count) - 1));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoVertical = F2(
	function (size, count) {
		return (count < 6) ? _Utils_Tuple2(count, 8) : ((count < 8) ? _Utils_Tuple2(count + 1, 8) : _Utils_Tuple2((size - 15) + count, 8));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatModule = F4(
	function (size, isBlack, row, col) {
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(true, isBlack)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo_ = F4(
	function (size, isBlackFn, count, matrix) {
		setFormatInfo_:
		while (true) {
			if (count < 15) {
				var isBlack = isBlackFn(count);
				var _v0 = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoVertical, size, count);
				var x2 = _v0.a;
				var y2 = _v0.b;
				var _v1 = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$formatInfoHorizontal, size, count);
				var x1 = _v1.a;
				var y1 = _v1.b;
				var $temp$size = size,
					$temp$isBlackFn = isBlackFn,
					$temp$count = count + 1,
					$temp$matrix = A5(
					$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatModule,
					size,
					isBlack,
					x2,
					y2,
					A5($pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatModule, size, isBlack, x1, y1, matrix));
				size = $temp$size;
				isBlackFn = $temp$isBlackFn;
				count = $temp$count;
				matrix = $temp$matrix;
				continue setFormatInfo_;
			} else {
				return matrix;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo = F4(
	function (ecLevel, size, mask, matrix) {
		var isBlack = F2(
			function (bits_, count) {
				return (1 & (bits_ >> count)) === 1;
			});
		var bits = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$encodeFormatInfo, ecLevel, mask);
		return A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo_,
			size,
			isBlack(bits),
			0,
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask_ = F5(
	function (ecLevel, size, matrix, mask, _v0) {
		var minSMatrix = _v0.a;
		var minScore = _v0.b;
		var maskedMatrix = A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo,
			ecLevel,
			size,
			mask,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$applyMask, size, mask, matrix));
		var _v1 = A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getMaskScore, size, maskedMatrix);
		var maskSMatrix = _v1.a;
		var maskScore = _v1.b;
		return ((_Utils_cmp(minScore, maskScore) < 0) && (!_Utils_eq(minScore, -1))) ? _Utils_Tuple2(minSMatrix, minScore) : _Utils_Tuple2(maskSMatrix, maskScore);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern0 = 0;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern1 = 1;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern2 = 2;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern3 = 3;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern4 = 4;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern5 = 5;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern6 = 6;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$Pattern7 = 7;
var $pablohirafuji$elm_qrcode$QRCode$Matrix$patternList = _List_fromArray(
	[0, 1, 2, 3, 4, 5, 6, 7]);
var $pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask = F3(
	function (ecLevel, size, matrix) {
		return A3(
			$elm$core$List$foldl,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask_, ecLevel, size, matrix),
			_Utils_Tuple2(_List_Nil, -1),
			$pablohirafuji$elm_qrcode$QRCode$Matrix$patternList).a;
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$reserveFormatInfo = F2(
	function (size, matrix) {
		return A4(
			$pablohirafuji$elm_qrcode$QRCode$Matrix$setFormatInfo_,
			size,
			$elm$core$Basics$always(true),
			0,
			matrix);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$encodeVersionInfo = function (version) {
	var g18Int = 7973;
	var g18Digit = $pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(g18Int);
	var helper = function (d_) {
		helper:
		while (true) {
			if (($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g18Digit) >= 0) {
				var $temp$d_ = d_ ^ (g18Int << ($pablohirafuji$elm_qrcode$QRCode$Matrix$getBCHDigit(d_) - g18Digit));
				d_ = $temp$d_;
				continue helper;
			} else {
				return d_ | (version << 12);
			}
		}
	};
	var d = version << 12;
	return helper(d);
};
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionModule = F3(
	function (size, isBlack, _v0) {
		var row = _v0.a;
		var col = _v0.b;
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			$elm$core$Maybe$Just(
				_Utils_Tuple2(true, isBlack)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo_ = F4(
	function (size, isBlackFn, count, matrix) {
		setVersionInfo_:
		while (true) {
			if (count < 18) {
				var topRight = _Utils_Tuple2(
					$elm$core$Basics$floor(count / 3),
					((A2($elm$core$Basics$modBy, 3, count) + size) - 8) - 3);
				var isBlack = isBlackFn(count);
				var bottomLeft = _Utils_Tuple2(
					((A2($elm$core$Basics$modBy, 3, count) + size) - 8) - 3,
					$elm$core$Basics$floor(count / 3));
				var $temp$size = size,
					$temp$isBlackFn = isBlackFn,
					$temp$count = count + 1,
					$temp$matrix = A4(
					$pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionModule,
					size,
					isBlack,
					bottomLeft,
					A4($pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionModule, size, isBlack, topRight, matrix));
				size = $temp$size;
				isBlackFn = $temp$isBlackFn;
				count = $temp$count;
				matrix = $temp$matrix;
				continue setVersionInfo_;
			} else {
				return matrix;
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo = F3(
	function (version, size, matrix) {
		if (version >= 7) {
			var isBlack = F2(
				function (bits_, count) {
					return (1 & (bits_ >> count)) === 1;
				});
			var bits = $pablohirafuji$elm_qrcode$QRCode$Matrix$encodeVersionInfo(version);
			return A4(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo_,
				size,
				isBlack(bits),
				0,
				matrix);
		} else {
			return matrix;
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$timingColor = F2(
	function (row, col) {
		return (!A2($elm$core$Basics$modBy, 2, row + col)) ? $elm$core$Maybe$Just(
			_Utils_Tuple2(true, true)) : $elm$core$Maybe$Just(
			_Utils_Tuple2(true, false));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$setTiming = F3(
	function (size, row, col) {
		return A2(
			$elm$core$Array$set,
			A3($pablohirafuji$elm_qrcode$QRCode$Matrix$getIndex, size, row, col),
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$timingColor, row, col));
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$timingPattern = F2(
	function (size, matrix) {
		var range = A2($elm$core$List$range, 8, size - 9);
		return A3(
			$elm$core$List$foldl,
			function (b) {
				return A3($pablohirafuji$elm_qrcode$QRCode$Matrix$setTiming, size, b, 6);
			},
			A3(
				$elm$core$List$foldl,
				A2($pablohirafuji$elm_qrcode$QRCode$Matrix$setTiming, size, 6),
				matrix,
				range),
			range);
	});
var $pablohirafuji$elm_qrcode$QRCode$Matrix$apply = function (_v0) {
	var ecLevel = _v0.a.iW;
	var groupInfo = _v0.a.mX;
	var bytes = _v0.b;
	var version = groupInfo.h2;
	var size = ((version - 1) * 4) + 21;
	return A2(
		$elm$core$Result$map,
		A2($pablohirafuji$elm_qrcode$QRCode$Matrix$getBestMask, ecLevel, size),
		A2(
			$elm$core$Result$map,
			A2($pablohirafuji$elm_qrcode$QRCode$Matrix$addData, size, bytes),
			A3(
				$pablohirafuji$elm_qrcode$QRCode$Matrix$alignmentPattern,
				version,
				size,
				A2(
					$pablohirafuji$elm_qrcode$QRCode$Matrix$timingPattern,
					size,
					A3(
						$pablohirafuji$elm_qrcode$QRCode$Matrix$darkModule,
						version,
						size,
						A3(
							$pablohirafuji$elm_qrcode$QRCode$Matrix$setVersionInfo,
							version,
							size,
							A2(
								$pablohirafuji$elm_qrcode$QRCode$Matrix$reserveFormatInfo,
								size,
								A4(
									$pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern,
									size,
									-1,
									size - 8,
									A4(
										$pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern,
										size,
										size - 8,
										-1,
										A4(
											$pablohirafuji$elm_qrcode$QRCode$Matrix$finderPattern,
											size,
											-1,
											-1,
											A2(
												$elm$core$Array$initialize,
												size * size,
												$elm$core$Basics$always($elm$core$Maybe$Nothing))))))))))));
};
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$H = 3;
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$L = 0;
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$M = 1;
var $pablohirafuji$elm_qrcode$QRCode$ECLevel$Q = 2;
var $pablohirafuji$elm_qrcode$QRCode$convertEC = function (ec) {
	switch (ec) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$AlignmentPatternNotFound = {$: 0};
var $pablohirafuji$elm_qrcode$QRCode$InputLengthOverflow = {$: 7};
var $pablohirafuji$elm_qrcode$QRCode$InvalidAlphanumericChar = {$: 2};
var $pablohirafuji$elm_qrcode$QRCode$InvalidNumericChar = {$: 1};
var $pablohirafuji$elm_qrcode$QRCode$InvalidUTF8Char = {$: 3};
var $pablohirafuji$elm_qrcode$QRCode$LogTableException = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_qrcode$QRCode$PolynomialModException = {$: 6};
var $pablohirafuji$elm_qrcode$QRCode$PolynomialMultiplyException = {$: 5};
var $pablohirafuji$elm_qrcode$QRCode$convertError = function (e) {
	switch (e.$) {
		case 0:
			return $pablohirafuji$elm_qrcode$QRCode$AlignmentPatternNotFound;
		case 1:
			return $pablohirafuji$elm_qrcode$QRCode$InvalidNumericChar;
		case 2:
			return $pablohirafuji$elm_qrcode$QRCode$InvalidAlphanumericChar;
		case 3:
			return $pablohirafuji$elm_qrcode$QRCode$InvalidUTF8Char;
		case 4:
			var n = e.a;
			return $pablohirafuji$elm_qrcode$QRCode$LogTableException(n);
		case 5:
			return $pablohirafuji$elm_qrcode$QRCode$PolynomialMultiplyException;
		case 6:
			return $pablohirafuji$elm_qrcode$QRCode$PolynomialModException;
		default:
			return $pablohirafuji$elm_qrcode$QRCode$InputLengthOverflow;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$firstFillerByte = 236;
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$secondFillerByte = 17;
var $pablohirafuji$elm_qrcode$QRCode$Encode$addFiller = F2(
	function (capacity, bytes) {
		var fillerLength = ((capacity / 8) | 0) - $elm$core$List$length(bytes);
		var ns = $elm$core$List$concat(
			A2(
				$elm$core$List$repeat,
				(fillerLength / 2) | 0,
				_List_fromArray(
					[$pablohirafuji$elm_qrcode$QRCode$Encode$firstFillerByte, $pablohirafuji$elm_qrcode$QRCode$Encode$secondFillerByte])));
		return (!A2($elm$core$Basics$modBy, 2, fillerLength)) ? _Utils_ap(bytes, ns) : _Utils_ap(
			bytes,
			_Utils_ap(
				ns,
				_List_fromArray(
					[$pablohirafuji$elm_qrcode$QRCode$Encode$firstFillerByte])));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$addTerminator = F3(
	function (capacity, bitsCount, bits) {
		return _Utils_ap(
			bits,
			_List_fromArray(
				[
					_Utils_Tuple2(
					0,
					A2($elm$core$Basics$min, 4, capacity - bitsCount))
				]));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes3 = function (_v0) {
	bitsToBytes3:
	while (true) {
		var _v1 = _v0.a;
		var bits = _v1.a;
		var length = _v1.b;
		var bytes = _v0.b;
		if (length >= 8) {
			var remLength = length - 8;
			var remBits = bits & ((1 << remLength) - 1);
			var _byte = bits >> remLength;
			var $temp$_v0 = _Utils_Tuple2(
				_Utils_Tuple2(remBits, remLength),
				A2($elm$core$List$cons, _byte, bytes));
			_v0 = $temp$_v0;
			continue bitsToBytes3;
		} else {
			return _Utils_Tuple2(
				_Utils_Tuple2(bits, length),
				bytes);
		}
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes2 = F2(
	function (_v0, _v1) {
		var curBits = _v0.a;
		var curLength = _v0.b;
		var _v2 = _v1.a;
		var remBits = _v2.a;
		var remLength = _v2.b;
		var bytes = _v1.b;
		var lengthSum = curLength + remLength;
		var bitsSum = curBits | (remBits << curLength);
		return $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes3(
			_Utils_Tuple2(
				_Utils_Tuple2(bitsSum, lengthSum),
				bytes));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes1 = F2(
	function (bits, _v0) {
		bitsToBytes1:
		while (true) {
			var _v1 = _v0.a;
			var remBits = _v1.a;
			var remLength = _v1.b;
			var bytes = _v0.b;
			if (bits.b) {
				var head = bits.a;
				var tail = bits.b;
				var $temp$bits = tail,
					$temp$_v0 = A2(
					$pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes2,
					head,
					_Utils_Tuple2(
						_Utils_Tuple2(remBits, remLength),
						bytes));
				bits = $temp$bits;
				_v0 = $temp$_v0;
				continue bitsToBytes1;
			} else {
				return (!remLength) ? $elm$core$List$reverse(bytes) : $elm$core$List$reverse(
					A2($elm$core$List$cons, remBits << (8 - remLength), bytes));
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes = function (bits) {
	return A2(
		$pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes1,
		bits,
		_Utils_Tuple2(
			_Utils_Tuple2(0, 0),
			_List_Nil));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8 = 3;
var $pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength = F2(
	function (mode, version) {
		if (version <= 9) {
			switch (mode) {
				case 0:
					return 10;
				case 1:
					return 9;
				case 2:
					return 8;
				default:
					return 8;
			}
		} else {
			if (version <= 26) {
				switch (mode) {
					case 0:
						return 12;
					case 1:
						return 11;
					case 2:
						return 16;
					default:
						return 16;
				}
			} else {
				switch (mode) {
					case 0:
						return 14;
					case 1:
						return 13;
					case 2:
						return 16;
					default:
						return 16;
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicator = F2(
	function (_v0, bits) {
		var groupInfo = _v0.mX;
		var inputStr = _v0.gN;
		var mode = _v0.eY;
		var length = A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength, mode, groupInfo.h2);
		var charCount = (mode === 3) ? $elm$core$List$length(bits) : $elm$core$String$length(inputStr);
		return _Utils_Tuple2(charCount, length);
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$modeIndicator = function (mode) {
	switch (mode) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		default:
			return 4;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$addInfoAndFinalBits = function (_v0) {
	var bits = _v0.a;
	var model = _v0.b;
	return _Utils_Tuple2(
		model,
		A2(
			$pablohirafuji$elm_qrcode$QRCode$Encode$addFiller,
			model.mX.em,
			$pablohirafuji$elm_qrcode$QRCode$Encode$bitsToBytes(
				A3(
					$pablohirafuji$elm_qrcode$QRCode$Encode$addTerminator,
					model.mX.em,
					model.gd,
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							$pablohirafuji$elm_qrcode$QRCode$Encode$modeIndicator(model.eY),
							4),
						A2(
							$elm$core$List$cons,
							A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicator, model, bits),
							bits))))));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$concatTranspose = function (_v0) {
	var model = _v0.a;
	var dataBlocks = _v0.b;
	var ecBlocks = _v0.c;
	return _Utils_Tuple2(
		model,
		$elm$core$List$concat(
			_Utils_ap(
				$pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(dataBlocks),
				$pablohirafuji$elm_qrcode$QRCode$Helpers$transpose(ecBlocks))));
};
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Error$InvalidAlphanumericChar = {$: 2};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$alphanumericCodes = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('0', 0),
			_Utils_Tuple2('1', 1),
			_Utils_Tuple2('2', 2),
			_Utils_Tuple2('3', 3),
			_Utils_Tuple2('4', 4),
			_Utils_Tuple2('5', 5),
			_Utils_Tuple2('6', 6),
			_Utils_Tuple2('7', 7),
			_Utils_Tuple2('8', 8),
			_Utils_Tuple2('9', 9),
			_Utils_Tuple2('A', 10),
			_Utils_Tuple2('B', 11),
			_Utils_Tuple2('C', 12),
			_Utils_Tuple2('D', 13),
			_Utils_Tuple2('E', 14),
			_Utils_Tuple2('F', 15),
			_Utils_Tuple2('G', 16),
			_Utils_Tuple2('H', 17),
			_Utils_Tuple2('I', 18),
			_Utils_Tuple2('J', 19),
			_Utils_Tuple2('K', 20),
			_Utils_Tuple2('L', 21),
			_Utils_Tuple2('M', 22),
			_Utils_Tuple2('N', 23),
			_Utils_Tuple2('O', 24),
			_Utils_Tuple2('P', 25),
			_Utils_Tuple2('Q', 26),
			_Utils_Tuple2('R', 27),
			_Utils_Tuple2('S', 28),
			_Utils_Tuple2('T', 29),
			_Utils_Tuple2('U', 30),
			_Utils_Tuple2('V', 31),
			_Utils_Tuple2('W', 32),
			_Utils_Tuple2('X', 33),
			_Utils_Tuple2('Y', 34),
			_Utils_Tuple2('Z', 35),
			_Utils_Tuple2(' ', 36),
			_Utils_Tuple2('$', 37),
			_Utils_Tuple2('%', 38),
			_Utils_Tuple2('*', 39),
			_Utils_Tuple2('+', 40),
			_Utils_Tuple2('-', 41),
			_Utils_Tuple2('.', 42),
			_Utils_Tuple2('/', 43),
			_Utils_Tuple2(':', 44)
		]));
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode = function (_char) {
	return A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$InvalidAlphanumericChar,
		A2($elm$core$Dict$get, _char, $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$alphanumericCodes));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toBinary = function (chars) {
	_v0$2:
	while (true) {
		if (chars.b) {
			if (chars.b.b) {
				if (!chars.b.b.b) {
					var firstChar = chars.a;
					var _v1 = chars.b;
					var secondChar = _v1.a;
					return A3(
						$elm$core$Result$map2,
						F2(
							function (firstCode, secondCode) {
								return _Utils_Tuple2((firstCode * 45) + secondCode, 11);
							}),
						$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode(firstChar),
						$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode(secondChar));
				} else {
					break _v0$2;
				}
			} else {
				var _char = chars.a;
				return A2(
					$elm$core$Result$map,
					function (a) {
						return _Utils_Tuple2(a, 6);
					},
					$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toAlphanumericCode(_char));
			}
		} else {
			break _v0$2;
		}
	}
	return $elm$core$Result$Err($pablohirafuji$elm_qrcode$QRCode$Error$InvalidAlphanumericChar);
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$encode = function (str) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Result$map2($elm$core$List$cons),
		$elm$core$Result$Ok(_List_Nil),
		A2(
			$elm$core$List$map,
			$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$toBinary,
			A2(
				$elm_community$list_extra$List$Extra$greedyGroupsOf,
				2,
				$elm$core$String$toList(str))));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$encode = function (str) {
	return $elm$core$Result$Ok(
		A2(
			$elm$core$List$map,
			function (a) {
				return _Utils_Tuple2(
					$elm$core$Char$toCode(a),
					8);
			},
			$elm$core$String$toList(str)));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$InvalidNumericChar = {$: 1};
var $elm$core$String$fromList = _String_fromList;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$numericLength = function (str) {
	var _v0 = $elm$core$String$length(str);
	switch (_v0) {
		case 1:
			return 4;
		case 2:
			return 7;
		default:
			return 10;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encodeHelp = function (chars) {
	var str = $elm$core$String$fromList(chars);
	return A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$InvalidNumericChar,
		A2(
			$elm$core$Maybe$map,
			function (a) {
				return _Utils_Tuple2(
					a,
					$pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$numericLength(str));
			},
			$elm$core$String$toInt(str)));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encode = function (str) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Result$map2($elm$core$List$cons),
		$elm$core$Result$Ok(_List_Nil),
		A2(
			$elm$core$List$map,
			$pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encodeHelp,
			A2(
				$elm_community$list_extra$List$Extra$greedyGroupsOf,
				3,
				$elm$core$String$toList(str))));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$InvalidUTF8Char = {$: 3};
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $elm$bytes$Bytes$Encode$getStringWidth = _Bytes_getStringWidth;
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $elm$bytes$Bytes$Decode$unsignedInt8 = _Bytes_read_u8;
var $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$step = function (_v0) {
	var n = _v0.a;
	var xs = _v0.b;
	return (n <= 0) ? $elm$bytes$Bytes$Decode$succeed(
		$elm$bytes$Bytes$Decode$Done(
			$elm$core$List$reverse(xs))) : A2(
		$elm$bytes$Bytes$Decode$map,
		function (x) {
			return $elm$bytes$Bytes$Decode$Loop(
				_Utils_Tuple2(
					n - 1,
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(x, 8),
						xs)));
		},
		$elm$bytes$Bytes$Decode$unsignedInt8);
};
var $elm$bytes$Bytes$Encode$Utf8 = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$string = function (str) {
	return A2(
		$elm$bytes$Bytes$Encode$Utf8,
		_Bytes_getStringWidth(str),
		str);
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$encode = function (str) {
	var utf8BytesWidth = $elm$bytes$Bytes$Encode$getStringWidth(str);
	var decoder = A2(
		$elm$bytes$Bytes$Decode$loop,
		_Utils_Tuple2(utf8BytesWidth, _List_Nil),
		$pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$step);
	return A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$InvalidUTF8Char,
		A2(
			$elm$bytes$Bytes$Decode$decode,
			decoder,
			$elm$bytes$Bytes$Encode$encode(
				$elm$bytes$Bytes$Encode$string(str))));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$encoder = function (mode) {
	switch (mode) {
		case 0:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$encode;
		case 1:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$encode;
		case 2:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$encode;
		default:
			return $pablohirafuji$elm_qrcode$QRCode$Encode$UTF8$encode;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$expTable = $elm$core$Array$fromList(
	_List_fromArray(
		[1, 2, 4, 8, 16, 32, 64, 128, 29, 58, 116, 232, 205, 135, 19, 38, 76, 152, 45, 90, 180, 117, 234, 201, 143, 3, 6, 12, 24, 48, 96, 192, 157, 39, 78, 156, 37, 74, 148, 53, 106, 212, 181, 119, 238, 193, 159, 35, 70, 140, 5, 10, 20, 40, 80, 160, 93, 186, 105, 210, 185, 111, 222, 161, 95, 190, 97, 194, 153, 47, 94, 188, 101, 202, 137, 15, 30, 60, 120, 240, 253, 231, 211, 187, 107, 214, 177, 127, 254, 225, 223, 163, 91, 182, 113, 226, 217, 175, 67, 134, 17, 34, 68, 136, 13, 26, 52, 104, 208, 189, 103, 206, 129, 31, 62, 124, 248, 237, 199, 147, 59, 118, 236, 197, 151, 51, 102, 204, 133, 23, 46, 92, 184, 109, 218, 169, 79, 158, 33, 66, 132, 21, 42, 84, 168, 77, 154, 41, 82, 164, 85, 170, 73, 146, 57, 114, 228, 213, 183, 115, 230, 209, 191, 99, 198, 145, 63, 126, 252, 229, 215, 179, 123, 246, 241, 255, 227, 219, 171, 75, 150, 49, 98, 196, 149, 55, 110, 220, 165, 87, 174, 65, 130, 25, 50, 100, 200, 141, 7, 14, 28, 56, 112, 224, 221, 167, 83, 166, 81, 162, 89, 178, 121, 242, 249, 239, 195, 155, 43, 86, 172, 69, 138, 9, 18, 36, 72, 144, 61, 122, 244, 245, 247, 243, 251, 235, 203, 139, 11, 22, 44, 88, 176, 125, 250, 233, 207, 131, 27, 54, 108, 216, 173, 71, 142, 1]));
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp = function (index) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Array$get,
			A2($elm$core$Basics$modBy, 255, index),
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$expTable));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$PolynomialMultiplyException = {$: 5};
var $pablohirafuji$elm_qrcode$QRCode$Error$LogTableException = function (a) {
	return {$: 4, a: a};
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$logTable = $elm$core$Array$fromList(
	_List_fromArray(
		[0, 1, 25, 2, 50, 26, 198, 3, 223, 51, 238, 27, 104, 199, 75, 4, 100, 224, 14, 52, 141, 239, 129, 28, 193, 105, 248, 200, 8, 76, 113, 5, 138, 101, 47, 225, 36, 15, 33, 53, 147, 142, 218, 240, 18, 130, 69, 29, 181, 194, 125, 106, 39, 249, 185, 201, 154, 9, 120, 77, 228, 114, 166, 6, 191, 139, 98, 102, 221, 48, 253, 226, 152, 37, 179, 16, 145, 34, 136, 54, 208, 148, 206, 143, 150, 219, 189, 241, 210, 19, 92, 131, 56, 70, 64, 30, 66, 182, 163, 195, 72, 126, 110, 107, 58, 40, 84, 250, 133, 186, 61, 202, 94, 155, 159, 10, 21, 121, 43, 78, 212, 229, 172, 115, 243, 167, 87, 7, 112, 192, 247, 140, 128, 99, 13, 103, 74, 222, 237, 49, 197, 254, 24, 227, 165, 153, 119, 38, 184, 180, 124, 17, 68, 146, 217, 35, 32, 137, 46, 55, 63, 209, 91, 149, 188, 207, 205, 144, 135, 151, 178, 220, 252, 190, 97, 242, 86, 211, 171, 20, 42, 93, 158, 132, 60, 57, 83, 71, 109, 65, 162, 31, 45, 67, 216, 183, 123, 164, 118, 196, 23, 73, 236, 127, 12, 111, 246, 108, 161, 59, 82, 41, 157, 85, 170, 251, 96, 134, 177, 187, 204, 62, 90, 203, 89, 95, 176, 156, 169, 160, 81, 11, 245, 22, 235, 122, 117, 44, 215, 79, 174, 213, 233, 230, 231, 173, 232, 116, 214, 244, 234, 168, 80, 88, 175]));
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog = function (index) {
	return (index < 1) ? $elm$core$Result$Err(
		$pablohirafuji$elm_qrcode$QRCode$Error$LogTableException(index)) : A2(
		$elm$core$Result$fromMaybe,
		$pablohirafuji$elm_qrcode$QRCode$Error$LogTableException(index),
		A2($elm$core$Array$get, index - 1, $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$logTable));
};
var $elm$core$Array$length = function (_v0) {
	var len = _v0.a;
	return len;
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getOffset = function (_v0) {
	getOffset:
	while (true) {
		var num = _v0.a;
		var offset = _v0.b;
		if (num.b) {
			var head = num.a;
			var tail = num.b;
			if (!head) {
				var $temp$_v0 = _Utils_Tuple2(tail, offset + 1);
				_v0 = $temp$_v0;
				continue getOffset;
			} else {
				return offset;
			}
		} else {
			return offset;
		}
	}
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial = F2(
	function (num, shift) {
		var offset = $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getOffset(
			_Utils_Tuple2(num, 0));
		var numArray = $elm$core$Array$fromList(num);
		return A2(
			$elm$core$Array$initialize,
			($elm$core$List$length(num) - offset) + shift,
			function (index) {
				return A2(
					$elm$core$Maybe$withDefault,
					0,
					A2($elm$core$Array$get, index + offset, numArray));
			});
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$multiply = F2(
	function (poly1, poly2) {
		var valuesArray = A2(
			$elm$core$List$indexedMap,
			F2(
				function (index1, value1) {
					return A2(
						$elm$core$List$indexedMap,
						F2(
							function (index2, value2) {
								return _Utils_Tuple3(index1 + index2, value1, value2);
							}),
						$elm$core$Array$toList(poly2));
				}),
			$elm$core$Array$toList(poly1));
		var process__ = F3(
			function (indexSum, num_, exp) {
				return A2(
					$elm$core$Result$fromMaybe,
					$pablohirafuji$elm_qrcode$QRCode$Error$PolynomialMultiplyException,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Bitwise$xor(exp),
						A2($elm$core$Array$get, indexSum, num_)));
			});
		var process_ = F2(
			function (_v0, num_) {
				var indexSum = _v0.a;
				var value1 = _v0.b;
				var value2 = _v0.c;
				return A2(
					$elm$core$Result$map,
					function (r) {
						return A3($elm$core$Array$set, indexSum, r, num_);
					},
					A2(
						$elm$core$Result$andThen,
						A2(process__, indexSum, num_),
						A2(
							$elm$core$Result$map,
							$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp,
							A3(
								$elm$core$Result$map2,
								$elm$core$Basics$add,
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog(value1),
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog(value2)))));
			});
		var process = F2(
			function (args, numResult) {
				return A2(
					$elm$core$Result$andThen,
					process_(args),
					numResult);
			});
		var num = A2(
			$elm$core$Array$initialize,
			($elm$core$Array$length(poly1) + $elm$core$Array$length(poly2)) - 1,
			$elm$core$Basics$always(0));
		return A2(
			$elm$core$Result$map,
			function (a) {
				return A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial, a, 0);
			},
			A2(
				$elm$core$Result$map,
				$elm$core$Array$toList,
				A3(
					$elm$core$List$foldl,
					process,
					$elm$core$Result$Ok(num),
					$elm$core$List$concat(valuesArray))));
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getECPolynomial = function (ecLength) {
	var generate = F2(
		function (count, polyResult) {
			generate:
			while (true) {
				if (_Utils_cmp(count, ecLength) < 0) {
					var $temp$count = count + 1,
						$temp$polyResult = A2(
						$elm$core$Result$andThen,
						function (a) {
							return A2(
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$multiply,
								a,
								A2(
									$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial,
									_List_fromArray(
										[
											1,
											$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp(count)
										]),
									0));
						},
						polyResult);
					count = $temp$count;
					polyResult = $temp$polyResult;
					continue generate;
				} else {
					return polyResult;
				}
			}
		});
	return A2(
		generate,
		0,
		$elm$core$Result$Ok(
			A2(
				$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial,
				_List_fromArray(
					[1]),
				0)));
};
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get___ = F2(
	function (ecLength, modPoly) {
		return $elm$core$Array$toList(
			A2(
				$elm$core$Array$initialize,
				ecLength,
				function (index) {
					var modIndex = (index + $elm$core$Array$length(modPoly)) - ecLength;
					return (modIndex >= 0) ? A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($elm$core$Array$get, modIndex, modPoly)) : 0;
				}));
	});
var $pablohirafuji$elm_qrcode$QRCode$Error$PolynomialModException = {$: 6};
var $elm$core$Array$foldl = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldl, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldl, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldl,
			func,
			A3($elm$core$Elm$JsArray$foldl, helper, baseCase, tree),
			tail);
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$mod = F2(
	function (poly1, poly2) {
		if (($elm$core$Array$length(poly1) - $elm$core$Array$length(poly2)) < 0) {
			return $elm$core$Result$Ok(poly1);
		} else {
			var helper_ = F3(
				function (index2, poly1_, exp) {
					return A2(
						$elm$core$Result$fromMaybe,
						$pablohirafuji$elm_qrcode$QRCode$Error$PolynomialModException,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Bitwise$xor(exp),
							A2($elm$core$Array$get, index2, poly1_)));
				});
			var getHead = function (poly) {
				return A2(
					$elm$core$Result$andThen,
					$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog,
					A2(
						$elm$core$Result$fromMaybe,
						$pablohirafuji$elm_qrcode$QRCode$Error$PolynomialModException,
						A2($elm$core$Array$get, 0, poly)));
			};
			var ratio = A3(
				$elm$core$Result$map2,
				$elm$core$Basics$sub,
				getHead(poly1),
				getHead(poly2));
			var helper = F2(
				function (_v0, poly1_) {
					var index2 = _v0.a;
					var value2 = _v0.b;
					return A2(
						$elm$core$Result$map,
						function (r) {
							return A3($elm$core$Array$set, index2, r, poly1_);
						},
						A2(
							$elm$core$Result$andThen,
							A2(helper_, index2, poly1_),
							A2(
								$elm$core$Result$map,
								$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getExp,
								A3(
									$elm$core$Result$map2,
									$elm$core$Basics$add,
									ratio,
									$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getLog(value2)))));
				});
			var numFold = F2(
				function (args, poly1Result) {
					return A2(
						$elm$core$Result$andThen,
						helper(args),
						poly1Result);
				});
			var numResult = A3(
				$elm$core$Array$foldl,
				numFold,
				$elm$core$Result$Ok(poly1),
				A2(
					$elm$core$Array$indexedMap,
					F2(
						function (a, b) {
							return _Utils_Tuple2(a, b);
						}),
					poly2));
			return A2(
				$elm$core$Result$andThen,
				function (a) {
					return A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$mod, a, poly2);
				},
				A2(
					$elm$core$Result$map,
					function (a) {
						return A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial, a, 0);
					},
					A2($elm$core$Result$map, $elm$core$Array$toList, numResult)));
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get__ = F2(
	function (rsPoly, dataCodewords) {
		return A2(
			$elm$core$Result$map,
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get___(
				$elm$core$Array$length(rsPoly) - 1),
			A2(
				$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$mod,
				A2(
					$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$newPolynomial,
					dataCodewords,
					$elm$core$Array$length(rsPoly) - 1),
				rsPoly));
	});
var $pablohirafuji$elm_qrcode$QRCode$Helpers$listResult = F3(
	function (fun, listb, lista) {
		if (lista.b) {
			var head = lista.a;
			var tail = lista.b;
			return A2(
				$elm$core$Result$andThen,
				function (a) {
					return A3($pablohirafuji$elm_qrcode$QRCode$Helpers$listResult, fun, a, tail);
				},
				A2(
					$elm$core$Result$map,
					function (r) {
						return A2($elm$core$List$cons, r, listb);
					},
					fun(head)));
		} else {
			return $elm$core$Result$Ok(
				$elm$core$List$reverse(listb));
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get_ = F2(
	function (byteBlocks, rsPoly) {
		return A3(
			$pablohirafuji$elm_qrcode$QRCode$Helpers$listResult,
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get__(rsPoly),
			_List_Nil,
			byteBlocks);
	});
var $pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get = F2(
	function (ecPerBlock, byteBlocks) {
		return A2(
			$elm$core$Result$andThen,
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get_(byteBlocks),
			$pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$getECPolynomial(ecPerBlock));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$getErrorCorrection = function (_v0) {
	var model = _v0.a;
	var dataBlocks = _v0.b;
	return A2(
		$elm$core$Result$map,
		function (c) {
			return _Utils_Tuple3(model, dataBlocks, c);
		},
		A2($pablohirafuji$elm_qrcode$QRCode$ErrorCorrection$get, model.mX.mF, dataBlocks));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric = 1;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte = 2;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric = 0;
var $elm$regex$Regex$contains = _Regex_contains;
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$onlyAlphanumeric = A2(
	$elm$regex$Regex$fromStringWith,
	{mh: false, np: false},
	'^[0-9A-Z $%*+\\-.\\/:]+$');
var $pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A2($elm$regex$Regex$contains, r, input);
			},
			$pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$onlyAlphanumeric));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$only8Bit = A2(
	$elm$regex$Regex$fromStringWith,
	{mh: false, np: false},
	'^[\\u0000-\\u00ff]+$');
var $pablohirafuji$elm_qrcode$QRCode$Encode$Byte$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A2($elm$regex$Regex$contains, r, input);
			},
			$pablohirafuji$elm_qrcode$QRCode$Encode$Byte$only8Bit));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$onlyNumber = A2(
	$elm$regex$Regex$fromStringWith,
	{mh: false, np: false},
	'^[0-9]+$');
var $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$isValid = function (input) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (r) {
				return A2($elm$regex$Regex$contains, r, input);
			},
			$pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$onlyNumber));
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$selectMode = function (input) {
	return $pablohirafuji$elm_qrcode$QRCode$Encode$Numeric$isValid(input) ? 0 : ($pablohirafuji$elm_qrcode$QRCode$Encode$Alphanumeric$isValid(input) ? 1 : ($pablohirafuji$elm_qrcode$QRCode$Encode$Byte$isValid(input) ? 2 : 3));
};
var $pablohirafuji$elm_qrcode$QRCode$Error$InputLengthOverflow = {$: 7};
var $pablohirafuji$elm_qrcode$QRCode$Encode$filterCapacity = F3(
	function (mode, dataLength, _v0) {
		var version = _v0.h2;
		var capacity = _v0.em;
		return _Utils_cmp(
			A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength, mode, version) + dataLength,
			capacity) < 1;
	});
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity = function (_v0) {
	var blockCount = _v0.a;
	var bytePerBlock = _v0.b;
	return blockCount * bytePerBlock;
};
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$byteCapacity = F2(
	function (group1, maybeGroup2) {
		if (!maybeGroup2.$) {
			var block2 = maybeGroup2.a;
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity(group1) + $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity(block2);
		} else {
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$blockByteCapacity(group1);
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo = F4(
	function (version, ecPerBlock, group1, maybeGroup2) {
		return {
			em: A2($pablohirafuji$elm_qrcode$QRCode$GroupInfo$byteCapacity, group1, maybeGroup2) * 8,
			mF: ecPerBlock,
			jw: group1,
			nh: maybeGroup2,
			h2: version
		};
	});
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataH = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		17,
		_Utils_Tuple2(1, 9),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		28,
		_Utils_Tuple2(1, 16),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		22,
		_Utils_Tuple2(2, 13),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		16,
		_Utils_Tuple2(4, 9),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		22,
		_Utils_Tuple2(2, 11),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 12))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		28,
		_Utils_Tuple2(4, 15),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		26,
		_Utils_Tuple2(4, 13),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 14))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		26,
		_Utils_Tuple2(4, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		24,
		_Utils_Tuple2(4, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		28,
		_Utils_Tuple2(6, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		24,
		_Utils_Tuple2(3, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(8, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		28,
		_Utils_Tuple2(7, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		22,
		_Utils_Tuple2(12, 11),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 12))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		24,
		_Utils_Tuple2(11, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		24,
		_Utils_Tuple2(11, 12),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 13))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		30,
		_Utils_Tuple2(3, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(2, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(17, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		28,
		_Utils_Tuple2(2, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(19, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		26,
		_Utils_Tuple2(9, 13),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(16, 14))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		28,
		_Utils_Tuple2(15, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		30,
		_Utils_Tuple2(19, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		24,
		_Utils_Tuple2(34, 13),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		30,
		_Utils_Tuple2(16, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		30,
		_Utils_Tuple2(30, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		30,
		_Utils_Tuple2(22, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		30,
		_Utils_Tuple2(33, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		30,
		_Utils_Tuple2(12, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(28, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		30,
		_Utils_Tuple2(11, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(31, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		30,
		_Utils_Tuple2(19, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(26, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		30,
		_Utils_Tuple2(23, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(25, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		30,
		_Utils_Tuple2(23, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(28, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		30,
		_Utils_Tuple2(19, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(35, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		30,
		_Utils_Tuple2(11, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(46, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		30,
		_Utils_Tuple2(59, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		30,
		_Utils_Tuple2(22, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(41, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		30,
		_Utils_Tuple2(2, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(64, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		30,
		_Utils_Tuple2(24, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(46, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		30,
		_Utils_Tuple2(42, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(32, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		30,
		_Utils_Tuple2(10, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(67, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		30,
		_Utils_Tuple2(20, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(61, 16)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataL = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		7,
		_Utils_Tuple2(1, 19),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		10,
		_Utils_Tuple2(1, 34),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		15,
		_Utils_Tuple2(1, 55),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		20,
		_Utils_Tuple2(1, 80),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		26,
		_Utils_Tuple2(1, 108),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		18,
		_Utils_Tuple2(2, 68),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		20,
		_Utils_Tuple2(2, 78),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		24,
		_Utils_Tuple2(2, 97),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		30,
		_Utils_Tuple2(2, 116),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		18,
		_Utils_Tuple2(2, 68),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 69))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		20,
		_Utils_Tuple2(4, 81),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		24,
		_Utils_Tuple2(2, 92),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 93))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		26,
		_Utils_Tuple2(4, 107),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		30,
		_Utils_Tuple2(3, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		22,
		_Utils_Tuple2(5, 87),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 88))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		24,
		_Utils_Tuple2(5, 98),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 99))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(1, 107),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 108))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		30,
		_Utils_Tuple2(5, 120),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 121))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		28,
		_Utils_Tuple2(3, 113),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 114))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		28,
		_Utils_Tuple2(3, 107),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 108))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		28,
		_Utils_Tuple2(4, 116),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 117))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		28,
		_Utils_Tuple2(2, 111),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 112))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		30,
		_Utils_Tuple2(4, 121),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 122))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		30,
		_Utils_Tuple2(6, 117),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 118))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		26,
		_Utils_Tuple2(8, 106),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 107))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		28,
		_Utils_Tuple2(10, 114),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 115))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		30,
		_Utils_Tuple2(8, 122),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 123))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		30,
		_Utils_Tuple2(3, 117),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 118))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		30,
		_Utils_Tuple2(7, 116),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 117))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		30,
		_Utils_Tuple2(5, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		30,
		_Utils_Tuple2(13, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(3, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		30,
		_Utils_Tuple2(17, 115),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		30,
		_Utils_Tuple2(17, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		30,
		_Utils_Tuple2(13, 115),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 116))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		30,
		_Utils_Tuple2(12, 121),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 122))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		30,
		_Utils_Tuple2(6, 121),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 122))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		30,
		_Utils_Tuple2(17, 122),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 123))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		30,
		_Utils_Tuple2(4, 122),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(18, 123))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		30,
		_Utils_Tuple2(20, 117),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 118))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		30,
		_Utils_Tuple2(19, 118),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 119)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataM = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		10,
		_Utils_Tuple2(1, 16),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		16,
		_Utils_Tuple2(1, 28),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		26,
		_Utils_Tuple2(1, 44),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		18,
		_Utils_Tuple2(2, 32),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		24,
		_Utils_Tuple2(2, 43),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		16,
		_Utils_Tuple2(4, 27),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		18,
		_Utils_Tuple2(4, 31),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		22,
		_Utils_Tuple2(2, 38),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 39))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		22,
		_Utils_Tuple2(3, 36),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 37))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		26,
		_Utils_Tuple2(4, 43),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 44))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		30,
		_Utils_Tuple2(1, 50),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 51))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		22,
		_Utils_Tuple2(6, 36),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 37))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		22,
		_Utils_Tuple2(8, 37),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 38))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		24,
		_Utils_Tuple2(4, 40),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 41))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		24,
		_Utils_Tuple2(5, 41),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 42))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		28,
		_Utils_Tuple2(7, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(3, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(10, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		26,
		_Utils_Tuple2(9, 43),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 44))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		26,
		_Utils_Tuple2(3, 44),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(11, 45))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		26,
		_Utils_Tuple2(3, 41),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 42))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		26,
		_Utils_Tuple2(17, 42),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		28,
		_Utils_Tuple2(17, 46),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		28,
		_Utils_Tuple2(4, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		28,
		_Utils_Tuple2(6, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		28,
		_Utils_Tuple2(8, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(13, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		28,
		_Utils_Tuple2(19, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		28,
		_Utils_Tuple2(22, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(3, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		28,
		_Utils_Tuple2(3, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(23, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		28,
		_Utils_Tuple2(21, 45),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 46))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		28,
		_Utils_Tuple2(19, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		28,
		_Utils_Tuple2(2, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(29, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		28,
		_Utils_Tuple2(10, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(23, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		28,
		_Utils_Tuple2(14, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(21, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		28,
		_Utils_Tuple2(14, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(23, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		28,
		_Utils_Tuple2(12, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(26, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		28,
		_Utils_Tuple2(6, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(34, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		28,
		_Utils_Tuple2(29, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		28,
		_Utils_Tuple2(13, 46),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(32, 47))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		28,
		_Utils_Tuple2(40, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 48))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		28,
		_Utils_Tuple2(18, 47),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(31, 48)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataQ = _List_fromArray(
	[
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		1,
		13,
		_Utils_Tuple2(1, 13),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		2,
		22,
		_Utils_Tuple2(1, 22),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		3,
		18,
		_Utils_Tuple2(2, 17),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		4,
		26,
		_Utils_Tuple2(2, 24),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		5,
		18,
		_Utils_Tuple2(2, 15),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 16))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		6,
		24,
		_Utils_Tuple2(4, 19),
		$elm$core$Maybe$Nothing),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		7,
		18,
		_Utils_Tuple2(2, 14),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 15))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		8,
		22,
		_Utils_Tuple2(4, 18),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 19))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		9,
		20,
		_Utils_Tuple2(4, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		10,
		24,
		_Utils_Tuple2(6, 19),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 20))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		11,
		28,
		_Utils_Tuple2(4, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		12,
		26,
		_Utils_Tuple2(4, 20),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 21))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		13,
		24,
		_Utils_Tuple2(8, 20),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 21))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		14,
		20,
		_Utils_Tuple2(11, 16),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 17))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		15,
		30,
		_Utils_Tuple2(5, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		16,
		24,
		_Utils_Tuple2(15, 19),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(2, 20))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		17,
		28,
		_Utils_Tuple2(1, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(15, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		18,
		28,
		_Utils_Tuple2(17, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		19,
		26,
		_Utils_Tuple2(17, 21),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(4, 22))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		20,
		30,
		_Utils_Tuple2(15, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(5, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		21,
		28,
		_Utils_Tuple2(17, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		22,
		30,
		_Utils_Tuple2(7, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(16, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		23,
		30,
		_Utils_Tuple2(11, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		24,
		30,
		_Utils_Tuple2(11, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(16, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		25,
		30,
		_Utils_Tuple2(7, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(22, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		26,
		28,
		_Utils_Tuple2(28, 22),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(6, 23))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		27,
		30,
		_Utils_Tuple2(8, 23),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(26, 24))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		28,
		30,
		_Utils_Tuple2(4, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(31, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		29,
		30,
		_Utils_Tuple2(1, 23),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(37, 24))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		30,
		30,
		_Utils_Tuple2(15, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(25, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		31,
		30,
		_Utils_Tuple2(42, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(1, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		32,
		30,
		_Utils_Tuple2(10, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(35, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		33,
		30,
		_Utils_Tuple2(29, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(19, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		34,
		30,
		_Utils_Tuple2(44, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(7, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		35,
		30,
		_Utils_Tuple2(39, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		36,
		30,
		_Utils_Tuple2(46, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		37,
		30,
		_Utils_Tuple2(49, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(10, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		38,
		30,
		_Utils_Tuple2(48, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(14, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		39,
		30,
		_Utils_Tuple2(43, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(22, 25))),
		A4(
		$pablohirafuji$elm_qrcode$QRCode$GroupInfo$newGroupInfo,
		40,
		30,
		_Utils_Tuple2(34, 24),
		$elm$core$Maybe$Just(
			_Utils_Tuple2(34, 25)))
	]);
var $pablohirafuji$elm_qrcode$QRCode$GroupInfo$getGroupData = function (ecLevel) {
	switch (ecLevel) {
		case 0:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataL;
		case 1:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataM;
		case 2:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataQ;
		default:
			return $pablohirafuji$elm_qrcode$QRCode$GroupInfo$dataH;
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$getVersion = F3(
	function (ecLevel, mode, dataLength) {
		return A2(
			$elm$core$Result$fromMaybe,
			$pablohirafuji$elm_qrcode$QRCode$Error$InputLengthOverflow,
			$elm$core$List$head(
				A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.em;
					},
					A2(
						$elm$core$List$filter,
						A2($pablohirafuji$elm_qrcode$QRCode$Encode$filterCapacity, mode, dataLength),
						$pablohirafuji$elm_qrcode$QRCode$GroupInfo$getGroupData(ecLevel)))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$versionToModel = F5(
	function (inputStr, ecLevel, mode, partialBitsCount, groupInfo) {
		return {
			gd: partialBitsCount + A2($pablohirafuji$elm_qrcode$QRCode$Encode$charCountIndicatorLength, mode, groupInfo.h2),
			iW: ecLevel,
			mX: groupInfo,
			gN: inputStr,
			eY: mode
		};
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$selectVersion = F4(
	function (inputStr, ecLevel, mode, encodedStr) {
		var partialBitsCount = 4 + A3(
			$elm$core$List$foldl,
			F2(
				function (a, b) {
					return a.b + b;
				}),
			0,
			encodedStr);
		return A2(
			$elm$core$Result$map,
			function (b) {
				return _Utils_Tuple2(encodedStr, b);
			},
			A2(
				$elm$core$Result$map,
				A4($pablohirafuji$elm_qrcode$QRCode$Encode$versionToModel, inputStr, ecLevel, mode, partialBitsCount),
				A3($pablohirafuji$elm_qrcode$QRCode$Encode$getVersion, ecLevel, mode, partialBitsCount)));
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$breakList = F3(
	function (checkFinish, _v0, _v1) {
		breakList:
		while (true) {
			var times = _v0.a;
			var itemCount = _v0.b;
			var byteList = _v1.a;
			var progress = _v1.b;
			if (times > 0) {
				var remainList = A2($elm$core$List$drop, itemCount, byteList);
				var block = A2($elm$core$List$take, itemCount, byteList);
				var $temp$checkFinish = checkFinish,
					$temp$_v0 = _Utils_Tuple2(times - 1, itemCount),
					$temp$_v1 = _Utils_Tuple2(
					remainList,
					A2($elm$core$List$cons, block, progress));
				checkFinish = $temp$checkFinish;
				_v0 = $temp$_v0;
				_v1 = $temp$_v1;
				continue breakList;
			} else {
				if (checkFinish && ($elm$core$List$length(byteList) > 0)) {
					return $elm$core$Result$Err($pablohirafuji$elm_qrcode$QRCode$Error$InputLengthOverflow);
				} else {
					return $elm$core$Result$Ok(
						_Utils_Tuple2(byteList, progress));
				}
			}
		}
	});
var $pablohirafuji$elm_qrcode$QRCode$Encode$toBlocks = function (_v0) {
	var model = _v0.a;
	var groupInfo = model.mX;
	var byteList = _v0.b;
	var _v1 = groupInfo.nh;
	if (!_v1.$) {
		var group2 = _v1.a;
		return A2(
			$elm$core$Result$map,
			function (b) {
				return _Utils_Tuple2(model, b);
			},
			A2(
				$elm$core$Result$map,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$List$reverse),
				A2(
					$elm$core$Result$andThen,
					A2($pablohirafuji$elm_qrcode$QRCode$Encode$breakList, true, group2),
					A3(
						$pablohirafuji$elm_qrcode$QRCode$Encode$breakList,
						false,
						groupInfo.jw,
						_Utils_Tuple2(byteList, _List_Nil)))));
	} else {
		return A2(
			$elm$core$Result$map,
			function (b) {
				return _Utils_Tuple2(model, b);
			},
			A2(
				$elm$core$Result$map,
				A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $elm$core$List$reverse),
				A3(
					$pablohirafuji$elm_qrcode$QRCode$Encode$breakList,
					true,
					groupInfo.jw,
					_Utils_Tuple2(byteList, _List_Nil))));
	}
};
var $pablohirafuji$elm_qrcode$QRCode$Encode$encode = F2(
	function (inputStr, ecLevel) {
		var mode = $pablohirafuji$elm_qrcode$QRCode$Encode$selectMode(inputStr);
		return A2(
			$elm$core$Result$map,
			$pablohirafuji$elm_qrcode$QRCode$Encode$concatTranspose,
			A2(
				$elm$core$Result$andThen,
				$pablohirafuji$elm_qrcode$QRCode$Encode$getErrorCorrection,
				A2(
					$elm$core$Result$andThen,
					$pablohirafuji$elm_qrcode$QRCode$Encode$toBlocks,
					A2(
						$elm$core$Result$map,
						$pablohirafuji$elm_qrcode$QRCode$Encode$addInfoAndFinalBits,
						A2(
							$elm$core$Result$andThen,
							A3($pablohirafuji$elm_qrcode$QRCode$Encode$selectVersion, inputStr, ecLevel, mode),
							A2($pablohirafuji$elm_qrcode$QRCode$Encode$encoder, mode, inputStr))))));
	});
var $pablohirafuji$elm_qrcode$QRCode$fromStringWith = F2(
	function (ecLevel, input) {
		return A2(
			$elm$core$Result$mapError,
			$pablohirafuji$elm_qrcode$QRCode$convertError,
			A2(
				$elm$core$Result$andThen,
				function (_v0) {
					var encodeModel = _v0.a;
					var encodedData = _v0.b;
					return A2(
						$elm$core$Result$map,
						function (matrix) {
							return {j8: matrix, h2: encodeModel.mX.h2};
						},
						$pablohirafuji$elm_qrcode$QRCode$Matrix$apply(
							_Utils_Tuple2(encodeModel, encodedData)));
				},
				A2(
					$pablohirafuji$elm_qrcode$QRCode$Encode$encode,
					input,
					$pablohirafuji$elm_qrcode$QRCode$convertEC(ecLevel))));
	});
var $pablohirafuji$elm_qrcode$QRCode$fromString = $pablohirafuji$elm_qrcode$QRCode$fromStringWith(2);
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize = 5;
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$appendLastRect = function (_v0) {
	var lastRect = _v0.a;
	var rowLines = _v0.b;
	return A2(
		$elm$core$List$cons,
		'h' + $elm$core$String$fromInt(lastRect.lZ * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize),
		rowLines);
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$shapeRendering = _VirtualDom_attribute('shape-rendering');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $elm$svg$Svg$Attributes$style = _VirtualDom_attribute('style');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$toRowLines = F2(
	function (isDark, _v0) {
		var lastRect = _v0.a;
		var rowLines = _v0.b;
		return isDark ? ((!lastRect.b_) ? _Utils_Tuple2(
			_Utils_update(
				lastRect,
				{lZ: lastRect.lZ + 1}),
			rowLines) : _Utils_Tuple2(
			{b_: 0, lZ: 1},
			A2(
				$elm$core$List$cons,
				$elm$core$String$concat(
					_List_fromArray(
						[
							(lastRect.lZ > 0) ? ('h' + $elm$core$String$fromInt(lastRect.lZ * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize)) : '',
							'm',
							$elm$core$String$fromInt(lastRect.b_ * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize),
							' 0'
						])),
				rowLines))) : _Utils_Tuple2(
			_Utils_update(
				lastRect,
				{b_: lastRect.b_ + 1}),
			rowLines);
	});
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewRow = F3(
	function (quietZoneSize, rowIndex, rowLines) {
		return A2(
			$elm$core$List$cons,
			'M0 ',
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(rowIndex * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize),
				rowLines));
	});
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewBase = F3(
	function (quietZoneSize, extraAttrs, matrix) {
		var quietZonePx = quietZoneSize * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize;
		var sizePx = $elm$core$String$fromInt(
			($elm$core$List$length(matrix) * $pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize) + (2 * quietZonePx));
		return A2(
			$elm$svg$Svg$svg,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$viewBox('0 0 ' + (sizePx + (' ' + sizePx))),
						$elm$svg$Svg$Attributes$shapeRendering('crispEdges'),
						$elm$svg$Svg$Attributes$stroke('#000'),
						$elm$svg$Svg$Attributes$strokeWidth(
						$elm$core$String$fromInt($pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize) + 'px')
					]),
				extraAttrs),
			function (d) {
				return _List_fromArray(
					[
						A2(
						$elm$svg$Svg$path,
						_List_fromArray(
							[
								d,
								$elm$svg$Svg$Attributes$transform(
								'translate(' + ($elm$core$String$fromInt(quietZonePx) + (', ' + ($elm$core$String$fromFloat(quietZonePx + ($pablohirafuji$elm_qrcode$QRCode$Render$Svg$moduleSize / 2)) + ')')))),
								$elm$svg$Svg$Attributes$style('stroke-width: 5px')
							]),
						_List_Nil)
					]);
			}(
				$elm$svg$Svg$Attributes$d(
					$elm$core$String$concat(
						$elm$core$List$concat(
							A2(
								$elm$core$List$indexedMap,
								$pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewRow(quietZoneSize),
								A2(
									$elm$core$List$map,
									A2(
										$elm$core$Basics$composeR,
										A2(
											$elm$core$List$foldl,
											$pablohirafuji$elm_qrcode$QRCode$Render$Svg$toRowLines,
											_Utils_Tuple2(
												{b_: 0, lZ: 0},
												_List_Nil)),
										A2($elm$core$Basics$composeR, $pablohirafuji$elm_qrcode$QRCode$Render$Svg$appendLastRect, $elm$core$List$reverse)),
									matrix)))))));
	});
var $pablohirafuji$elm_qrcode$QRCode$Render$Svg$view = $pablohirafuji$elm_qrcode$QRCode$Render$Svg$viewBase(4);
var $pablohirafuji$elm_qrcode$QRCode$toSvg = F2(
	function (extraAttrs, _v0) {
		var matrix = _v0.j8;
		return A2($pablohirafuji$elm_qrcode$QRCode$Render$Svg$view, extraAttrs, matrix);
	});
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $author$project$Page$LinkBuilder$qrCodeView = function (message) {
	return A2(
		$elm$core$Result$withDefault,
		$elm$html$Html$text('Error while encoding to QRCode.'),
		A2(
			$elm$core$Result$map,
			$pablohirafuji$elm_qrcode$QRCode$toSvg(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$width(300),
						$elm$html$Html$Attributes$height(300)
					])),
			$pablohirafuji$elm_qrcode$QRCode$fromString(message)));
};
var $elm$html$Html$p = _VirtualDom_node('p');
var $rundis$elm_bootstrap$Bootstrap$Card$Block$text = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$p,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('card-text')
					]),
				attributes),
			children);
	});
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $rundis$elm_bootstrap$Bootstrap$Card$Block$title = F3(
	function (elemFn, attributes, children) {
		return A2(
			elemFn,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('card-title'),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Card$Block$titleH4 = $rundis$elm_bootstrap$Bootstrap$Card$Block$title($elm$html$Html$h4);
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$applyModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 0:
				var align = option.a;
				return _Utils_update(
					options,
					{
						a4: $elm$core$Maybe$Just(align)
					});
			case 1:
				var coloring = option.a;
				return _Utils_update(
					options,
					{
						a7: $elm$core$Maybe$Just(coloring)
					});
			case 2:
				var coloring = option.a;
				return _Utils_update(
					options,
					{
						br: $elm$core$Maybe$Just(coloring)
					});
			default:
				var attrs = option.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$defaultOptions = {a4: $elm$core$Maybe$Nothing, eg: _List_Nil, a7: $elm$core$Maybe$Nothing, br: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$cardAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Card$Internal$applyModifier, $rundis$elm_bootstrap$Bootstrap$Card$Internal$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card')
			]),
		_Utils_ap(
			function () {
				var _v0 = options.a7;
				if (!_v0.$) {
					if (!_v0.a.$) {
						var role = _v0.a.a;
						return _List_fromArray(
							[
								A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role)
							]);
					} else {
						var role = _v0.a.a;
						return _List_fromArray(
							[
								A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'border', role)
							]);
					}
				} else {
					return _List_Nil;
				}
			}(),
			_Utils_ap(
				function () {
					var _v1 = options.br;
					if (!_v1.$) {
						var color = _v1.a;
						return _List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Internal$Text$textColorClass(color)
							]);
					} else {
						return _List_Nil;
					}
				}(),
				_Utils_ap(
					function () {
						var _v2 = options.a4;
						if (!_v2.$) {
							var align = _v2.a;
							return _List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Internal$Text$textAlignClass(align)
								]);
						} else {
							return _List_Nil;
						}
					}(),
					options.eg))));
};
var $rundis$elm_bootstrap$Bootstrap$Card$Internal$renderBlocks = function (blocks) {
	return A2(
		$elm$core$List$map,
		function (block_) {
			if (!block_.$) {
				var e = block_.a;
				return e;
			} else {
				var e = block_.a;
				return e;
			}
		},
		blocks);
};
var $rundis$elm_bootstrap$Bootstrap$Card$view = function (_v0) {
	var conf = _v0;
	return A2(
		$elm$html$Html$div,
		$rundis$elm_bootstrap$Bootstrap$Card$Internal$cardAttributes(conf.ny),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$map,
						function (_v1) {
							var e = _v1;
							return e;
						},
						conf.eJ),
						A2(
						$elm$core$Maybe$map,
						function (_v2) {
							var e = _v2;
							return e;
						},
						conf.gE)
					])),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Card$Internal$renderBlocks(conf.ge),
				A2(
					$elm$core$List$filterMap,
					$elm$core$Basics$identity,
					_List_fromArray(
						[
							A2(
							$elm$core$Maybe$map,
							function (_v3) {
								var e = _v3;
								return e;
							},
							conf.gy),
							A2(
							$elm$core$Maybe$map,
							function (_v4) {
								var e = _v4;
								return e;
							},
							conf.gD)
						])))));
};
var $author$project$Page$LinkBuilder$linkCard = function (url) {
	return $rundis$elm_bootstrap$Bootstrap$Card$view(
		A3(
			$rundis$elm_bootstrap$Bootstrap$Card$block,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Card$Block$titleH4,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Link')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Card$Block$text,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href(url),
									$elm$html$Html$Attributes$class('d-block max-height-80'),
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1,
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3,
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3,
									$elm$html$Html$Attributes$target('_blank')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(url)
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Card$Block$text,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-center')
						]),
					_List_fromArray(
						[
							$author$project$Page$LinkBuilder$qrCodeView(url)
						])),
					$rundis$elm_bootstrap$Bootstrap$Card$Block$custom(
					A2(
						$rundis$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$primary,
								$rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('float-right')
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Download')
							])))
				]),
			$rundis$elm_bootstrap$Bootstrap$Card$config(
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Card$attrs(_List_Nil)
					]))));
};
var $author$project$Page$LinkBuilder$linkRow = function (model) {
	var url = A4($author$project$Page$LinkBuilder$createUrl, model.gh.mC, model.iE, model.fz, model.ig);
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Page$LinkBuilder$linkCard(url)
					]))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Number = 7;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$number = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(7);
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col5 = 5;
var $rundis$elm_bootstrap$Bootstrap$General$Internal$SM = 1;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm5 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 5);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$text = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(0);
var $author$project$Page$LinkBuilder$formRow = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
						$elm$html$Html$Attributes$class('fade-in')
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm5]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$group,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$for('ref-id')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Source')
									])),
								$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Form$Input$id('ref-id'),
										$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Page$LinkBuilder$RefCodeUpdated)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$help,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('This code will be used to track the context of a donation and enable tracking. After sharing a link with a source, navigate to the Transactions view to see which transactions come from which context.')
									]))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$group,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$label,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$for('amount')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('Amount')
									])),
								$rundis$elm_bootstrap$Bootstrap$Form$Input$number(
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Form$Input$id('amount'),
										$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput($author$project$Page$LinkBuilder$AmountUpdated)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$help,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text('This amount will be prefilled when the donation form is loaded.')
									]))
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm5]),
				_List_fromArray(
					[
						$author$project$Page$LinkBuilder$linkRow(model)
					]))
			]));
};
var $author$project$Page$LinkBuilder$view = function (model) {
	return {
		ms: A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Page$LinkBuilder$formRow(model)
				])),
		lu: '4US'
	};
};
var $author$project$Asset$error = $author$project$Asset$image('error.jpg');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $author$project$Page$NotFound$view = {
	ms: A2(
		$elm$html$Html$main_,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$id('content'),
				$elm$html$Html$Attributes$class('container'),
				$elm$html$Html$Attributes$tabindex(-1)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h1,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text('Not Found')
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$author$project$Asset$src($author$project$Asset$error)
							]),
						_List_Nil)
					]))
			])),
	lu: 'Page Not Found'
};
var $author$project$Page$Transactions$ShowTxnFormModal = function (a) {
	return {$: 42, a: a};
};
var $author$project$Page$Transactions$ContribRuleUnverifiedModalHide = {$: 43};
var $author$project$Page$Transactions$ContribRuleUnverifiedSubmit = {$: 46};
var $author$project$TxnForm$ContribRuleUnverified$totalSelectedMatch = function (model) {
	return _Utils_eq(
		A3(
			$elm$core$List$foldr,
			F2(
				function (txn, acc) {
					return acc + txn.ig;
				}),
			0,
			model.li),
		model.ik.ig) ? false : true;
};
var $author$project$TxnForm$ContribRuleUnverified$toSubmitDisabled = function (model) {
	return model.ht && $author$project$TxnForm$ContribRuleUnverified$totalSelectedMatch(model);
};
var $rundis$elm_bootstrap$Bootstrap$Modal$Body = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$body = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				$9: $elm$core$Maybe$Just(
					{eg: attributes, mk: children})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$General$Internal$Between = 4;
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 0, 4);
var $author$project$Asset$lockGlyph = $author$project$Asset$glyph('fas fa-lock');
var $author$project$Asset$lockOpenGlyph = $author$project$Asset$glyph('fas fa-lock-open');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2 = $elm$html$Html$Attributes$class('ml-2');
var $author$project$SubmitButton$delete = F3(
	function (submitMsg, isSending, isDeleteConfirmed) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$outlineDanger,
					$rundis$elm_bootstrap$Bootstrap$Button$onClick(submitMsg),
					$rundis$elm_bootstrap$Bootstrap$Button$disabled(isSending),
					$rundis$elm_bootstrap$Bootstrap$Button$block
				]),
			_List_fromArray(
				[
					isSending ? $author$project$SubmitButton$spinner : $elm$html$Html$text('Delete'),
					function () {
					switch (isDeleteConfirmed) {
						case 0:
							return $author$project$Asset$lockOpenGlyph(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb1]));
						case 1:
							return $author$project$Asset$lockGlyph(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb1]));
						default:
							return $author$project$Asset$lockGlyph(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb1]));
					}
				}()
				]));
	});
var $author$project$PlatformModal$exitButton = function (hideMsg) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
				$rundis$elm_bootstrap$Bootstrap$Button$block,
				$rundis$elm_bootstrap$Bootstrap$Button$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(hideMsg)
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Exit')
			]));
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$LG = 3;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 3, 3);
var $rundis$elm_bootstrap$Bootstrap$Internal$Button$Success = 2;
var $rundis$elm_bootstrap$Bootstrap$Button$success = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Roled(2));
var $author$project$SubmitButton$submitButton = F4(
	function (label, submitMsg, loading, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$success,
					$rundis$elm_bootstrap$Bootstrap$Button$block,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(submitMsg)
						])),
					$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled)
				]),
			_List_fromArray(
				[
					loading ? $author$project$SubmitButton$spinner : $elm$html$Html$text(label)
				]));
	});
var $author$project$PlatformModal$buttonRow = function (config) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3,
						$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('text-left')
							]))
					]),
				function () {
					var _v0 = _Utils_Tuple2(config.gs, config.ct);
					if (_v0.a) {
						if (!_v0.b.$) {
							var deleteMsg = _v0.b.a;
							return _List_fromArray(
								[
									A3($author$project$SubmitButton$delete, deleteMsg, config.V, config.co)
								]);
						} else {
							var _v1 = _v0.b;
							return _List_fromArray(
								[
									$author$project$PlatformModal$exitButton(config.ck)
								]);
						}
					} else {
						return _List_Nil;
					}
				}()),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3]),
				config.go ? _List_Nil : _List_fromArray(
					[
						A4($author$project$SubmitButton$submitButton, config.cK, config.cJ, config.lo, config.iQ)
					]))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$config = function (closeMsg) {
	return {
		$9: $elm$core$Maybe$Nothing,
		c_: closeMsg,
		gy: $elm$core$Maybe$Nothing,
		eJ: $elm$core$Maybe$Nothing,
		ny: {eh: _List_Nil, eo: true, dl: true, dw: $elm$core$Maybe$Nothing, fG: false},
		b6: $elm$core$Maybe$Nothing
	};
};
var $rundis$elm_bootstrap$Bootstrap$Alert$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Alert$children = F2(
	function (children_, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{mk: children_});
	});
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$Secondary = 1;
var $rundis$elm_bootstrap$Bootstrap$Alert$config = {eg: _List_Nil, mk: _List_Nil, bK: $elm$core$Maybe$Nothing, fC: 1, cT: 0, b6: false};
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$Danger = 5;
var $rundis$elm_bootstrap$Bootstrap$Alert$role = F2(
	function (role_, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{fC: role_});
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$danger = function (conf) {
	return A2($rundis$elm_bootstrap$Bootstrap$Alert$role, 5, conf);
};
var $rundis$elm_bootstrap$Bootstrap$Alert$dismissableWithAnimation = F2(
	function (dismissMsg, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{
				bK: $elm$core$Maybe$Just(dismissMsg),
				b6: true
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$headingPrivate = F3(
	function (elemFn, attributes, children_) {
		return A2(
			elemFn,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('alert-header'),
				attributes),
			children_);
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$h5 = F2(
	function (attributes, children_) {
		return A3($rundis$elm_bootstrap$Bootstrap$Alert$headingPrivate, $elm$html$Html$h5, attributes, children_);
	});
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $rundis$elm_bootstrap$Bootstrap$Alert$h6 = F2(
	function (attributes, children_) {
		return A3($rundis$elm_bootstrap$Bootstrap$Alert$headingPrivate, $elm$html$Html$h6, attributes, children_);
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$StartClose = 1;
var $rundis$elm_bootstrap$Bootstrap$Alert$clickHandler = F2(
	function (visibility, configRec) {
		var handleClick = F2(
			function (viz, toMsg) {
				return $elm$html$Html$Events$onClick(
					toMsg(viz));
			});
		var _v0 = configRec.bK;
		if (!_v0.$) {
			var dismissMsg = _v0.a;
			return _List_fromArray(
				[
					configRec.b6 ? A2(handleClick, 1, dismissMsg) : A2(handleClick, 3, dismissMsg)
				]);
		} else {
			return _List_Nil;
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$injectButton = F2(
	function (btn, children_) {
		if (children_.b) {
			var head = children_.a;
			var tail = children_.b;
			return A2(
				$elm$core$List$cons,
				head,
				A2($elm$core$List$cons, btn, tail));
		} else {
			return _List_fromArray(
				[btn]);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$isDismissable = function (configRec) {
	var _v0 = configRec.bK;
	if (!_v0.$) {
		return true;
	} else {
		return false;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Alert$maybeAddDismissButton = F3(
	function (visibilty, configRec, children_) {
		return $rundis$elm_bootstrap$Bootstrap$Alert$isDismissable(configRec) ? A2(
			$rundis$elm_bootstrap$Bootstrap$Alert$injectButton,
			A2(
				$elm$html$Html$button,
				_Utils_ap(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$type_('button'),
							$elm$html$Html$Attributes$class('close'),
							A2($elm$html$Html$Attributes$attribute, 'aria-label', 'close')
						]),
					A2($rundis$elm_bootstrap$Bootstrap$Alert$clickHandler, visibilty, configRec)),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('×')
							]))
					])),
			children_) : children_;
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$viewAttributes = F2(
	function (visibility, configRec) {
		var visibiltyAttributes = (visibility === 3) ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'display', 'none')
			]) : _List_Nil;
		var animationAttributes = function () {
			if (configRec.b6) {
				var _v0 = configRec.bK;
				if (!_v0.$) {
					var dismissMsg = _v0.a;
					return _List_fromArray(
						[
							A2(
							$elm$html$Html$Events$on,
							'transitionend',
							$elm$json$Json$Decode$succeed(
								dismissMsg(3)))
						]);
				} else {
					return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		}();
		var alertAttributes = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'role', 'alert'),
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('alert', true),
						_Utils_Tuple2(
						'alert-dismissible',
						$rundis$elm_bootstrap$Bootstrap$Alert$isDismissable(configRec)),
						_Utils_Tuple2('fade', configRec.b6),
						_Utils_Tuple2('show', !visibility)
					])),
				A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'alert', configRec.fC)
			]);
		return $elm$core$List$concat(
			_List_fromArray(
				[configRec.eg, alertAttributes, visibiltyAttributes, animationAttributes]));
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$view = F2(
	function (visibility, _v0) {
		var configRec = _v0;
		return A2(
			$elm$html$Html$div,
			A2($rundis$elm_bootstrap$Bootstrap$Alert$viewAttributes, visibility, configRec),
			A3($rundis$elm_bootstrap$Bootstrap$Alert$maybeAddDismissButton, visibility, configRec, configRec.mk));
	});
var $author$project$DeleteInfo$deletionAlert = F2(
	function (msg, visibility) {
		var _v0 = _Utils_Tuple2(msg, visibility);
		if ((!_v0.a.$) && (!_v0.b.$)) {
			var alertMsg = _v0.a.a;
			var alertVisibility = _v0.b.a;
			return A2(
				$rundis$elm_bootstrap$Bootstrap$Alert$view,
				alertVisibility,
				A2(
					$rundis$elm_bootstrap$Bootstrap$Alert$children,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Alert$h5,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-weight-bold')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Warning')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Alert$h6,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text('This action is irreversible')
								]))
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Alert$dismissableWithAnimation,
						alertMsg,
						$rundis$elm_bootstrap$Bootstrap$Alert$danger($rundis$elm_bootstrap$Bootstrap$Alert$config))));
		} else {
			return $elm$html$Html$text('');
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$Footer = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$footer = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				gy: $elm$core$Maybe$Just(
					{eg: attributes, mk: children})
			});
	});
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $rundis$elm_bootstrap$Bootstrap$Modal$Header = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Modal$header = F3(
	function (attributes, children, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				eJ: $elm$core$Maybe$Just(
					{eg: attributes, mk: children})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$titledHeader = F3(
	function (itemFn, attributes, children) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Modal$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					itemFn,
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Attributes$class('modal-title'),
						attributes),
					children)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$h3 = $rundis$elm_bootstrap$Bootstrap$Modal$titledHeader($elm$html$Html$h3);
var $rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick = F2(
	function (hide, _v0) {
		var conf = _v0;
		var options = conf.ny;
		return _Utils_update(
			conf,
			{
				ny: _Utils_update(
					options,
					{dl: hide})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$large = function (_v0) {
	var conf = _v0;
	var options = conf.ny;
	return _Utils_update(
		conf,
		{
			ny: _Utils_update(
				options,
				{
					dw: $elm$core$Maybe$Just(3)
				})
		});
};
var $rundis$elm_bootstrap$Bootstrap$Modal$scrollableBody = F2(
	function (scrollable, _v0) {
		var conf = _v0;
		var options = conf.ny;
		return _Utils_update(
			conf,
			{
				ny: _Utils_update(
					options,
					{fG: scrollable})
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Offset10 = 10;
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColOffset = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Offset = F2(
	function (screenSize, offsetCount) {
		return {ko: offsetCount, lh: screenSize};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$offset = F2(
	function (size, count) {
		return $rundis$elm_bootstrap$Bootstrap$Grid$Internal$ColOffset(
			A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$Offset, size, count));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$offsetLg10 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$offset, 3, 10);
var $author$project$PlatformModal$successButtonRow = function (hideMsg) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Grid$Row$aroundXs]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Grid$Col$offsetLg10]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Button$button,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
								$rundis$elm_bootstrap$Bootstrap$Button$block,
								$rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Events$onClick(hideMsg)
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('OK')
							]))
					]))
			]));
};
var $author$project$Asset$circleCheckGlyph = $author$project$Asset$glyph('fa-check-circle');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml3 = $elm$html$Html$Attributes$class('ml-3');
var $author$project$PlatformModal$successMessage = function (successViewMessage) {
	return A2(
		$elm$html$Html$h2,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('align-middle text-green'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3
			]),
		_List_fromArray(
			[
				$author$project$Asset$circleCheckGlyph(_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('align-middle text-green'),
						$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml3
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(successViewMessage)
					]))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$StartClose = 1;
var $rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg = function (config_) {
	var _v0 = config_.b6;
	if (!_v0.$) {
		var animationMsg = _v0.a;
		return animationMsg(1);
	} else {
		return config_.c_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$isFade = function (conf) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return true;
			},
			conf.b6));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$backdrop = F2(
	function (visibility, conf) {
		var attributes = function () {
			switch (visibility) {
				case 0:
					return _Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal-backdrop', true),
										_Utils_Tuple2(
										'fade',
										$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
										_Utils_Tuple2('show', true)
									]))
							]),
						conf.ny.dl ? _List_fromArray(
							[
								$elm$html$Html$Events$onClick(
								$rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf))
							]) : _List_Nil);
				case 1:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', true)
								]))
						]);
				case 2:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', true),
									_Utils_Tuple2('fade', true),
									_Utils_Tuple2('show', false)
								]))
						]);
				default:
					return _List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal-backdrop', false),
									_Utils_Tuple2(
									'fade',
									$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
									_Utils_Tuple2('show', false)
								]))
						]);
			}
		}();
		return _List_fromArray(
			[
				A2($elm$html$Html$div, attributes, _List_Nil)
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['className']),
	$elm$json$Json$Decode$string);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder = function (closeMsg) {
	return A2(
		$elm$json$Json$Decode$andThen,
		function (c) {
			return A2($elm$core$String$contains, 'elm-bootstrap-modal', c) ? $elm$json$Json$Decode$succeed(closeMsg) : $elm$json$Json$Decode$fail('ignoring');
		},
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$target($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$display = F2(
	function (visibility, conf) {
		switch (visibility) {
			case 0:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 1:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', true)
							]))
					]);
			case 2:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'pointer-events', 'none'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2('fade', true),
								_Utils_Tuple2('show', false)
							])),
						A2(
						$elm$html$Html$Events$on,
						'transitionend',
						$elm$json$Json$Decode$succeed(conf.c_))
					]);
			default:
				return _List_fromArray(
					[
						A2($elm$html$Html$Attributes$style, 'height', '0px'),
						A2($elm$html$Html$Attributes$style, 'display', 'block'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal', true),
								_Utils_Tuple2(
								'fade',
								$rundis$elm_bootstrap$Bootstrap$Modal$isFade(conf)),
								_Utils_Tuple2('show', false)
							]))
					]);
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$modalClass = function (size) {
	var _v0 = $rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size);
	if (!_v0.$) {
		var s = _v0.a;
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal-' + s)
			]);
	} else {
		return _List_Nil;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes = function (options) {
	return _Utils_ap(
		options.eh,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal-dialog', true),
							_Utils_Tuple2('modal-dialog-centered', options.eo),
							_Utils_Tuple2('modal-dialog-scrollable', options.fG)
						])),
					A2($elm$html$Html$Attributes$style, 'pointer-events', 'auto')
				]),
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Modal$modalClass, options.dw))));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderBody = function (maybeBody) {
	if (!maybeBody.$) {
		var cfg = maybeBody.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-body'),
					cfg.eg),
				cfg.mk));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderFooter = function (maybeFooter) {
	if (!maybeFooter.$) {
		var cfg = maybeFooter.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-footer'),
					cfg.eg),
				cfg.mk));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$closeButton = function (closeMsg) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('close'),
				$elm$html$Html$Events$onClick(closeMsg)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('×')
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Modal$renderHeader = function (conf_) {
	var _v0 = conf_.eJ;
	if (!_v0.$) {
		var cfg = _v0.a;
		return $elm$core$Maybe$Just(
			A2(
				$elm$html$Html$div,
				A2(
					$elm$core$List$cons,
					$elm$html$Html$Attributes$class('modal-header'),
					cfg.eg),
				_Utils_ap(
					cfg.mk,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Modal$closeButton(
							$rundis$elm_bootstrap$Bootstrap$Modal$getCloseMsg(conf_))
						]))));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Modal$view = F2(
	function (visibility, _v0) {
		var conf = _v0;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_Utils_ap(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$tabindex(-1)
								]),
							A2($rundis$elm_bootstrap$Bootstrap$Modal$display, visibility, conf)),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_Utils_ap(
									_List_fromArray(
										[
											A2($elm$html$Html$Attributes$attribute, 'role', 'document'),
											$elm$html$Html$Attributes$class('elm-bootstrap-modal')
										]),
									_Utils_ap(
										$rundis$elm_bootstrap$Bootstrap$Modal$modalAttributes(conf.ny),
										conf.ny.dl ? _List_fromArray(
											[
												A2(
												$elm$html$Html$Events$on,
												'click',
												$rundis$elm_bootstrap$Bootstrap$Modal$containerClickDecoder(conf.c_))
											]) : _List_Nil)),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('modal-content')
											]),
										A2(
											$elm$core$List$filterMap,
											$elm$core$Basics$identity,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Modal$renderHeader(conf),
													$rundis$elm_bootstrap$Bootstrap$Modal$renderBody(conf.$9),
													$rundis$elm_bootstrap$Bootstrap$Modal$renderFooter(conf.gy)
												])))
									]))
							]))
					]),
				A2($rundis$elm_bootstrap$Bootstrap$Modal$backdrop, visibility, conf)));
	});
var $rundis$elm_bootstrap$Bootstrap$Modal$withAnimation = F2(
	function (animateMsg, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				b6: $elm$core$Maybe$Just(animateMsg)
			});
	});
var $author$project$PlatformModal$view = function (config) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Modal$view,
		config.cT,
		A3(
			$rundis$elm_bootstrap$Bootstrap$Modal$footer,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$DeleteInfo$deletionAlert, config.b8, config.r),
							config.cL ? $author$project$PlatformModal$successButtonRow(config.ck) : $author$project$PlatformModal$buttonRow(
							{go: config.cL, iQ: config.gO, gs: true, ck: config.ck, co: config.co, V: config.V, ct: config.ct, cJ: config.cJ, cK: config.cK, lo: config.aG})
						]))
				]),
			A3(
				$rundis$elm_bootstrap$Bootstrap$Modal$body,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(config.jB)
					]),
				config.cL ? _List_fromArray(
					[
						$author$project$PlatformModal$successMessage(config.cM)
					]) : _List_fromArray(
					[
						A2(
						$elm$html$Html$map,
						config.cR,
						config.cI(config.cH))
					]),
				A3(
					$rundis$elm_bootstrap$Bootstrap$Modal$h3,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(config.lu)
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick,
						true,
						A2(
							$rundis$elm_bootstrap$Bootstrap$Modal$scrollableBody,
							true,
							$rundis$elm_bootstrap$Bootstrap$Modal$large(
								A2(
									$rundis$elm_bootstrap$Bootstrap$Modal$withAnimation,
									config.ca,
									$rundis$elm_bootstrap$Bootstrap$Modal$config(config.ck)))))))));
};
var $author$project$TxnForm$ContribRuleUnverified$CreateContribToggled = {$: 32};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml1 = $elm$html$Html$Attributes$class('ml-1');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4 = $elm$html$Html$Attributes$class('mt-4');
var $author$project$Asset$plusCircleGlyph = $author$project$Asset$glyph('fa fa-plus-circle');
var $author$project$TxnForm$ContribRuleUnverified$addContribButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4,
			$elm$html$Html$Attributes$class('text-slate-blue font-size-medium hover-underline hover-pointer'),
			$elm$html$Html$Events$onClick($author$project$TxnForm$ContribRuleUnverified$CreateContribToggled)
		]),
	_List_fromArray(
		[
			$author$project$Asset$plusCircleGlyph(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-slate-blue font-size-22')
				])),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml1,
					$elm$html$Html$Attributes$class('align-middle')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Add Contribution')
				]))
		]));
var $author$project$TxnForm$ContribRuleUnverified$addContribButtonOrHeading = function (model) {
	return model.a8 ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4,
				$elm$html$Html$Attributes$class('font-size-large'),
				$elm$html$Html$Events$onClick($author$project$TxnForm$ContribRuleUnverified$NoOp)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Create Contribution')
			])) : $author$project$TxnForm$ContribRuleUnverified$addContribButton;
};
var $author$project$TxnForm$ContribRuleUnverified$AddressLine1Updated = function (a) {
	return {$: 8, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$AddressLine2Updated = function (a) {
	return {$: 9, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$AmountUpdated = function (a) {
	return {$: 27, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CVVUpdated = function (a) {
	return {$: 26, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CardMonthUpdated = function (a) {
	return {$: 23, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CardNumberUpdated = function (a) {
	return {$: 24, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CardYearUpdated = function (a) {
	return {$: 22, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CheckNumberUpdated = function (a) {
	return {$: 28, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CityUpdated = function (a) {
	return {$: 10, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$CreateContribSubmitted = {$: 33};
var $author$project$TxnForm$ContribRuleUnverified$EmailAddressUpdated = function (a) {
	return {$: 3, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$EmployerUpdated = function (a) {
	return {$: 14, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$EmploymentStatusUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$EntityNameUpdated = function (a) {
	return {$: 16, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$EntityTypeUpdated = function (a) {
	return {$: 17, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$FirstNameUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$InKindDescUpdated = function (a) {
	return {$: 31, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$InKindTypeUpdated = function (a) {
	return {$: 30, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$LastNameUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$MiddleNameUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$OccupationUpdated = function (a) {
	return {$: 15, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$OrgOrIndUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$PaymentDateUpdated = function (a) {
	return {$: 29, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$PaymentMethodUpdated = function (a) {
	return {$: 25, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$PhoneNumberUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$PostalCodeUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$StateUpdated = function (a) {
	return {$: 11, a: a};
};
var $author$project$TxnForm$ContribRuleUnverified$ToggleEdit = {$: 1};
var $rtfeldman$elm_validate$Validate$any = F2(
	function (validators, subject) {
		any:
		while (true) {
			if (!validators.b) {
				return true;
			} else {
				var getErrors = validators.a;
				var others = validators.b;
				var _v1 = getErrors(subject);
				if (!_v1.b) {
					var $temp$validators = others,
						$temp$subject = subject;
					validators = $temp$validators;
					subject = $temp$subject;
					continue any;
				} else {
					var error = _v1.a;
					return false;
				}
			}
		}
	});
var $author$project$TxnForm$ContribRuleUnverified$exitButton = F2(
	function (hideMsg, displayText) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
					$rundis$elm_bootstrap$Bootstrap$Button$block,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(hideMsg)
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(displayText)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col4 = 4;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$lg4 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 3, 4);
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb5 = $elm$html$Html$Attributes$class('mb-5');
var $author$project$TxnForm$ContribRuleUnverified$buttonRow = F6(
	function (hideMsg, displayText, exitText, msg, submitting, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs,
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb5]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-left')
								]))
						]),
					_List_fromArray(
						[
							A2($author$project$TxnForm$ContribRuleUnverified$exitButton, hideMsg, exitText)
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg4]),
					_List_fromArray(
						[
							A4($author$project$SubmitButton$submitButton, displayText, msg, submitting, disabled)
						]))
				]));
	});
var $author$project$TxnForm$ContribRuleUnverified$dateWithFormat = function (model) {
	return (model.kE === '') ? A2(
		$author$project$Timestamp$formDate,
		$justinmimbs$timezone_data$TimeZone$america__new_york(0),
		model.ik.kE) : model.kE;
};
var $author$project$DataMsg$toData = function (_v0) {
	var data = _v0.a;
	return data;
};
var $author$project$DataMsg$toMsg = function (_v0) {
	var msg = _v0.b;
	return msg;
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs = function (a) {
	return {$: 10, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Date = 3;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$date = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(3);
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Disabled(disabled_);
};
var $author$project$AppInput$inputStyle = function (disabled) {
	return disabled ? '' : 'border-top-0 border-left-0 border-right-0 rounded-0';
};
var $author$project$AppInput$inputDate = F5(
	function (msg, val, disabled, id, label) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$date(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data-cy', id),
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(disabled))
								]))
						]))
				]));
	});
var $author$project$AppInput$inputText = F5(
	function (msg, val, disabled, id, label) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data-cy', id),
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(disabled))
								]))
						]))
				]));
	});
var $author$project$AmountDate$row = F4(
	function (_v0, _v1, disabled, label) {
		var amount = _v0.a;
		var amountMsg = _v0.b;
		var paymentDate = _v1.a;
		var paymentDateMsg = _v1.b;
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A5($author$project$AppInput$inputText, amountMsg, amount, disabled, 'paymentAmount', '*Payment Amount')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A5($author$project$AppInput$inputDate, paymentDateMsg, paymentDate, disabled, 'paymentDate', label)
						]))
				]));
	});
var $author$project$AmountDate$view = function (_v0) {
	var amount = _v0.ig;
	var paymentDate = _v0.kE;
	var disabled = _v0.iQ;
	var label = _v0.jY;
	return _List_fromArray(
		[
			A4($author$project$AmountDate$row, amount, paymentDate, disabled, label)
		]);
};
var $author$project$ContribInfo$amountDateRow = function (_v0) {
	var amount = _v0.ig;
	var paymentDate = _v0.kE;
	var disabled = _v0.iQ;
	var paymentMethod = _v0.kF;
	return $author$project$AmountDate$view(
		{
			ig: _Utils_Tuple2(
				$author$project$DataMsg$toData(amount),
				$author$project$DataMsg$toMsg(amount)),
			iQ: disabled,
			jY: '* Date Received from Donor',
			kE: _Utils_Tuple2(
				$author$project$DataMsg$toData(paymentDate),
				$author$project$DataMsg$toMsg(paymentDate))
		});
};
var $author$project$Asset$editGlyph = $author$project$Asset$glyph('fas fa-edit');
var $author$project$Asset$redoGlyph = $author$project$Asset$glyph('fas fa-redo');
var $author$project$ContribInfo$donorHeadingRow = F3(
	function (toggleMsg, disabled, isEditable) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h5,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('font-weight-bold d-inline')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Donor Info')
							])),
						isEditable ? A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('hover-underline hover-pointer align-middle'),
								$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2,
								$elm$html$Html$Events$onClick(toggleMsg)
							]),
						_List_fromArray(
							[
								disabled ? $author$project$Asset$editGlyph(_List_Nil) : $author$project$Asset$redoGlyph(_List_Nil)
							])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
					]))
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$Config = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$mapOptions = F2(
	function (mapper, _v0) {
		var conf = _v0;
		var options = conf.ny;
		return _Utils_update(
			conf,
			{
				ny: mapper(options)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup = $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$mapOptions(
	function (opts) {
		return _Utils_update(
			opts,
			{eN: true});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Checked = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$checked = function (isCheck) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Checked(isCheck);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$mapConfig = F2(
	function (mapper, _v0) {
		var configRec = _v0;
		return mapper(configRec);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$children = function (children_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$mapConfig(
		function (conf) {
			return _Utils_update(
				conf,
				{mk: children_});
		});
};
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$config = {
	mk: _List_Nil,
	eQ: $elm$core$Maybe$Nothing,
	ny: {eg: _List_Nil, iQ: false, eN: false}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Custom = {$: 5};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Radio = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$createAdvanced = F2(
	function (options, label_) {
		return {jY: label_, ny: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Label = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$label = F2(
	function (attributes, children) {
		return {eg: attributes, mk: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$create = F2(
	function (options, label_) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Radio$createAdvanced,
			options,
			A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(label_)
					])));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom = function (options) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$create(
		A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Form$Radio$Custom, options));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced = function (options) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$createAdvanced(
		A2($elm$core$List$cons, $rundis$elm_bootstrap$Bootstrap$Form$Radio$Custom, options));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Disabled = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Disabled(disabled_);
};
var $elm$html$Html$form = _VirtualDom_node('form');
var $rundis$elm_bootstrap$Bootstrap$Form$form = F2(
	function (attributes, children) {
		return A2($elm$html$Html$form, attributes, children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Id = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$id = function (theId) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Id(theId);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Inline = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$inline = $rundis$elm_bootstrap$Bootstrap$Form$Radio$Inline;
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$legend = F2(
	function (attributes, children_) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$mapConfig(
			function (conf) {
				return _Utils_update(
					conf,
					{
						eQ: $elm$core$Maybe$Just(
							A2($elm$html$Html$legend, attributes, children_))
					});
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$OnClick = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$OnClick(toMsg);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$addOption = F2(
	function (opt, _v0) {
		var radio_ = _v0;
		var options = radio_.ny;
		return _Utils_update(
			radio_,
			{
				ny: A2($elm$core$List$cons, opt, options)
			});
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$Name = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$name = function (name_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Radio$Name(name_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						jB: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{ep: val});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						nq: $elm$core$Maybe$Just(val)
					});
			case 2:
				return _Utils_update(
					options,
					{dn: true});
			case 4:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						e9: $elm$core$Maybe$Just(toMsg)
					});
			case 5:
				return _Utils_update(
					options,
					{ad: true});
			case 6:
				var val = modifier.a;
				return _Utils_update(
					options,
					{iQ: val});
			case 7:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						f$: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$defaultOptions = {eg: _List_Nil, ep: false, ad: false, iQ: false, jB: $elm$core$Maybe$Nothing, dn: false, nq: $elm$core$Maybe$Nothing, e9: $elm$core$Maybe$Nothing, f$: $elm$core$Maybe$Nothing};
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.ad),
						_Utils_Tuple2('custom-control-input', options.ad)
					])),
				$elm$html$Html$Attributes$type_('radio'),
				$elm$html$Html$Attributes$disabled(options.iQ),
				$elm$html$Html$Attributes$checked(options.ep)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onClick, options.e9),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$name, options.nq),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.jB)
					])),
			options.eg));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$view = function (_v0) {
	var radio_ = _v0;
	var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Radio$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Radio$defaultOptions, radio_.ny);
	var _v1 = radio_.jY;
	var label_ = _v1;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.ad),
						_Utils_Tuple2('form-check-inline', (!opts.ad) && opts.dn),
						_Utils_Tuple2('custom-control', opts.ad),
						_Utils_Tuple2('custom-radio', opts.ad),
						_Utils_Tuple2('custom-control-inline', opts.dn && opts.ad)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$toAttributes(opts),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_Utils_ap(
					label_.eg,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-check-label', !opts.ad),
										_Utils_Tuple2('custom-control-label', opts.ad)
									]))
							]),
						function () {
							var _v2 = opts.jB;
							if (!_v2.$) {
								var v = _v2.a;
								return _List_fromArray(
									[
										$elm$html$Html$Attributes$for(v)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				label_.mk)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList = F2(
	function (groupName, radios) {
		return A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeL,
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$view,
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$addOption(
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$name(groupName))),
			radios);
	});
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $rundis$elm_bootstrap$Bootstrap$Form$Fieldset$view = function (_v0) {
	var rec = _v0;
	var options = rec.ny;
	return A2(
		$elm$html$Html$fieldset,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-group', options.eN)
						])),
					$elm$html$Html$Attributes$disabled(options.iQ)
				]),
			options.eg),
		function (xs) {
			return A2($elm$core$List$append, xs, rec.mk);
		}(
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					function (e) {
						return _List_fromArray(
							[e]);
					},
					rec.eQ))));
};
var $author$project$EntityType$familyRadioList = F4(
	function (msg, currentValue, disabled, txnId) {
		var id = A2($elm$core$Maybe$withDefault, '', txnId);
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$form,
				_List_Nil,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$view(
						A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$children,
							A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
								'candidateRelationship',
								_List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'ind'),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
												msg(1)),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
												_Utils_eq(
													currentValue,
													$elm$core$Maybe$Just(1)))
											]),
										'Not Related'),
										A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'can'),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
												msg(5)),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
												_Utils_eq(
													currentValue,
													$elm$core$Maybe$Just(5)))
											]),
										'The candidate or spouse of the candidate'),
										A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'fam'),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
												msg(0)),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
												_Utils_eq(
													currentValue,
													$elm$core$Maybe$Just(0)))
											]),
										A2(
											$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
											_List_Nil,
											_List_fromArray(
												[
													$elm$html$Html$text('Family member* of the candidate'),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]),
													_List_fromArray(
														[
															$elm$html$Html$text('*Defined as the candidate\'s child, parent, grandparent, brother, or sister of any such persons ')
														]))
												])))
									])),
							A3(
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
								_List_Nil,
								_List_Nil,
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($rundis$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
					]))
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col8 = 8;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs8 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 8);
var $author$project$ContribInfo$familyRow = function (_v0) {
	var maybeEntityType = _v0.kb;
	var disabled = _v0.iQ;
	var txnId = _v0.fS;
	var maybeEntityMsg = $author$project$DataMsg$toMsg(maybeEntityType);
	var entityMsg = A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, maybeEntityMsg);
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Is the donor a family member of the candidate that will receive this contribution?')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs8]),
					A4(
						$author$project$EntityType$familyRadioList,
						entityMsg,
						$author$project$DataMsg$toData(maybeEntityType),
						disabled,
						txnId))
				]))
		]);
};
var $rundis$elm_bootstrap$Bootstrap$Button$outlineSuccess = $rundis$elm_bootstrap$Bootstrap$Internal$Button$Coloring(
	$rundis$elm_bootstrap$Bootstrap$Internal$Button$Outlined(2));
var $author$project$OrgOrInd$selectButton = F5(
	function (msg, displayText, value, currentVal, disabled) {
		var selected = _Utils_eq(currentVal, value);
		var color = selected ? $rundis$elm_bootstrap$Bootstrap$Button$success : $rundis$elm_bootstrap$Bootstrap$Button$outlineSuccess;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Button$button,
					_List_fromArray(
						[
							color,
							$rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$id(displayText)
								])),
							$rundis$elm_bootstrap$Bootstrap$Button$block,
							$rundis$elm_bootstrap$Bootstrap$Button$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-weight-bold border-round')
								])),
							$rundis$elm_bootstrap$Bootstrap$Button$onClick(
							msg(value)),
							$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(displayText)
						]))
				]));
	});
var $author$project$OrgOrInd$toString = function (orgOrInd) {
	if (!orgOrInd) {
		return 'Organization';
	} else {
		return 'Individual';
	}
};
var $author$project$OrgOrInd$row = F3(
	function (msg, currentValue, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A5(
							$author$project$OrgOrInd$selectButton,
							msg,
							$author$project$OrgOrInd$toString(1),
							$elm$core$Maybe$Just(1),
							currentValue,
							disabled)
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A5(
							$author$project$OrgOrInd$selectButton,
							msg,
							$author$project$OrgOrInd$toString(0),
							$elm$core$Maybe$Just(0),
							currentValue,
							disabled)
						]))
				]));
	});
var $author$project$ContribInfo$orgOrIndRow = function (_v0) {
	var maybeOrgOrInd = _v0.ni;
	var disabled = _v0.iQ;
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Will the donor be contributing as an individual or on behalf of an organization?')
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$OrgOrInd$row,
							$author$project$DataMsg$toMsg(maybeOrgOrInd),
							$author$project$DataMsg$toData(maybeOrgOrInd),
							disabled)
						]))
				]))
		]);
};
var $rundis$elm_bootstrap$Bootstrap$General$Internal$MD = 2;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$md4 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 2, 4);
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs = function (a) {
	return {$: 6, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Disabled = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$disabled = function (disabled_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Disabled(disabled_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Id = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$id = function (id_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$Id(id_);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Item = $elm$core$Basics$identity;
var $elm$html$Html$option = _VirtualDom_node('option');
var $rundis$elm_bootstrap$Bootstrap$Form$Select$item = F2(
	function (attributes, children) {
		return A2($elm$html$Html$option, attributes, children);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$OnChange = function (a) {
	return {$: 4, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$onChange = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Select$OnChange(toMsg);
};
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $author$project$EntityType$toDisplayString = function (entityType) {
	switch (entityType) {
		case 0:
			return 'Family';
		case 1:
			return 'Individual';
		case 2:
			return 'Sole Proprietorship';
		case 3:
			return 'Partnership including LLPs';
		case 5:
			return 'Candidate';
		case 4:
			return 'Corporation';
		case 6:
			return 'Union';
		case 7:
			return 'Association';
		case 8:
			return 'Professional/Limited Liability Company';
		case 9:
			return 'Political Action Committee';
		case 10:
			return 'Political Committee';
		default:
			return 'Other';
	}
};
var $author$project$EntityType$orgSelect = F2(
	function (entityType, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$value(
					$author$project$EntityType$toDataString(entityType)),
					$elm$html$Html$Attributes$selected(
					_Utils_eq(
						$elm$core$Maybe$Just(entityType),
						currentValue))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$EntityType$toDisplayString(entityType))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$Select = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Select$create = F2(
	function (options, items) {
		return {jW: items, ny: options};
	});
var $elm$html$Html$select = _VirtualDom_node('select');
var $rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var size_ = modifier.a;
				return _Utils_update(
					options,
					{
						hE: $elm$core$Maybe$Just(size_)
					});
			case 1:
				var id_ = modifier.a;
				return _Utils_update(
					options,
					{
						jB: $elm$core$Maybe$Just(id_)
					});
			case 2:
				return _Utils_update(
					options,
					{ad: true});
			case 3:
				var val = modifier.a;
				return _Utils_update(
					options,
					{iQ: val});
			case 4:
				var onChange_ = modifier.a;
				return _Utils_update(
					options,
					{
						e7: $elm$core$Maybe$Just(onChange_)
					});
			case 5:
				var validation_ = modifier.a;
				return _Utils_update(
					options,
					{
						f$: $elm$core$Maybe$Just(validation_)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions = {eg: _List_Nil, ad: false, iQ: false, jB: $elm$core$Maybe$Nothing, e7: $elm$core$Maybe$Nothing, hE: $elm$core$Maybe$Nothing, f$: $elm$core$Maybe$Nothing};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute = F2(
	function (isCustom, size_) {
		var prefix = isCustom ? 'custom-select-' : 'form-control-';
		return A2(
			$elm$core$Maybe$map,
			function (s) {
				return $elm$html$Html$Attributes$class(
					_Utils_ap(prefix, s));
			},
			$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption(size_));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute = function (validation_) {
	return $elm$html$Html$Attributes$class(
		$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(validation_));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes = function (modifiers) {
	var options = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Select$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Select$defaultOptions, modifiers);
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-control', !options.ad),
						_Utils_Tuple2('custom-select', options.ad)
					])),
				$elm$html$Html$Attributes$disabled(options.iQ)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.jB),
						A2(
						$elm$core$Maybe$andThen,
						$rundis$elm_bootstrap$Bootstrap$Form$Select$sizeAttribute(options.ad),
						options.hE),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Select$customEventOnChange, options.e7),
						A2($elm$core$Maybe$map, $rundis$elm_bootstrap$Bootstrap$Form$Select$validationAttribute, options.f$)
					])),
			options.eg));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$view = function (_v0) {
	var options = _v0.ny;
	var items = _v0.jW;
	return A2(
		$elm$html$Html$select,
		$rundis$elm_bootstrap$Bootstrap$Form$Select$toAttributes(options),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var e = _v1;
				return e;
			},
			items));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Select$select = F2(
	function (options, items) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Select$view(
			A2($rundis$elm_bootstrap$Bootstrap$Form$Select$create, options, items));
	});
var $author$project$EntityType$orgView = F3(
	function (msg, currentValue, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Classification')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Select$id('entityType'),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(
							A2($elm$core$Basics$composeR, $author$project$EntityType$fromString, msg)),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(disabled))
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('--Classification--')
								])),
							A2($author$project$EntityType$orgSelect, 2, currentValue),
							A2($author$project$EntityType$orgSelect, 3, currentValue),
							A2($author$project$EntityType$orgSelect, 4, currentValue),
							A2($author$project$EntityType$orgSelect, 6, currentValue),
							A2($author$project$EntityType$orgSelect, 7, currentValue),
							A2($author$project$EntityType$orgSelect, 8, currentValue),
							A2($author$project$EntityType$orgSelect, 9, currentValue),
							A2($author$project$EntityType$orgSelect, 10, currentValue),
							A2($author$project$EntityType$orgSelect, 11, currentValue)
						]))
				]));
	});
var $author$project$OwnersView$AddOwner = {$: 11};
var $author$project$OwnersView$OwnerFirstNameUpdated = function (a) {
	return {$: 0, a: a};
};
var $author$project$OwnersView$OwnerLastNameUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$OwnersView$OwnerOwnershipUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$OwnersView$OwnersUpdate = {$: 8};
var $author$project$OwnersView$ToggleOwnerForm = function (a) {
	return {$: 12, a: a};
};
var $author$project$OwnersView$CreatingOwner = 1;
var $author$project$OwnersView$addOwnerButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4,
			$elm$html$Html$Attributes$class('text-slate-blue font-size-medium hover-underline hover-pointer'),
			$elm$html$Html$Events$onClick(
			$author$project$OwnersView$ToggleOwnerForm(
				$elm$core$Maybe$Just(1)))
		]),
	_List_fromArray(
		[
			$author$project$Asset$plusCircleGlyph(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-slate-blue font-size-22')
				])),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml1,
					$elm$html$Html$Attributes$class('align-middle'),
					A2($elm$html$Html$Attributes$attribute, 'data-cy', 'addOwner')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Add Owner')
				]))
		]));
var $author$project$OwnersView$addOwnerRow = _List_fromArray(
	[
		A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[$author$project$OwnersView$addOwnerButton]))
			]))
	]);
var $author$project$OwnersView$cancelButton = F3(
	function (msg, disabled, btnName) {
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$success,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg),
						$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled),
						$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(btnName)
					]))
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Table$Inversed = {$: 0};
var $rundis$elm_bootstrap$Bootstrap$Table$isResponsive = function (option) {
	if (option.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$KeyedTBody = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$TBody = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$InversedRow = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Row = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$InversedCell = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Td = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$Th = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell = function (cell) {
	var inverseOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $rundis$elm_bootstrap$Bootstrap$Table$InversedCell(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (cell.$ === 1) {
		var cellCfg = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellCfg,
				{
					ny: inverseOptions(cellCfg.ny)
				}));
	} else {
		var cellCfg = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Td(
			_Utils_update(
				cellCfg,
				{
					ny: inverseOptions(cellCfg.ny)
				}));
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow = function (row) {
	var inversedOptions = function (options) {
		return A2(
			$elm$core$List$map,
			function (opt) {
				if (!opt.$) {
					var role = opt.a;
					return $rundis$elm_bootstrap$Bootstrap$Table$InversedRow(role);
				} else {
					return opt;
				}
			},
			options);
	};
	if (!row.$) {
		var options = row.a.ny;
		var cells = row.a.R;
		return $rundis$elm_bootstrap$Bootstrap$Table$Row(
			{
				R: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell, cells),
				ny: inversedOptions(options)
			});
	} else {
		var options = row.a.ny;
		var cells = row.a.R;
		return $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow(
			{
				R: A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var cell = _v1.b;
						return _Utils_Tuple2(
							key,
							$rundis$elm_bootstrap$Bootstrap$Table$mapInversedCell(cell));
					},
					cells),
				ny: inversedOptions(options)
			});
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody = F2(
	function (isTableInversed, tbody_) {
		var _v0 = _Utils_Tuple2(isTableInversed, tbody_);
		if (!_v0.a) {
			return tbody_;
		} else {
			if (!_v0.b.$) {
				var body = _v0.b.a;
				return $rundis$elm_bootstrap$Bootstrap$Table$TBody(
					_Utils_update(
						body,
						{
							fD: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow, body.fD)
						}));
			} else {
				var keyedBody = _v0.b.a;
				return $rundis$elm_bootstrap$Bootstrap$Table$KeyedTBody(
					_Utils_update(
						keyedBody,
						{
							fD: A2(
								$elm$core$List$map,
								function (_v1) {
									var key = _v1.a;
									var row = _v1.b;
									return _Utils_Tuple2(
										key,
										$rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow(row));
								},
								keyedBody.fD)
						}));
			}
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Table$InversedHead = {$: 0};
var $rundis$elm_bootstrap$Bootstrap$Table$THead = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead = F2(
	function (isTableInversed, _v0) {
		var thead_ = _v0;
		var isHeadInversed = A2(
			$elm$core$List$any,
			function (opt) {
				return _Utils_eq(opt, $rundis$elm_bootstrap$Bootstrap$Table$InversedHead);
			},
			thead_.ny);
		return (isTableInversed || isHeadInversed) ? _Utils_update(
			thead_,
			{
				fD: A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$mapInversedRow, thead_.fD)
			}) : thead_;
	});
var $rundis$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive = F2(
	function (options, table_) {
		var responsiveClass = $elm$html$Html$Attributes$class(
			'table-responsive' + A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function (v) {
						return '-' + v;
					},
					A2(
						$elm$core$Maybe$andThen,
						$rundis$elm_bootstrap$Bootstrap$General$Internal$screenSizeOption,
						A2(
							$elm$core$Maybe$andThen,
							function (opt) {
								if (opt.$ === 5) {
									var val = opt.a;
									return val;
								} else {
									return $elm$core$Maybe$Nothing;
								}
							},
							$elm$core$List$head(
								A2($elm$core$List$filter, $rundis$elm_bootstrap$Bootstrap$Table$isResponsive, options)))))));
		return A2($elm$core$List$any, $rundis$elm_bootstrap$Bootstrap$Table$isResponsive, options) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[responsiveClass]),
			_List_fromArray(
				[table_])) : table_;
	});
var $rundis$elm_bootstrap$Bootstrap$Table$CellAttr = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttr = function (attr_) {
	return $rundis$elm_bootstrap$Bootstrap$Table$CellAttr(attr_);
};
var $elm$html$Html$Attributes$scope = $elm$html$Html$Attributes$stringProperty('scope');
var $rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh = function (cell) {
	if (cell.$ === 1) {
		var cellConfig = cell.a;
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			_Utils_update(
				cellConfig,
				{
					ny: A2(
						$elm$core$List$cons,
						$rundis$elm_bootstrap$Bootstrap$Table$cellAttr(
							$elm$html$Html$Attributes$scope('row')),
						cellConfig.ny)
				}));
	} else {
		return cell;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell = function (row) {
	if (!row.$) {
		var options = row.a.ny;
		var cells = row.a.R;
		if (!cells.b) {
			return row;
		} else {
			var first = cells.a;
			var rest = cells.b;
			return $rundis$elm_bootstrap$Bootstrap$Table$Row(
				{
					R: A2(
						$elm$core$List$cons,
						$rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first),
						rest),
					ny: options
				});
		}
	} else {
		var options = row.a.ny;
		var cells = row.a.R;
		if (!cells.b) {
			return row;
		} else {
			var _v3 = cells.a;
			var firstKey = _v3.a;
			var first = _v3.b;
			var rest = cells.b;
			return $rundis$elm_bootstrap$Bootstrap$Table$KeyedRow(
				{
					R: A2(
						$elm$core$List$cons,
						_Utils_Tuple2(
							firstKey,
							$rundis$elm_bootstrap$Bootstrap$Table$addScopeIfTh(first)),
						rest),
					ny: options
				});
		}
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttribute = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role);
			} else {
				var _v1 = option.a;
				return $elm$html$Html$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg-', role);
			} else {
				var _v2 = option.a;
				return $elm$html$Html$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$cellAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$cellAttribute, options);
};
var $elm$html$Html$td = _VirtualDom_node('td');
var $elm$html$Html$th = _VirtualDom_node('th');
var $rundis$elm_bootstrap$Bootstrap$Table$renderCell = function (cell) {
	if (!cell.$) {
		var options = cell.a.ny;
		var children = cell.a.mk;
		return A2(
			$elm$html$Html$td,
			$rundis$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	} else {
		var options = cell.a.ny;
		var children = cell.a.mk;
		return A2(
			$elm$html$Html$th,
			$rundis$elm_bootstrap$Bootstrap$Table$cellAttributes(options),
			children);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowClass = function (option) {
	switch (option.$) {
		case 0:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'table', role_);
			} else {
				var _v1 = option.a;
				return $elm$html$Html$Attributes$class('table-active');
			}
		case 1:
			if (!option.a.$) {
				var role_ = option.a.a;
				return A2($rundis$elm_bootstrap$Bootstrap$Internal$Role$toClass, 'bg', role_);
			} else {
				var _v2 = option.a;
				return $elm$html$Html$Attributes$class('bg-active');
			}
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$rowClass, options);
};
var $elm$html$Html$tr = _VirtualDom_node('tr');
var $rundis$elm_bootstrap$Bootstrap$Table$renderRow = function (row) {
	if (!row.$) {
		var options = row.a.ny;
		var cells = row.a.R;
		return A2(
			$elm$html$Html$tr,
			$rundis$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$renderCell, cells));
	} else {
		var options = row.a.ny;
		var cells = row.a.R;
		return A3(
			$elm$html$Html$Keyed$node,
			'tr',
			$rundis$elm_bootstrap$Bootstrap$Table$rowAttributes(options),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var cell = _v1.b;
					return _Utils_Tuple2(
						key,
						$rundis$elm_bootstrap$Bootstrap$Table$renderCell(cell));
				},
				cells));
	}
};
var $elm$html$Html$tbody = _VirtualDom_node('tbody');
var $rundis$elm_bootstrap$Bootstrap$Table$renderTBody = function (body) {
	if (!body.$) {
		var attributes = body.a.eg;
		var rows = body.a.fD;
		return A2(
			$elm$html$Html$tbody,
			attributes,
			A2(
				$elm$core$List$map,
				function (row) {
					return $rundis$elm_bootstrap$Bootstrap$Table$renderRow(
						$rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row));
				},
				rows));
	} else {
		var attributes = body.a.eg;
		var rows = body.a.fD;
		return A3(
			$elm$html$Html$Keyed$node,
			'tbody',
			attributes,
			A2(
				$elm$core$List$map,
				function (_v1) {
					var key = _v1.a;
					var row = _v1.b;
					return _Utils_Tuple2(
						key,
						$rundis$elm_bootstrap$Bootstrap$Table$renderRow(
							$rundis$elm_bootstrap$Bootstrap$Table$maybeAddScopeToFirstCell(row)));
				},
				rows));
	}
};
var $elm$html$Html$thead = _VirtualDom_node('thead');
var $rundis$elm_bootstrap$Bootstrap$Table$theadAttribute = function (option) {
	switch (option.$) {
		case 0:
			return $elm$html$Html$Attributes$class('thead-dark');
		case 1:
			return $elm$html$Html$Attributes$class('thead-default');
		default:
			var attr_ = option.a;
			return attr_;
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$theadAttributes = function (options) {
	return A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$theadAttribute, options);
};
var $rundis$elm_bootstrap$Bootstrap$Table$renderTHead = function (_v0) {
	var options = _v0.ny;
	var rows = _v0.fD;
	return A2(
		$elm$html$Html$thead,
		$rundis$elm_bootstrap$Bootstrap$Table$theadAttributes(options),
		A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$renderRow, rows));
};
var $elm$html$Html$table = _VirtualDom_node('table');
var $rundis$elm_bootstrap$Bootstrap$Table$tableClass = function (option) {
	switch (option.$) {
		case 0:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-dark'));
		case 1:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-striped'));
		case 2:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-bordered'));
		case 3:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-hover'));
		case 4:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-sm'));
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$class('table-reflow'));
		default:
			var attr_ = option.a;
			return $elm$core$Maybe$Just(attr_);
	}
};
var $rundis$elm_bootstrap$Bootstrap$Table$tableAttributes = function (options) {
	return A2(
		$elm$core$List$cons,
		$elm$html$Html$Attributes$class('table'),
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Table$tableClass, options)));
};
var $rundis$elm_bootstrap$Bootstrap$Table$table = function (rec) {
	var isInversed = A2(
		$elm$core$List$any,
		function (opt) {
			return _Utils_eq(opt, $rundis$elm_bootstrap$Bootstrap$Table$Inversed);
		},
		rec.ny);
	var classOptions = A2(
		$elm$core$List$filter,
		function (opt) {
			return !$rundis$elm_bootstrap$Bootstrap$Table$isResponsive(opt);
		},
		rec.ny);
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$maybeWrapResponsive,
		rec.ny,
		A2(
			$elm$html$Html$table,
			$rundis$elm_bootstrap$Bootstrap$Table$tableAttributes(classOptions),
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Table$renderTHead(
					A2($rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTHead, isInversed, rec.n_)),
					$rundis$elm_bootstrap$Bootstrap$Table$renderTBody(
					A2($rundis$elm_bootstrap$Bootstrap$Table$maybeMapInversedTBody, isInversed, rec.nY))
				])));
};
var $rundis$elm_bootstrap$Bootstrap$Table$simpleTable = function (_v0) {
	var thead_ = _v0.a;
	var tbody_ = _v0.b;
	return $rundis$elm_bootstrap$Bootstrap$Table$table(
		{ny: _List_Nil, nY: tbody_, n_: thead_});
};
var $author$project$OwnersView$EditOwner = function (a) {
	return {$: 10, a: a};
};
var $author$project$OwnersView$OwnerDeleted = function (a) {
	return {$: 9, a: a};
};
var $author$project$Asset$deleteGlyph = $author$project$Asset$glyph('fas fa-trash');
var $author$project$Owners$getOwnerFullName = function (owner) {
	return owner.jh + (' ' + owner.j_);
};
var $rundis$elm_bootstrap$Bootstrap$Table$tbody = F2(
	function (attributes, rows) {
		return $rundis$elm_bootstrap$Bootstrap$Table$TBody(
			{eg: attributes, fD: rows});
	});
var $rundis$elm_bootstrap$Bootstrap$Table$td = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Td(
			{mk: children, ny: options});
	});
var $rundis$elm_bootstrap$Bootstrap$Table$tr = F2(
	function (options, cells) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Row(
			{R: cells, ny: options});
	});
var $author$project$OwnersView$tableBody = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$tbody,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (owner) {
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tr,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Owners$getOwnerFullName(owner))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(owner.dD + '%')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									model.iQ ? $elm$html$Html$text('') : A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$OwnersView$EditOwner(owner))
										]),
									_List_fromArray(
										[
											$author$project$Asset$editGlyph(
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hover-pointer')
												]))
										]))
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Table$td,
							_List_Nil,
							_List_fromArray(
								[
									model.iQ ? $elm$html$Html$text('') : A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(
											$author$project$OwnersView$OwnerDeleted(owner))
										]),
									_List_fromArray(
										[
											$author$project$Asset$deleteGlyph(
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('text-danger hover-pointer')
												]))
										]))
								]))
						]));
			},
			model.ku));
};
var $rundis$elm_bootstrap$Bootstrap$Table$th = F2(
	function (options, children) {
		return $rundis$elm_bootstrap$Bootstrap$Table$Th(
			{mk: children, ny: options});
	});
var $author$project$OwnersView$emptyTableHead = A2(
	$rundis$elm_bootstrap$Bootstrap$Table$th,
	_List_Nil,
	_List_fromArray(
		[
			$elm$html$Html$text('')
		]));
var $rundis$elm_bootstrap$Bootstrap$Table$thead = F2(
	function (options, rows) {
		return {ny: options, fD: rows};
	});
var $rundis$elm_bootstrap$Bootstrap$Table$simpleThead = function (cells) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$thead,
		_List_Nil,
		_List_fromArray(
			[
				A2($rundis$elm_bootstrap$Bootstrap$Table$tr, _List_Nil, cells)
			]));
};
var $author$project$OwnersView$tableHead = $rundis$elm_bootstrap$Bootstrap$Table$simpleThead(
	_List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Name')
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Table$th,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Percent Ownership')
				])),
			$author$project$OwnersView$emptyTableHead,
			$author$project$OwnersView$emptyTableHead
		]));
var $author$project$OwnersView$capTable = function (model) {
	return ($elm$core$List$length(model.ku) > 0) ? A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Table$simpleTable(
				_Utils_Tuple2(
					$author$project$OwnersView$tableHead,
					$author$project$OwnersView$tableBody(model)))
			])) : A2($elm$html$Html$div, _List_Nil, _List_Nil);
};
var $author$project$OwnersView$errorMessages = function (errors) {
	return (!$elm$core$List$length(errors)) ? _List_Nil : _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							A2(
								$elm$core$List$map,
								function (error) {
									return A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('text-danger list-unstyled mt-2'),
												$elm$html$Html$Attributes$id('errors')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(error)
											]));
								},
								errors))
						]))
				]))
		]);
};
var $author$project$AppInput$inputNumber = F5(
	function (msg, val, disabled, id, label) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$number(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data-cy', id),
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(disabled))
								]))
						]))
				]));
	});
var $author$project$Copy$llcDialogue = function (disabled) {
	return disabled ? $elm$html$Html$text('Ownership Breakdown') : $elm$html$Html$text('Please specify the current ownership breakdown of your company.');
};
var $author$project$OwnersView$OwnerAddressLine1Updated = function (a) {
	return {$: 2, a: a};
};
var $author$project$OwnersView$OwnerAddressLine2Updated = function (a) {
	return {$: 3, a: a};
};
var $author$project$OwnersView$OwnerCityUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$OwnersView$OwnerPostalCodeUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$OwnersView$OwnerStateUpdated = function (a) {
	return {$: 5, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Grid$Row$centerLg = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowHAlign, 3, 1);
var $rundis$elm_bootstrap$Bootstrap$Form$Col = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$col = F2(
	function (options, children) {
		return {mk: children, i$: $elm$html$Html$div, ny: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 3, 6);
var $rundis$elm_bootstrap$Bootstrap$Form$renderCol = function (_v0) {
	var elemFn = _v0.i$;
	var options = _v0.ny;
	var children = _v0.mk;
	return A2(
		elemFn,
		$rundis$elm_bootstrap$Bootstrap$Grid$Internal$colAttributes(options),
		children);
};
var $rundis$elm_bootstrap$Bootstrap$Form$row = F2(
	function (options, cols) {
		return A2(
			$elm$html$Html$div,
			A2(
				$elm$core$List$cons,
				$elm$html$Html$Attributes$class('form-group'),
				$rundis$elm_bootstrap$Bootstrap$Grid$Internal$rowAttributes(options)),
			A2($elm$core$List$map, $rundis$elm_bootstrap$Bootstrap$Form$renderCol, cols));
	});
var $author$project$State$statesAndAbbrsList = _List_fromArray(
	[
		_Utils_Tuple2('AL', 'Alabama'),
		_Utils_Tuple2('AK', 'Alaska'),
		_Utils_Tuple2('AS', 'American Samoa'),
		_Utils_Tuple2('AZ', 'Arizona'),
		_Utils_Tuple2('AR', 'Arkansas'),
		_Utils_Tuple2('CA', 'California'),
		_Utils_Tuple2('CO', 'Colorado'),
		_Utils_Tuple2('CT', 'Connecticut'),
		_Utils_Tuple2('DE', 'Delaware'),
		_Utils_Tuple2('DC', 'District of Columbia'),
		_Utils_Tuple2('FL', 'Florida'),
		_Utils_Tuple2('GA', 'Georgia'),
		_Utils_Tuple2('GU', 'Guam'),
		_Utils_Tuple2('HI', 'Hawaii'),
		_Utils_Tuple2('ID', 'Idaho'),
		_Utils_Tuple2('IL', 'Illinois'),
		_Utils_Tuple2('IN', 'Indiana'),
		_Utils_Tuple2('IA', 'Iowa'),
		_Utils_Tuple2('KS', 'Kansas'),
		_Utils_Tuple2('KY', 'Kentucky'),
		_Utils_Tuple2('LA', 'Louisiana'),
		_Utils_Tuple2('ME', 'Maine'),
		_Utils_Tuple2('MD', 'Maryland'),
		_Utils_Tuple2('MA', 'Massachusetts'),
		_Utils_Tuple2('MI', 'Michigan'),
		_Utils_Tuple2('MN', 'Minnesota'),
		_Utils_Tuple2('MS', 'Mississippi'),
		_Utils_Tuple2('MO', 'Missouri'),
		_Utils_Tuple2('MT', 'Montana'),
		_Utils_Tuple2('NE', 'Nebraska'),
		_Utils_Tuple2('NV', 'Nevada'),
		_Utils_Tuple2('NH', 'New Hampshire'),
		_Utils_Tuple2('NJ', 'New Jersey'),
		_Utils_Tuple2('NM', 'New Mexico'),
		_Utils_Tuple2('NY', 'New York'),
		_Utils_Tuple2('NC', 'North Carolina'),
		_Utils_Tuple2('ND', 'North Dakota'),
		_Utils_Tuple2('MP', 'Northern Mariana Islands'),
		_Utils_Tuple2('OH', 'Ohio'),
		_Utils_Tuple2('OK', 'Oklahoma'),
		_Utils_Tuple2('OR', 'Oregon'),
		_Utils_Tuple2('PA', 'Pennsylvania'),
		_Utils_Tuple2('PR', 'Puerto Rico'),
		_Utils_Tuple2('RI', 'Rhode Island'),
		_Utils_Tuple2('SC', 'South Carolina'),
		_Utils_Tuple2('SD', 'South Dakota'),
		_Utils_Tuple2('TN', 'Tennessee'),
		_Utils_Tuple2('TX', 'Texas'),
		_Utils_Tuple2('UT', 'Utah'),
		_Utils_Tuple2('VT', 'Vermont'),
		_Utils_Tuple2('VI', 'Virgin Islands'),
		_Utils_Tuple2('VA', 'Virginia'),
		_Utils_Tuple2('WA', 'Washington'),
		_Utils_Tuple2('WV', 'West Virginia'),
		_Utils_Tuple2('WI', 'Wisconsin'),
		_Utils_Tuple2('WY', 'Wyoming')
	]);
var $author$project$State$view = F4(
	function (msg, currentValue, isDisabled, id) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('*State')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Select$id('State'),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(msg),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$disabled(isDisabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data-cy', id + 'state'),
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(isDisabled))
								]))
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value('')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('-- State --')
									]))
							]),
						A2(
							$elm$core$List$map,
							function (_v0) {
								var abbr = _v0.a;
								var whole = _v0.b;
								return A2(
									$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$value(abbr),
											$elm$html$Html$Attributes$selected(
											_Utils_eq(currentValue, abbr) || _Utils_eq(
												$elm$core$String$toUpper(currentValue),
												abbr))
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(whole)
										]));
							},
							$author$project$State$statesAndAbbrsList)))
				]));
	});
var $author$project$Address$rows = F7(
	function (_v0, _v1, _v2, _v3, _v4, disabled, id) {
		var addressLine1 = _v0.a;
		var address1Msg = _v0.b;
		var addressLine2 = _v1.a;
		var address2Msg = _v1.b;
		var city = _v2.a;
		var cityMsg = _v2.b;
		var state = _v3.a;
		var stateMsg = _v3.b;
		var postalCode = _v4.a;
		var postalCodeMsg = _v4.b;
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$form,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$row,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Grid$Row$centerLg,
								$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2,
										A2($elm$html$Html$Attributes$attribute, 'id', id + 'addressRow')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6]),
								_List_fromArray(
									[
										A5($author$project$AppInput$inputText, address1Msg, addressLine1, disabled, id + 'addressLine1', '*Street Address')
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6]),
								_List_fromArray(
									[
										A5($author$project$AppInput$inputText, address2Msg, addressLine2, disabled, id + 'addressLine2', 'Secondary Address')
									]))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$row,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Grid$Row$centerLg,
								$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
							]),
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg6]),
								_List_fromArray(
									[
										A5($author$project$AppInput$inputText, cityMsg, city, disabled, id + 'city', '*City')
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3]),
								_List_fromArray(
									[
										A4($author$project$State$view, stateMsg, state, disabled, id)
									])),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$col,
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3]),
								_List_fromArray(
									[
										A5($author$project$AppInput$inputText, postalCodeMsg, postalCode, disabled, id + 'postalCode', '*Postal Code')
									]))
							]))
					]))
			]);
	});
var $author$project$Address$view = function (_v0) {
	var addressLine1 = _v0.h9;
	var addressLine2 = _v0.ia;
	var city = _v0.iw;
	var state = _v0.ln;
	var postalCode = _v0.kM;
	var disabled = _v0.iQ;
	var id = _v0.jB;
	return A7($author$project$Address$rows, addressLine1, addressLine2, city, state, postalCode, disabled, id);
};
var $author$project$OwnersView$ownerAddressRows = function (model) {
	return $author$project$Address$view(
		{
			h9: _Utils_Tuple2(model.h9, $author$project$OwnersView$OwnerAddressLine1Updated),
			ia: _Utils_Tuple2(model.ia, $author$project$OwnersView$OwnerAddressLine2Updated),
			iw: _Utils_Tuple2(model.iw, $author$project$OwnersView$OwnerCityUpdated),
			iQ: model.iQ,
			jB: 'createOwner',
			kM: _Utils_Tuple2(model.kM, $author$project$OwnersView$OwnerPostalCodeUpdated),
			ln: _Utils_Tuple2(model.ln, $author$project$OwnersView$OwnerStateUpdated)
		});
};
var $author$project$OwnersView$ownersSubmitButton = F2(
	function (msg, btnName) {
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$success,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg),
						$rundis$elm_bootstrap$Bootstrap$Button$disabled(false),
						$rundis$elm_bootstrap$Bootstrap$Button$block
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(btnName)
					]))
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p2 = $elm$html$Html$Attributes$class('p-2');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl4 = $elm$html$Html$Attributes$class('pl-4');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr4 = $elm$html$Html$Attributes$class('pr-4');
var $author$project$OwnersView$saveButton = F4(
	function (msg, model, disabled, btnName) {
		var totalOwnership = $author$project$Owners$foldOwnership(model.ku) + A2(
			$elm$core$Maybe$withDefault,
			0,
			$elm$core$String$toFloat(model.dD));
		var disabledSave = (totalOwnership - $author$project$Owners$ownershipToFloat(model.c1)) > 100;
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Button$button,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Button$success,
						$rundis$elm_bootstrap$Bootstrap$Button$onClick(msg),
						$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled || disabledSave)
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(btnName)
					]))
			]);
	});
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col2 = 2;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 2);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 4);
var $author$project$OwnersView$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb4,
				$elm$html$Html$Attributes$class('border rounded'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p2,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl4,
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr4,
				$elm$html$Html$Attributes$id('owners-view')
			]),
		_Utils_ap(
			_List_Nil,
			_Utils_ap(
				$author$project$OwnersView$errorMessages(model.gv),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
								]),
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_Nil,
									_List_fromArray(
										[
											$author$project$Copy$llcDialogue(model.iQ)
										]))
								]))
						]),
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$OwnersView$capTable(model)
							]),
						_Utils_ap(
							model.au ? _Utils_ap(
								_List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Grid$row,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
												_List_fromArray(
													[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
											]),
										_List_fromArray(
											[
												A2(
												$rundis$elm_bootstrap$Bootstrap$Grid$col,
												_List_Nil,
												_List_fromArray(
													[
														A5($author$project$AppInput$inputText, $author$project$OwnersView$OwnerFirstNameUpdated, model.jh, model.iQ, 'createOwnerFirstName', 'First Name')
													])),
												A2(
												$rundis$elm_bootstrap$Bootstrap$Grid$col,
												_List_Nil,
												_List_fromArray(
													[
														A5($author$project$AppInput$inputText, $author$project$OwnersView$OwnerLastNameUpdated, model.j_, model.iQ, 'createOwnerLastName', 'Last Name')
													]))
											]))
									]),
								_Utils_ap(
									$author$project$OwnersView$ownerAddressRows(model),
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Grid$row,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
													_List_fromArray(
														[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
												]),
											_List_fromArray(
												[
													A2(
													$rundis$elm_bootstrap$Bootstrap$Grid$col,
													_List_Nil,
													_List_fromArray(
														[
															A5($author$project$AppInput$inputNumber, $author$project$OwnersView$OwnerOwnershipUpdated, model.dD, model.iQ, 'createOwnerPercent', 'Percent Ownership')
														]))
												]))
										]))) : _List_Nil,
							_Utils_ap(
								function () {
									var _v0 = model.au;
									if (!_v0) {
										return _List_Nil;
									} else {
										var _v1 = model.df;
										if (!_v1.$) {
											if (!_v1.a) {
												var _v2 = _v1.a;
												return _List_fromArray(
													[
														A2(
														$rundis$elm_bootstrap$Bootstrap$Grid$row,
														_List_fromArray(
															[
																$rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs,
																$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
																_List_fromArray(
																	[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
															]),
														_List_fromArray(
															[
																A2(
																$rundis$elm_bootstrap$Bootstrap$Grid$col,
																_List_fromArray(
																	[
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4,
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
																		_List_fromArray(
																			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
																	]),
																A3(
																	$author$project$OwnersView$cancelButton,
																	$author$project$OwnersView$ToggleOwnerForm($elm$core$Maybe$Nothing),
																	false,
																	'Cancel')),
																A2(
																$rundis$elm_bootstrap$Bootstrap$Grid$col,
																_List_fromArray(
																	[
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2,
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
																		_List_fromArray(
																			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
																	]),
																A4($author$project$OwnersView$saveButton, $author$project$OwnersView$OwnersUpdate, model, model.iQ, 'Save'))
															]))
													]);
											} else {
												var _v3 = _v1.a;
												return _List_fromArray(
													[
														A2(
														$rundis$elm_bootstrap$Bootstrap$Grid$row,
														_List_fromArray(
															[
																$rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs,
																$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
																_List_fromArray(
																	[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
															]),
														_List_fromArray(
															[
																A2(
																$rundis$elm_bootstrap$Bootstrap$Grid$col,
																_List_fromArray(
																	[
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs4,
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
																		_List_fromArray(
																			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
																	]),
																A3(
																	$author$project$OwnersView$cancelButton,
																	$author$project$OwnersView$ToggleOwnerForm($elm$core$Maybe$Nothing),
																	false,
																	'Cancel')),
																A2(
																$rundis$elm_bootstrap$Bootstrap$Grid$col,
																_List_fromArray(
																	[
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs3,
																		$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
																		_List_fromArray(
																			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3]))
																	]),
																A2($author$project$OwnersView$ownersSubmitButton, $author$project$OwnersView$AddOwner, 'Add Member'))
															]))
													]);
											}
										} else {
											return _List_Nil;
										}
									}
								}(),
								function () {
									var _v4 = model.iQ;
									if (_v4) {
										return _List_Nil;
									} else {
										return (model.au || ($author$project$Owners$foldOwnership(model.ku) === 100)) ? _List_Nil : $author$project$OwnersView$addOwnerRow;
									}
								}())))))));
};
var $author$project$ContribInfo$orgRows = function (c) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A3(
								$author$project$EntityType$orgView,
								$author$project$DataMsg$toMsg(c.kb),
								$author$project$DataMsg$toData(c.kb),
								c.iQ)
							]))
					]))
			]),
		_Utils_ap(
			function () {
				if ($author$project$EntityType$isLLCorLLP(
					$author$project$DataMsg$toData(c.kb))) {
					var viewModel = c.kv;
					var state = _Utils_update(
						viewModel,
						{iQ: c.iQ});
					return _Utils_ap(
						_List_fromArray(
							[
								A2(
								$elm$html$Html$map,
								c.kw,
								$author$project$OwnersView$view(state))
							]),
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$row,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Grid$col,
										_List_fromArray(
											[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$h6,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('font-weight-bold d-inline')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Company Contact Info')
													]))
											]))
									]))
							]));
				} else {
					return _List_Nil;
				}
			}(),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A5(
									$author$project$AppInput$inputText,
									$author$project$DataMsg$toMsg(c.i5),
									$author$project$DataMsg$toData(c.i5),
									c.iQ,
									'contribOwnerName',
									'*Organization Name')
								]))
						]))
				])));
};
var $author$project$ContribInfo$addressRows = function (c) {
	return $author$project$Address$view(
		{h9: c.h9, ia: c.ia, iw: c.iw, iQ: c.iQ, jB: 'createContrib', kM: c.kM, ln: c.ln});
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Email = 8;
var $rundis$elm_bootstrap$Bootstrap$Form$Input$email = $rundis$elm_bootstrap$Bootstrap$Form$Input$input(8);
var $author$project$AppInput$inputEmail = F5(
	function (msg, val, disabled, id, label) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					$rundis$elm_bootstrap$Bootstrap$Form$Input$email(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$value(val),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(msg),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
							_List_fromArray(
								[
									A2($elm$html$Html$Attributes$attribute, 'data-cy', id),
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(disabled))
								]))
						]))
				]));
	});
var $author$project$ContribInfo$piiRows = function (c) {
	return _Utils_ap(
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$AppInput$inputEmail,
								$author$project$DataMsg$toMsg(c.i0),
								$author$project$DataMsg$toData(c.i0),
								c.iQ,
								'createContribEmail',
								'Email Address')
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$AppInput$inputText,
								$author$project$DataMsg$toMsg(c.kH),
								$author$project$DataMsg$toData(c.kH),
								c.iQ,
								'createContribPhoneNumber',
								'Phone Number')
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$AppInput$inputText,
								$author$project$DataMsg$toMsg(c.jh),
								$author$project$DataMsg$toData(c.jh),
								c.iQ,
								'createContribFirstName',
								'*First Name')
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A5(
								$author$project$AppInput$inputText,
								$author$project$DataMsg$toMsg(c.j_),
								$author$project$DataMsg$toData(c.j_),
								c.iQ,
								'createContribLastName',
								'*Last Name')
							]))
					]))
			]),
		$author$project$ContribInfo$addressRows(c));
};
var $author$project$ContribInfo$donorInfoRows = function (model) {
	var formRows = function () {
		var _v0 = $author$project$DataMsg$toData(model.ni);
		if (!_v0.$) {
			if (!_v0.a) {
				var _v1 = _v0.a;
				return _Utils_ap(
					$author$project$ContribInfo$orgRows(model),
					$author$project$ContribInfo$piiRows(model));
			} else {
				var _v2 = _v0.a;
				return _Utils_ap(
					$author$project$ContribInfo$piiRows(model),
					$author$project$ContribInfo$familyRow(model));
			}
		} else {
			return _List_Nil;
		}
	}();
	return _Utils_ap(
		$author$project$ContribInfo$orgOrIndRow(model),
		formRows);
};
var $author$project$ContribInfo$errorRow = function (maybeStr) {
	if (maybeStr.$ === 1) {
		return _List_Nil;
	} else {
		var str = maybeStr.a;
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-danger')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(str)
									]))
							]))
					]))
			]);
	}
};
var $author$project$ContribInfo$labelRow = function (str) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$h5,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('font-weight-bold')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(str)
								]))
						]))
				]))
		]);
};
var $author$project$InKindType$radioList = F3(
	function (msg, currentValue, disabled) {
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$form,
				_List_Nil,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$view(
						A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$children,
							A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
								'inKindType',
								_List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$id('inKindType-servicesFacilitiesProvided'),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
												msg(0)),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
												_Utils_eq(
													currentValue,
													$elm$core$Maybe$Just(0))),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
											]),
										'Service/Facilities Provided'),
										A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$id('inKindType-campaignExpensesPaid'),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
												msg(1)),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
												_Utils_eq(
													currentValue,
													$elm$core$Maybe$Just(1))),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
											]),
										'Campaign Expenses Paid'),
										A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$id('inKindType-propertyGiven'),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
												msg(2)),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
												_Utils_eq(
													currentValue,
													$elm$core$Maybe$Just(2))),
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
											]),
										'Property Given')
									])),
							A3(
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
								_List_Nil,
								_List_Nil,
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($rundis$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
					]))
			]);
	});
var $author$project$ContribInfo$inKindRow = function (_v0) {
	var inKindType = _v0.m4;
	var inKindDesc = _v0.m2;
	var disabled = _v0.iQ;
	var maybeMsg = $author$project$DataMsg$toMsg(inKindType);
	var msg = A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, maybeMsg);
	return _Utils_ap(
		$author$project$ContribInfo$labelRow('In-kind Info'),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
								$elm$html$Html$Attributes$class('fade-in')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_Utils_ap(
							A3(
								$author$project$InKindType$radioList,
								msg,
								$author$project$DataMsg$toData(inKindType),
								disabled),
							_List_fromArray(
								[
									A5(
									$author$project$AppInput$inputText,
									$author$project$DataMsg$toMsg(inKindDesc),
									$author$project$DataMsg$toData(inKindDesc),
									disabled,
									'createContribDescription',
									'*Description')
								])))
					]))
			]));
};
var $author$project$ContribInfo$checkRow = function (_v0) {
	var checkNumber = _v0.iu;
	var disabled = _v0.iQ;
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
							$elm$html$Html$Attributes$class('fade-in')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A5(
							$author$project$AppInput$inputText,
							$author$project$DataMsg$toMsg(checkNumber),
							$author$project$DataMsg$toData(checkNumber),
							disabled,
							'createDisbCheck',
							'*Check Number')
						]))
				]))
		]);
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder = function (value_) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Input$Placeholder(value_);
};
var $author$project$MonthSelector$view = F2(
	function (updateMsg, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Select$id('card-month'),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(updateMsg)
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === ''),
									$elm$html$Html$Attributes$value('')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Select month')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '1'),
									$elm$html$Html$Attributes$value('1')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('1 - Jan')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '2'),
									$elm$html$Html$Attributes$value('2')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('2 - Feb')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '3'),
									$elm$html$Html$Attributes$value('3')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('3 - Mar')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '4'),
									$elm$html$Html$Attributes$value('4')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('4 - Apr')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '5'),
									$elm$html$Html$Attributes$value('5')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('5 - May')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '6'),
									$elm$html$Html$Attributes$value('6')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('6 - Jun')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '7'),
									$elm$html$Html$Attributes$value('7')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('7 - Jul')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '8'),
									$elm$html$Html$Attributes$value('8')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('8 - Aug')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '9'),
									$elm$html$Html$Attributes$value('9')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('9 - Sept')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '10'),
									$elm$html$Html$Attributes$value('10')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('10 - Oct')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '11'),
									$elm$html$Html$Attributes$value('11')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('11 - Nov')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$selected(currentValue === '12'),
									$elm$html$Html$Attributes$value('12')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('12 - Dec')
								]))
						]))
				]));
	});
var $author$project$YearSelector$view = F2(
	function (updateMsg, currentValue) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Form$Select$id('card-year'),
					$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(updateMsg)
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === ''),
							$elm$html$Html$Attributes$value('')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Select year')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2020'),
							$elm$html$Html$Attributes$value('2020')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2020')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2021'),
							$elm$html$Html$Attributes$value('2021')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2021')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2022'),
							$elm$html$Html$Attributes$value('2022')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2022')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2023'),
							$elm$html$Html$Attributes$value('2023')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2023')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2024'),
							$elm$html$Html$Attributes$value('2024')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2024')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2025'),
							$elm$html$Html$Attributes$value('2025')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2025')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2026'),
							$elm$html$Html$Attributes$value('2026')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2026')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2027'),
							$elm$html$Html$Attributes$value('2027')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2027')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2028'),
							$elm$html$Html$Attributes$value('2028')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2028')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2029'),
							$elm$html$Html$Attributes$value('2029')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2029')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$selected(currentValue === '2030'),
							$elm$html$Html$Attributes$value('2030')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('2030')
						]))
				]));
	});
var $author$project$ContribInfo$creditRow = function (_v0) {
	var cardNumber = _v0.ir;
	var expirationMonth = _v0.i9;
	var expirationYear = _v0.ja;
	var cvv = _v0.iH;
	var disabled = _v0.iQ;
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$centerLg,
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3,
							$elm$html$Html$Attributes$class('fade-in')
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Form$Input$id('card-number'),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$DataMsg$toMsg(cardNumber)),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder('Card Number'),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$value(
									$author$project$DataMsg$toData(cardNumber)),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled)
								]))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$MonthSelector$view,
							$author$project$DataMsg$toMsg(expirationMonth),
							$author$project$DataMsg$toData(expirationMonth))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$author$project$YearSelector$view,
							$author$project$DataMsg$toMsg(expirationYear),
							$author$project$DataMsg$toData(expirationYear))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Form$Input$id('cvv'),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
									$author$project$DataMsg$toMsg(cvv)),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$value(
									$author$project$DataMsg$toData(cvv)),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$placeholder('CVV'),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled)
								]))
						]))
				]))
		]);
};
var $author$project$ContribInfo$processingRow = function (c) {
	var _v0 = _Utils_Tuple2(
		$author$project$DataMsg$toData(c.kF),
		c.kP);
	_v0$3:
	while (true) {
		if (!_v0.a.$) {
			switch (_v0.a.a) {
				case 4:
					if (_v0.b) {
						var _v1 = _v0.a.a;
						return _Utils_ap(
							_List_Nil,
							_Utils_ap(
								$author$project$ContribInfo$errorRow(
									$elm$core$Maybe$Just('Contributions via credit will be processed on submission of this form.')),
								$author$project$ContribInfo$creditRow(c)));
					} else {
						break _v0$3;
					}
				case 3:
					var _v2 = _v0.a.a;
					return $author$project$ContribInfo$checkRow(c);
				case 5:
					var _v3 = _v0.a.a;
					return $author$project$ContribInfo$inKindRow(c);
				default:
					break _v0$3;
			}
		} else {
			break _v0$3;
		}
	}
	return _List_Nil;
};
var $author$project$PaymentMethod$select = F5(
	function (processPayment, msg, currentValue, disabled, txnId) {
		var id = A2($elm$core$Maybe$withDefault, '', txnId);
		var inKindRadio = processPayment ? _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'paymentMethodInKind-retired'),
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
						msg(5)),
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
						_Utils_eq(
							currentValue,
							$elm$core$Maybe$Just(5))),
						$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
					]),
				A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'data-cy', 'payMethod-inKind')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('In-Kind')
						])))
			]) : _List_Nil;
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$form,
				_List_Nil,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$view(
						A2(
							$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$children,
							A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
								'paymentMethod',
								_Utils_ap(
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'paymentMethod-check'),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
													msg(3)),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
													_Utils_eq(
														currentValue,
														$elm$core$Maybe$Just(3))),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
												]),
											A2(
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$attribute, 'data-cy', 'payMethod-check')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Check')
													]))),
											A2(
											$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'paymentMethod-credit'),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
													msg(4)),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
													_Utils_eq(
														currentValue,
														$elm$core$Maybe$Just(4))),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
												]),
											A2(
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$attribute, 'data-cy', 'payMethod-credit')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Credit')
													]))),
											A2(
											$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustomAdvanced,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + 'paymentMethod-cash'),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
													msg(0)),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked(
													_Utils_eq(
														currentValue,
														$elm$core$Maybe$Just(0))),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
												]),
											A2(
												$rundis$elm_bootstrap$Bootstrap$Form$Radio$label,
												_List_fromArray(
													[
														A2($elm$html$Html$Attributes$attribute, 'data-cy', 'payMethod-cash')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Cash')
													])))
										]),
									inKindRadio)),
							A3(
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
								_List_Nil,
								_List_Nil,
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($rundis$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
					]))
			]);
	});
var $author$project$ContribInfo$view = function (c) {
	var maybeMsg = $author$project$DataMsg$toMsg(c.kF);
	var msg = A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, maybeMsg);
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_Nil,
		_Utils_ap(
			_List_Nil,
			_Utils_ap(
				A3($author$project$ContribInfo$donorHeadingRow, c.ly, c.iQ, c.eM),
				_Utils_ap(
					(!c.eM) ? $author$project$ContribInfo$amountDateRow(c) : _List_Nil,
					_Utils_ap(
						$author$project$ContribInfo$errorRow(c.kc),
						_Utils_ap(
							$author$project$ContribInfo$donorInfoRows(c),
							function () {
								var _v0 = c.eM;
								if (_v0) {
									var _v1 = $author$project$DataMsg$toData(c.kF);
									_v1$2:
									while (true) {
										if (!_v1.$) {
											switch (_v1.a) {
												case 5:
													var _v2 = _v1.a;
													return $author$project$ContribInfo$inKindRow(c);
												case 3:
													var _v3 = _v1.a;
													return _Utils_ap(
														_List_Nil,
														_Utils_ap(
															$author$project$ContribInfo$labelRow('Check Number'),
															$author$project$ContribInfo$processingRow(c)));
												default:
													break _v1$2;
											}
										} else {
											break _v1$2;
										}
									}
									return _List_Nil;
								} else {
									return _Utils_ap(
										_List_Nil,
										_Utils_ap(
											$author$project$ContribInfo$labelRow('Processing Info'),
											_Utils_ap(
												A5(
													$author$project$PaymentMethod$select,
													c.kP,
													msg,
													$author$project$DataMsg$toData(c.kF),
													c.iQ,
													c.fS),
												$author$project$ContribInfo$processingRow(c))));
								}
							}()))))));
};
var $author$project$TxnForm$ContribRuleUnverified$contribFormRow = function (model) {
	return model.a8 ? _Utils_ap(
		_List_fromArray(
			[
				$author$project$ContribInfo$view(
				{
					h9: _Utils_Tuple2(model.h9, $author$project$TxnForm$ContribRuleUnverified$AddressLine1Updated),
					ia: _Utils_Tuple2(model.ia, $author$project$TxnForm$ContribRuleUnverified$AddressLine2Updated),
					ig: _Utils_Tuple2(model.ig, $author$project$TxnForm$ContribRuleUnverified$AmountUpdated),
					ir: _Utils_Tuple2(model.ir, $author$project$TxnForm$ContribRuleUnverified$CardNumberUpdated),
					iu: _Utils_Tuple2(model.iu, $author$project$TxnForm$ContribRuleUnverified$CheckNumberUpdated),
					iw: _Utils_Tuple2(model.iw, $author$project$TxnForm$ContribRuleUnverified$CityUpdated),
					iH: _Utils_Tuple2(model.iH, $author$project$TxnForm$ContribRuleUnverified$CVVUpdated),
					iQ: model.iQ,
					i0: _Utils_Tuple2(model.i0, $author$project$TxnForm$ContribRuleUnverified$EmailAddressUpdated),
					i1: _Utils_Tuple2(model.i1, $author$project$TxnForm$ContribRuleUnverified$EmployerUpdated),
					i2: _Utils_Tuple2(model.i2, $author$project$TxnForm$ContribRuleUnverified$EmploymentStatusUpdated),
					i5: _Utils_Tuple2(model.i5, $author$project$TxnForm$ContribRuleUnverified$EntityNameUpdated),
					i9: _Utils_Tuple2(model.i9, $author$project$TxnForm$ContribRuleUnverified$CardMonthUpdated),
					ja: _Utils_Tuple2(model.ja, $author$project$TxnForm$ContribRuleUnverified$CardYearUpdated),
					jh: _Utils_Tuple2(model.jh, $author$project$TxnForm$ContribRuleUnverified$FirstNameUpdated),
					m2: _Utils_Tuple2(model.m2, $author$project$TxnForm$ContribRuleUnverified$InKindDescUpdated),
					m4: _Utils_Tuple2(model.m4, $author$project$TxnForm$ContribRuleUnverified$InKindTypeUpdated),
					eM: false,
					j_: _Utils_Tuple2(model.j_, $author$project$TxnForm$ContribRuleUnverified$LastNameUpdated),
					kb: _Utils_Tuple2(model.kb, $author$project$TxnForm$ContribRuleUnverified$EntityTypeUpdated),
					kc: model.kc,
					ni: _Utils_Tuple2(model.ni, $author$project$TxnForm$ContribRuleUnverified$OrgOrIndUpdated),
					nk: _Utils_Tuple2(model.nk, $author$project$TxnForm$ContribRuleUnverified$MiddleNameUpdated),
					g7: _Utils_Tuple2(model.g7, $author$project$TxnForm$ContribRuleUnverified$OccupationUpdated),
					kv: model.kv,
					kw: $author$project$TxnForm$ContribRuleUnverified$OwnersViewUpdated,
					kE: _Utils_Tuple2(
						$author$project$TxnForm$ContribRuleUnverified$dateWithFormat(model),
						$author$project$TxnForm$ContribRuleUnverified$PaymentDateUpdated),
					kF: _Utils_Tuple2(model.kF, $author$project$TxnForm$ContribRuleUnverified$PaymentMethodUpdated),
					kH: _Utils_Tuple2(model.kH, $author$project$TxnForm$ContribRuleUnverified$PhoneNumberUpdated),
					kM: _Utils_Tuple2(model.kM, $author$project$TxnForm$ContribRuleUnverified$PostalCodeUpdated),
					kP: false,
					ln: _Utils_Tuple2(model.ln, $author$project$TxnForm$ContribRuleUnverified$StateUpdated),
					ly: $author$project$TxnForm$ContribRuleUnverified$ToggleEdit,
					fS: $elm$core$Maybe$Just(model.ik.jB)
				})
			]),
		_List_fromArray(
			[
				A6(
				$author$project$TxnForm$ContribRuleUnverified$buttonRow,
				$author$project$TxnForm$ContribRuleUnverified$CreateContribToggled,
				'Create',
				'Cancel',
				$author$project$TxnForm$ContribRuleUnverified$CreateContribSubmitted,
				model.bE,
				!A2($rtfeldman$elm_validate$Validate$any, $author$project$TxnForm$ContribRuleUnverified$requiredFieldValidators, model))
			])) : _List_Nil;
};
var $author$project$Copy$contribUnverifiedDialogue = _List_fromArray(
	[
		A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('font-weight-bold')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Instructions: ')
			])),
		$elm$html$Html$text(' 4US has confirmed a payment came into your bank account.'),
		$elm$html$Html$text(' Below you’ll see what information was pulled from your bank statement.'),
		$elm$html$Html$text(' Please reconcile this transaction with the list of contributions you pre-populated, or create a new record with the required data.'),
		$elm$html$Html$text(' This transaction will not show up on your disclosure report until the required compliance data is provided.'),
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]),
		_List_fromArray(
			[
				$elm$html$Html$text(' If you run into any issues please email us at support@4US.net. Our team is ready to help.')
			]))
	]);
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$Info = 3;
var $rundis$elm_bootstrap$Bootstrap$Alert$attrs = F2(
	function (attributes, _v0) {
		var configRec = _v0;
		return _Utils_update(
			configRec,
			{eg: attributes});
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$simple = F3(
	function (role_, attributes, children_) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Alert$view,
			0,
			A2(
				$rundis$elm_bootstrap$Bootstrap$Alert$children,
				children_,
				A2(
					$rundis$elm_bootstrap$Bootstrap$Alert$attrs,
					attributes,
					A2($rundis$elm_bootstrap$Bootstrap$Alert$role, role_, $rundis$elm_bootstrap$Bootstrap$Alert$config))));
	});
var $rundis$elm_bootstrap$Bootstrap$Alert$simpleInfo = $rundis$elm_bootstrap$Bootstrap$Alert$simple(3);
var $author$project$TxnForm$ContribRuleUnverified$dialogueBox = A2($rundis$elm_bootstrap$Bootstrap$Alert$simpleInfo, _List_Nil, $author$project$Copy$contribUnverifiedDialogue);
var $author$project$LabelWithData$dataLabelStyle = $elm$html$Html$Attributes$class('font-weight-bold font-size-medium');
var $author$project$LabelWithData$dataLabel = function (label) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$author$project$LabelWithData$dataLabelStyle]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$LabelWithData$labelWithContent = F2(
	function (label, content) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$LabelWithData$dataLabel(label),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$Asset$timesGlyph = $author$project$Asset$glyph('fas fa-times');
var $author$project$TxnForm$ContribRuleUnverified$matchesIcon = function (val) {
	return val ? $author$project$Asset$circleCheckGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-green font-size-large')
			])) : $author$project$Asset$timesGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-danger font-size-large')
			]));
};
var $author$project$TxnForm$ContribRuleUnverified$labelWithBankVerificationIcon = F2(
	function (label, matchesStatus) {
		return A2(
			$author$project$LabelWithData$labelWithContent,
			label,
			$author$project$TxnForm$ContribRuleUnverified$matchesIcon(matchesStatus));
	});
var $author$project$LabelWithData$labelWithData = F2(
	function (label, data) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$LabelWithData$dataLabel(label),
					$author$project$LabelWithData$dataText(data)
				]));
	});
var $author$project$TxnForm$ContribRuleUnverified$reconcileInfoRow = F2(
	function (bankTxn, selectedTxns) {
		var selectedTotal = A3(
			$elm$core$List$foldr,
			F2(
				function (txn, acc) {
					return acc + txn.ig;
				}),
			0,
			selectedTxns);
		var matches = _Utils_eq(selectedTotal, bankTxn.ig);
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
					_List_fromArray(
						[
							A2(
							$author$project$LabelWithData$labelWithData,
							'Amount',
							$author$project$Cents$toDollar(bankTxn.ig))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
					_List_fromArray(
						[
							A2(
							$author$project$LabelWithData$labelWithData,
							'Total Selected',
							$author$project$Cents$toDollar(selectedTotal))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
					_List_fromArray(
						[
							A2($author$project$TxnForm$ContribRuleUnverified$labelWithBankVerificationIcon, 'Matches', matches)
						]))
				]));
	});
var $author$project$TxnForm$ContribRuleUnverified$labels = _List_fromArray(
	['Selected', 'Date', 'Entity Name', 'Amount', 'Entity Type']);
var $author$project$TxnForm$ContribRuleUnverified$RelatedTransactionClicked = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Checkbox = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create = F2(
	function (options, label_) {
		return {jY: label_, ny: options};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Label = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label = F2(
	function (attributes, children) {
		return {eg: attributes, mk: children};
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier = F2(
	function (modifier, options) {
		switch (modifier.$) {
			case 0:
				var val = modifier.a;
				return _Utils_update(
					options,
					{
						jB: $elm$core$Maybe$Just(val)
					});
			case 1:
				var val = modifier.a;
				return _Utils_update(
					options,
					{ln: val});
			case 2:
				return _Utils_update(
					options,
					{dn: true});
			case 3:
				var toMsg = modifier.a;
				return _Utils_update(
					options,
					{
						e8: $elm$core$Maybe$Just(toMsg)
					});
			case 4:
				return _Utils_update(
					options,
					{ad: true});
			case 5:
				var val = modifier.a;
				return _Utils_update(
					options,
					{iQ: val});
			case 6:
				var validation = modifier.a;
				return _Utils_update(
					options,
					{
						f$: $elm$core$Maybe$Just(validation)
					});
			default:
				var attrs_ = modifier.a;
				return _Utils_update(
					options,
					{
						eg: _Utils_ap(options.eg, attrs_)
					});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Off = 1;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions = {eg: _List_Nil, ad: false, iQ: false, jB: $elm$core$Maybe$Nothing, dn: false, e8: $elm$core$Maybe$Nothing, ln: 1, f$: $elm$core$Maybe$Nothing};
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute = function (state) {
	switch (state) {
		case 0:
			return $elm$html$Html$Attributes$checked(true);
		case 1:
			return $elm$html$Html$Attributes$checked(false);
		default:
			return A2($elm$html$Html$Attributes$attribute, 'indeterminate', 'true');
	}
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes = function (options) {
	return _Utils_ap(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check-input', !options.ad),
						_Utils_Tuple2('custom-control-input', options.ad)
					])),
				$elm$html$Html$Attributes$type_('checkbox'),
				$elm$html$Html$Attributes$disabled(options.iQ),
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$stateAttribute(options.ln)
			]),
		_Utils_ap(
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				_List_fromArray(
					[
						A2($elm$core$Maybe$map, $elm$html$Html$Events$onCheck, options.e8),
						A2($elm$core$Maybe$map, $elm$html$Html$Attributes$id, options.jB)
					])),
			_Utils_ap(
				function () {
					var _v0 = options.f$;
					if (!_v0.$) {
						var v = _v0.a;
						return _List_fromArray(
							[
								$elm$html$Html$Attributes$class(
								$rundis$elm_bootstrap$Bootstrap$Form$FormInternal$validationToString(v))
							]);
					} else {
						return _List_Nil;
					}
				}(),
				options.eg)));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view = function (_v0) {
	var chk = _v0;
	var opts = A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$applyModifier, $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$defaultOptions, chk.ny);
	var _v1 = chk.jY;
	var label_ = _v1;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-check', !opts.ad),
						_Utils_Tuple2('form-check-inline', (!opts.ad) && opts.dn),
						_Utils_Tuple2('custom-control', opts.ad),
						_Utils_Tuple2('custom-checkbox', opts.ad),
						_Utils_Tuple2('custom-control-inline', opts.dn && opts.ad)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$input,
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$toAttributes(opts),
				_List_Nil),
				A2(
				$elm$html$Html$label,
				_Utils_ap(
					label_.eg,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-check-label', !opts.ad),
										_Utils_Tuple2('custom-control-label', opts.ad)
									]))
							]),
						function () {
							var _v2 = opts.jB;
							if (!_v2.$) {
								var v = _v2.a;
								return _List_fromArray(
									[
										$elm$html$Html$Attributes$for(v)
									]);
							} else {
								return _List_Nil;
							}
						}())),
				label_.mk)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox = F2(
	function (options, labelText) {
		return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$view(
			A2(
				$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$create,
				options,
				A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$label,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text(labelText)
						]))));
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$On = 0;
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value = function (a) {
	return {$: 1, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked = function (isCheck) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Value(
		isCheck ? 0 : 1);
};
var $author$project$Transactions$getAmount = function (transaction) {
	var _v0 = transaction.iP;
	if (_v0 === 1) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-danger')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					'(' + ($author$project$Cents$toDollar(transaction.ig) + ')'))
				]));
	} else {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-green')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Cents$toDollar(transaction.ig))
				]));
	}
};
var $author$project$Transactions$missingContent = A2(
	$elm$html$Html$span,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('text-danger')
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Missing')
		]));
var $author$project$Transactions$getContext = function (transaction) {
	var _v0 = transaction.iP;
	if (_v0 === 1) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Transactions$missingContent,
			A2(
				$elm$core$Maybe$map,
				A2($elm$core$Basics$composeL, $elm$html$Html$text, $author$project$PurposeCode$toString),
				transaction.nE));
	} else {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Transactions$missingContent,
			A2(
				$elm$core$Maybe$map,
				A2($elm$core$Basics$composeL, $elm$html$Html$text, $author$project$EntityType$toDisplayString),
				transaction.i6));
	}
};
var $author$project$Transactions$getEntityName = function (transaction) {
	var personName = A3(
		$elm$core$Maybe$map2,
		F2(
			function (a, b) {
				return a + (' ' + b);
			}),
		transaction.jh,
		transaction.j_);
	var _v0 = _Utils_Tuple2(transaction.iP, transaction.i6);
	_v0$4:
	while (true) {
		if (_v0.a === 1) {
			var _v1 = _v0.a;
			return transaction.i5;
		} else {
			if (!_v0.b.$) {
				switch (_v0.b.a) {
					case 1:
						var _v2 = _v0.a;
						var _v3 = _v0.b.a;
						return personName;
					case 0:
						var _v4 = _v0.a;
						var _v5 = _v0.b.a;
						return personName;
					case 5:
						var _v6 = _v0.a;
						var _v7 = _v0.b.a;
						return personName;
					default:
						break _v0$4;
				}
			} else {
				break _v0$4;
			}
		}
	}
	return transaction.i5;
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Id = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id = function (theId) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$Id(theId);
};
var $author$project$TxnForm$ContribRuleUnverified$isSelected = F2(
	function (txn, selected) {
		return A2(
			$elm$core$List$any,
			function (val) {
				return _Utils_eq(val.jB, txn.jB);
			},
			selected);
	});
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck = function (toMsg) {
	return $rundis$elm_bootstrap$Bootstrap$Form$Checkbox$OnChecked(toMsg);
};
var $author$project$Transactions$uppercaseText = function (str) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-capitalize')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $author$project$TxnForm$ContribRuleUnverified$transactionRowMap = function (_v0) {
	var maybeSelected = _v0.a;
	var maybeMsg = _v0.b;
	var txn = _v0.c;
	var selected = A2($elm$core$Maybe$withDefault, _List_Nil, maybeSelected);
	var name = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Transactions$missingContent,
		A2(
			$elm$core$Maybe$map,
			$author$project$Transactions$uppercaseText,
			$author$project$Transactions$getEntityName(txn)));
	var isChecked = A2($author$project$TxnForm$ContribRuleUnverified$isSelected, txn, selected);
	var amount = $author$project$Transactions$getAmount(txn);
	return _Utils_Tuple2(
		$elm$core$Maybe$Nothing,
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Selected',
				A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id(txn.jB),
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(isChecked),
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
							$author$project$TxnForm$ContribRuleUnverified$RelatedTransactionClicked(txn))
						]),
					'')),
				_Utils_Tuple2(
				'Date',
				$elm$html$Html$text(
					A2(
						$author$project$Timestamp$format,
						$justinmimbs$timezone_data$TimeZone$america__new_york(0),
						txn.kE))),
				_Utils_Tuple2('Entity Name', name),
				_Utils_Tuple2('Amount', amount),
				_Utils_Tuple2(
				'Entity Type',
				$author$project$Transactions$getContext(txn))
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Table$TableAttr = function (a) {
	return {$: 7, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$attr = function (attr_) {
	return $rundis$elm_bootstrap$Bootstrap$Table$TableAttr(attr_);
};
var $rundis$elm_bootstrap$Bootstrap$Table$RowAttr = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Table$rowAttr = function (attr_) {
	return $rundis$elm_bootstrap$Bootstrap$Table$RowAttr(attr_);
};
var $author$project$DataTable$dataRow = function (_v0) {
	var maybeMsg = _v0.a;
	var data = _v0.b;
	var actionAttr = function () {
		if (!maybeMsg.$) {
			var action = maybeMsg.a;
			return _List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Table$rowAttr(
					$elm$html$Html$Events$onClick(action)),
					$rundis$elm_bootstrap$Bootstrap$Table$rowAttr(
					$elm$html$Html$Attributes$class('hover-pointer'))
				]);
		} else {
			return _List_Nil;
		}
	}();
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$tr,
		actionAttr,
		A2(
			$elm$core$List$map,
			function (_v1) {
				var content = _v1.b;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Table$td,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Table$cellAttr($rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3)
						]),
					_List_fromArray(
						[content]));
			},
			data));
};
var $author$project$DataTable$emptyText = function (copy) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-center'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(copy)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Table$Hover = {$: 3};
var $rundis$elm_bootstrap$Bootstrap$Table$hover = $rundis$elm_bootstrap$Bootstrap$Table$Hover;
var $author$project$DataTable$stickyTh = function (str) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$th,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Table$cellAttr(
				$elm$html$Html$Attributes$class('bg-white shadow-sm'))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $author$project$DataTable$labelRow = function (labels) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Table$tr,
		_List_Nil,
		A2($elm$core$List$map, $author$project$DataTable$stickyTh, labels));
};
var $rundis$elm_bootstrap$Bootstrap$Table$Striped = {$: 1};
var $rundis$elm_bootstrap$Bootstrap$Table$striped = $rundis$elm_bootstrap$Bootstrap$Table$Striped;
var $author$project$DataTable$view = F4(
	function (emptyCopy, labels, mapper, data) {
		var table = $rundis$elm_bootstrap$Bootstrap$Table$table(
			{
				ny: _List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Table$attr(
						$elm$html$Html$Attributes$class('main-table')),
						$rundis$elm_bootstrap$Bootstrap$Table$striped,
						$rundis$elm_bootstrap$Bootstrap$Table$hover
					]),
				nY: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$tbody,
					_List_Nil,
					A2(
						$elm$core$List$map,
						$author$project$DataTable$dataRow,
						A2($elm$core$List$map, mapper, data))),
				n_: A2(
					$rundis$elm_bootstrap$Bootstrap$Table$thead,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$DataTable$labelRow(labels)
						]))
			});
		return ($elm$core$List$length(data) > 0) ? table : A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					table,
					$author$project$DataTable$emptyText(emptyCopy)
				]));
	});
var $author$project$TxnForm$ContribRuleUnverified$reconcileItemsTable = F2(
	function (relatedTxns, selectedTxns) {
		return A4(
			$author$project$DataTable$view,
			'Awaiting Transactions.',
			$author$project$TxnForm$ContribRuleUnverified$labels,
			$author$project$TxnForm$ContribRuleUnverified$transactionRowMap,
			A2(
				$elm$core$List$map,
				function (d) {
					return _Utils_Tuple3(
						$elm$core$Maybe$Just(selectedTxns),
						$elm$core$Maybe$Nothing,
						d);
				},
				relatedTxns));
	});
var $author$project$LabelWithData$labelWithMaybeData = F2(
	function (label, data) {
		if (!data.$) {
			var a = data.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$LabelWithData$dataLabel(label),
						$author$project$LabelWithData$dataText(a)
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$LabelWithData$dataLabel(label),
						$author$project$LabelWithData$dataText('N/A')
					]));
		}
	});
var $author$project$LabelWithData$dataLongText = function (data) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1,
				$elm$html$Html$Attributes$class('font-size-medium')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(data)
			]));
};
var $author$project$LabelWithData$labelWithMaybeLongData = F2(
	function (label, data) {
		if (!data.$) {
			var a = data.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$LabelWithData$dataLabel(label),
						$author$project$LabelWithData$dataLongText(a)
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$LabelWithData$dataLabel(label),
						$author$project$LabelWithData$dataText('N/A')
					]));
		}
	});
var $author$project$LabelWithData$labelWithMaybeTimeData = F2(
	function (label, data) {
		if (!data.$) {
			var a = data.a;
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$LabelWithData$dataLabel(label),
						$author$project$LabelWithData$dataText(
						A2(
							$author$project$Timestamp$format,
							$justinmimbs$timezone_data$TimeZone$america__new_york(0),
							a))
					]));
		} else {
			return A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$LabelWithData$dataLabel(label),
						$author$project$LabelWithData$dataText('N/A')
					]));
		}
	});
var $author$project$LabelWithData$labelWithTimeData = F2(
	function (label, data) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$LabelWithData$dataLabel(label),
					$author$project$LabelWithData$dataText(
					A2(
						$author$project$Timestamp$format,
						$justinmimbs$timezone_data$TimeZone$america__new_york(0),
						data))
				]));
	});
var $author$project$BankData$bankDataRows = function (data) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$LabelWithData$labelWithMaybeData, 'Analyzed Payee Name', data.mS)
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$LabelWithData$labelWithMaybeData, 'Analyzed Category', data.mQ)
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$LabelWithData$labelWithMaybeLongData, 'Description', data.mR)
						]))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$core$Maybe$withDefault,
							$elm$html$Html$text('N/A'),
							A2(
								$elm$core$Maybe$map,
								$author$project$LabelWithData$labelWithTimeData('Posted Date'),
								data.mT))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2($author$project$LabelWithData$labelWithMaybeTimeData, 'Transaction Date', data.mU)
						]))
				]))
		]);
};
var $author$project$BankData$view = F2(
	function (isShowing, model) {
		var heading = isShowing ? _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('text-size-medium')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Bank Data')
					]))
			]) : _List_Nil;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_Utils_ap(
				heading,
				$author$project$BankData$bankDataRows(model)));
	});
var $author$project$TxnForm$ContribRuleUnverified$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$TxnForm$ContribRuleUnverified$dialogueBox,
				A2($author$project$BankData$view, true, model.ik),
				A2(
				$elm$html$Html$h6,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]),
				_List_fromArray(
					[
						$elm$html$Html$text('Reconcile')
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2($author$project$TxnForm$ContribRuleUnverified$reconcileInfoRow, model.ik, model.li),
							$author$project$TxnForm$ContribRuleUnverified$addContribButtonOrHeading(model)
						]),
					_Utils_ap(
						$author$project$TxnForm$ContribRuleUnverified$contribFormRow(model),
						_List_fromArray(
							[
								A2($author$project$TxnForm$ContribRuleUnverified$reconcileItemsTable, model.fA, model.li)
							]))))
			]));
};
var $author$project$Page$Transactions$contribRuleUnverifiedModal = function (model) {
	return $author$project$PlatformModal$view(
		{
			b8: $elm$core$Maybe$Nothing,
			r: $elm$core$Maybe$Nothing,
			ca: $author$project$Page$Transactions$ContribRuleUnverifiedModalAnimate,
			ck: $author$project$Page$Transactions$ContribRuleUnverifiedModalHide,
			jB: $author$project$FormID$toString(3),
			co: model.G,
			V: false,
			gO: $author$project$TxnForm$ContribRuleUnverified$toSubmitDisabled(model.L),
			aG: model.bB,
			ct: $elm$core$Maybe$Nothing,
			cH: model.L,
			cI: $author$project$TxnForm$ContribRuleUnverified$view,
			cJ: $author$project$Page$Transactions$ContribRuleUnverifiedSubmit,
			cK: 'Reconcile',
			cL: model.c$,
			cM: ' Reconciliation Successful!',
			lu: 'Reconcile Contribution',
			cR: $author$project$Page$Transactions$ContribRuleUnverifiedModalUpdate,
			cT: model.bA
		});
};
var $author$project$Page$Transactions$ContribRuleVerifiedModalHide = {$: 48};
var $author$project$Page$Transactions$ContribRuleVerifiedSubmit = {$: 51};
var $author$project$Page$Transactions$ToggleDeletePrompt = {$: 57};
var $author$project$Page$Transactions$TxnDelete = function (a) {
	return {$: 53, a: a};
};
var $author$project$Page$Transactions$toDeleteMsg = F3(
	function (model, mapper, subModel) {
		var txn = mapper(subModel);
		var notBlank = $elm$core$String$length(txn.jB) > 0;
		var notActBlue = !(!txn.nQ);
		var isUnreconciled = !txn.ga;
		var isUnprocessed = _Utils_eq(txn.nT, $elm$core$Maybe$Nothing);
		var _v0 = isUnreconciled && (isUnprocessed && (notBlank && notActBlue));
		if (_v0) {
			var _v1 = model.G;
			switch (_v1) {
				case 0:
					return $elm$core$Maybe$Just(
						$author$project$Page$Transactions$TxnDelete(txn));
				case 1:
					return $elm$core$Maybe$Just($author$project$Page$Transactions$ToggleDeletePrompt);
				default:
					var _v2 = isUnreconciled && (isUnprocessed && (notBlank && notActBlue));
					if (_v2) {
						return $elm$core$Maybe$Just($author$project$Page$Transactions$ToggleDeletePrompt);
					} else {
						return $elm$core$Maybe$Nothing;
					}
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$TxnForm$ContribRuleVerified$toTxn = function (model) {
	return model.dX;
};
var $author$project$TxnForm$ContribRuleVerified$BankDataToggled = {$: 2};
var $rundis$elm_bootstrap$Bootstrap$Grid$container = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container')
					]),
				attributes),
			children);
	});
var $author$project$TxnForm$ContribRuleVerified$AddressLine1Updated = function (a) {
	return {$: 10, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$AddressLine2Updated = function (a) {
	return {$: 11, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$AmountUpdated = function (a) {
	return {$: 29, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$CVVUpdated = function (a) {
	return {$: 28, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$CardMonthUpdated = function (a) {
	return {$: 25, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$CardNumberUpdated = function (a) {
	return {$: 26, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$CardYearUpdated = function (a) {
	return {$: 24, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$CheckNumberUpdated = function (a) {
	return {$: 30, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$CityUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$EmailAddressUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$EmployerUpdated = function (a) {
	return {$: 16, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$EmploymentStatusUpdated = function (a) {
	return {$: 15, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$EntityNameUpdated = function (a) {
	return {$: 18, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$EntityTypeUpdated = function (a) {
	return {$: 19, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$FirstNameUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$InKindDescUpdated = function (a) {
	return {$: 32, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$InKindTypeUpdated = function (a) {
	return {$: 33, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$LastNameUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$MiddleNameUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$OccupationUpdated = function (a) {
	return {$: 17, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$OrgOrIndUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$PaymentDateUpdated = function (a) {
	return {$: 31, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$PaymentMethodUpdated = function (a) {
	return {$: 27, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$PhoneNumberUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$PostalCodeUpdated = function (a) {
	return {$: 14, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$StateUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$TxnForm$ContribRuleVerified$ToggleEdit = {$: 1};
var $author$project$TxnForm$ContribRuleVerified$dateWithFormat = function (model) {
	return (model.kE === '') ? A2(
		$author$project$Timestamp$formDate,
		$justinmimbs$timezone_data$TimeZone$america__new_york(0),
		model.dX.kE) : model.kE;
};
var $author$project$TxnForm$ContribRuleVerified$contribFormRow = function (model) {
	return $author$project$ContribInfo$view(
		{
			h9: _Utils_Tuple2(model.h9, $author$project$TxnForm$ContribRuleVerified$AddressLine1Updated),
			ia: _Utils_Tuple2(model.ia, $author$project$TxnForm$ContribRuleVerified$AddressLine2Updated),
			ig: _Utils_Tuple2(model.ig, $author$project$TxnForm$ContribRuleVerified$AmountUpdated),
			ir: _Utils_Tuple2(model.ir, $author$project$TxnForm$ContribRuleVerified$CardNumberUpdated),
			iu: _Utils_Tuple2(model.iu, $author$project$TxnForm$ContribRuleVerified$CheckNumberUpdated),
			iw: _Utils_Tuple2(model.iw, $author$project$TxnForm$ContribRuleVerified$CityUpdated),
			iH: _Utils_Tuple2(model.iH, $author$project$TxnForm$ContribRuleVerified$CVVUpdated),
			iQ: model.iQ,
			i0: _Utils_Tuple2(model.i0, $author$project$TxnForm$ContribRuleVerified$EmailAddressUpdated),
			i1: _Utils_Tuple2(model.i1, $author$project$TxnForm$ContribRuleVerified$EmployerUpdated),
			i2: _Utils_Tuple2(model.i2, $author$project$TxnForm$ContribRuleVerified$EmploymentStatusUpdated),
			i5: _Utils_Tuple2(model.i5, $author$project$TxnForm$ContribRuleVerified$EntityNameUpdated),
			i9: _Utils_Tuple2(model.i9, $author$project$TxnForm$ContribRuleVerified$CardMonthUpdated),
			ja: _Utils_Tuple2(model.ja, $author$project$TxnForm$ContribRuleVerified$CardYearUpdated),
			jh: _Utils_Tuple2(model.jh, $author$project$TxnForm$ContribRuleVerified$FirstNameUpdated),
			m2: _Utils_Tuple2(model.m2, $author$project$TxnForm$ContribRuleVerified$InKindDescUpdated),
			m4: _Utils_Tuple2(model.m4, $author$project$TxnForm$ContribRuleVerified$InKindTypeUpdated),
			eM: true,
			j_: _Utils_Tuple2(model.j_, $author$project$TxnForm$ContribRuleVerified$LastNameUpdated),
			kb: _Utils_Tuple2(model.kb, $author$project$TxnForm$ContribRuleVerified$EntityTypeUpdated),
			kc: model.kc,
			ni: _Utils_Tuple2(model.ni, $author$project$TxnForm$ContribRuleVerified$OrgOrIndUpdated),
			nk: _Utils_Tuple2(model.nk, $author$project$TxnForm$ContribRuleVerified$MiddleNameUpdated),
			g7: _Utils_Tuple2(model.g7, $author$project$TxnForm$ContribRuleVerified$OccupationUpdated),
			kv: model.kv,
			kw: $author$project$TxnForm$ContribRuleVerified$OwnersViewUpdated,
			kE: _Utils_Tuple2(
				$author$project$TxnForm$ContribRuleVerified$dateWithFormat(model),
				$author$project$TxnForm$ContribRuleVerified$PaymentDateUpdated),
			kF: _Utils_Tuple2(model.kF, $author$project$TxnForm$ContribRuleVerified$PaymentMethodUpdated),
			kH: _Utils_Tuple2(model.kH, $author$project$TxnForm$ContribRuleVerified$PhoneNumberUpdated),
			kM: _Utils_Tuple2(model.kM, $author$project$TxnForm$ContribRuleVerified$PostalCodeUpdated),
			kP: false,
			ln: _Utils_Tuple2(model.ln, $author$project$TxnForm$ContribRuleVerified$StateUpdated),
			ly: $author$project$TxnForm$ContribRuleVerified$ToggleEdit,
			fS: $elm$core$Maybe$Just(model.dX.jB)
		});
};
var $author$project$Asset$angleDownGlyph = $author$project$Asset$glyph('fas fa-angle-down');
var $author$project$Asset$angleUpGlyph = $author$project$Asset$glyph('fas fa-angle-up');
var $author$project$ExpandableBankData$angleIcon = function (val) {
	if (val) {
		return $author$project$Asset$angleUpGlyph(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-slate-blue ml-2')
				]));
	} else {
		return $author$project$Asset$angleDownGlyph(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-slate-blue ml-2')
				]));
	}
};
var $author$project$ExpandableBankData$bankHeaderStyle = $elm$html$Html$Attributes$class('font-weight-bold text-decoration-underline hover-pointer text-slate-blue');
var $elm$html$Html$u = _VirtualDom_node('u');
var $author$project$ExpandableBankData$headerRow = F3(
	function (id, msg, val) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$u,
					_List_fromArray(
						[
							$author$project$ExpandableBankData$bankHeaderStyle,
							$elm$html$Html$Events$onClick(msg)
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Bank Data: ' + id),
							$author$project$ExpandableBankData$angleIcon(val)
						]))
				]));
	});
var $author$project$ExpandableBankData$view = F3(
	function (dataIsVisible, txn, toggleMsg) {
		return _List_fromArray(
			[
				A3($author$project$ExpandableBankData$headerRow, txn.jB, toggleMsg, dataIsVisible),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
				_List_Nil,
				_Utils_ap(
					_List_Nil,
					dataIsVisible ? _List_fromArray(
						[
							A2($author$project$BankData$view, false, txn)
						]) : _List_Nil))
			]);
	});
var $author$project$PaymentInfo$scoreText = function (score) {
	var style = (score !== '0') ? 'font-size-large' : 'text-danger font-size-large';
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class(style)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(score)
			]));
};
var $author$project$PaymentInfo$scoreToString = function (score) {
	if (score.$ === 1) {
		return 'N/A';
	} else {
		var a = score.a;
		return $elm$core$String$fromInt(a);
	}
};
var $author$project$PaymentInfo$labelWithScore = F2(
	function (score, label) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$LabelWithData$dataLabel(label),
					$author$project$PaymentInfo$scoreText(
					$author$project$PaymentInfo$scoreToString(score))
				]));
	});
var $author$project$PaymentMethod$toDisplayString = function (src) {
	switch (src) {
		case 0:
			return 'Cash';
		case 1:
			return 'ACH';
		case 2:
			return 'Wire';
		case 3:
			return 'Check';
		case 4:
			return 'Credit';
		case 6:
			return 'Debit';
		case 5:
			return 'In-kind';
		case 7:
			return 'Transfer';
		case 8:
			return 'Online Processor';
		default:
			return 'Other';
	}
};
var $author$project$Asset$minusCircleGlyph = $author$project$Asset$glyph('fa-minus-circle');
var $author$project$PaymentInfo$statusContent = function (val) {
	return val ? $author$project$Asset$circleCheckGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-green font-size-large')
			])) : $author$project$Asset$minusCircleGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-warning font-size-large')
			]));
};
var $author$project$PaymentInfo$verified = F2(
	function (label, isVerified) {
		return A2(
			$author$project$LabelWithData$labelWithContent,
			label,
			$author$project$PaymentInfo$statusContent(isVerified));
	});
var $author$project$PaymentInfo$dataView = function (txn) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$container,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$LabelWithData$labelWithData,
								'Amount',
								$author$project$Cents$toDollar(txn.ig))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$row,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Grid$col,
										_List_Nil,
										_List_fromArray(
											[
												A2($author$project$LabelWithData$labelWithTimeData, 'Date Initiated', txn.kE)
											]))
									]))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$author$project$LabelWithData$labelWithData,
								'Payment Type',
								$author$project$PaymentMethod$toDisplayString(txn.kF))
							]))
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$PaymentInfo$verified, 'Rule Verified', txn.lb)
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$PaymentInfo$verified, 'Bank Verified', txn.ga)
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2($author$project$PaymentInfo$labelWithScore, txn.mD, 'Verification Score')
							]))
					]))
			]));
};
var $author$project$PaymentInfo$view = function (txn) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('fade-in')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h6,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Payment Info')
						])),
					$author$project$PaymentInfo$dataView(txn)
				]))
		]);
};
var $author$project$TxnForm$ContribRuleVerified$loadedView = function (model) {
	var bankData = (model.dX.ga && (model.dX.kF !== 5)) ? A3($author$project$ExpandableBankData$view, model.dJ, model.dX, $author$project$TxnForm$ContribRuleVerified$BankDataToggled) : _List_Nil;
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$container,
		_List_Nil,
		_Utils_ap(
			_List_Nil,
			_Utils_ap(
				$author$project$PaymentInfo$view(model.dX),
				_Utils_ap(
					_List_fromArray(
						[
							$author$project$TxnForm$ContribRuleVerified$contribFormRow(model)
						]),
					bankData))));
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$Attrs = function (a) {
	return {$: 3, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$attrs = function (attrs_) {
	return $rundis$elm_bootstrap$Bootstrap$Spinner$Attrs(attrs_);
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$Color = function (a) {
	return {$: 2, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$color = function (color_) {
	return $rundis$elm_bootstrap$Bootstrap$Spinner$Color(color_);
};
var $rundis$elm_bootstrap$Bootstrap$Spinner$Large = 2;
var $rundis$elm_bootstrap$Bootstrap$Spinner$large = $rundis$elm_bootstrap$Bootstrap$Spinner$Size(2);
var $rundis$elm_bootstrap$Bootstrap$Internal$Role$Primary = 0;
var $rundis$elm_bootstrap$Bootstrap$Internal$Text$Role = function (a) {
	return {$: 0, a: a};
};
var $rundis$elm_bootstrap$Bootstrap$Text$primary = $rundis$elm_bootstrap$Bootstrap$Internal$Text$Role(0);
var $author$project$Loading$spinner = A2(
	$rundis$elm_bootstrap$Bootstrap$Spinner$spinner,
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Spinner$large,
			$rundis$elm_bootstrap$Bootstrap$Spinner$color($rundis$elm_bootstrap$Bootstrap$Text$primary),
			$rundis$elm_bootstrap$Bootstrap$Spinner$attrs(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('opacity-light')
				]))
		]),
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Spinner$srMessage('Loading...')
		]));
var $author$project$Loading$view = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('text-center'),
			$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt5
		]),
	_List_fromArray(
		[$author$project$Loading$spinner]));
var $author$project$TxnForm$ContribRuleVerified$loadingView = $author$project$Loading$view;
var $author$project$TxnForm$ContribRuleVerified$view = function (model) {
	return model.dt ? $author$project$TxnForm$ContribRuleVerified$loadingView : $author$project$TxnForm$ContribRuleVerified$loadedView(model);
};
var $author$project$Page$Transactions$contribRuleVerifiedModal = function (model) {
	return $author$project$PlatformModal$view(
		{
			b8: $elm$core$Maybe$Just($author$project$Page$Transactions$DeleteAlertMsg),
			r: $elm$core$Maybe$Just(model.r),
			ca: $author$project$Page$Transactions$ContribRuleVerifiedModalAnimate,
			ck: $author$project$Page$Transactions$ContribRuleVerifiedModalHide,
			jB: $author$project$FormID$toString(5),
			co: model.G,
			V: model.V,
			gO: model.o.gO,
			aG: model.bC,
			ct: A3($author$project$Page$Transactions$toDeleteMsg, model, $author$project$TxnForm$ContribRuleVerified$toTxn, model.o),
			cH: model.o,
			cI: $author$project$TxnForm$ContribRuleVerified$view,
			cJ: $author$project$Page$Transactions$ContribRuleVerifiedSubmit,
			cK: 'Save',
			cL: model.c0,
			cM: ' Revision Successful!',
			lu: 'Contribution',
			cR: $author$project$Page$Transactions$ContribRuleVerifiedModalUpdate,
			cT: model.ar
		});
};
var $author$project$Page$Transactions$HideCreateContributionModal = {$: 3};
var $author$project$Page$Transactions$SubmitCreateContribution = {$: 14};
var $author$project$ContribInfo$toSubmitDisabled = $rtfeldman$elm_validate$Validate$any($author$project$ContribInfo$requiredFieldValidators);
var $author$project$CreateContribution$AddressLine1Updated = function (a) {
	return {$: 9, a: a};
};
var $author$project$CreateContribution$AddressLine2Updated = function (a) {
	return {$: 10, a: a};
};
var $author$project$CreateContribution$AmountUpdated = function (a) {
	return {$: 0, a: a};
};
var $author$project$CreateContribution$CVVUpdated = function (a) {
	return {$: 26, a: a};
};
var $author$project$CreateContribution$CardMonthUpdated = function (a) {
	return {$: 22, a: a};
};
var $author$project$CreateContribution$CardNumberUpdated = function (a) {
	return {$: 23, a: a};
};
var $author$project$CreateContribution$CardYearUpdated = function (a) {
	return {$: 21, a: a};
};
var $author$project$CreateContribution$CheckNumberUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$CreateContribution$CityUpdated = function (a) {
	return {$: 11, a: a};
};
var $author$project$CreateContribution$EmailAddressUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$CreateContribution$EmployerUpdated = function (a) {
	return {$: 15, a: a};
};
var $author$project$CreateContribution$EmploymentStatusUpdated = function (a) {
	return {$: 14, a: a};
};
var $author$project$CreateContribution$EntityNameUpdated = function (a) {
	return {$: 17, a: a};
};
var $author$project$CreateContribution$EntityTypeUpdated = function (a) {
	return {$: 18, a: a};
};
var $author$project$CreateContribution$FirstNameUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$CreateContribution$InKindDescUpdated = function (a) {
	return {$: 28, a: a};
};
var $author$project$CreateContribution$InKindTypeUpdated = function (a) {
	return {$: 27, a: a};
};
var $author$project$CreateContribution$LastNameUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$CreateContribution$MiddleNameUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$CreateContribution$NoOp = {$: 24};
var $author$project$CreateContribution$OccupationUpdated = function (a) {
	return {$: 16, a: a};
};
var $author$project$CreateContribution$OrgOrIndUpdated = function (a) {
	return {$: 3, a: a};
};
var $author$project$CreateContribution$PaymentDateUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$CreateContribution$PaymentMethodUpdated = function (a) {
	return {$: 25, a: a};
};
var $author$project$CreateContribution$PhoneNumberUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$CreateContribution$PostalCodeUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$CreateContribution$StateUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$CreateContribution$view = function (model) {
	return $author$project$ContribInfo$view(
		{
			h9: _Utils_Tuple2(model.h9, $author$project$CreateContribution$AddressLine1Updated),
			ia: _Utils_Tuple2(model.ia, $author$project$CreateContribution$AddressLine2Updated),
			ig: _Utils_Tuple2(model.ig, $author$project$CreateContribution$AmountUpdated),
			ir: _Utils_Tuple2(model.ir, $author$project$CreateContribution$CardNumberUpdated),
			iu: _Utils_Tuple2(model.iu, $author$project$CreateContribution$CheckNumberUpdated),
			iw: _Utils_Tuple2(model.iw, $author$project$CreateContribution$CityUpdated),
			iH: _Utils_Tuple2(model.iH, $author$project$CreateContribution$CVVUpdated),
			iQ: false,
			i0: _Utils_Tuple2(model.i0, $author$project$CreateContribution$EmailAddressUpdated),
			i1: _Utils_Tuple2(model.i1, $author$project$CreateContribution$EmployerUpdated),
			i2: _Utils_Tuple2(model.i2, $author$project$CreateContribution$EmploymentStatusUpdated),
			i5: _Utils_Tuple2(model.i5, $author$project$CreateContribution$EntityNameUpdated),
			i9: _Utils_Tuple2(model.i9, $author$project$CreateContribution$CardMonthUpdated),
			ja: _Utils_Tuple2(model.ja, $author$project$CreateContribution$CardYearUpdated),
			jh: _Utils_Tuple2(model.jh, $author$project$CreateContribution$FirstNameUpdated),
			m2: _Utils_Tuple2(model.m2, $author$project$CreateContribution$InKindDescUpdated),
			m4: _Utils_Tuple2(model.m4, $author$project$CreateContribution$InKindTypeUpdated),
			eM: false,
			j_: _Utils_Tuple2(model.j_, $author$project$CreateContribution$LastNameUpdated),
			kb: _Utils_Tuple2(model.kb, $author$project$CreateContribution$EntityTypeUpdated),
			kc: model.kc,
			ni: _Utils_Tuple2(model.ni, $author$project$CreateContribution$OrgOrIndUpdated),
			nk: _Utils_Tuple2(model.nk, $author$project$CreateContribution$MiddleNameUpdated),
			g7: _Utils_Tuple2(model.g7, $author$project$CreateContribution$OccupationUpdated),
			kv: model.kv,
			kw: $author$project$CreateContribution$OwnersViewUpdated,
			kE: _Utils_Tuple2(model.kE, $author$project$CreateContribution$PaymentDateUpdated),
			kF: _Utils_Tuple2(model.kF, $author$project$CreateContribution$PaymentMethodUpdated),
			kH: _Utils_Tuple2(model.kH, $author$project$CreateContribution$PhoneNumberUpdated),
			kM: _Utils_Tuple2(model.kM, $author$project$CreateContribution$PostalCodeUpdated),
			kP: true,
			ln: _Utils_Tuple2(model.ln, $author$project$CreateContribution$StateUpdated),
			ly: $author$project$CreateContribution$NoOp,
			fS: $elm$core$Maybe$Nothing
		});
};
var $author$project$Page$Transactions$createContributionModal = function (model) {
	return $author$project$PlatformModal$view(
		{
			b8: $elm$core$Maybe$Nothing,
			r: $elm$core$Maybe$Nothing,
			ca: $author$project$Page$Transactions$AnimateCreateContributionModal,
			ck: $author$project$Page$Transactions$HideCreateContributionModal,
			jB: $author$project$FormID$toString(1),
			co: model.G,
			V: false,
			gO: !$author$project$ContribInfo$toSubmitDisabled(
				$author$project$CreateContribution$validationMapper(model.A)),
			aG: model.ba,
			ct: $elm$core$Maybe$Nothing,
			cH: model.A,
			cI: $author$project$CreateContribution$view,
			cJ: $author$project$Page$Transactions$SubmitCreateContribution,
			cK: 'Submit',
			cL: false,
			cM: '',
			lu: 'Create Contribution',
			cR: $author$project$Page$Transactions$CreateContributionModalUpdated,
			cT: model.a9
		});
};
var $author$project$Page$Transactions$CreateDisbursementModalHide = {$: 27};
var $author$project$Page$Transactions$CreateDisbursementSubmit = {$: 31};
var $author$project$CreateDisbursement$toSubmitDisabled = $rtfeldman$elm_validate$Validate$any($author$project$CreateDisbursement$requiredFieldValidators);
var $author$project$CreateDisbursement$AddressLine1Updated = function (a) {
	return {$: 2, a: a};
};
var $author$project$CreateDisbursement$AddressLine2Updated = function (a) {
	return {$: 3, a: a};
};
var $author$project$CreateDisbursement$AmountUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$CreateDisbursement$CheckNumberUpdated = function (a) {
	return {$: 15, a: a};
};
var $author$project$CreateDisbursement$CityUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$CreateDisbursement$EntityNameUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$CreateDisbursement$IsExistingLiabilityUpdated = function (a) {
	return {$: 10, a: a};
};
var $author$project$CreateDisbursement$IsInKindUpdated = function (a) {
	return {$: 11, a: a};
};
var $author$project$CreateDisbursement$IsPartialPaymentUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$CreateDisbursement$IsSubcontractedUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$CreateDisbursement$NoOp = {$: 0};
var $author$project$CreateDisbursement$PaymentDateUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$CreateDisbursement$PaymentMethodUpdated = function (a) {
	return {$: 14, a: a};
};
var $author$project$CreateDisbursement$PostalCodeUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$CreateDisbursement$PurposeCodeUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$CreateDisbursement$StateUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$PaymentMethod$paymentMethods = _List_fromArray(
	[0, 1, 2, 3, 4, 6, 5, 7]);
var $author$project$PaymentMethod$dropdown = F3(
	function (maybePaymentMethod, updateMsg, isDisbursement) {
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Form$group,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$label,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$for('paymentMethod')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Payment Method')
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Form$Select$id('paymentMethod'),
								$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(
								A2($elm$core$Basics$composeR, $author$project$PaymentMethod$fromString, updateMsg)),
								$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$value(
										$author$project$PaymentMethod$fromMaybeToString(maybePaymentMethod)),
										$elm$html$Html$Attributes$class(
										$author$project$AppInput$inputStyle(false))
									]))
							]),
						_Utils_ap(
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$selected(
											_Utils_eq(maybePaymentMethod, $elm$core$Maybe$Nothing)),
											$elm$html$Html$Attributes$value('---')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('-- Payment Method --')
										]))
								]),
							A2(
								$elm$core$List$map,
								function (paymentMethod) {
									return A2(
										$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$value(
												$author$project$PaymentMethod$toDataString(paymentMethod)),
												$elm$html$Html$Attributes$selected(
												_Utils_eq(
													$elm$core$Maybe$Just(paymentMethod),
													maybePaymentMethod))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$PaymentMethod$toDisplayString(paymentMethod))
											]));
								},
								isDisbursement ? A2(
									$elm$core$List$filter,
									function (paymentMethod) {
										return paymentMethod !== 5;
									},
									$author$project$PaymentMethod$paymentMethods) : $author$project$PaymentMethod$paymentMethods)))
					]))
			]);
	});
var $author$project$PurposeCode$select = F3(
	function (maybePurposeCode, updateMsg, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Form$group,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$for('purpose')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text('Purpose')
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Select$select,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Select$id('purpose'),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$onChange(
							A2($elm$core$Basics$composeR, $author$project$PurposeCode$fromString, updateMsg)),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$value(
									$author$project$PurposeCode$fromMaybeToString(maybePurposeCode))
								])),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$disabled(disabled),
							$rundis$elm_bootstrap$Bootstrap$Form$Select$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class(
									$author$project$AppInput$inputStyle(disabled))
								]))
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$selected(
										_Utils_eq(maybePurposeCode, $elm$core$Maybe$Nothing)),
										$elm$html$Html$Attributes$value('---')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text('-- Purpose --')
									]))
							]),
						A2(
							$elm$core$List$map,
							function (_v0) {
								var codeText = _v0.b;
								var purposeText = _v0.c;
								return A2(
									$rundis$elm_bootstrap$Bootstrap$Form$Select$item,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$selected(
											_Utils_eq(
												codeText,
												$author$project$PurposeCode$fromMaybeToString(maybePurposeCode))),
											$elm$html$Html$Attributes$value(codeText)
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(purposeText)
										]));
							},
							$author$project$PurposeCode$purposeCodeText)))
				]));
	});
var $author$project$YesOrNo$radioState = function (maybeBool) {
	if (!maybeBool.$) {
		var bool = maybeBool.a;
		if (bool) {
			return 'Yes';
		} else {
			return 'No';
		}
	} else {
		return '';
	}
};
var $author$project$YesOrNo$yesOrNo = F4(
	function (question, data, txnID, disabled) {
		var msg = $author$project$DataMsg$toMsg(data);
		var id = A2($elm$core$Maybe$withDefault, '', txnID);
		var bool = $author$project$DataMsg$toData(data);
		var state = $author$project$YesOrNo$radioState(bool);
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$col,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Form$form,
					_List_Nil,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$view(
							A2(
								$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$children,
								A2(
									$rundis$elm_bootstrap$Bootstrap$Form$Radio$radioList,
									'radios',
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + (question + 'yes')),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
													msg(
														$elm$core$Maybe$Just(true))),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked('Yes' === state),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
												]),
											'Yes'),
											A2(
											$rundis$elm_bootstrap$Bootstrap$Form$Radio$createCustom,
											_List_fromArray(
												[
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$id(id + (question + 'no')),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$inline,
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$onClick(
													msg(
														$elm$core$Maybe$Just(false))),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$checked('No' === state),
													$rundis$elm_bootstrap$Bootstrap$Form$Radio$disabled(disabled)
												]),
											'No')
										])),
								A3(
									$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$legend,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('font-size-18')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(question)
										]),
									$rundis$elm_bootstrap$Bootstrap$Form$Fieldset$asGroup($rundis$elm_bootstrap$Bootstrap$Form$Fieldset$config))))
						]))
				]));
	});
var $author$project$DisbInfo$view = function (_v0) {
	var checkNumber = _v0.iu;
	var entityName = _v0.i5;
	var addressLine1 = _v0.h9;
	var addressLine2 = _v0.ia;
	var city = _v0.iw;
	var state = _v0.ln;
	var postalCode = _v0.kM;
	var purposeCode = _v0.nE;
	var isSubcontracted = _v0.nb;
	var isPartialPayment = _v0.na;
	var isExistingLiability = _v0.m8;
	var isInKind = _v0.m9;
	var amount = _v0.ig;
	var paymentDate = _v0.kE;
	var paymentMethod = _v0.kF;
	var disabled = _v0.iQ;
	var isEditable = _v0.eM;
	var toggleEdit = _v0.ly;
	var maybeError = _v0.kc;
	var txnID = _v0.n7;
	var errorContent = function () {
		if (!maybeError.$) {
			var error = maybeError.a;
			return _List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2,
									$elm$html$Html$Attributes$class('text-danger')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									$elm$html$Html$text(error)
								]))
						]))
				]);
		} else {
			return _List_Nil;
		}
	}();
	return _Utils_ap(
		errorContent,
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2,
									$elm$html$Html$Attributes$class('fade-in')
								]))
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Form$label,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$for('recipient-name')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('*Recipient Info'),
											isEditable ? A2(
											$elm$html$Html$span,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('hover-underline hover-pointer'),
													$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2,
													$elm$html$Html$Events$onClick(toggleEdit)
												]),
											_List_fromArray(
												[
													disabled ? $author$project$Asset$editGlyph(_List_Nil) : $author$project$Asset$redoGlyph(_List_Nil)
												])) : A2($elm$html$Html$span, _List_Nil, _List_Nil)
										])),
									$rundis$elm_bootstrap$Bootstrap$Form$Input$text(
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Form$Input$id('recipient-name'),
											$rundis$elm_bootstrap$Bootstrap$Form$Input$onInput(
											$author$project$DataMsg$toMsg(entityName)),
											$rundis$elm_bootstrap$Bootstrap$Form$Input$value(
											$author$project$DataMsg$toData(entityName)),
											$rundis$elm_bootstrap$Bootstrap$Form$Input$disabled(disabled),
											$rundis$elm_bootstrap$Bootstrap$Form$Input$attrs(
											_List_fromArray(
												[
													A2($elm$html$Html$Attributes$attribute, 'data-cy', 'recipientName'),
													$elm$html$Html$Attributes$class(
													$author$project$AppInput$inputStyle(disabled))
												]))
										]))
								]))
						]))
				]),
			_Utils_ap(
				$author$project$Address$view(
					{h9: addressLine1, ia: addressLine2, iw: city, iQ: disabled, jB: 'createDisb', kM: postalCode, ln: state}),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$row,
							_List_fromArray(
								[
									$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
									_List_fromArray(
										[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
								]),
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$col,
									_List_Nil,
									_List_fromArray(
										[
											A3(
											$author$project$PurposeCode$select,
											$author$project$DataMsg$toData(purposeCode),
											$author$project$DataMsg$toMsg(purposeCode),
											disabled)
										]))
								]))
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Grid$row,
								_List_Nil,
								_List_fromArray(
									[
										A4($author$project$YesOrNo$yesOrNo, 'Is expenditure subcontracted?', isSubcontracted, txnID, disabled),
										A4($author$project$YesOrNo$yesOrNo, 'Is expenditure a partial payment?', isPartialPayment, txnID, disabled),
										A4($author$project$YesOrNo$yesOrNo, 'Is this an existing Liability?', isExistingLiability, txnID, disabled),
										A4($author$project$YesOrNo$yesOrNo, 'Is this an In-Kind payment?', isInKind, txnID, disabled)
									]))
							]),
						function () {
							var _v1 = _Utils_Tuple3(amount, paymentDate, paymentMethod);
							if ((!_v1.a.$) && (!_v1.b.$)) {
								if (!_v1.c.$) {
									var a = _v1.a.a;
									var p = _v1.b.a;
									var pm = _v1.c.a;
									return _Utils_ap(
										$author$project$AmountDate$view(
											{ig: a, iQ: disabled, jY: '*Payment Date', kE: p}),
										_Utils_ap(
											_List_fromArray(
												[
													A2(
													$rundis$elm_bootstrap$Bootstrap$Grid$row,
													_List_fromArray(
														[
															$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
															_List_fromArray(
																[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
														]),
													_List_fromArray(
														[
															A2(
															$rundis$elm_bootstrap$Bootstrap$Grid$col,
															_List_Nil,
															A3(
																$author$project$PaymentMethod$dropdown,
																$author$project$DataMsg$toData(pm),
																$author$project$DataMsg$toMsg(pm),
																true))
														]))
												]),
											_Utils_eq(
												$author$project$DataMsg$toData(pm),
												$elm$core$Maybe$Just(3)) ? _List_fromArray(
												[
													A2(
													$rundis$elm_bootstrap$Bootstrap$Grid$row,
													_List_fromArray(
														[
															$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
															_List_fromArray(
																[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
														]),
													_List_fromArray(
														[
															A2(
															$rundis$elm_bootstrap$Bootstrap$Grid$col,
															_List_Nil,
															_List_fromArray(
																[
																	A5(
																	$author$project$AppInput$inputText,
																	$author$project$DataMsg$toMsg(checkNumber),
																	$author$project$DataMsg$toData(checkNumber),
																	false,
																	'createDisbCheck',
																	'*Check Number')
																]))
														]))
												]) : _List_Nil));
								} else {
									var a = _v1.a.a;
									var p = _v1.b.a;
									return $author$project$AmountDate$view(
										{ig: a, iQ: disabled, jY: '*Payment Date', kE: p});
								}
							} else {
								return _List_Nil;
							}
						}())))));
};
var $author$project$CreateDisbursement$view = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_Nil,
		$author$project$DisbInfo$view(
			{
				h9: _Utils_Tuple2(model.h9, $author$project$CreateDisbursement$AddressLine1Updated),
				ia: _Utils_Tuple2(model.ia, $author$project$CreateDisbursement$AddressLine2Updated),
				ig: $elm$core$Maybe$Just(
					_Utils_Tuple2(model.ig, $author$project$CreateDisbursement$AmountUpdated)),
				iu: _Utils_Tuple2(model.iu, $author$project$CreateDisbursement$CheckNumberUpdated),
				iw: _Utils_Tuple2(model.iw, $author$project$CreateDisbursement$CityUpdated),
				iQ: false,
				i5: _Utils_Tuple2(model.i5, $author$project$CreateDisbursement$EntityNameUpdated),
				eM: false,
				m8: _Utils_Tuple2(model.m8, $author$project$CreateDisbursement$IsExistingLiabilityUpdated),
				m9: _Utils_Tuple2(model.m9, $author$project$CreateDisbursement$IsInKindUpdated),
				na: _Utils_Tuple2(model.na, $author$project$CreateDisbursement$IsPartialPaymentUpdated),
				nb: _Utils_Tuple2(model.nb, $author$project$CreateDisbursement$IsSubcontractedUpdated),
				kc: model.kc,
				kE: $elm$core$Maybe$Just(
					_Utils_Tuple2(model.kE, $author$project$CreateDisbursement$PaymentDateUpdated)),
				kF: $elm$core$Maybe$Just(
					_Utils_Tuple2(model.kF, $author$project$CreateDisbursement$PaymentMethodUpdated)),
				kM: _Utils_Tuple2(model.kM, $author$project$CreateDisbursement$PostalCodeUpdated),
				nE: _Utils_Tuple2(model.nE, $author$project$CreateDisbursement$PurposeCodeUpdated),
				ln: _Utils_Tuple2(model.ln, $author$project$CreateDisbursement$StateUpdated),
				ly: $author$project$CreateDisbursement$NoOp,
				n7: $elm$core$Maybe$Nothing
			}));
};
var $author$project$Page$Transactions$createDisbursementModal = function (model) {
	return $author$project$PlatformModal$view(
		{
			b8: $elm$core$Maybe$Nothing,
			r: $elm$core$Maybe$Nothing,
			ca: $author$project$Page$Transactions$CreateDisbursementModalAnimate,
			ck: $author$project$Page$Transactions$CreateDisbursementModalHide,
			jB: $author$project$FormID$toString(0),
			co: model.G,
			V: false,
			gO: !$author$project$CreateDisbursement$toSubmitDisabled(model.B),
			aG: model.cd,
			ct: $elm$core$Maybe$Nothing,
			cH: model.B,
			cI: $author$project$CreateDisbursement$view,
			cJ: $author$project$Page$Transactions$CreateDisbursementSubmit,
			cK: 'Create Disbursement',
			cL: false,
			cM: '',
			lu: 'Create Disbursement',
			cR: $author$project$Page$Transactions$CreateDisbursementModalUpdate,
			cT: model.bc
		});
};
var $author$project$Page$Transactions$DisbRuleUnverifiedModalHide = {$: 32};
var $author$project$Page$Transactions$DisbRuleUnverifiedSubmit = {$: 35};
var $author$project$TxnForm$DisbRuleUnverified$totalSelectedMatch = function (model) {
	return _Utils_eq(
		A3(
			$elm$core$List$foldr,
			F2(
				function (txn, acc) {
					return acc + txn.ig;
				}),
			0,
			model.li),
		model.ik.ig) ? false : true;
};
var $author$project$TxnForm$DisbRuleUnverified$toSubmitDisabled = function (model) {
	return model.ht && $author$project$TxnForm$DisbRuleUnverified$totalSelectedMatch(model);
};
var $author$project$TxnForm$DisbRuleUnverified$CreateDisbToggled = {$: 16};
var $author$project$TxnForm$DisbRuleUnverified$addDisbButton = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4,
			$elm$html$Html$Attributes$class('text-slate-blue font-size-medium hover-underline hover-pointer'),
			$elm$html$Html$Events$onClick($author$project$TxnForm$DisbRuleUnverified$CreateDisbToggled)
		]),
	_List_fromArray(
		[
			$author$project$Asset$plusCircleGlyph(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('text-slate-blue font-size-22')
				])),
			A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml1,
					$elm$html$Html$Attributes$class('align-middle'),
					A2($elm$html$Html$Attributes$attribute, 'data-cy', 'addDisbBtn')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Add Disbursement')
				]))
		]));
var $author$project$TxnForm$DisbRuleUnverified$addDisbButtonOrHeading = function (model) {
	return model.aQ ? A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4,
				$elm$html$Html$Attributes$class('font-size-large'),
				$elm$html$Html$Events$onClick($author$project$TxnForm$DisbRuleUnverified$NoOp)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Create Disbursement')
			])) : $author$project$TxnForm$DisbRuleUnverified$addDisbButton;
};
var $author$project$Copy$disbUnverifiedDialogue = _List_fromArray(
	[
		A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('font-weight-bold')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Instructions: ')
			])),
		$elm$html$Html$text(' 4US has confirmed a payment left your bank account.'),
		$elm$html$Html$text(' Below you’ll see what information  was pulled from your bank statement.'),
		$elm$html$Html$text(' Please reconcile this transaction with the list of disbursements you pre-populated, or create a new record with the required data.'),
		$elm$html$Html$text(' This transaction will not show up on your disclosure report until the required compliance data is provided.'),
		A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]),
		_List_fromArray(
			[
				$elm$html$Html$text(' If you run into any issues please email us at support@4US.net. Our team is ready to help.')
			]))
	]);
var $author$project$TxnForm$DisbRuleUnverified$dialogueBox = A2($rundis$elm_bootstrap$Bootstrap$Alert$simpleInfo, _List_Nil, $author$project$Copy$disbUnverifiedDialogue);
var $author$project$TxnForm$DisbRuleUnverified$AddressLine1Updated = function (a) {
	return {$: 2, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$AddressLine2Updated = function (a) {
	return {$: 3, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$AmountUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$CheckNumberUpdated = function (a) {
	return {$: 15, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$CityUpdated = function (a) {
	return {$: 4, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$CreateDisbSubmitted = {$: 17};
var $author$project$TxnForm$DisbRuleUnverified$EntityNameUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$IsExistingLiabilityUpdated = function (a) {
	return {$: 10, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$IsInKindUpdated = function (a) {
	return {$: 11, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$IsPartialPaymentUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$IsSubcontractedUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$PaymentDateUpdated = function (a) {
	return {$: 13, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$PaymentMethodUpdated = function (a) {
	return {$: 14, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$PostalCodeUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$PurposeCodeUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$StateUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$TxnForm$DisbRuleUnverified$exitButton = F2(
	function (hideMsg, displayText) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
					$rundis$elm_bootstrap$Bootstrap$Button$block,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					_List_fromArray(
						[
							$elm$html$Html$Events$onClick(hideMsg)
						]))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(displayText)
				]));
	});
var $author$project$TxnForm$DisbRuleUnverified$buttonRow = F6(
	function (hideMsg, displayText, exitText, msg, submitting, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs,
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb5]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg4,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-left')
								]))
						]),
					_List_fromArray(
						[
							A2($author$project$TxnForm$DisbRuleUnverified$exitButton, hideMsg, exitText)
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg4]),
					_List_fromArray(
						[
							A4($author$project$SubmitButton$submitButton, displayText, msg, submitting, disabled)
						]))
				]));
	});
var $author$project$TxnForm$DisbRuleUnverified$dateWithFormat = function (model) {
	return (model.kE === '') ? A2(
		$author$project$Timestamp$formDate,
		$justinmimbs$timezone_data$TimeZone$america__new_york(0),
		model.ik.kE) : model.kE;
};
var $author$project$TxnForm$DisbRuleUnverified$disbFormRow = function (model) {
	return model.aQ ? _Utils_ap(
		$author$project$DisbInfo$view(
			{
				h9: _Utils_Tuple2(model.h9, $author$project$TxnForm$DisbRuleUnverified$AddressLine1Updated),
				ia: _Utils_Tuple2(model.ia, $author$project$TxnForm$DisbRuleUnverified$AddressLine2Updated),
				ig: $elm$core$Maybe$Just(
					_Utils_Tuple2(model.ig, $author$project$TxnForm$DisbRuleUnverified$AmountUpdated)),
				iu: _Utils_Tuple2(model.iu, $author$project$TxnForm$DisbRuleUnverified$CheckNumberUpdated),
				iw: _Utils_Tuple2(model.iw, $author$project$TxnForm$DisbRuleUnverified$CityUpdated),
				iQ: false,
				i5: _Utils_Tuple2(model.i5, $author$project$TxnForm$DisbRuleUnverified$EntityNameUpdated),
				eM: false,
				m8: _Utils_Tuple2(model.m8, $author$project$TxnForm$DisbRuleUnverified$IsExistingLiabilityUpdated),
				m9: _Utils_Tuple2(model.m9, $author$project$TxnForm$DisbRuleUnverified$IsInKindUpdated),
				na: _Utils_Tuple2(model.na, $author$project$TxnForm$DisbRuleUnverified$IsPartialPaymentUpdated),
				nb: _Utils_Tuple2(model.nb, $author$project$TxnForm$DisbRuleUnverified$IsSubcontractedUpdated),
				kc: model.kc,
				kE: $elm$core$Maybe$Just(
					_Utils_Tuple2(
						$author$project$TxnForm$DisbRuleUnverified$dateWithFormat(model),
						$author$project$TxnForm$DisbRuleUnverified$PaymentDateUpdated)),
				kF: $elm$core$Maybe$Just(
					_Utils_Tuple2(model.kF, $author$project$TxnForm$DisbRuleUnverified$PaymentMethodUpdated)),
				kM: _Utils_Tuple2(model.kM, $author$project$TxnForm$DisbRuleUnverified$PostalCodeUpdated),
				nE: _Utils_Tuple2(model.nE, $author$project$TxnForm$DisbRuleUnverified$PurposeCodeUpdated),
				ln: _Utils_Tuple2(model.ln, $author$project$TxnForm$DisbRuleUnverified$StateUpdated),
				ly: $author$project$TxnForm$DisbRuleUnverified$NoOp,
				n7: $elm$core$Maybe$Just(model.ik.jB)
			}),
		_List_fromArray(
			[
				A6(
				$author$project$TxnForm$DisbRuleUnverified$buttonRow,
				$author$project$TxnForm$DisbRuleUnverified$CreateDisbToggled,
				'Create',
				'Cancel',
				$author$project$TxnForm$DisbRuleUnverified$CreateDisbSubmitted,
				model.bb,
				!A2($rtfeldman$elm_validate$Validate$any, $author$project$TxnForm$DisbRuleUnverified$requiredFieldValidators, model))
			])) : _List_Nil;
};
var $author$project$TxnForm$DisbRuleUnverified$matchesIcon = function (val) {
	return val ? $author$project$Asset$circleCheckGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-green font-size-large')
			])) : $author$project$Asset$timesGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-danger font-size-large')
			]));
};
var $author$project$TxnForm$DisbRuleUnverified$labelWithBankVerificationIcon = F2(
	function (label, matchesStatus) {
		return A2(
			$author$project$LabelWithData$labelWithContent,
			label,
			$author$project$TxnForm$DisbRuleUnverified$matchesIcon(matchesStatus));
	});
var $author$project$TxnForm$DisbRuleUnverified$reconcileInfoRow = F2(
	function (bankTxn, selectedTxns) {
		var selectedTotal = A3(
			$elm$core$List$foldr,
			F2(
				function (txn, acc) {
					return acc + txn.ig;
				}),
			0,
			selectedTxns);
		var matches = _Utils_eq(selectedTotal, bankTxn.ig);
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
				]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
					_List_fromArray(
						[
							A2(
							$author$project$LabelWithData$labelWithData,
							'Amount',
							$author$project$Cents$toDollar(bankTxn.ig))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
					_List_fromArray(
						[
							A2(
							$author$project$LabelWithData$labelWithData,
							'Total Selected',
							$author$project$Cents$toDollar(selectedTotal))
						])),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$md4]),
					_List_fromArray(
						[
							A2($author$project$TxnForm$DisbRuleUnverified$labelWithBankVerificationIcon, 'Matches', matches)
						]))
				]));
	});
var $author$project$TxnForm$DisbRuleUnverified$labels = _List_fromArray(
	['Selected', 'Date', 'Entity Name', 'Amount', 'Purpose Code']);
var $author$project$TxnForm$DisbRuleUnverified$RelatedTransactionClicked = F2(
	function (a, b) {
		return {$: 18, a: a, b: b};
	});
var $author$project$TxnForm$DisbRuleUnverified$isSelected = F2(
	function (txn, selected) {
		return A2(
			$elm$core$List$any,
			function (val) {
				return _Utils_eq(val.jB, txn.jB);
			},
			selected);
	});
var $author$project$TxnForm$DisbRuleUnverified$transactionRowMap = function (_v0) {
	var maybeSelected = _v0.a;
	var maybeMsg = _v0.b;
	var txn = _v0.c;
	var selected = A2($elm$core$Maybe$withDefault, _List_Nil, maybeSelected);
	var name = A2(
		$elm$core$Maybe$withDefault,
		$author$project$Transactions$missingContent,
		A2(
			$elm$core$Maybe$map,
			$author$project$Transactions$uppercaseText,
			$author$project$Transactions$getEntityName(txn)));
	var isChecked = A2($author$project$TxnForm$DisbRuleUnverified$isSelected, txn, selected);
	var amount = $author$project$Transactions$getAmount(txn);
	return _Utils_Tuple2(
		$elm$core$Maybe$Nothing,
		_List_fromArray(
			[
				_Utils_Tuple2(
				'Selected',
				A2(
					$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checkbox,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$id(txn.jB),
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$checked(isChecked),
							$rundis$elm_bootstrap$Bootstrap$Form$Checkbox$onCheck(
							$author$project$TxnForm$DisbRuleUnverified$RelatedTransactionClicked(txn))
						]),
					'')),
				_Utils_Tuple2(
				'Date / Time',
				$elm$html$Html$text(
					A2(
						$author$project$Timestamp$format,
						$justinmimbs$timezone_data$TimeZone$america__new_york(0),
						txn.kE))),
				_Utils_Tuple2('Entity Name', name),
				_Utils_Tuple2('Amount', amount),
				_Utils_Tuple2(
				'Purpose Code',
				$author$project$Transactions$getContext(txn))
			]));
};
var $author$project$TxnForm$DisbRuleUnverified$reconcileItemsTable = F2(
	function (relatedTxns, selectedTxns) {
		return A4(
			$author$project$DataTable$view,
			'Awaiting Transactions.',
			$author$project$TxnForm$DisbRuleUnverified$labels,
			$author$project$TxnForm$DisbRuleUnverified$transactionRowMap,
			A2(
				$elm$core$List$map,
				function (d) {
					return _Utils_Tuple3(
						$elm$core$Maybe$Just(selectedTxns),
						$elm$core$Maybe$Nothing,
						d);
				},
				relatedTxns));
	});
var $author$project$TxnForm$DisbRuleUnverified$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$TxnForm$DisbRuleUnverified$dialogueBox,
				A2($author$project$BankData$view, true, model.ik),
				A2(
				$elm$html$Html$h6,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]),
				_List_fromArray(
					[
						$elm$html$Html$text('Reconcile')
					])),
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2($author$project$TxnForm$DisbRuleUnverified$reconcileInfoRow, model.ik, model.li),
							$author$project$TxnForm$DisbRuleUnverified$addDisbButtonOrHeading(model)
						]),
					_Utils_ap(
						$author$project$TxnForm$DisbRuleUnverified$disbFormRow(model),
						_List_fromArray(
							[
								A2($author$project$TxnForm$DisbRuleUnverified$reconcileItemsTable, model.fA, model.li)
							]))))
			]));
};
var $author$project$Page$Transactions$disbRuleUnverifiedModal = function (model) {
	return $author$project$PlatformModal$view(
		{
			b8: $elm$core$Maybe$Nothing,
			r: $elm$core$Maybe$Nothing,
			ca: $author$project$Page$Transactions$DisbRuleUnverifiedModalAnimate,
			ck: $author$project$Page$Transactions$DisbRuleUnverifiedModalHide,
			jB: $author$project$FormID$toString(2),
			co: model.G,
			V: false,
			gO: $author$project$TxnForm$DisbRuleUnverified$toSubmitDisabled(model.D),
			aG: model.bI,
			ct: $elm$core$Maybe$Nothing,
			cH: model.D,
			cI: $author$project$TxnForm$DisbRuleUnverified$view,
			cJ: $author$project$Page$Transactions$DisbRuleUnverifiedSubmit,
			cK: 'Reconcile',
			cL: model.c6,
			cM: ' Reconciliation Successful!',
			lu: 'Reconcile Disbursement',
			cR: $author$project$Page$Transactions$DisbRuleUnverifiedModalUpdate,
			cT: model.bd
		});
};
var $author$project$Page$Transactions$DisbRuleVerifiedModalHide = {$: 37};
var $author$project$Page$Transactions$DisbRuleVerifiedSubmit = {$: 40};
var $author$project$TxnForm$DisbRuleVerified$toTxn = function (model) {
	return model.dX;
};
var $author$project$TxnForm$DisbRuleVerified$BankDataToggled = {$: 18};
var $author$project$TxnForm$DisbRuleVerified$CheckNumberUpdated = function (a) {
	return {$: 16, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$AddressLine1Updated = function (a) {
	return {$: 3, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$AddressLine2Updated = function (a) {
	return {$: 4, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$CityUpdated = function (a) {
	return {$: 5, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$EditFormToggled = {$: 17};
var $author$project$TxnForm$DisbRuleVerified$EntityNameUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$IsExistingLiabilityUpdated = function (a) {
	return {$: 11, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$IsInKindUpdated = function (a) {
	return {$: 12, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$IsPartialPaymentUpdated = function (a) {
	return {$: 10, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$IsSubcontractedUpdated = function (a) {
	return {$: 9, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$PostalCodeUpdated = function (a) {
	return {$: 7, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$PurposeCodeUpdated = function (a) {
	return {$: 8, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$StateUpdated = function (a) {
	return {$: 6, a: a};
};
var $author$project$TxnForm$DisbRuleVerified$disbFormRow = function (model) {
	return $author$project$DisbInfo$view(
		{
			h9: _Utils_Tuple2(model.h9, $author$project$TxnForm$DisbRuleVerified$AddressLine1Updated),
			ia: _Utils_Tuple2(model.ia, $author$project$TxnForm$DisbRuleVerified$AddressLine2Updated),
			ig: $elm$core$Maybe$Nothing,
			iu: _Utils_Tuple2(model.iu, $author$project$TxnForm$DisbRuleVerified$CheckNumberUpdated),
			iw: _Utils_Tuple2(model.iw, $author$project$TxnForm$DisbRuleVerified$CityUpdated),
			iQ: model.bP,
			i5: _Utils_Tuple2(model.i5, $author$project$TxnForm$DisbRuleVerified$EntityNameUpdated),
			eM: true,
			m8: _Utils_Tuple2(model.m8, $author$project$TxnForm$DisbRuleVerified$IsExistingLiabilityUpdated),
			m9: _Utils_Tuple2(
				$elm$core$Maybe$Just(false),
				$author$project$TxnForm$DisbRuleVerified$IsInKindUpdated),
			na: _Utils_Tuple2(model.na, $author$project$TxnForm$DisbRuleVerified$IsPartialPaymentUpdated),
			nb: _Utils_Tuple2(model.nb, $author$project$TxnForm$DisbRuleVerified$IsSubcontractedUpdated),
			kc: model.kc,
			kE: $elm$core$Maybe$Nothing,
			kF: $elm$core$Maybe$Nothing,
			kM: _Utils_Tuple2(model.kM, $author$project$TxnForm$DisbRuleVerified$PostalCodeUpdated),
			nE: _Utils_Tuple2(model.nE, $author$project$TxnForm$DisbRuleVerified$PurposeCodeUpdated),
			ln: _Utils_Tuple2(model.ln, $author$project$TxnForm$DisbRuleVerified$StateUpdated),
			ly: $author$project$TxnForm$DisbRuleVerified$EditFormToggled,
			n7: $elm$core$Maybe$Just(model.dX.jB)
		});
};
var $author$project$TxnForm$DisbRuleVerified$loadedView = function (model) {
	var bankData = model.dX.ga ? A3($author$project$ExpandableBankData$view, model.dJ, model.dX, $author$project$TxnForm$DisbRuleVerified$BankDataToggled) : _List_Nil;
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$container,
		_List_Nil,
		_Utils_ap(
			_List_Nil,
			_Utils_ap(
				$author$project$PaymentInfo$view(model.dX),
				_Utils_ap(
					$author$project$TxnForm$DisbRuleVerified$disbFormRow(model),
					_Utils_ap(
						bankData,
						function () {
							var _v0 = model.kF;
							if ((!_v0.$) && (_v0.a === 3)) {
								var _v1 = _v0.a;
								return _List_fromArray(
									[
										A2(
										$rundis$elm_bootstrap$Bootstrap$Grid$row,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
												_List_fromArray(
													[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt2]))
											]),
										_List_fromArray(
											[
												A2(
												$rundis$elm_bootstrap$Bootstrap$Grid$col,
												_List_Nil,
												_List_fromArray(
													[
														A5($author$project$AppInput$inputText, $author$project$TxnForm$DisbRuleVerified$CheckNumberUpdated, model.iu, model.bP, 'createDisbCheck', '*Check Number')
													]))
											]))
									]);
							} else {
								return _List_Nil;
							}
						}())))));
};
var $author$project$TxnForm$DisbRuleVerified$loadingView = $author$project$Loading$view;
var $author$project$TxnForm$DisbRuleVerified$view = function (model) {
	return model.dt ? $author$project$TxnForm$DisbRuleVerified$loadingView : $author$project$TxnForm$DisbRuleVerified$loadedView(model);
};
var $author$project$Page$Transactions$disbRuleVerifiedModal = function (model) {
	return $author$project$PlatformModal$view(
		{
			b8: $elm$core$Maybe$Just($author$project$Page$Transactions$DeleteAlertMsg),
			r: $elm$core$Maybe$Just(model.r),
			ca: $author$project$Page$Transactions$DisbRuleVerifiedModalAnimate,
			ck: $author$project$Page$Transactions$DisbRuleVerifiedModalHide,
			jB: $author$project$FormID$toString(4),
			co: model.G,
			V: model.V,
			gO: model.p.gO,
			aG: model.bJ,
			ct: A3($author$project$Page$Transactions$toDeleteMsg, model, $author$project$TxnForm$DisbRuleVerified$toTxn, model.p),
			cH: model.p,
			cI: $author$project$TxnForm$DisbRuleVerified$view,
			cJ: $author$project$Page$Transactions$DisbRuleVerifiedSubmit,
			cK: 'Save',
			cL: model.c7,
			cM: ' Revision Successful!',
			lu: 'Disbursement',
			cR: $author$project$Page$Transactions$DisbRuleVerifiedModalUpdate,
			cT: model.aR
		});
};
var $author$project$Page$Transactions$CreateDisbursementModalShow = {$: 30};
var $author$project$Page$Transactions$ShowCreateContributionModal = {$: 4};
var $author$project$Page$Transactions$ShowGenerateDisclosureModal = {$: 6};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$DropdownItem = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem = F2(
	function (attributes, children) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Attributes$class('dropdown-item')
					]),
				attributes),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropDir = function (maybeDir) {
	var toAttrs = function (dir) {
		return _List_fromArray(
			[
				$elm$html$Html$Attributes$class(
				'drop' + function () {
					if (!dir) {
						return 'left';
					} else {
						return 'right';
					}
				}())
			]);
	};
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2($elm$core$Maybe$map, toAttrs, maybeDir));
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownAttributes = F2(
	function (status, config) {
		return _Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('btn-group', true),
							_Utils_Tuple2('show', status !== 2),
							_Utils_Tuple2('dropup', config.$7)
						]))
				]),
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Dropdown$dropDir(config.c9),
				config.eg));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$menuStyles = F2(
	function (_v0, config) {
		var status = _v0.nR;
		var toggleSize = _v0.fP;
		var menuSize = _v0.dv;
		var px = function (n) {
			return $elm$core$String$fromFloat(n) + 'px';
		};
		var translate = F3(
			function (x, y, z) {
				return 'translate3d(' + (px(x) + (',' + (px(y) + (',' + (px(z) + ')')))));
			});
		var _default = _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'top', '0'),
				A2($elm$html$Html$Attributes$style, 'left', '0')
			]);
		var _v1 = _Utils_Tuple2(config.$7, config.c9);
		_v1$0:
		while (true) {
			if (!_v1.b.$) {
				if (_v1.b.a === 1) {
					if (_v1.a) {
						break _v1$0;
					} else {
						var _v2 = _v1.b.a;
						return _default;
					}
				} else {
					if (_v1.a) {
						break _v1$0;
					} else {
						var _v3 = _v1.b.a;
						return _Utils_ap(
							_default,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$Attributes$style,
									'transform',
									A3(translate, (-toggleSize.lZ) - menuSize.lZ, 0, 0))
								]));
					}
				}
			} else {
				if (_v1.a) {
					break _v1$0;
				} else {
					return _Utils_ap(
						_default,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$Attributes$style,
								'transform',
								A3(translate, -toggleSize.lZ, toggleSize.jx, 0))
							]));
				}
			}
		}
		return _Utils_ap(
			_default,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Attributes$style,
					'transform',
					A3(translate, -toggleSize.lZ, -menuSize.jx, 0))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownMenu = F3(
	function (state, config, items) {
		var status = state.nR;
		var menuSize = state.dv;
		var wrapperStyles = (status === 2) ? _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'height', '0'),
				A2($elm$html$Html$Attributes$style, 'overflow', 'hidden'),
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]) : _List_fromArray(
			[
				A2($elm$html$Html$Attributes$style, 'position', 'relative')
			]);
		return A2(
			$elm$html$Html$div,
			wrapperStyles,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_Utils_ap(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('dropdown-menu', true),
										_Utils_Tuple2('dropdown-menu-right', config.eI),
										_Utils_Tuple2('show', status !== 2)
									]))
							]),
						_Utils_ap(
							A2($rundis$elm_bootstrap$Bootstrap$Dropdown$menuStyles, state, config),
							config.eV)),
					A2(
						$elm$core$List$map,
						function (_v0) {
							var x = _v0;
							return x;
						},
						items))
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$applyModifier = F2(
	function (option, options) {
		switch (option.$) {
			case 1:
				return _Utils_update(
					options,
					{eI: true});
			case 0:
				return _Utils_update(
					options,
					{$7: true});
			case 4:
				var attrs_ = option.a;
				return _Utils_update(
					options,
					{eg: attrs_});
			case 2:
				var dir = option.a;
				return _Utils_update(
					options,
					{
						c9: $elm$core$Maybe$Just(dir)
					});
			default:
				var attrs_ = option.a;
				return _Utils_update(
					options,
					{eV: attrs_});
		}
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$defaultOptions = {eg: _List_Nil, c9: $elm$core$Maybe$Nothing, eI: false, $7: false, eV: _List_Nil};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$toConfig = function (options) {
	return A3($elm$core$List$foldl, $rundis$elm_bootstrap$Bootstrap$Dropdown$applyModifier, $rundis$elm_bootstrap$Bootstrap$Dropdown$defaultOptions, options);
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown = F2(
	function (state, _v0) {
		var status = state.nR;
		var toggleMsg = _v0.lz;
		var toggleButton = _v0.lx;
		var items = _v0.jW;
		var options = _v0.ny;
		var config = $rundis$elm_bootstrap$Bootstrap$Dropdown$toConfig(options);
		var _v1 = toggleButton;
		var buttonFn = _v1;
		return A2(
			$elm$html$Html$div,
			A2($rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownAttributes, status, config),
			_List_fromArray(
				[
					A2(buttonFn, toggleMsg, state),
					A3($rundis$elm_bootstrap$Bootstrap$Dropdown$dropdownMenu, state, config, items)
				]));
	});
var $author$project$Committee$isPolicapital = function (model) {
	return !model.hp;
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl3 = $elm$html$Html$Attributes$class('pl-3');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr3 = $elm$html$Html$Attributes$class('pr-3');
var $rundis$elm_bootstrap$Bootstrap$Dropdown$DropdownToggle = $elm$core$Basics$identity;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$Open = 0;
var $rundis$elm_bootstrap$Bootstrap$Dropdown$nextStatus = function (status) {
	switch (status) {
		case 0:
			return 2;
		case 1:
			return 2;
		default:
			return 0;
	}
};
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight = A2($elm$json$Json$Decode$field, 'offsetHeight', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $elm$json$Json$Decode$map4 = _Json_map4;
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft = A2($elm$json$Json$Decode$field, 'offsetLeft', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent = F2(
	function (x, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$field,
					'offsetParent',
					$elm$json$Json$Decode$null(x)),
					A2($elm$json$Json$Decode$field, 'offsetParent', decoder)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop = A2($elm$json$Json$Decode$field, 'offsetTop', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft = A2($elm$json$Json$Decode$field, 'scrollLeft', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop = A2($elm$json$Json$Decode$field, 'scrollTop', $elm$json$Json$Decode$float);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position = F2(
	function (x, y) {
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var x_ = _v0.a;
				var y_ = _v0.b;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetParent,
					_Utils_Tuple2(x_, y_),
					A2($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, x_, y_));
			},
			A5(
				$elm$json$Json$Decode$map4,
				F4(
					function (scrollLeft_, scrollTop_, offsetLeft_, offsetTop_) {
						return _Utils_Tuple2((x + offsetLeft_) - scrollLeft_, (y + offsetTop_) - scrollTop_);
					}),
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollLeft,
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$scrollTop,
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetLeft,
				$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetTop));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea = A4(
	$elm$json$Json$Decode$map3,
	F3(
		function (_v0, width, height) {
			var x = _v0.a;
			var y = _v0.b;
			return {jx: height, gP: x, fQ: y, lZ: width};
		}),
	A2($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$position, 0, 0),
	$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetWidth,
	$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$offsetHeight);
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$childNode = function (idx) {
	return $elm$json$Json$Decode$at(
		_List_fromArray(
			[
				'childNodes',
				$elm$core$String$fromInt(idx)
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$nextSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'nextSibling', decoder);
};
var $rundis$elm_bootstrap$Bootstrap$Dropdown$isToggle = A2(
	$elm$json$Json$Decode$andThen,
	function (_class) {
		return A2($elm$core$String$contains, 'dropdown-toggle', _class) ? $elm$json$Json$Decode$succeed(true) : $elm$json$Json$Decode$succeed(false);
	},
	$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className);
var $rundis$elm_bootstrap$Bootstrap$Dropdown$toggler = F2(
	function (path, decoder) {
		return $elm$json$Json$Decode$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$json$Json$Decode$andThen,
					function (res) {
						return res ? A2($elm$json$Json$Decode$at, path, decoder) : $elm$json$Json$Decode$fail('');
					},
					A2($elm$json$Json$Decode$at, path, $rundis$elm_bootstrap$Bootstrap$Dropdown$isToggle)),
					A2(
					$elm$json$Json$Decode$andThen,
					function (_v0) {
						return A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$toggler,
							_Utils_ap(
								path,
								_List_fromArray(
									['parentElement'])),
							decoder);
					},
					A2(
						$elm$json$Json$Decode$at,
						_Utils_ap(
							path,
							_List_fromArray(
								['parentElement'])),
						$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$className)),
					$elm$json$Json$Decode$fail('No toggler found')
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$sizeDecoder = A3(
	$elm$json$Json$Decode$map2,
	$elm$core$Tuple$pair,
	A2(
		$rundis$elm_bootstrap$Bootstrap$Dropdown$toggler,
		_List_fromArray(
			['target']),
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea),
	A2(
		$rundis$elm_bootstrap$Bootstrap$Dropdown$toggler,
		_List_fromArray(
			['target']),
		$rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$nextSibling(
			A2($rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$childNode, 0, $rundis$elm_bootstrap$Bootstrap$Utilities$DomHelper$boundingArea))));
var $rundis$elm_bootstrap$Bootstrap$Dropdown$clickHandler = F2(
	function (toMsg, state) {
		var status = state.nR;
		return A2(
			$elm$json$Json$Decode$andThen,
			function (_v0) {
				var b = _v0.a;
				var m = _v0.b;
				return $elm$json$Json$Decode$succeed(
					toMsg(
						{
							dv: m,
							nR: $rundis$elm_bootstrap$Bootstrap$Dropdown$nextStatus(status),
							fP: b
						}));
			},
			$rundis$elm_bootstrap$Bootstrap$Dropdown$sizeDecoder);
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$togglePrivate = F4(
	function (buttonOptions, children, toggleMsg, state) {
		return A2(
			$elm$html$Html$button,
			_Utils_ap(
				$rundis$elm_bootstrap$Bootstrap$Internal$Button$buttonAttributes(buttonOptions),
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('dropdown-toggle'),
						$elm$html$Html$Attributes$type_('button'),
						A2(
						$elm$html$Html$Events$on,
						'click',
						A2($rundis$elm_bootstrap$Bootstrap$Dropdown$clickHandler, toggleMsg, state))
					])),
			children);
	});
var $rundis$elm_bootstrap$Bootstrap$Dropdown$toggle = F2(
	function (buttonOptions, children) {
		return A2($rundis$elm_bootstrap$Bootstrap$Dropdown$togglePrivate, buttonOptions, children);
	});
var $author$project$Page$Transactions$actionsDropdown = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown,
				model.cU,
				{
					jW: _List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$ShowCreateContributionModal)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Create Contribution')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$CreateDisbursementModalShow)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Create Disbursement')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$ShowGenerateDisclosureModal)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Get Disclosure')
								]))
						]),
					ny: _List_Nil,
					lx: A2(
						$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$success,
								$rundis$elm_bootstrap$Bootstrap$Button$disabled(
								$author$project$Committee$isPolicapital(model.iD)),
								$rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl3,
										$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr3,
										$elm$html$Html$Attributes$id('actions-dropdown')
									]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Actions')
							])),
					lz: $author$project$Page$Transactions$ToggleActionsDropdown
				})
			]));
};
var $author$project$Page$Transactions$FilterAll = {$: 26};
var $author$project$Page$Transactions$FilterByContributions = {$: 22};
var $author$project$Page$Transactions$FilterByDisbursements = {$: 23};
var $author$project$Page$Transactions$FilterNeedsReview = {$: 24};
var $author$project$Page$Transactions$filtersDropdown = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown,
				model.dc,
				{
					jW: _List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$FilterNeedsReview)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Needs Review')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$FilterByContributions)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Contributions')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$FilterByDisbursements)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Disbursements')
								])),
							A2(
							$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick($author$project$Page$Transactions$FilterAll)
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('All Transactions')
								]))
						]),
					ny: _List_Nil,
					lx: A2(
						$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Button$success,
								$rundis$elm_bootstrap$Bootstrap$Button$disabled(
								$author$project$Committee$isPolicapital(model.iD)),
								$rundis$elm_bootstrap$Bootstrap$Button$attrs(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr3]))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Filters')
							])),
					lz: $author$project$Page$Transactions$ToggleFiltersDropdown
				})
			]));
};
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml0 = $elm$html$Html$Attributes$class('ml-0');
var $rundis$elm_bootstrap$Bootstrap$Grid$Internal$Col1 = 1;
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$xs1 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 0, 1);
var $author$project$Page$Transactions$dropdowns = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('justify-content-start')
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs1,
								$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml0, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0]))
							]),
						_List_fromArray(
							[
								$author$project$Page$Transactions$actionsDropdown(model)
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
						_List_fromArray(
							[
								$author$project$Page$Transactions$filtersDropdown(model)
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('text-center')
									]))
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$h2,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(model.bR)
									]))
							])),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[
								$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs1,
								$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
								_List_fromArray(
									[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml0, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl0]))
							]),
						_List_Nil),
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_fromArray(
							[$rundis$elm_bootstrap$Bootstrap$Grid$Col$xs2]),
						_List_Nil)
					]))
			]));
};
var $author$project$Page$Transactions$FileDisclosure = {$: 20};
var $author$project$Page$Transactions$GenerateReport = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Page$Transactions$HideGenerateDisclosureModal = {$: 5};
var $author$project$Page$Transactions$ReturnFromGenerateDisclosureModalPreview = {$: 19};
var $author$project$Page$Transactions$exitButton = A2(
	$rundis$elm_bootstrap$Bootstrap$Button$button,
	_List_fromArray(
		[
			$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
			$rundis$elm_bootstrap$Bootstrap$Button$block,
			$rundis$elm_bootstrap$Bootstrap$Button$attrs(
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick($author$project$Page$Transactions$HideCreateContributionModal)
				]))
		]),
	_List_fromArray(
		[
			$elm$html$Html$text('Exit')
		]));
var $author$project$Page$Transactions$buttonRow = F5(
	function (displayText, msg, submitting, enableExit, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[$rundis$elm_bootstrap$Bootstrap$Grid$Row$betweenXs]),
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3,
							$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('text-left')
								]))
						]),
					enableExit ? _List_fromArray(
						[$author$project$Page$Transactions$exitButton]) : _List_Nil),
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$col,
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Grid$Col$lg3]),
					_List_fromArray(
						[
							A4($author$project$SubmitButton$submitButton, displayText, msg, submitting, disabled)
						]))
				]));
	});
var $BrianHicks$elm_csv$Csv$Decode$FieldNamesFromFirstRow = {$: 2};
var $author$project$FileDisclosure$aggregateCol = F2(
	function (name, data) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$col,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Col$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-secondary font-weight-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(name)
						])),
					A2(
					$elm$html$Html$h5,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('font-weight-bold'),
							$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt1
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(data)
						]))
				]));
	});
var $author$project$FileDisclosure$aggregateRows = function (aggs) {
	return _List_fromArray(
		[
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$FileDisclosure$aggregateCol,
					'Beginning Cash Balance',
					$author$project$Cents$stringToDollar('0')),
					A2(
					$author$project$FileDisclosure$aggregateCol,
					'Ending Cash Balance',
					$author$project$Cents$stringToDollar(
						$elm$core$String$fromInt(aggs.f8)))
				])),
			A2(
			$rundis$elm_bootstrap$Bootstrap$Grid$row,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
					_List_fromArray(
						[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$FileDisclosure$aggregateCol,
					'Total Contributions Received',
					$author$project$Cents$stringToDollar(
						$elm$core$String$fromInt(aggs.hZ))),
					A2(
					$author$project$FileDisclosure$aggregateCol,
					'Total Expenditures',
					$author$project$Cents$stringToDollar(
						$elm$core$String$fromInt(aggs.h_)))
				]))
		]);
};
var $author$project$FileDisclosure$backButton = function (backMsg) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Button$button,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
				$rundis$elm_bootstrap$Bootstrap$Button$block,
				$rundis$elm_bootstrap$Bootstrap$Button$attrs(
				_List_fromArray(
					[
						$elm$html$Html$Events$onClick(backMsg)
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text('Back')
			]));
};
var $BrianHicks$elm_csv$Csv$Decode$ParsingError = function (a) {
	return {$: 0, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$DecodingErrors = function (a) {
	return {$: 2, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$OnlyColumn_ = {$: 2};
var $BrianHicks$elm_csv$Csv$Decode$NoFieldNamesOnFirstRow = {$: 1};
var $elm$core$String$trim = _String_trim;
var $BrianHicks$elm_csv$Csv$Decode$getFieldNames = F2(
	function (headers, rows) {
		var fromList = function (names) {
			return A3(
				$elm$core$List$foldl,
				F2(
					function (name, _v2) {
						var soFar = _v2.a;
						var i = _v2.b;
						return _Utils_Tuple2(
							A3($elm$core$Dict$insert, name, i, soFar),
							i + 1);
					}),
				_Utils_Tuple2($elm$core$Dict$empty, 0),
				names).a;
		};
		switch (headers.$) {
			case 0:
				return $elm$core$Result$Ok(
					_Utils_Tuple3($elm$core$Dict$empty, 0, rows));
			case 1:
				var names = headers.a;
				return $elm$core$Result$Ok(
					_Utils_Tuple3(
						fromList(names),
						0,
						rows));
			default:
				if (!rows.b) {
					return $elm$core$Result$Err($BrianHicks$elm_csv$Csv$Decode$NoFieldNamesOnFirstRow);
				} else {
					var first = rows.a;
					var rest = rows.b;
					return $elm$core$Result$Ok(
						_Utils_Tuple3(
							fromList(
								A2($elm$core$List$map, $elm$core$String$trim, first)),
							1,
							rest));
				}
		}
	});
var $BrianHicks$elm_csv$Csv$Decode$applyDecoder = F3(
	function (fieldNames, _v0, allRows) {
		var decode = _v0;
		var defaultLocation = $BrianHicks$elm_csv$Csv$Decode$OnlyColumn_;
		return A2(
			$elm$core$Result$andThen,
			function (_v1) {
				var resolvedNames = _v1.a;
				var firstRowNumber = _v1.b;
				var rows = _v1.c;
				return A2(
					$elm$core$Result$mapError,
					A2($elm$core$Basics$composeL, $BrianHicks$elm_csv$Csv$Decode$DecodingErrors, $elm$core$List$reverse),
					A2(
						$elm$core$Result$map,
						$elm$core$List$reverse,
						A3(
							$elm$core$List$foldl,
							F2(
								function (row, _v2) {
									var soFar = _v2.a;
									var rowNum = _v2.b;
									return _Utils_Tuple2(
										function () {
											var _v3 = A4(decode, defaultLocation, resolvedNames, rowNum, row);
											if (!_v3.$) {
												var val = _v3.a;
												if (!soFar.$) {
													var values = soFar.a;
													return $elm$core$Result$Ok(
														A2($elm$core$List$cons, val, values));
												} else {
													var errs = soFar.a;
													return $elm$core$Result$Err(errs);
												}
											} else {
												var err = _v3.a;
												if (!soFar.$) {
													return $elm$core$Result$Err(
														_List_fromArray(
															[err]));
												} else {
													var errs = soFar.a;
													return $elm$core$Result$Err(
														A2($elm$core$List$cons, err, errs));
												}
											}
										}(),
										rowNum + 1);
								}),
							_Utils_Tuple2(
								$elm$core$Result$Ok(_List_Nil),
								firstRowNumber),
							rows).a));
			},
			A2($BrianHicks$elm_csv$Csv$Decode$getFieldNames, fieldNames, allRows));
	});
var $BrianHicks$elm_csv$Csv$Parser$AdditionalCharactersAfterClosingQuote = function (a) {
	return {$: 1, a: a};
};
var $BrianHicks$elm_csv$Csv$Parser$SourceEndedWithoutClosingQuote = function (a) {
	return {$: 0, a: a};
};
var $BrianHicks$elm_csv$Csv$Parser$parse = F2(
	function (config, source) {
		var finalLength = $elm$core$String$length(source);
		var parseQuotedField = F4(
			function (isFieldSeparator, soFar, startOffset, endOffset) {
				parseQuotedField:
				while (true) {
					if ((endOffset - finalLength) >= 0) {
						return $elm$core$Result$Err($BrianHicks$elm_csv$Csv$Parser$SourceEndedWithoutClosingQuote);
					} else {
						if (A3($elm$core$String$slice, endOffset, endOffset + 1, source) === '\"') {
							var segment = A3($elm$core$String$slice, startOffset, endOffset, source);
							if (((endOffset + 2) - finalLength) >= 0) {
								return $elm$core$Result$Ok(
									_Utils_Tuple3(
										_Utils_ap(soFar, segment),
										endOffset + 1,
										false));
							} else {
								var next = A3($elm$core$String$slice, endOffset + 1, endOffset + 2, source);
								if (next === '\"') {
									var newPos = endOffset + 2;
									var $temp$isFieldSeparator = isFieldSeparator,
										$temp$soFar = soFar + (segment + '\"'),
										$temp$startOffset = newPos,
										$temp$endOffset = newPos;
									isFieldSeparator = $temp$isFieldSeparator;
									soFar = $temp$soFar;
									startOffset = $temp$startOffset;
									endOffset = $temp$endOffset;
									continue parseQuotedField;
								} else {
									if (isFieldSeparator(next)) {
										return $elm$core$Result$Ok(
											_Utils_Tuple3(
												_Utils_ap(soFar, segment),
												endOffset + 2,
												false));
									} else {
										if (next === '\n') {
											return $elm$core$Result$Ok(
												_Utils_Tuple3(
													_Utils_ap(soFar, segment),
													endOffset + 2,
													true));
										} else {
											if ((next === '\u000D') && (A3($elm$core$String$slice, endOffset + 2, endOffset + 3, source) === '\n')) {
												return $elm$core$Result$Ok(
													_Utils_Tuple3(
														_Utils_ap(soFar, segment),
														endOffset + 3,
														true));
											} else {
												return $elm$core$Result$Err($BrianHicks$elm_csv$Csv$Parser$AdditionalCharactersAfterClosingQuote);
											}
										}
									}
								}
							}
						} else {
							var $temp$isFieldSeparator = isFieldSeparator,
								$temp$soFar = soFar,
								$temp$startOffset = startOffset,
								$temp$endOffset = endOffset + 1;
							isFieldSeparator = $temp$isFieldSeparator;
							soFar = $temp$soFar;
							startOffset = $temp$startOffset;
							endOffset = $temp$endOffset;
							continue parseQuotedField;
						}
					}
				}
			});
		var parseComma = F4(
			function (row, rows, startOffset, endOffset) {
				parseComma:
				while (true) {
					if ((endOffset - finalLength) >= 0) {
						var finalField = A3($elm$core$String$slice, startOffset, endOffset, source);
						return ((finalField === '') && _Utils_eq(row, _List_Nil)) ? $elm$core$Result$Ok(
							$elm$core$List$reverse(rows)) : $elm$core$Result$Ok(
							$elm$core$List$reverse(
								A2(
									$elm$core$List$cons,
									$elm$core$List$reverse(
										A2($elm$core$List$cons, finalField, row)),
									rows)));
					} else {
						var first = A3($elm$core$String$slice, endOffset, endOffset + 1, source);
						if (first === ',') {
							var newPos = endOffset + 1;
							var $temp$row = A2(
								$elm$core$List$cons,
								A3($elm$core$String$slice, startOffset, endOffset, source),
								row),
								$temp$rows = rows,
								$temp$startOffset = newPos,
								$temp$endOffset = newPos;
							row = $temp$row;
							rows = $temp$rows;
							startOffset = $temp$startOffset;
							endOffset = $temp$endOffset;
							continue parseComma;
						} else {
							if (first === '\n') {
								var newPos = endOffset + 1;
								var $temp$row = _List_Nil,
									$temp$rows = A2(
									$elm$core$List$cons,
									$elm$core$List$reverse(
										A2(
											$elm$core$List$cons,
											A3($elm$core$String$slice, startOffset, endOffset, source),
											row)),
									rows),
									$temp$startOffset = newPos,
									$temp$endOffset = newPos;
								row = $temp$row;
								rows = $temp$rows;
								startOffset = $temp$startOffset;
								endOffset = $temp$endOffset;
								continue parseComma;
							} else {
								if ((first === '\u000D') && (A3($elm$core$String$slice, endOffset + 1, endOffset + 2, source) === '\n')) {
									var newPos = endOffset + 2;
									var $temp$row = _List_Nil,
										$temp$rows = A2(
										$elm$core$List$cons,
										$elm$core$List$reverse(
											A2(
												$elm$core$List$cons,
												A3($elm$core$String$slice, startOffset, endOffset, source),
												row)),
										rows),
										$temp$startOffset = newPos,
										$temp$endOffset = newPos;
									row = $temp$row;
									rows = $temp$rows;
									startOffset = $temp$startOffset;
									endOffset = $temp$endOffset;
									continue parseComma;
								} else {
									if (first === '\"') {
										var newPos = endOffset + 1;
										var _v0 = A4(
											parseQuotedField,
											function (c) {
												return c === ',';
											},
											'',
											newPos,
											newPos);
										if (!_v0.$) {
											var _v1 = _v0.a;
											var value = _v1.a;
											var afterQuotedField = _v1.b;
											var rowEnded = _v1.c;
											if (_Utils_cmp(afterQuotedField, finalLength) > -1) {
												return $elm$core$Result$Ok(
													$elm$core$List$reverse(
														A2(
															$elm$core$List$cons,
															$elm$core$List$reverse(
																A2($elm$core$List$cons, value, row)),
															rows)));
											} else {
												if (rowEnded) {
													var $temp$row = _List_Nil,
														$temp$rows = A2(
														$elm$core$List$cons,
														$elm$core$List$reverse(
															A2($elm$core$List$cons, value, row)),
														rows),
														$temp$startOffset = afterQuotedField,
														$temp$endOffset = afterQuotedField;
													row = $temp$row;
													rows = $temp$rows;
													startOffset = $temp$startOffset;
													endOffset = $temp$endOffset;
													continue parseComma;
												} else {
													var $temp$row = A2($elm$core$List$cons, value, row),
														$temp$rows = rows,
														$temp$startOffset = afterQuotedField,
														$temp$endOffset = afterQuotedField;
													row = $temp$row;
													rows = $temp$rows;
													startOffset = $temp$startOffset;
													endOffset = $temp$endOffset;
													continue parseComma;
												}
											}
										} else {
											var problem = _v0.a;
											return $elm$core$Result$Err(
												problem(
													$elm$core$List$length(rows) + 1));
										}
									} else {
										var $temp$row = row,
											$temp$rows = rows,
											$temp$startOffset = startOffset,
											$temp$endOffset = endOffset + 1;
										row = $temp$row;
										rows = $temp$rows;
										startOffset = $temp$startOffset;
										endOffset = $temp$endOffset;
										continue parseComma;
									}
								}
							}
						}
					}
				}
			});
		var parseHelp = F5(
			function (isFieldSeparator, row, rows, startOffset, endOffset) {
				parseHelp:
				while (true) {
					if ((endOffset - finalLength) >= 0) {
						var finalField = A3($elm$core$String$slice, startOffset, endOffset, source);
						return ((finalField === '') && _Utils_eq(row, _List_Nil)) ? $elm$core$Result$Ok(
							$elm$core$List$reverse(rows)) : $elm$core$Result$Ok(
							$elm$core$List$reverse(
								A2(
									$elm$core$List$cons,
									$elm$core$List$reverse(
										A2($elm$core$List$cons, finalField, row)),
									rows)));
					} else {
						var first = A3($elm$core$String$slice, endOffset, endOffset + 1, source);
						if (isFieldSeparator(first)) {
							var newPos = endOffset + 1;
							var $temp$isFieldSeparator = isFieldSeparator,
								$temp$row = A2(
								$elm$core$List$cons,
								A3($elm$core$String$slice, startOffset, endOffset, source),
								row),
								$temp$rows = rows,
								$temp$startOffset = newPos,
								$temp$endOffset = newPos;
							isFieldSeparator = $temp$isFieldSeparator;
							row = $temp$row;
							rows = $temp$rows;
							startOffset = $temp$startOffset;
							endOffset = $temp$endOffset;
							continue parseHelp;
						} else {
							if (first === '\n') {
								var newPos = endOffset + 1;
								var $temp$isFieldSeparator = isFieldSeparator,
									$temp$row = _List_Nil,
									$temp$rows = A2(
									$elm$core$List$cons,
									$elm$core$List$reverse(
										A2(
											$elm$core$List$cons,
											A3($elm$core$String$slice, startOffset, endOffset, source),
											row)),
									rows),
									$temp$startOffset = newPos,
									$temp$endOffset = newPos;
								isFieldSeparator = $temp$isFieldSeparator;
								row = $temp$row;
								rows = $temp$rows;
								startOffset = $temp$startOffset;
								endOffset = $temp$endOffset;
								continue parseHelp;
							} else {
								if ((first === '\u000D') && (A3($elm$core$String$slice, endOffset + 1, endOffset + 2, source) === '\n')) {
									var newPos = endOffset + 2;
									var $temp$isFieldSeparator = isFieldSeparator,
										$temp$row = _List_Nil,
										$temp$rows = A2(
										$elm$core$List$cons,
										$elm$core$List$reverse(
											A2(
												$elm$core$List$cons,
												A3($elm$core$String$slice, startOffset, endOffset, source),
												row)),
										rows),
										$temp$startOffset = newPos,
										$temp$endOffset = newPos;
									isFieldSeparator = $temp$isFieldSeparator;
									row = $temp$row;
									rows = $temp$rows;
									startOffset = $temp$startOffset;
									endOffset = $temp$endOffset;
									continue parseHelp;
								} else {
									if (first === '\"') {
										var newPos = endOffset + 1;
										var _v2 = A4(parseQuotedField, isFieldSeparator, '', newPos, newPos);
										if (!_v2.$) {
											var _v3 = _v2.a;
											var value = _v3.a;
											var afterQuotedField = _v3.b;
											var rowEnded = _v3.c;
											if (_Utils_cmp(afterQuotedField, finalLength) > -1) {
												return $elm$core$Result$Ok(
													$elm$core$List$reverse(
														A2(
															$elm$core$List$cons,
															$elm$core$List$reverse(
																A2($elm$core$List$cons, value, row)),
															rows)));
											} else {
												if (rowEnded) {
													var $temp$isFieldSeparator = isFieldSeparator,
														$temp$row = _List_Nil,
														$temp$rows = A2(
														$elm$core$List$cons,
														$elm$core$List$reverse(
															A2($elm$core$List$cons, value, row)),
														rows),
														$temp$startOffset = afterQuotedField,
														$temp$endOffset = afterQuotedField;
													isFieldSeparator = $temp$isFieldSeparator;
													row = $temp$row;
													rows = $temp$rows;
													startOffset = $temp$startOffset;
													endOffset = $temp$endOffset;
													continue parseHelp;
												} else {
													var $temp$isFieldSeparator = isFieldSeparator,
														$temp$row = A2($elm$core$List$cons, value, row),
														$temp$rows = rows,
														$temp$startOffset = afterQuotedField,
														$temp$endOffset = afterQuotedField;
													isFieldSeparator = $temp$isFieldSeparator;
													row = $temp$row;
													rows = $temp$rows;
													startOffset = $temp$startOffset;
													endOffset = $temp$endOffset;
													continue parseHelp;
												}
											}
										} else {
											var problem = _v2.a;
											return $elm$core$Result$Err(
												problem(
													$elm$core$List$length(rows) + 1));
										}
									} else {
										var $temp$isFieldSeparator = isFieldSeparator,
											$temp$row = row,
											$temp$rows = rows,
											$temp$startOffset = startOffset,
											$temp$endOffset = endOffset + 1;
										isFieldSeparator = $temp$isFieldSeparator;
										row = $temp$row;
										rows = $temp$rows;
										startOffset = $temp$startOffset;
										endOffset = $temp$endOffset;
										continue parseHelp;
									}
								}
							}
						}
					}
				}
			});
		var parseSemicolon = F4(
			function (row, rows, startOffset, endOffset) {
				parseSemicolon:
				while (true) {
					if ((endOffset - finalLength) >= 0) {
						var finalField = A3($elm$core$String$slice, startOffset, endOffset, source);
						return ((finalField === '') && _Utils_eq(row, _List_Nil)) ? $elm$core$Result$Ok(
							$elm$core$List$reverse(rows)) : $elm$core$Result$Ok(
							$elm$core$List$reverse(
								A2(
									$elm$core$List$cons,
									$elm$core$List$reverse(
										A2($elm$core$List$cons, finalField, row)),
									rows)));
					} else {
						var first = A3($elm$core$String$slice, endOffset, endOffset + 1, source);
						if (first === ';') {
							var newPos = endOffset + 1;
							var $temp$row = A2(
								$elm$core$List$cons,
								A3($elm$core$String$slice, startOffset, endOffset, source),
								row),
								$temp$rows = rows,
								$temp$startOffset = newPos,
								$temp$endOffset = newPos;
							row = $temp$row;
							rows = $temp$rows;
							startOffset = $temp$startOffset;
							endOffset = $temp$endOffset;
							continue parseSemicolon;
						} else {
							if (first === '\n') {
								var newPos = endOffset + 1;
								var $temp$row = _List_Nil,
									$temp$rows = A2(
									$elm$core$List$cons,
									$elm$core$List$reverse(
										A2(
											$elm$core$List$cons,
											A3($elm$core$String$slice, startOffset, endOffset, source),
											row)),
									rows),
									$temp$startOffset = newPos,
									$temp$endOffset = newPos;
								row = $temp$row;
								rows = $temp$rows;
								startOffset = $temp$startOffset;
								endOffset = $temp$endOffset;
								continue parseSemicolon;
							} else {
								if ((first === '\u000D') && (A3($elm$core$String$slice, endOffset + 1, endOffset + 2, source) === '\n')) {
									var newPos = endOffset + 2;
									var $temp$row = _List_Nil,
										$temp$rows = A2(
										$elm$core$List$cons,
										$elm$core$List$reverse(
											A2(
												$elm$core$List$cons,
												A3($elm$core$String$slice, startOffset, endOffset, source),
												row)),
										rows),
										$temp$startOffset = newPos,
										$temp$endOffset = newPos;
									row = $temp$row;
									rows = $temp$rows;
									startOffset = $temp$startOffset;
									endOffset = $temp$endOffset;
									continue parseSemicolon;
								} else {
									if (first === '\"') {
										var newPos = endOffset + 1;
										var _v4 = A4(
											parseQuotedField,
											function (c) {
												return c === ';';
											},
											'',
											newPos,
											newPos);
										if (!_v4.$) {
											var _v5 = _v4.a;
											var value = _v5.a;
											var afterQuotedField = _v5.b;
											var rowEnded = _v5.c;
											if (_Utils_cmp(afterQuotedField, finalLength) > -1) {
												return $elm$core$Result$Ok(
													$elm$core$List$reverse(
														A2(
															$elm$core$List$cons,
															$elm$core$List$reverse(
																A2($elm$core$List$cons, value, row)),
															rows)));
											} else {
												if (rowEnded) {
													var $temp$row = _List_Nil,
														$temp$rows = A2(
														$elm$core$List$cons,
														$elm$core$List$reverse(
															A2($elm$core$List$cons, value, row)),
														rows),
														$temp$startOffset = afterQuotedField,
														$temp$endOffset = afterQuotedField;
													row = $temp$row;
													rows = $temp$rows;
													startOffset = $temp$startOffset;
													endOffset = $temp$endOffset;
													continue parseSemicolon;
												} else {
													var $temp$row = A2($elm$core$List$cons, value, row),
														$temp$rows = rows,
														$temp$startOffset = afterQuotedField,
														$temp$endOffset = afterQuotedField;
													row = $temp$row;
													rows = $temp$rows;
													startOffset = $temp$startOffset;
													endOffset = $temp$endOffset;
													continue parseSemicolon;
												}
											}
										} else {
											var problem = _v4.a;
											return $elm$core$Result$Err(
												problem(
													$elm$core$List$length(rows) + 1));
										}
									} else {
										var $temp$row = row,
											$temp$rows = rows,
											$temp$startOffset = startOffset,
											$temp$endOffset = endOffset + 1;
										row = $temp$row;
										rows = $temp$rows;
										startOffset = $temp$startOffset;
										endOffset = $temp$endOffset;
										continue parseSemicolon;
									}
								}
							}
						}
					}
				}
			});
		var fieldSeparator = $elm$core$String$fromChar(config.mO);
		return $elm$core$String$isEmpty(source) ? $elm$core$Result$Ok(_List_Nil) : ((config.mO === ',') ? A4(parseComma, _List_Nil, _List_Nil, 0, 0) : ((config.mO === ';') ? A4(parseSemicolon, _List_Nil, _List_Nil, 0, 0) : A5(
			parseHelp,
			function (s) {
				return _Utils_eq(s, fieldSeparator);
			},
			_List_Nil,
			_List_Nil,
			0,
			0)));
	});
var $BrianHicks$elm_csv$Csv$Decode$decodeCustom = F4(
	function (config, fieldNames, decoder, source) {
		return A2(
			$elm$core$Result$andThen,
			A2($BrianHicks$elm_csv$Csv$Decode$applyDecoder, fieldNames, decoder),
			A2(
				$elm$core$Result$mapError,
				$BrianHicks$elm_csv$Csv$Decode$ParsingError,
				A2($BrianHicks$elm_csv$Csv$Parser$parse, config, source)));
	});
var $BrianHicks$elm_csv$Csv$Decode$decodeCsv = $BrianHicks$elm_csv$Csv$Decode$decodeCustom(
	{mO: ','});
var $author$project$DiscCsv$Model = function (filerId) {
	return function (filingPeriodId) {
		return function (filingCatId) {
			return function (electId) {
				return function (resigTermTypeId) {
					return function (rFilingDate) {
						return function (filingSchedId) {
							return function (loanLibNumber) {
								return function (transNumber) {
									return function (transMapping) {
										return function (schedDate) {
											return function (orgDate) {
												return function (cntrbrTypeId) {
													return function (cntrbnTypeId) {
														return function (transferTypeId) {
															return function (receiptTypeId) {
																return function (receiptCodeId) {
																	return function (purposeCodeId) {
																		return function (isExpenditureSubcontracted) {
																			return function (isExpenditureAPartialPayment) {
																				return function (isThisExistingLiability) {
																					return function (isLiabilityAPartialForgiven) {
																						return function (flngEntName) {
																							return function (flngEntFirstName) {
																								return function (flngEntMiddleName) {
																									return function (flngEntLastName) {
																										return function (flngEntAdd1) {
																											return function (flngEntCity) {
																												return function (flngEntZip) {
																													return function (flngEntCountry) {
																														return function (paymentTypeId) {
																															return function (payNumber) {
																																return function (owedAmt) {
																																	return function (orgAmt) {
																																		return function (transExplntn) {
																																			return function (loanOtherId) {
																																				return function (rItemized) {
																																					return function (rLiability) {
																																						return function (electionDate) {
																																							return function (electionType) {
																																								return function (electionYear) {
																																									return function (treasId) {
																																										return function (treasOccupation) {
																																											return function (treasEmployer) {
																																												return function (treasAdd1) {
																																													return function (treasCity) {
																																														return function (treasState) {
																																															return function (treasZip) {
																																																return function (partFlngEntId) {
																																																	return function (officeId) {
																																																		return function (district) {
																																																			return function (distOffCandBalProp) {
																																																				return function (ieCntrbrOcc) {
																																																					return function (ieContrbrEmp) {
																																																						return function (ieDesc) {
																																																							return function (rIeSupported) {
																																																								return function (rIeIncluded) {
																																																									return function (rParent) {
																																																										return {ix: cntrbnTypeId, iy: cntrbrTypeId, iR: distOffCandBalProp, iS: district, iX: electId, iY: electionDate, iZ: electionType, i_: electionYear, jb: filerId, jc: filingCatId, jd: filingPeriodId, je: filingSchedId, ji: flngEntAdd1, jj: flngEntCity, jk: flngEntCountry, jl: flngEntFirstName, jm: flngEntLastName, jn: flngEntMiddleName, jo: flngEntName, jp: flngEntZip, jE: ieCntrbrOcc, jF: ieContrbrEmp, jG: ieDesc, jP: isExpenditureAPartialPayment, jQ: isExpenditureSubcontracted, jR: isLiabilityAPartialForgiven, jT: isThisExistingLiability, j5: loanLibNumber, j6: loanOtherId, km: officeId, kp: orgAmt, kq: orgDate, kt: owedAmt, kA: partFlngEntId, kD: payNumber, kG: paymentTypeId, kS: purposeCodeId, kW: rFilingDate, kX: rIeIncluded, kY: rIeSupported, kZ: rItemized, k_: rLiability, k$: rParent, k0: receiptCodeId, k1: receiptTypeId, k7: resigTermTypeId, lg: schedDate, lD: transExplntn, lE: transMapping, lF: transNumber, lJ: transferTypeId, lK: treasAdd1, lL: treasCity, lM: treasEmployer, lN: treasId, lO: treasOccupation, lP: treasState, lQ: treasZip};
																																																									};
																																																								};
																																																							};
																																																						};
																																																					};
																																																				};
																																																			};
																																																		};
																																																	};
																																																};
																																															};
																																														};
																																													};
																																												};
																																											};
																																										};
																																									};
																																								};
																																							};
																																						};
																																					};
																																				};
																																			};
																																		};
																																	};
																																};
																															};
																														};
																													};
																												};
																											};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $BrianHicks$elm_csv$Csv$Decode$Decoder = $elm$core$Basics$identity;
var $BrianHicks$elm_csv$Csv$Decode$Field_ = function (a) {
	return {$: 1, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$field = F2(
	function (name, _v0) {
		var decoder = _v0;
		return F3(
			function (_v1, fieldNames, row) {
				return A3(
					decoder,
					$BrianHicks$elm_csv$Csv$Decode$Field_(name),
					fieldNames,
					row);
			});
	});
var $BrianHicks$elm_csv$Csv$Decode$succeed = function (value) {
	return F4(
		function (_v0, _v1, _v2, _v3) {
			return $elm$core$Result$Ok(value);
		});
};
var $BrianHicks$elm_csv$Csv$Decode$into = $BrianHicks$elm_csv$Csv$Decode$succeed;
var $BrianHicks$elm_csv$Csv$Decode$map2 = F3(
	function (transform, _v0, _v1) {
		var decodeA = _v0;
		var decodeB = _v1;
		return F4(
			function (location, fieldNames, rowNum, row) {
				return A3(
					$elm$core$Result$map2,
					transform,
					A4(decodeA, location, fieldNames, rowNum, row),
					A4(decodeB, location, fieldNames, rowNum, row));
			});
	});
var $BrianHicks$elm_csv$Csv$Decode$pipeline = $BrianHicks$elm_csv$Csv$Decode$map2(
	F2(
		function (value, fn) {
			return fn(value);
		}));
var $BrianHicks$elm_csv$Csv$Decode$ColumnNotFound = function (a) {
	return {$: 0, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$ExpectedOneColumn = function (a) {
	return {$: 3, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$FieldNotFound = function (a) {
	return {$: 1, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$FieldNotProvided = function (a) {
	return {$: 2, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$Column = function (a) {
	return {$: 0, a: a};
};
var $BrianHicks$elm_csv$Csv$Decode$Field = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $BrianHicks$elm_csv$Csv$Decode$OnlyColumn = {$: 2};
var $BrianHicks$elm_csv$Csv$Decode$locationToColumn = F2(
	function (fieldNames, location) {
		switch (location.$) {
			case 0:
				var i = location.a;
				return $BrianHicks$elm_csv$Csv$Decode$Column(i);
			case 1:
				var name = location.a;
				return A2(
					$BrianHicks$elm_csv$Csv$Decode$Field,
					name,
					A2($elm$core$Dict$get, name, fieldNames));
			default:
				return $BrianHicks$elm_csv$Csv$Decode$OnlyColumn;
		}
	});
var $BrianHicks$elm_csv$Csv$Decode$fromString = function (convert) {
	return F4(
		function (location, fieldNames, rowNum, row) {
			switch (location.$) {
				case 0:
					var colNum = location.a;
					var _v1 = $elm$core$List$head(
						A2($elm$core$List$drop, colNum, row));
					if (!_v1.$) {
						var value = _v1.a;
						var _v2 = convert(value);
						if (!_v2.$) {
							var converted = _v2.a;
							return $elm$core$Result$Ok(converted);
						} else {
							var problem = _v2.a;
							return $elm$core$Result$Err(
								{
									aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
									X: _List_fromArray(
										[problem]),
									aK: rowNum
								});
						}
					} else {
						return $elm$core$Result$Err(
							{
								aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
								X: _List_fromArray(
									[
										$BrianHicks$elm_csv$Csv$Decode$ColumnNotFound(colNum)
									]),
								aK: rowNum
							});
					}
				case 1:
					var name = location.a;
					var _v3 = A2($elm$core$Dict$get, name, fieldNames);
					if (!_v3.$) {
						var colNum = _v3.a;
						var _v4 = $elm$core$List$head(
							A2($elm$core$List$drop, colNum, row));
						if (!_v4.$) {
							var value = _v4.a;
							var _v5 = convert(value);
							if (!_v5.$) {
								var converted = _v5.a;
								return $elm$core$Result$Ok(converted);
							} else {
								var problem = _v5.a;
								return $elm$core$Result$Err(
									{
										aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
										X: _List_fromArray(
											[problem]),
										aK: rowNum
									});
							}
						} else {
							return $elm$core$Result$Err(
								{
									aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
									X: _List_fromArray(
										[
											$BrianHicks$elm_csv$Csv$Decode$FieldNotFound(name)
										]),
									aK: rowNum
								});
						}
					} else {
						return $elm$core$Result$Err(
							{
								aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
								X: _List_fromArray(
									[
										$BrianHicks$elm_csv$Csv$Decode$FieldNotProvided(name)
									]),
								aK: rowNum
							});
					}
				default:
					if (!row.b) {
						return $elm$core$Result$Err(
							{
								aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
								X: _List_fromArray(
									[
										$BrianHicks$elm_csv$Csv$Decode$ColumnNotFound(0)
									]),
								aK: rowNum
							});
					} else {
						if (!row.b.b) {
							var only = row.a;
							var _v7 = convert(only);
							if (!_v7.$) {
								var converted = _v7.a;
								return $elm$core$Result$Ok(converted);
							} else {
								var problem = _v7.a;
								return $elm$core$Result$Err(
									{
										aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
										X: _List_fromArray(
											[problem]),
										aK: rowNum
									});
							}
						} else {
							return $elm$core$Result$Err(
								{
									aB: A2($BrianHicks$elm_csv$Csv$Decode$locationToColumn, fieldNames, location),
									X: _List_fromArray(
										[
											$BrianHicks$elm_csv$Csv$Decode$ExpectedOneColumn(
											$elm$core$List$length(row))
										]),
									aK: rowNum
								});
						}
					}
			}
		});
};
var $BrianHicks$elm_csv$Csv$Decode$string = $BrianHicks$elm_csv$Csv$Decode$fromString($elm$core$Result$Ok);
var $author$project$DiscCsv$decoder = A2(
	$BrianHicks$elm_csv$Csv$Decode$pipeline,
	A2($BrianHicks$elm_csv$Csv$Decode$field, 'R_PARENT', $BrianHicks$elm_csv$Csv$Decode$string),
	A2(
		$BrianHicks$elm_csv$Csv$Decode$pipeline,
		A2($BrianHicks$elm_csv$Csv$Decode$field, 'R_IE_INCLUDED', $BrianHicks$elm_csv$Csv$Decode$string),
		A2(
			$BrianHicks$elm_csv$Csv$Decode$pipeline,
			A2($BrianHicks$elm_csv$Csv$Decode$field, 'R_IE_SUPPORTED', $BrianHicks$elm_csv$Csv$Decode$string),
			A2(
				$BrianHicks$elm_csv$Csv$Decode$pipeline,
				A2($BrianHicks$elm_csv$Csv$Decode$field, 'IE_DESC', $BrianHicks$elm_csv$Csv$Decode$string),
				A2(
					$BrianHicks$elm_csv$Csv$Decode$pipeline,
					A2($BrianHicks$elm_csv$Csv$Decode$field, 'IE_CNTRBR_EMP', $BrianHicks$elm_csv$Csv$Decode$string),
					A2(
						$BrianHicks$elm_csv$Csv$Decode$pipeline,
						A2($BrianHicks$elm_csv$Csv$Decode$field, 'IE_CNTRBR_OCC', $BrianHicks$elm_csv$Csv$Decode$string),
						A2(
							$BrianHicks$elm_csv$Csv$Decode$pipeline,
							A2($BrianHicks$elm_csv$Csv$Decode$field, 'DIST_OFF_CAND_BAL_PROP', $BrianHicks$elm_csv$Csv$Decode$string),
							A2(
								$BrianHicks$elm_csv$Csv$Decode$pipeline,
								A2($BrianHicks$elm_csv$Csv$Decode$field, 'DISTRICT', $BrianHicks$elm_csv$Csv$Decode$string),
								A2(
									$BrianHicks$elm_csv$Csv$Decode$pipeline,
									A2($BrianHicks$elm_csv$Csv$Decode$field, 'OFFICE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
									A2(
										$BrianHicks$elm_csv$Csv$Decode$pipeline,
										A2($BrianHicks$elm_csv$Csv$Decode$field, 'PART_FLNG_ENT_ID', $BrianHicks$elm_csv$Csv$Decode$string),
										A2(
											$BrianHicks$elm_csv$Csv$Decode$pipeline,
											A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_ZIP', $BrianHicks$elm_csv$Csv$Decode$string),
											A2(
												$BrianHicks$elm_csv$Csv$Decode$pipeline,
												A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_STATE', $BrianHicks$elm_csv$Csv$Decode$string),
												A2(
													$BrianHicks$elm_csv$Csv$Decode$pipeline,
													A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_CITY', $BrianHicks$elm_csv$Csv$Decode$string),
													A2(
														$BrianHicks$elm_csv$Csv$Decode$pipeline,
														A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_ADD1', $BrianHicks$elm_csv$Csv$Decode$string),
														A2(
															$BrianHicks$elm_csv$Csv$Decode$pipeline,
															A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_EMPLOYER', $BrianHicks$elm_csv$Csv$Decode$string),
															A2(
																$BrianHicks$elm_csv$Csv$Decode$pipeline,
																A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_OCCUPATION', $BrianHicks$elm_csv$Csv$Decode$string),
																A2(
																	$BrianHicks$elm_csv$Csv$Decode$pipeline,
																	A2($BrianHicks$elm_csv$Csv$Decode$field, 'TREAS_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																	A2(
																		$BrianHicks$elm_csv$Csv$Decode$pipeline,
																		A2($BrianHicks$elm_csv$Csv$Decode$field, 'ELECTION_YEAR', $BrianHicks$elm_csv$Csv$Decode$string),
																		A2(
																			$BrianHicks$elm_csv$Csv$Decode$pipeline,
																			A2($BrianHicks$elm_csv$Csv$Decode$field, 'ELECTION_TYPE', $BrianHicks$elm_csv$Csv$Decode$string),
																			A2(
																				$BrianHicks$elm_csv$Csv$Decode$pipeline,
																				A2($BrianHicks$elm_csv$Csv$Decode$field, 'ELECTION_DATE', $BrianHicks$elm_csv$Csv$Decode$string),
																				A2(
																					$BrianHicks$elm_csv$Csv$Decode$pipeline,
																					A2($BrianHicks$elm_csv$Csv$Decode$field, 'R_LIABILITY', $BrianHicks$elm_csv$Csv$Decode$string),
																					A2(
																						$BrianHicks$elm_csv$Csv$Decode$pipeline,
																						A2($BrianHicks$elm_csv$Csv$Decode$field, 'R_ITEMIZED', $BrianHicks$elm_csv$Csv$Decode$string),
																						A2(
																							$BrianHicks$elm_csv$Csv$Decode$pipeline,
																							A2($BrianHicks$elm_csv$Csv$Decode$field, 'LOAN_OTHER_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																							A2(
																								$BrianHicks$elm_csv$Csv$Decode$pipeline,
																								A2($BrianHicks$elm_csv$Csv$Decode$field, 'TRANS_EXPLNTN', $BrianHicks$elm_csv$Csv$Decode$string),
																								A2(
																									$BrianHicks$elm_csv$Csv$Decode$pipeline,
																									A2($BrianHicks$elm_csv$Csv$Decode$field, 'ORG_AMT', $BrianHicks$elm_csv$Csv$Decode$string),
																									A2(
																										$BrianHicks$elm_csv$Csv$Decode$pipeline,
																										A2($BrianHicks$elm_csv$Csv$Decode$field, 'OWED_AMT', $BrianHicks$elm_csv$Csv$Decode$string),
																										A2(
																											$BrianHicks$elm_csv$Csv$Decode$pipeline,
																											A2($BrianHicks$elm_csv$Csv$Decode$field, 'PAY_NUMBER', $BrianHicks$elm_csv$Csv$Decode$string),
																											A2(
																												$BrianHicks$elm_csv$Csv$Decode$pipeline,
																												A2($BrianHicks$elm_csv$Csv$Decode$field, 'PAYMENT_TYPE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																												A2(
																													$BrianHicks$elm_csv$Csv$Decode$pipeline,
																													A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_COUNTRY', $BrianHicks$elm_csv$Csv$Decode$string),
																													A2(
																														$BrianHicks$elm_csv$Csv$Decode$pipeline,
																														A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_ZIP', $BrianHicks$elm_csv$Csv$Decode$string),
																														A2(
																															$BrianHicks$elm_csv$Csv$Decode$pipeline,
																															A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_STATE', $BrianHicks$elm_csv$Csv$Decode$string),
																															A2(
																																$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_CITY', $BrianHicks$elm_csv$Csv$Decode$string),
																																A2(
																																	$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																	A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_ADD1', $BrianHicks$elm_csv$Csv$Decode$string),
																																	A2(
																																		$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																		A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_LAST_NAME', $BrianHicks$elm_csv$Csv$Decode$string),
																																		A2(
																																			$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																			A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_MIDDLE_NAME', $BrianHicks$elm_csv$Csv$Decode$string),
																																			A2(
																																				$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																				A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_FIRST_NAME', $BrianHicks$elm_csv$Csv$Decode$string),
																																				A2(
																																					$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																					A2($BrianHicks$elm_csv$Csv$Decode$field, 'FLNG_ENT_NAME', $BrianHicks$elm_csv$Csv$Decode$string),
																																					A2(
																																						$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																						A2($BrianHicks$elm_csv$Csv$Decode$field, 'Is Liability a Partial Forgiven?', $BrianHicks$elm_csv$Csv$Decode$string),
																																						A2(
																																							$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																							A2($BrianHicks$elm_csv$Csv$Decode$field, 'Is this existing Liability?', $BrianHicks$elm_csv$Csv$Decode$string),
																																							A2(
																																								$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																								A2($BrianHicks$elm_csv$Csv$Decode$field, 'Is Expenditure a Partial Payment?', $BrianHicks$elm_csv$Csv$Decode$string),
																																								A2(
																																									$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																									A2($BrianHicks$elm_csv$Csv$Decode$field, 'Is Expenditure Subcontracted?', $BrianHicks$elm_csv$Csv$Decode$string),
																																									A2(
																																										$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																										A2($BrianHicks$elm_csv$Csv$Decode$field, 'PURPOSE_CODE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																										A2(
																																											$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																											A2($BrianHicks$elm_csv$Csv$Decode$field, 'RECEIPT_CODE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																											A2(
																																												$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																												A2($BrianHicks$elm_csv$Csv$Decode$field, 'RECEIPT_TYPE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																												A2(
																																													$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																													A2($BrianHicks$elm_csv$Csv$Decode$field, 'TRANSFER_TYPE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																													A2(
																																														$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																														A2($BrianHicks$elm_csv$Csv$Decode$field, 'CNTRBN_TYPE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																														A2(
																																															$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																															A2($BrianHicks$elm_csv$Csv$Decode$field, 'CNTRBR_TYPE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																															A2(
																																																$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																A2($BrianHicks$elm_csv$Csv$Decode$field, 'ORG_DATE', $BrianHicks$elm_csv$Csv$Decode$string),
																																																A2(
																																																	$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																	A2($BrianHicks$elm_csv$Csv$Decode$field, 'SCHED_DATE', $BrianHicks$elm_csv$Csv$Decode$string),
																																																	A2(
																																																		$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																		A2($BrianHicks$elm_csv$Csv$Decode$field, 'TRANS_MAPPING', $BrianHicks$elm_csv$Csv$Decode$string),
																																																		A2(
																																																			$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																			A2($BrianHicks$elm_csv$Csv$Decode$field, 'TRANS_NUMBER', $BrianHicks$elm_csv$Csv$Decode$string),
																																																			A2(
																																																				$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																				A2($BrianHicks$elm_csv$Csv$Decode$field, 'LOAN_LIB_NUMBER', $BrianHicks$elm_csv$Csv$Decode$string),
																																																				A2(
																																																					$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																					A2($BrianHicks$elm_csv$Csv$Decode$field, 'FILING_SCHED_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																																					A2(
																																																						$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																						A2($BrianHicks$elm_csv$Csv$Decode$field, 'R_FILING_DATE', $BrianHicks$elm_csv$Csv$Decode$string),
																																																						A2(
																																																							$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																							A2($BrianHicks$elm_csv$Csv$Decode$field, 'RESIG_TERM_TYPE_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																																							A2(
																																																								$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																								A2($BrianHicks$elm_csv$Csv$Decode$field, 'FILING_CAT_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																																								A2(
																																																									$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																									A2($BrianHicks$elm_csv$Csv$Decode$field, 'FILING_PERIOD_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																																									A2(
																																																										$BrianHicks$elm_csv$Csv$Decode$pipeline,
																																																										A2($BrianHicks$elm_csv$Csv$Decode$field, 'FILER_ID', $BrianHicks$elm_csv$Csv$Decode$string),
																																																										$BrianHicks$elm_csv$Csv$Decode$into($author$project$DiscCsv$Model)))))))))))))))))))))))))))))))))))))))))))))))))))))))))));
var $author$project$DiscCsv$disclosureRowMap = function (_v0) {
	var maybeMsg = _v0.b;
	var d = _v0.c;
	return _Utils_Tuple2(
		maybeMsg,
		_List_fromArray(
			[
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jb)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jd)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jc)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.iX)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.k7)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kW)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.je)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.j5)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lF)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lE)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lg)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kq)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.iy)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.ix)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lJ)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.k1)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.k0)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kS)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jQ)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jP)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jT)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jR)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jo)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jl)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jn)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jm)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.ji)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jj)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jp)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jk)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kG)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kD)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kt)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kp)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lD)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.j6)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kZ)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.k_)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.iY)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.iZ)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.i_)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lN)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lO)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lM)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lK)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lL)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lP)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.lQ)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kA)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.km)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.iS)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.iR)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jE)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jF)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.jG)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kY)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.kX)),
				_Utils_Tuple2(
				'',
				$elm$html$Html$text(d.k$))
			]));
};
var $author$project$FileFormat$CSV = 1;
var $author$project$FileFormat$PDF = 0;
var $author$project$FileDisclosure$downloadRows = F3(
	function (_v0, _v1, downloadMsg) {
		var dropdownToggleDownloadMsg = _v0.a;
		var dropdownDownloadState = _v0.b;
		var dropdownTogglePreviewMsg = _v1.a;
		var dropdownTogglePreviewState = _v1.b;
		var pdfMsg = downloadMsg(0);
		var csvMsg = downloadMsg(1);
		return _List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$row,
				_List_fromArray(
					[
						$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(_List_Nil)
					]),
				_List_fromArray(
					[
						A2(
						$rundis$elm_bootstrap$Bootstrap$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown,
								dropdownDownloadState,
								{
									jW: _List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													csvMsg(false))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('CSV')
												]))
										]),
									ny: _List_Nil,
									lx: A2(
										$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Button$outlineSuccess,
												$rundis$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4]))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Download as ')
											])),
									lz: dropdownToggleDownloadMsg
								}),
								A2(
								$rundis$elm_bootstrap$Bootstrap$Dropdown$dropdown,
								dropdownTogglePreviewState,
								{
									jW: _List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Dropdown$buttonItem,
											_List_fromArray(
												[
													$elm$html$Html$Events$onClick(
													csvMsg(true))
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('CSV')
												]))
										]),
									ny: _List_Nil,
									lx: A2(
										$rundis$elm_bootstrap$Bootstrap$Dropdown$toggle,
										_List_fromArray(
											[
												$rundis$elm_bootstrap$Bootstrap$Button$outlineSuccess,
												$rundis$elm_bootstrap$Bootstrap$Button$attrs(
												_List_fromArray(
													[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb3, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt4, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml2]))
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Preview')
											])),
									lz: dropdownTogglePreviewMsg
								})
							]))
					]))
			]);
	});
var $author$project$DiscCsv$labels = _List_fromArray(
	['FILER_ID', 'FILING_PERIOD_ID', 'FILING_CAT_ID', 'RESIG_TERM_TYPE_ID', 'R_FILING_DATE', 'FILING_SCHED_ID', 'LOAN_LIB_NUMBER', 'TRANS_NUMBER', 'TRANS_MAPPING', 'SCHED_DATE', 'ORG_DATE', 'CNTRBR_TYPE_ID', 'CNTRBN_TYPE_ID', 'TRANSFER_TYPE_ID', 'RECEIPT_TYPE_ID', 'RECEIPT_CODE_ID', 'PURPOSE_CODE_ID', 'Is Expenditure Subcontracted?', 'Is Expenditure a Partial Payment?', 'Is this existing Liability?', 'Is Liability a Partial Forgiven?', 'FLNG_ENT_NAME', 'FLNG_ENT_FIRST_NAME', 'FLNG_ENT_MIDDLE_NAME', 'FLNG_ENT_LAST_NAME', 'FLNG_ENT_ADD1', 'FLNG_ENT_CITY', 'FLNG_ENT_STATE', 'FLNG_ENT_ZIP', 'FLNG_ENT_COUNTRY', 'PAYMENT_TYPE_ID', 'PAY_NUMBER', 'OWED_AMT', 'ORG_AMT', 'TRANS_EXPLNTN', 'LOAN_OTHER_ID', 'R_ITEMIZED', 'R_LIABILITY', 'ELECTION_DATE', 'ELECTION_TYPE', 'ELECTION_YEAR', 'TREAS_ID', 'TREAS_OCCUPATION', 'TREAS_EMPLOYER', 'TREAS_ADD1', 'TREAS_CITY', 'TREAS_STATE', 'TREAS_ZIP', 'PART_FLNG_ENT_ID', 'OFFICE_ID', 'DISTRICT', 'DIST_OFF_CAND_BAL_PROP', 'IE_CNTRBR_OCC', 'IE_CNTRBR_EMP', 'IE_DESC', 'R_IE_SUPPORTED', 'R_IE_INCLUDED', 'R_PARENT']);
var $rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2 = A2($rundis$elm_bootstrap$Bootstrap$Grid$Internal$width, 1, 2);
var $author$project$FileDisclosure$successRows = _List_fromArray(
	[
		A2(
		$elm$html$Html$h2,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('align-middle text-green'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3
			]),
		_List_fromArray(
			[
				$author$project$Asset$circleCheckGlyph(_List_Nil),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('align-middle text-green'),
						$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml3
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Disclosure Submitted!')
					]))
			]))
	]);
var $author$project$FileDisclosure$titleRows = _List_fromArray(
	[
		A2(
		$rundis$elm_bootstrap$Bootstrap$Grid$row,
		_List_fromArray(
			[
				$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mt3]))
			]),
		_List_fromArray(
			[
				A2(
				$rundis$elm_bootstrap$Bootstrap$Grid$col,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$h4,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text('Summary for Current Period')
							]))
					]))
			]))
	]);
var $author$project$Asset$exclamationCircleGlyph = $author$project$Asset$glyph('fas fa-exclamation-circle');
var $author$project$AppDialogue$warning = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('border border-danger text-danger font-weight-bold shadow-sm rounded'),
				$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$p3
			]),
		_List_fromArray(
			[
				$author$project$Asset$exclamationCircleGlyph(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('font-size-22')
					])),
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$ml3]),
				_List_fromArray(
					[content]))
			]));
};
var $author$project$FileDisclosure$warningRows = F2(
	function (goToNeedsReviewMsg, aggs) {
		if (!aggs.ki) {
			return _List_Nil;
		} else {
			var transactionsAre = (aggs.ki === 1) ? ' transaction needs ' : ' transactions need ';
			var errorMessage = $elm$core$String$fromInt(aggs.ki) + (transactionsAre + ' to be reviewed.');
			return _List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_fromArray(
						[
							$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(_List_Nil)
						]),
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$span,
									_List_fromArray(
										[
											$elm$html$Html$Events$onClick(goToNeedsReviewMsg),
											$elm$html$Html$Attributes$class('hover-underline-red hover-pointer')
										]),
									_List_fromArray(
										[
											$author$project$AppDialogue$warning(
											$elm$html$Html$text(errorMessage))
										]))
								]))
						]))
				]);
		}
	});
var $author$project$FileDisclosure$view = F8(
	function (aggs, dropdownDownloadConfig, dropdownPreviewConfig, downloadMsg, goToNeedsReviewMsg, submitted, preview, backMsg) {
		if (!preview.$) {
			var a = preview.a;
			var decodedCsv = A3($BrianHicks$elm_csv$Csv$Decode$decodeCsv, $BrianHicks$elm_csv$Csv$Decode$FieldNamesFromFirstRow, $author$project$DiscCsv$decoder, a);
			if (!decodedCsv.$) {
				var value = decodedCsv.a;
				return A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$row,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rundis$elm_bootstrap$Bootstrap$Grid$col,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$row,
									_List_fromArray(
										[
											$rundis$elm_bootstrap$Bootstrap$Grid$Row$attrs(
											_List_fromArray(
												[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$mb2]))
										]),
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Grid$col,
											_List_fromArray(
												[$rundis$elm_bootstrap$Bootstrap$Grid$Col$sm2]),
											_List_fromArray(
												[
													$author$project$FileDisclosure$backButton(backMsg)
												]))
										])),
									A2(
									$rundis$elm_bootstrap$Bootstrap$Grid$row,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rundis$elm_bootstrap$Bootstrap$Grid$col,
											_List_Nil,
											_List_fromArray(
												[
													A4(
													$author$project$DataTable$view,
													'',
													$author$project$DiscCsv$labels,
													$author$project$DiscCsv$disclosureRowMap,
													A2(
														$elm$core$List$map,
														function (d) {
															return _Utils_Tuple3($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, d);
														},
														value))
												]))
										]))
								]))
						]));
			} else {
				var error = decodedCsv.a;
				return $elm$html$Html$text('Awaiting Verified Transactions');
			}
		} else {
			var summary = _Utils_ap(
				A2($author$project$FileDisclosure$warningRows, goToNeedsReviewMsg, aggs),
				_Utils_ap(
					$author$project$FileDisclosure$titleRows,
					_Utils_ap(
						$author$project$FileDisclosure$aggregateRows(aggs),
						A3($author$project$FileDisclosure$downloadRows, dropdownDownloadConfig, dropdownPreviewConfig, downloadMsg))));
			var rows = submitted ? $author$project$FileDisclosure$successRows : summary;
			return A2($rundis$elm_bootstrap$Bootstrap$Grid$containerFluid, _List_Nil, rows);
		}
	});
var $author$project$Page$Transactions$generateDisclosureModal = function (model) {
	return A2(
		$rundis$elm_bootstrap$Bootstrap$Modal$view,
		model.bg,
		A3(
			$rundis$elm_bootstrap$Bootstrap$Modal$footer,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rundis$elm_bootstrap$Bootstrap$Grid$containerFluid,
					_List_Nil,
					_List_fromArray(
						[
							A5($author$project$Page$Transactions$buttonRow, 'File', $author$project$Page$Transactions$FileDisclosure, model.c8, false, true)
						]))
				]),
			A3(
				$rundis$elm_bootstrap$Bootstrap$Modal$body,
				_List_Nil,
				_List_fromArray(
					[
						A8(
						$author$project$FileDisclosure$view,
						model.ic,
						_Utils_Tuple2($author$project$Page$Transactions$ToggleGenerateDisclosureModalDownloadDropdown, model.di),
						_Utils_Tuple2($author$project$Page$Transactions$ToggleGenerateDisclosureModalPreviewDropdown, model.dj),
						$author$project$Page$Transactions$GenerateReport,
						$author$project$Page$Transactions$FilterNeedsReview,
						model.ez,
						model.cj,
						$author$project$Page$Transactions$ReturnFromGenerateDisclosureModalPreview)
					]),
				A3(
					$rundis$elm_bootstrap$Bootstrap$Modal$h3,
					_List_Nil,
					_List_fromArray(
						[
							$elm$html$Html$text('Get Disclosure')
						]),
					A2(
						$rundis$elm_bootstrap$Bootstrap$Modal$scrollableBody,
						true,
						A2(
							$rundis$elm_bootstrap$Bootstrap$Modal$hideOnBackdropClick,
							true,
							$rundis$elm_bootstrap$Bootstrap$Modal$large(
								A2(
									$rundis$elm_bootstrap$Bootstrap$Modal$withAnimation,
									$author$project$Page$Transactions$AnimateGenerateDisclosureModal,
									$rundis$elm_bootstrap$Bootstrap$Modal$config($author$project$Page$Transactions$HideGenerateDisclosureModal)))))))));
};
var $author$project$Page$Transactions$MoreTxnsClicked = {$: 55};
var $author$project$SubmitButton$custom = F5(
	function (attrs, label, submitMsg, loading, disabled) {
		return A2(
			$rundis$elm_bootstrap$Bootstrap$Button$button,
			_List_fromArray(
				[
					$rundis$elm_bootstrap$Bootstrap$Button$outlinePrimary,
					$rundis$elm_bootstrap$Bootstrap$Button$attrs(
					A2(
						$elm$core$List$cons,
						$elm$html$Html$Events$onClick(submitMsg),
						attrs)),
					$rundis$elm_bootstrap$Bootstrap$Button$disabled(disabled)
				]),
			_List_fromArray(
				[
					loading ? $author$project$SubmitButton$spinner : $elm$html$Html$text(label)
				]));
	});
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl5 = $elm$html$Html$Attributes$class('pl-5');
var $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr5 = $elm$html$Html$Attributes$class('pr-5');
var $author$project$Page$Transactions$moreTxnsButton = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-center')
			]),
		model.e$ ? _List_Nil : _List_fromArray(
			[
				A5(
				$author$project$SubmitButton$custom,
				_List_fromArray(
					[$rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pl5, $rundis$elm_bootstrap$Bootstrap$Utilities$Spacing$pr5]),
				'Load More',
				$author$project$Page$Transactions$MoreTxnsClicked,
				model.cw,
				model.cw)
			]));
};
var $author$project$Transactions$labels = _List_fromArray(
	['Date / Time', 'Entity Name', 'Entity Type', 'Amount', 'ID Verified', 'Payment Source', 'Ref Code', 'Bank Status']);
var $author$project$EntityType$toGridString = function (entityType) {
	switch (entityType) {
		case 0:
			return 'Family';
		case 1:
			return 'Individual';
		case 2:
			return 'Sole Prop';
		case 3:
			return 'Partnership';
		case 5:
			return 'Candidate';
		case 4:
			return 'Corporation';
		case 6:
			return 'Union';
		case 7:
			return 'Assoc';
		case 8:
			return 'LLC';
		case 9:
			return 'PAC';
		case 10:
			return 'Committee';
		default:
			return 'Other';
	}
};
var $author$project$Transactions$getEntityType = function (transaction) {
	var missingText = (transaction.iP === 1) ? $elm$html$Html$text('N/A') : $author$project$Transactions$missingContent;
	return A2(
		$elm$core$Maybe$withDefault,
		missingText,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeL, $elm$html$Html$text, $author$project$EntityType$toGridString),
			transaction.i6));
};
var $author$project$Transactions$statusContent = function (val) {
	return val ? $author$project$Asset$circleCheckGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-green data-icon-size')
			])) : $author$project$Asset$minusCircleGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-warning data-icon-size')
			]));
};
var $author$project$Transactions$getStatus = function (model) {
	return $author$project$Transactions$statusContent(model.ga);
};
var $author$project$Asset$actBlueLogo = $author$project$Asset$image('actblue-logo.png');
var $author$project$Transactions$getPaymentMethod = function (txn) {
	var _v0 = _Utils_Tuple2(txn.kF, txn.nT);
	return $author$project$PaymentMethod$toDisplayString(txn.kF);
};
var $author$project$Asset$stripeLogo = $author$project$Asset$image('stripe-logo.png');
var $author$project$Transactions$toPaymentMethodOrProcessor = function (txn) {
	var source = txn.nQ;
	switch (source) {
		case 0:
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Asset$src($author$project$Asset$actBlueLogo),
						$elm$html$Html$Attributes$class('stripe-logo')
					]),
				_List_Nil);
		case 1:
			return A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$author$project$Asset$src($author$project$Asset$stripeLogo),
						$elm$html$Html$Attributes$class('stripe-logo')
					]),
				_List_Nil);
		default:
			return $elm$html$Html$text(
				$author$project$Transactions$getPaymentMethod(txn));
	}
};
var $author$project$Transactions$verifiedContent = function (val) {
	return val ? $author$project$Asset$circleCheckGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-green data-icon-size')
			])) : $author$project$Asset$minusCircleGlyph(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('text-warning data-icon-size')
			]));
};
var $author$project$Transactions$transactionRowMap = F2(
	function (committee, _v0) {
		var maybeMsg = _v0.b;
		var transaction = _v0.c;
		var name = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Transactions$missingContent,
			A2(
				$elm$core$Maybe$map,
				$author$project$Transactions$uppercaseText,
				$author$project$Transactions$getEntityName(transaction)));
		var entityType = $author$project$Transactions$getEntityType(transaction);
		var context = $author$project$Transactions$getContext(transaction);
		var amount = $author$project$Transactions$getAmount(transaction);
		return _Utils_Tuple2(
			maybeMsg,
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Date / Time',
					$elm$html$Html$text(
						A2(
							$author$project$Timestamp$format,
							$justinmimbs$timezone_data$TimeZone$america__new_york(0),
							transaction.kE))),
					_Utils_Tuple2('Entity Name', name),
					_Utils_Tuple2('Context', entityType),
					_Utils_Tuple2('Amount', amount),
					_Utils_Tuple2(
					'Verified',
					$author$project$Transactions$verifiedContent(transaction.lb)),
					_Utils_Tuple2(
					'Payment Source',
					$author$project$Transactions$toPaymentMethodOrProcessor(transaction)),
					_Utils_Tuple2(
					'Ref Code',
					$elm$html$Html$text(
						A2($elm$core$Maybe$withDefault, 'N/A', transaction.fz))),
					_Utils_Tuple2(
					'Status',
					$author$project$Transactions$getStatus(transaction))
				]));
	});
var $author$project$Transactions$viewInteractive = F3(
	function (committee, selectMsg, txns) {
		return A4(
			$author$project$DataTable$view,
			'Awaiting Transactions.',
			$author$project$Transactions$labels,
			$author$project$Transactions$transactionRowMap(committee),
			A2(
				$elm$core$List$map,
				function (t) {
					return _Utils_Tuple3(
						$elm$core$Maybe$Nothing,
						$elm$core$Maybe$Just(
							selectMsg(t)),
						t);
				},
				$elm$core$List$reverse(
					A2(
						$elm$core$List$sortBy,
						function ($) {
							return $.kE;
						},
						txns))));
	});
var $author$project$Page$Transactions$loadedView = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fade-in')
			]),
		_List_fromArray(
			[
				$author$project$Page$Transactions$dropdowns(model),
				A3($author$project$Transactions$viewInteractive, model.iD, $author$project$Page$Transactions$ShowTxnFormModal, model.lI),
				$author$project$Page$Transactions$moreTxnsButton(model),
				$author$project$Page$Transactions$createContributionModal(model),
				$author$project$Page$Transactions$generateDisclosureModal(model),
				$author$project$Page$Transactions$createDisbursementModal(model),
				$author$project$Page$Transactions$disbRuleUnverifiedModal(model),
				$author$project$Page$Transactions$disbRuleVerifiedModal(model),
				$author$project$Page$Transactions$contribRuleUnverifiedModal(model),
				$author$project$Page$Transactions$contribRuleVerifiedModal(model)
			]));
};
var $author$project$Page$Transactions$view = function (model) {
	return {
		ms: model.dt ? $author$project$Loading$view : $author$project$Page$Transactions$loadedView(model),
		lu: '4US'
	};
};
var $author$project$Main$view = function (model) {
	var config = $author$project$Main$toConfig(model);
	var committee = $author$project$Main$toCommittee(model);
	var aggregations = $author$project$Main$toAggregations(model);
	var viewPage = F3(
		function (page, toMsg, conf) {
			var _v1 = A5($author$project$Page$view, config, aggregations, committee, page, conf);
			var title = _v1.lu;
			var body = _v1.$9;
			return {
				$9: A2(
					$elm$core$List$map,
					$elm$html$Html$map(toMsg),
					body),
				lu: title
			};
		});
	switch (model.$) {
		case 1:
			return A5($author$project$Page$view, config, aggregations, committee, 0, $author$project$Page$Blank$view);
		case 0:
			return A5($author$project$Page$view, config, aggregations, committee, 0, $author$project$Page$NotFound$view);
		case 3:
			var transactions = model.a;
			return A3(
				viewPage,
				3,
				$author$project$Main$GotTransactionsMsg,
				$author$project$Page$Transactions$view(transactions));
		case 2:
			var linkBuilder = model.a;
			return A3(
				viewPage,
				2,
				$author$project$Main$GotLinkBuilderMsg,
				$author$project$Page$LinkBuilder$view(linkBuilder));
		default:
			var demo = model.a;
			return A3(
				viewPage,
				4,
				$author$project$Main$GotDemoMsg,
				$author$project$Page$Demo$view(demo));
	}
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{m6: $author$project$Main$init, nv: $author$project$Main$ChangedUrl, nw: $author$project$Main$ClickedLink, nV: $author$project$Main$subscriptions, n8: $author$project$Main$update, ob: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (token) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (redirectUri) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (donorUrl) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (cognitoDomain) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (cognitoClientId) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (apiEndpoint) {
													return $elm$json$Json$Decode$succeed(
														{ma: apiEndpoint, mm: cognitoClientId, mn: cognitoDomain, mC: donorUrl, k2: redirectUri, n6: token});
												},
												A2($elm$json$Json$Decode$field, 'apiEndpoint', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'cognitoClientId', $elm$json$Json$Decode$string));
								},
								A2($elm$json$Json$Decode$field, 'cognitoDomain', $elm$json$Json$Decode$string));
						},
						A2($elm$json$Json$Decode$field, 'donorUrl', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'redirectUri', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string)))(0)}});}(this));